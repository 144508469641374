import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Board from "react-trello";
import "../app.css";
import { db, auth } from "../../firebase";
import {
  updateDoc,
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  addDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

function Kanban() {
  const { id } = useParams();
  const userID = id;
  const [loading, setLoading] = useState(false);

  const [kanbanData, setKanbanData] = useState([]);
  const [gotData, setGotData] = useState(false);
  const getUserKanbanData = async (id) => {
    console.log('Getting Kanban Data')
    setGotData(false);
    const docRef = doc(db, "kanban", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setKanbanData(docSnap.data());
      setGotData(true);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      setKanbanData({
        lanes: [
          {
            id: 'lane1',
            title: 'To Do',
            label: '',
            cards: [
              {id: 'Card1', title: 'Add My First Card', description: 'Try it out!', label: 'Low Priority', draggable: true},
            ]
          },
          {
            id: 'lane2',
            title: 'In Progress',
            label: '',
            cards: []
          },
          {
            id: 'lane3',
            title: 'Completed',
            label: '',
            cards: []
          }
        ]
      })
      setGotData(true);
    }
  };

  const shouldReceiveNewData = async nextData => {
    console.log('Board has changed')
    console.log(nextData)
    await setDoc(doc(db, "kanban", userID), nextData);
  }

  const handleCardDelete = (cardId, laneId) => {
    console.log(`Card: ${cardId} deleted from lane: ${laneId}`)
  }

  const handleCardAdd = (card, laneId) => {
    console.log(`New card added to lane ${laneId}`)
    console.log(card)
  }

  useEffect(() => {
    if (loading === false) {
      if (gotData === false) getUserKanbanData(userID);
    }
  }, [db, gotData])
  

  return (
  <>
  {gotData === true && (
    <>
    <h1 className="title-1">My Kanban Board</h1>
    <Board
          data={kanbanData}
          draggable={true}
          editLaneTitle={true}
          canAddLanes
          id="EditableBoard1"
          onDataChange={shouldReceiveNewData}
          onCardDelete={handleCardDelete}
          onCardAdd={handleCardAdd}
          // onCardMoveAcrossLanes={syncData}
          // onConfirmCardDelete
          // onLaneAdd={syncData}
          // onLaneDelete={syncData}
          
          // onCardClick={(cardId, metadata, laneId) => alert(`Card with id:${cardId} clicked. Card in lane: ${laneId}`)}
          editable
          style={{backgroundColor: 'rgb(127 29 29)', width: "100%", borderRadius: "15px"}}
        />
    </>
  )} 
  </>
  );
}
export default Kanban;
