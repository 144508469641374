import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  where,
  orderBy,
  setDoc,
  updateDoc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import DatePicker from "react-multi-date-picker";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import "../app.css";
import axios from "axios";

function SickDayHistory(props) {
  const userInfo = props.userInfo;
  // Date Stuff
  function toDateTime(secs) {
    var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    t.setSeconds(secs);
    return t.toDateString();
  }

  //   Get Selected User's Info
  const [sickDays, setSickDays] = useState([]);
  const [gotSickDayHistory, setGotSickDayHistory] = useState(false);
  const getSickDayHistory = async (usersID) => {
    setSickDays([]);
    setGotSickDayHistory(false);
    const q = query(
      collection(db, "sickDays-requests"),
      where("requesterID", "==", usersID)
    );

    const querySnapshot = await getDocs(q);
    let values = [];
    querySnapshot.forEach((doc) => {
      values.push({
        ...doc.data(),
        id: doc.id,
        startDate: convertDateToShortString(doc.data().startDate.seconds),
        endDate: convertDateToShortString(doc.data().endDate.seconds),
      });
    });
    setSickDays(values);
    setGotSickDayHistory(true);
  };

  console.log(sickDays);

  const [showResponseSuccess, setShowResponseSuccess] = useState(false);

  const [ptoUpdated, setPtoUpdated] = useState(1);
  const updatePTO = async (e, requestID, newStatus) => {
    const ptoRef = doc(db, "sickDays-requests", requestID);

    setDoc(
      ptoRef,
      {
        status: newStatus,
        dismissedByUser: false,
        reviewedBy: auth.currentUser.displayName,
        reviewedOn: new Date(),
      },
      { merge: true }
    );
    await addDoc(collection(db, "system-activity"), {
      activityDate: new Date(),
      authorID: auth.currentUser.uid,
      authorName: auth.currentUser.displayName,
      description:
        "A time off request for " +
        props.selectedUserInfo.first +
        " " +
        props.selectedUserInfo.last +
        " was " +
        newStatus +
        " by " +
        auth.currentUser.displayName,
    });
  };

  const convertDateToShortString = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString().slice(3, 15);
  };

  const deleteThisSickDay = async (thisID) => {
    let verify = prompt(
      "You are about to delete a PTO entry! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "sickDays-requests", thisID));
      getSickDayHistory(props.selectedUserID);
    }
  };

  useEffect(() => {
    !gotSickDayHistory && getSickDayHistory(props.selectedUserID);
  }, [db, ptoUpdated, gotSickDayHistory]);

  return (
    <section>
      {gotSickDayHistory === true && (
        <>
          <div className="card mb-3 mt-3">
            <div className="card-header bg-gray-200">
              <button
                onClick={(e) =>
                  props.setDisplayUsersSickDaysData(
                    !props.displayUsersSickDaysData
                  )
                }
                className="text-lg font-bold"
              >
                {props.selectedUserInfo?.first} {props.selectedUserInfo?.last}
                's Sick Days
              </button>
            </div>
            {props.displayUsersSickDaysData && (
              <div className="card-body">
                <Table className="data-table">
                  <Thead>
                    <Tr>
                      <Td className="bg-red-900 text-white">Submitted</Td>
                      <Td className="bg-red-900 text-white">Reason</Td>
                      <Td className="bg-red-900 text-white">Start/End</Td>
                      <Td className="bg-red-900 text-white">Half Day</Td>
                      <Td className="bg-red-900 text-white">Total Days</Td>
                      <Td className="bg-red-900 text-white">Doctors Note</Td>
                      {userInfo.admin === true && (
                        <Td className="bg-red-900 text-white">Delete</Td>
                      )}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {/* Map User Sick Day History */}
                    {sickDays.map((sickDay, i) => {
                      return (
                        <Tr key={"sickDay" + sickDay.id}>
                          <Td>
                            {convertDateToShortString(
                              sickDay.requestDate.seconds
                            )}
                          </Td>
                          <Td>{sickDay.reason}</Td>

                          {/* Map Request Dates */}
                          <Td>
                            <p className="d-block">{sickDay.startDate}</p>
                            <p className="d-block">{sickDay.endDate}</p>
                          </Td>
                          <Td>{sickDay.halfSickDay}</Td>
                          <Td>{sickDay.totalDays}</Td>
                          <Td>
                            {sickDay?.noteProvided}
                          </Td>
                          {userInfo.admin === true && (
                            <Td>
                              <button
                                onClick={(e) =>
                                  deleteThisSickDay(sickDay.id)
                                }
                                className="text-red-600"
                              >
                                Delete
                              </button>
                            </Td>
                          )}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
}

export default SickDayHistory;
