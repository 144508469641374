import React, { useState, useEffect } from "react";
import { UserAuth } from "../Context/AuthContent";
import {
  getDoc,
  collection,
  getDocs,
  updateDoc,
  doc,
  where,
  query,
  setDoc,
  FieldPath,
} from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { db, auth } from "../firebase";
import NumericInput from "react-numeric-input";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./app.css";
import CompanyInfo from "./CompanyInfo";
import { setDate } from "date-fns/esm";
import { set } from "lodash";
import { Beforeunload, useBeforeunload } from "react-beforeunload";

// Set Up For Tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Exported Function
function EditFactfinder(props) {
  //  Date Converter
  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output;
  };

  // Child Data
  const [childData, setChildData] = useState({});

  // Tabs Stuff
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    updateFactfinder();
    updateCommodityTotal();
  };

  // Date Stuff
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;
  let navigate = useNavigate();

  // Get Params and Set Company ID from props if not in params
  let { id, companyID } = useParams();
  if (!companyID) {
    companyID = props.companyID;
  }

  const factfinderRef = doc(db, "Factfinders", id);

  // Handle Form Stuff
  const saveNewFactfinder = async (e) => {
    setDoc(
      factfinderRef,
      { ...formData, dateCreated: date, lastUpdated: date },
      { merge: true }
    );
  };

  const handleBlur = async (e) => {
    e.preventDefault();
    // if (e.target.value === "") {
    //   e.target.className = "border p-3 text-xl font-medium";
    // } else {
    //   e.target.className = "text-xl font-medium border-0 p-3";
    // }

    setDoc(factfinderRef, { ...formData, lastUpdated: date }, { merge: true });
  };

  const handleBlurCommodity = async (e, i) => {
    e.preventDefault();
    const values = [...commodityFields];
    // if (e.target.value === "") {
    //   e.target.className = "border p-3 text-xl font-medium";
    // } else {
    //   e.target.className = "text-xl font-medium border-0 p-3";
    //   updateCommodityTotal();
    // }
    await updateDoc(factfinderRef, { commodityFields: values });
  };

  const handleSelectBlur = async (e) => {
    e.preventDefault();
    // if (e.target.value === "Choose One") {
    //   e.target.className = "border p-3 bg-red-100";
    // } else {
    //   e.target.className = "text-xl font-medium border-0 p-3";
    // }
    setDoc(factfinderRef, { ...formData, lastUpdated: date }, { merge: true });
  };

  const [fields, setFields] = useState([
    {
      id: 1,
      routeDescription: "",
    },
  ]);

  const handleAdd = () => {
    const i = fields.length;
    const lastID = fields[i - 1].id;
    setFields([...fields, { id: lastID + 1, routeDescription: "" }]);
  };

  const handleSubtract = async (e, i) => {
    e.preventDefault();
    const values = [...fields];
    values.splice(i, 1);
    setFields([...values]);
    if (values.length === 0) {
      setFields([
        {
          id: 1,
          routeDescription: "",
        },
      ]);
      setDedicatedRoutes("No");
    }
    await updateDoc(factfinderRef, { fields: values });
  };

  const handleChangeInput = (i, e) => {
    const values = [...fields];
    values[i][e.target.name] = e.target.value;
    setFields(values);
  };

  const [commodityFields, setCommodityFields] = useState([
    {
      id: 1,
      commodityDescription: "",
      commodityLoad: 0,
    },
  ]);

  const handleAddCommodity = () => {
    const i = commodityFields.length;
    const lastID = commodityFields[i - 1].id;
    setCommodityFields([
      ...commodityFields,
      { id: lastID + 1, commodityDescription: "", commodityLoad: 0 },
    ]);
  };

  const handleSubtractCommodity = async (e, i) => {
    e.preventDefault();
    const values = [...commodityFields];
    values.splice(i, 1);
    setCommodityFields([...values]);
    if (values.length === 0) {
      setCommodityFields([
        {
          id: 1,
          commodityDescription: "",
          commodityLoad: 0,
        },
      ]);
    }
    await updateDoc(factfinderRef, { commodityFields: values });
  };

  const handleChangeInputCommodity = (i, e) => {
    const values = [...commodityFields];
    values[i][e.target.name] = e.target.value;
    setCommodityFields(values);
  };

  const updateCommodityTotal = () => {
    let total = 0;
    commodityFields?.forEach((element) => {
      total = parseInt(total, 10) + parseInt(element.commodityLoad, 10);
    });
    setCommodityTotal(parseInt(total, 10));
  };

  const [violationFields, setViolationFields] = useState([
    {
      id: 1,
      dateOfViolation: "",
      locationOfViolation: "",
      driverName: "",
      movingViolation: false,
      description: "",
    },
  ]);

  const handleAddViolation = () => {
    const i = violationFields.length;
    const lastID = violationFields[i - 1].id;
    setViolationFields([
      ...violationFields,
      {
        id: lastID + 1,
        dateOfViolation: "",
        locationOfViolation: "",
        driverName: "",
        movingViolation: false,
        violationDescription: "",
      },
    ]);
  };

  const handleSubtractViolation = async (e, i) => {
    e.preventDefault();
    const values = [...violationFields];
    values.splice(i, 1);
    setViolationFields([...values]);
    if (values.length === 0) {
      setViolationFields([
        {
          id: 1,
          dateOfViolation: "",
          locationOfViolation: "",
          driverName: "",
          movingViolation: false,
          violationDescription: "",
        },
      ]);
      setDriverViolations("No");
    }
    await updateDoc(factfinderRef, { violationFields: values });
  };

  const handleChangeInputViolation = (i, e) => {
    const values = [...violationFields];
    values[i][e.target.name] = e.target.value;
    setViolationFields(values);
  };

  const [operationFields, setOperationFields] = useState([
    {
      id: 1,
      operationName: "",
    },
  ]);

  const handleAddOperation = () => {
    const i = operationFields.length;
    const lastID = operationFields[i - 1].id;
    setOperationFields([
      ...operationFields,
      { id: lastID + 1, operationName: "" },
    ]);
  };

  const handleSubtractOperation = async (e, i) => {
    e.preventDefault();
    const values = [...operationFields];
    values.splice(i, 1);
    setOperationFields([...values]);
    if (values.length === 0) {
      setOperationFields([
        {
          id: 1,
          operationName: "",
        },
      ]);
      setOtherOperations("No");
    }
    await updateDoc(factfinderRef, { operationFields: values });
  };

  const handleChangeInputOperation = (i, e) => {
    const values = [...operationFields];
    values[i][e.target.name] = e.target.value;
    setOperationFields(values);
  };

  const [carrierFields, setCarrierFields] = useState([
    {
      id: 1,
      carrierName: "",
    },
  ]);

  const handleAddCarrier = () => {
    const i = carrierFields.length;
    const lastID = carrierFields[i - 1].id;
    setCarrierFields([...carrierFields, { id: lastID + 1, carrierName: "" }]);
  };

  const handleSubtractCarrier = async (e, i) => {
    e.preventDefault();
    const values = [...carrierFields];
    values.splice(i, 1);
    setCarrierFields([...values]);
    if (values.length === 0) {
      setCarrierFields([
        {
          id: 1,
          carrierName: "",
        },
      ]);
      setOtherMotorCarriers("No");
    }
    await updateDoc(factfinderRef, { carrierFields: values });
  };

  const handleChangeInputCarrier = (i, e) => {
    const values = [...carrierFields];
    values[i][e.target.name] = e.target.value;
    setCarrierFields(values);
  };

  const [claimFields, setClaimFields] = useState([
    {
      id: 1,
      dateOfClaim: "",
      bodilyInjury: false,
      propertyDamage: false,
      collision: false,
      otherLoss: false,
      locationOfClaim: "",
      atFault: false,
      claimDescription: "",
    },
  ]);

  const handleAddClaim = (id) => {
    const i = claimFields.length;
    const lastID = claimFields[i - 1].id;
    setClaimFields([
      ...claimFields,
      {
        id: lastID + 1,
        dateOfClaim: "",
        bodilyInjury: false,
        propertyDamage: false,
        collision: false,
        otherLoss: false,
        locationOfClaim: "",
        atFault: false,
        description: "",
      },
    ]);
  };

  const handleSubtractClaim = async (e, i) => {
    e.preventDefault();
    const values = [...claimFields];
    values.splice(i, 1);
    setClaimFields([...values]);
    if (values.length === 0) {
      setClaimFields([
        {
          id: 1,
          dateOfClaim: "",
          bodilyInjury: false,
          propertyDamage: false,
          collision: false,
          otherLoss: false,
          locationOfClaim: "",
          atFault: false,
          description: "",
        },
      ]);
      setClaimsPastTwoYears("No");
    }
    await updateDoc(factfinderRef, { claimFields: values });
  };

  const handleChangeInputClaim = (i, e) => {
    const values = [...claimFields];
    values[i][e.target.name] = e.target.value;
    setClaimFields(values);
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  // Factfinder Info
  const [factfinderStatus, setFactfinderStatus] = useState("Unsubmitted");
  const [dateCreated, setDateCreated] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  // Commodity
  const [commodityTotal, setCommodityTotal] = useState(0);
  // Signer Info
  const [signerName, setSignerName] = useState("");
  const [signerEmail, setSignerEmail] = useState("");
  //   Company Info
  const [company, setCompany] = useState("");
  const [authorID, setAuthorID] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [dot, setDot] = useState("");
  const [mc, setMc] = useState("");
  //   Safety Officer Info
  const [safetyOfficer, setSafetyOfficer] = useState("");
  const [safetyOfficerPhone, setSafetyOfficerPhone] = useState("");
  const [safetyOfficerEmail, setSafetyOfficerEmail] = useState("");
  const [safetyOfficerTitle, setSafetyOfficerTitle] = useState("");
  //   Operations Info
  const [dedicatedRoutes, setDedicatedRoutes] = useState("Choose One");
  const [yearsInBusiness, setYearsInBusiness] = useState(0);
  const [hazardousCargo, setHazardousCargo] = useState("Choose One");
  const [hazardousCargoPercentage, setHazardousCargoPercentage] = useState(0);
  const [hazardousCargoType, setHazardousCargoType] = useState("");
  const [safetyManual, setSafetyManual] = useState("Choose One");
  const [safetyManualUpdated, setSafetyManualUpdated] = useState("");
  const [hiringProcedures, setHiringProcedures] = useState("Choose One");
  const [claimsPastTwoYears, setClaimsPastTwoYears] = useState("Choose One");
  const [pullDoubles, setPullDoubles] = useState("Choose One");
  const [pullTriples, setPullTriples] = useState("Choose One");
  const [otherMotorCarriers, setOtherMotorCarriers] = useState("Choose One");
  const [ownerOperators, setOwnerOperators] = useState("Choose One");
  const [workersComp, setWorkersComp] = useState("Choose One");
  const [otherOperations, setOtherOperations] = useState("Choose One");
  const [driverViolations, setDriverViolations] = useState("Choose One");
  const [allCommercialEquip, setAllCommercialEquip] = useState("Choose One");
  const [cameras, setCameras] = useState("Choose One");
  const [cameraDetails, setCameraDetails] = useState("");
  const [GPS, setGPS] = useState("Choose One");
  const [GPSWhere, setGPSWhere] = useState("Choose One");
  const [collisionWarning, setCollisionWarning] = useState("Choose One");
  const [terrorismCoverage, setTerrorismCoverage] = useState("Choose One");
  const [motorCarrier, setMotorCarrier] = useState("Choose One");
  const [alreadyMotorCarrier, setAlreadyMotorCarrier] = useState("Choose One");
  const [singlePowerUnit, setSinglePowerUnit] = useState("Choose One");
  const [howManyOwnerOperators, setHowManyOwnerOperators] = useState(0);
  const [workersCompCarrier, setWorkersCompCarrier] = useState("");
  const [allCommercialEquipDetails, setAllCommercialEquipDetails] =
    useState("");
  const [motorCarrierSince, setMotorCarrierSince] = useState(new Date());
  // Checklist
  const [presentation, setPresentation] = useState(false);
  const [experience, setExperience] = useState(false);
  const [offices, setOffices] = useState(false);
  const [service, setService] = useState(false);
  const [training, setTraining] = useState(false);
  const [markets, setMarkets] = useState(false);
  const [clients, setClients] = useState(false);
  const [system, setSystem] = useState(false);
  const [fifty, setFifty] = useState(false);
  const [oneHundred, setOneHundred] = useState(false);
  const [twoFifty, setTwoFifty] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const [eightHundred, setEightHundred] = useState(false);
  const [oneThousand, setOneThousand] = useState(false);
  const [twelveHundred, setTwelveHundred] = useState(false);
  const [fifteenHundred, setFifteenHundred] = useState(false);
  const [overFifteenHundred, setOverFifteenHundred] = useState(false);
  const [generalLiability, setGeneralLiability] = useState(false);
  const [autoLiability, setAutoLiability] = useState(false);
  const [excessLiability, setExcessLiability] = useState(false);
  const [cargo, setCargo] = useState(false);
  const [physicalDamage, setPhysicalDamage] = useState(false);
  const [nonOwnedTruck, setNonOwnedTruck] = useState(false);
  const [nonOwnedTrailer, setNonOwnedTrailer] = useState(false);
  const [trailerInterchange, setTrailerInterchange] = useState(false);
  const [reefer, setReefer] = useState(false);
  const [hazmat, setHazmat] = useState(false);
  const [hasWorkersComp, setHasWorkersComp] = useState(false);
  // Checklist Fields
  const [GLCoverageAmount, setGLCoverageAmount] = useState("");
  const [ALCoverageAmount, setALCoverageAmount] = useState("");
  const [ELCoverageAmount, setELCoverageAmount] = useState("");
  const [ELDeductibleAmount, setELDeductibleAmount] = useState("");
  const [cargoCoverageAmount, setCargoCoverageAmount] = useState("");
  const [cargoDeductibleAmount, setCargoDeductibleAmount] = useState("");
  const [APDCoverageAmount, setAPDCoverageAmount] = useState("");
  const [APDDeductbileAmount, setAPDDeductibleAmount] = useState("");
  const [nonOwnedTrailerCoverageAmount, setNonOwnedTrailerCoverageAmount] =
    useState("");
  const [nonOwnedTruckCoverageAmount, setNonOwnedTruckCoverageAmount] =
    useState("");
  const [TICoverageAmount, setTICoverageAmount] = useState("");
  const [totalTripsPerMonth, setTotalTripsPerMonth] = useState("");
  const [tripsRequiringTI, setTripsRequiringTI] = useState("");
  const [specialCoverageDetails, setSpecialCoverageDetails] = useState("");
  const [hasSpecialCoverage, setHasSpecialCoverage] = useState("");

  const formData = {
    signerName,
    signerEmail,
    authorID,
    authorName,
    factfinderStatus,
    companyName,
    company,
    dot,
    mc,
    safetyOfficer,
    safetyOfficerTitle,
    safetyOfficerPhone,
    safetyOfficerEmail,
    dedicatedRoutes,
    yearsInBusiness,
    hazardousCargo,
    hazardousCargoPercentage,
    hazardousCargoType,
    safetyManual,
    safetyManualUpdated,
    hiringProcedures,
    claimsPastTwoYears,
    claimFields,
    pullDoubles,
    pullTriples,
    otherMotorCarriers,
    carrierFields,
    ownerOperators,
    workersComp,
    otherOperations,
    operationFields,
    driverViolations,
    violationFields,
    commodityFields,
    allCommercialEquip,
    allCommercialEquipDetails,
    cameras,
    cameraDetails,
    GPS,
    GPSWhere,
    collisionWarning,
    terrorismCoverage,
    motorCarrier,
    alreadyMotorCarrier,
    motorCarrierSince,
    singlePowerUnit,
    howManyOwnerOperators,
    workersCompCarrier,
    fields,
    presentation,
    experience,
    offices,
    service,
    training,
    markets,
    clients,
    system,
    fifty,
    oneHundred,
    twoFifty,
    fiveHundred,
    eightHundred,
    oneThousand,
    twelveHundred,
    fifteenHundred,
    overFifteenHundred,
    generalLiability,
    autoLiability,
    excessLiability,
    cargo,
    physicalDamage,
    nonOwnedTruck,
    nonOwnedTrailer,
    trailerInterchange,
    reefer,
    hazmat,
    hasWorkersComp,
    GLCoverageAmount,
    ALCoverageAmount,
    ELCoverageAmount,
    ELDeductibleAmount,
    cargoCoverageAmount,
    cargoDeductibleAmount,
    APDCoverageAmount,
    APDDeductbileAmount,
    nonOwnedTrailerCoverageAmount,
    nonOwnedTruckCoverageAmount,
    TICoverageAmount,
    totalTripsPerMonth,
    tripsRequiringTI,
    hasSpecialCoverage,
    specialCoverageDetails,
  };

  // Docusign Signature Request
  const getSignature = (e) => {
    e.preventDefault();
    var data = formData;
    var url = " http://localhost:9000/docusign-factfinder";
    var issues = document.querySelector("#issues");
    // Uncomment this to add validation back
    // if (issues.textContent.length === 0) {
      axios
        .post(url, data)
        .then((response) => console.log(response))
        .catch((e) => console.log(e))
        .then(alert("Signature Requested"));
    // } else {
    //   alert(
    //     "You may not yet submit, please see issues listed on the Submit tab."
    //   );
    // }
  };

  // Queitly Save the Factfinder in the Background
  const updateFactfinder = async (e) => {
    setDoc(factfinderRef, { ...formData, lastUpdated: date }, { merge: true });
  };

  // Save the Factfinder and Return to Account Page
  const saveFactfinder = async (e) => {
    e.preventDefault();
    setDoc(factfinderRef, { ...formData, lastUpdated: date }, { merge: true });
    navigate("/account");
  };

  // Get factfinder info
  const [myData, setMyData] = useState({});
  const [gotFactfinder, setGotFactfinder] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const getFactfinder = async (id) => {
    setLoading(true);
    // If new factfinder, then input the core data
    if (props.newFactfinder === "true") {
      console.log("New factfinder");
      setCompany(props.companyID);
      setCompanyName(props.companyName);
      setMc(props.mc);
      setDot(props.dot);
      setDateCreated(date);
      setAuthorID(auth.currentUser.uid);
      setAuthorName(auth.currentUser.displayName);
      saveNewFactfinder();
    } else {
      // If not a new factfinder, then populate the data
      try {
        console.log("Getting factfinder");
        const docSnap = await getDoc(factfinderRef);
        const data = docSnap.data();

        setMyData(data);
        setSignerName(myData?.signerName);
        setSignerEmail(myData?.signerEmail);
        setCompanyName(myData?.companyName);
        setDot(myData?.dot);
        setMc(myData?.mc);
        setDateCreated(myData?.dateCreated);
        setLastUpdated(myData?.lastUpdated);
        setAuthorName(myData?.authorName);
        setAuthorID(myData?.authorID);
        setFactfinderStatus(myData?.factfinderStatus);
        setCompany(myData?.company);
        setSafetyOfficer(myData?.safetyOfficer);
        setSafetyOfficerPhone(myData?.safetyOfficerPhone);
        setSafetyOfficerEmail(myData?.safetyOfficerEmail);
        setSafetyOfficerTitle(myData?.safetyOfficerTitle);
        setDedicatedRoutes(myData?.dedicatedRoutes);
        setYearsInBusiness(myData?.yearsInBusiness);
        setHazardousCargo(myData?.hazardousCargo);
        setHazardousCargoPercentage(myData?.hazardousCargoPercentage);
        setHazardousCargoType(myData?.hazardousCargoType);
        setSafetyManual(myData?.safetyManual);
        setSafetyManualUpdated(myData?.safetyManualUpdated);
        setHiringProcedures(myData?.hiringProcedures);
        setClaimsPastTwoYears(myData?.claimsPastTwoYears);
        setPullDoubles(myData?.pullDoubles);
        setPullTriples(myData?.pullTriples);
        setOtherMotorCarriers(myData?.otherMotorCarriers);
        setOwnerOperators(myData?.ownerOperators);
        setWorkersComp(myData?.workersComp);
        setOtherOperations(myData?.otherOperations);
        setDriverViolations(myData?.driverViolations);
        setAllCommercialEquip(myData?.allCommercialEquip);
        setAllCommercialEquipDetails(myData?.allCommercialEquipDetails);
        setCameras(myData?.cameras);
        setGPS(myData?.GPS);
        setGPSWhere(myData?.GPSWhere);
        setCollisionWarning(myData?.collisionWarning);
        setTerrorismCoverage(myData?.terrorismCoverage);
        setMotorCarrier(myData?.motorCarrier);
        setAlreadyMotorCarrier(myData?.alreadyMotorCarrier);
        setSinglePowerUnit(myData?.singlePowerUnit);
        setHowManyOwnerOperators(myData?.howManyOwnerOperators);
        setWorkersCompCarrier(myData?.workersCompCarrier);
        setCameraDetails(myData?.cameraDetails);
        setMotorCarrierSince(convertDate(myData?.motorCarrierSince.seconds));
        setClaimFields(myData?.claimFields);
        setCarrierFields(myData?.carrierFields);
        setViolationFields(myData?.violationFields);

        setOperationFields(myData?.operationFields);
        setFields(myData?.fields);
        setCommodityFields(myData?.commodityFields);

        // Checklist
        setPresentation(myData?.presentation);
        setExperience(myData?.experience);
        setOffices(myData?.offices);
        setService(myData?.service);
        setTraining(myData?.training);
        setMarkets(myData?.markets);
        setClients(myData?.clients);
        setSystem(myData?.system);
        setFifty(myData?.fifty);
        setOneHundred(myData?.oneHundred);
        setTwoFifty(myData?.twoFifty);
        setFiveHundred(myData?.fiveHundred);
        setEightHundred(myData?.eightHundred);
        setOneThousand(myData?.oneThousand);
        setTwelveHundred(myData?.twelveHundred);
        setFifteenHundred(myData?.fifteenHundred);
        setOverFifteenHundred(myData?.overFifteenHundred);
        setGeneralLiability(myData?.generalLiability);
        setGLCoverageAmount(myData?.GLCoverageAmount);
        setAutoLiability(myData?.autoLiability);
        setALCoverageAmount(myData?.ALCoverageAmount);
        setExcessLiability(myData?.excessLiability);
        setELCoverageAmount(myData?.ELCoverageAmount);
        setELDeductibleAmount(myData?.ELDeductibleAmount);
        setCargo(myData?.cargo);
        setCargoCoverageAmount(myData?.cargoCoverageAmount);
        setCargoDeductibleAmount(myData?.cargoDeductibleAmount);
        setPhysicalDamage(myData?.physicalDamage);
        setAPDCoverageAmount(myData?.APDCoverageAmount);
        setAPDDeductibleAmount(myData?.APDDeductbileAmount);
        setNonOwnedTruck(myData?.nonOwnedTruck);
        setNonOwnedTruckCoverageAmount(myData?.nonOwnedTruckCoverageAmount);
        setNonOwnedTrailer(myData?.nonOwnedTrailer);
        setNonOwnedTrailerCoverageAmount(myData?.nonOwnedTrailerCoverageAmount);
        setTrailerInterchange(myData?.trailerInterchange);
        setTICoverageAmount(myData?.TICoverageAmount);
        setTotalTripsPerMonth(myData?.totalTripsPerMonth);
        setTripsRequiringTI(myData?.tripsRequiringTI);
        setReefer(myData?.reefer);
        setHazmat(myData?.hazmat);
        setHasWorkersComp(myData?.hasWorkersComp);
        setSpecialCoverageDetails(myData?.specialCoverageDetails);
        setHasSpecialCoverage(myData?.hasSpecialCoverage);
      } catch (err) {
        console.log(err);
      }
    }
  };

  // Commodity List For Predictive Text
  const commodityPredictList = [
    "Electronics",
    "Beer/Wine",
    "Alcohol",
    "Eggs",
    "Seafood",
    "Meats/Poultry",
    "Motor Vehicles",
    "Containerized Freight",
    "Mobile Equipment",
    "Tobacco/Cigars",
    "Clothing",
    "Tires",
    "Metals/Coils",
    "Copper",
    "Heavy Machinery",
    "Non-Ferrous Metal",
    "In Scrap and/or Ingo",
    "In-Hook",
    "Documents",
    "Pharmaceuticals",
    "Precious Stones/Jewelry",
    "Marijuana Based Products",
    "Live Animals",
    "Art (Any Type)",
    "Fur",
    "Auto Parts",
    "Canned/Dry Food",
    "Furniture",
  ];

  // Check User's Permissions
  const [checkedPerms, setCheckedPerms] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);
  const checkPermissions = () => {
    console.log("Checking permissions");
    if (myData?.factfinderStatus === "Submitted") {
      setDisabled(true);
      console.log(
        "You may not edit this document because it has already been submitted."
      );
    } else {
      console.log("Factfinder Unsubmitted...");
    }
    if (myData.authorID !== auth.currentUser.uid) {
      console.log(
        "identifier =>" +
          auth.currentUser.uid +
          " compared to " +
          myData.authorID
      );
      setDisabled(true);
      setIsAuthor(false);
      console.log(
        "You may not edit this document because you are not the author."
      );
    } else {
      setIsAuthor(true);
      console.log("You are the original author and may edit this document.");
    }
    setCheckedPerms(true);
  };

  // User Info
  const [userInfo, setUserInfo] = useState({});
  const [gotUserInfo, setGotUserInfo] = useState(false);
  const getUserInfo = async (usersID) => {
    const docRef = doc(db, "users", usersID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Got User Info");
      setUserInfo(docSnap.data());
      setGotUserInfo(true);
    } else {
      // doc.data() will be undefined in this case
      console.log("Could not get user info");
    }
    console.log("user role => " + userInfo.role);
  };

  useEffect(() => {
    if (id && gotFactfinder === false) {
      getFactfinder(id)
        .then(() => {
          setLoading(false);
        })
        .then(() => {
          if (checkedPerms === false && myData.authorID) {
            checkPermissions();
          }
        })
        .then(() => {
          if (gotUserInfo === false) {
            getUserInfo(auth.currentUser.uid);
          }
        })
        .then(() => {});
    }
  }, [
    id,
    myData?.companyName,
    myData?.authorID,
    props,
    props?.companyData?.companyName,
    companyID,
    props.companyName,
    gotUserInfo,
  ]);

  if (!loading) {
    return (
      <fieldset>
        <Beforeunload onBeforeunload={() => "You’ll lose your data!"} />
        <Container className="bg-gray-100 h-screen" fluid>
          <Row>
            <Col md="12" sm="12" className="bg-gray-100">
              <Box sx={{ width: "100%", height: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Factfinder Tabs"
                    // disable the tab indicator because it doesn't work well with wrapped container
                    // TabIndicatorProps={{ sx: { display: "none" } }}
                    indicatorColor="black"
                    sx={{
                      "& .MuiTabs-flexContainer": {
                        flexWrap: "wrap",
                        backgroundColor: "lightGray",
                        marginTop: "5px",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <Tab label="Company" {...a11yProps(0)} />
                    <Tab label="Checklist" {...a11yProps(1)} />
                    <Tab label="Safety" {...a11yProps(2)} />
                    <Tab label="Operations" {...a11yProps(3)} />
                    <Tab label="Employees" {...a11yProps(4)} />
                    <Tab label="Equipment" {...a11yProps(5)} />
                    <Tab label="Cargo" {...a11yProps(6)} />
                    <Tab label="Commodities" {...a11yProps(7)} />
                    <Tab label="Insurance" {...a11yProps(8)} />
                    <Tab label="Submit" {...a11yProps(9)} />
                  </Tabs>
                </Box>
                {/* Signer */}
                <TabPanel value={value} index={0} className="bg-gray-100">
                  <section>
                    <div
                      id="safetyOfficerInfo"
                      className="max-w-[650px] mx-auto p-2"
                    >
                      <h1 className="text-xl font-medium text-center mb-1">
                        Factfinder Created by {authorName}
                      </h1>
                      {props.newFactfinder != "true" && (
                        <>
                          {isAuthor === false && (
                            <>
                              <div className="text-center bg-red-200 p-2 mb-2">
                                <h1 className="text-xl font-medium">
                                  Only the original author of this document can
                                  make changes.
                                </h1>
                              </div>
                            </>
                          )}
                          {userInfo.role === "Admin" && (
                            <div className="text-center bg-red-200 p-2 mb-2">
                              <h1 className="text-xl font-medium">
                                You are an administrator,
                                {disabled === true && (
                                  <>
                                    <button
                                      onClick={() => setDisabled(false)}
                                      className="p-1"
                                    >
                                      click here
                                    </button>
                                    to edit this document
                                  </>
                                )}
                                {disabled === false && (
                                  <>
                                    <button
                                      onClick={() => setDisabled(true)}
                                      className="p-1"
                                    >
                                      click here
                                    </button>
                                    to disable editing
                                  </>
                                )}
                              </h1>
                            </div>
                          )}
                        </>
                      )}

                      <h1 className="text-lg font-bold text-red-900 w-full text-center">
                        Company & Signer Information
                      </h1>
                      {companyID !== "0" && (
                        <>
                          <CompanyInfo
                            companyID={companyID}
                            passChildData={setChildData}
                          />
                        </>
                      )}

                      <div>
                        <label className="input-label">Signer Name:</label>
                        <input
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => setSignerName(e.target.value)}
                          onBlur={(e) => handleBlur(e)}
                          className={`text-input w-full ${
                            signerName === "" && `bg-red-100`
                          }`}
                          type="text"
                          value={signerName}
                        />
                      </div>
                      <div>
                        <label className="input-label">Signer Email:</label>
                        <input
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => setSignerEmail(e.target.value)}
                          onBlur={handleBlur}
                          className={`text-input w-full ${
                            signerEmail === "" && `bg-red-100`
                          }`}
                          type="text"
                          value={signerEmail}
                        />
                      </div>
                    </div>
                  </section>
                  <article className="back-next-container">
                    <button disabled className="button-1">
                      Back
                    </button>
                    <button
                      onClick={() => setValue(value + 1)}
                      className="button-2"
                    >
                      Next
                    </button>
                  </article>
                </TabPanel>
                {/* Checklist */}
                <TabPanel value={value} index={1} className="bg-gray-100">
                  <Container fluid className="no-pad">
                    <Row className="no-pad">
                      <Col className="no-pad" sm={12} md={12} lg={5}>
                        {/* What You Need To Say */}
                        <section className="max-w-[600px] mb-4">
                          <Container fluid className="no-pad">
                            <Row className="no-pad">
                              <Col className="no-pad">
                                <h1 className="text-xl font-bold text-red-900 w-full mb-3">
                                  What you need to say:
                                </h1>
                                <ul className="list">
                                  <li>
                                    <input
                                      id="presentation"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={presentation}
                                      onChange={() => {
                                        if (presentation === true) {
                                          setPresentation(false);
                                        } else {
                                          setPresentation(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="presentation"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Presentation
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      id="experience"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={experience}
                                      onChange={() => {
                                        if (experience === true) {
                                          setExperience(false);
                                        } else {
                                          setExperience(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="experience"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      50 Years Experience
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      id="offices"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={offices}
                                      onChange={() => {
                                        if (offices === true) {
                                          setOffices(false);
                                        } else {
                                          setOffices(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="offices"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Mention All Our Offices
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      id="service"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={service}
                                      onChange={() => {
                                        if (service === true) {
                                          setService(false);
                                        } else {
                                          setService(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="service"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      We Focus On Customer Service
                                    </label>
                                  </li>
                                  <li>
                                    {/* <div className="item"> */}
                                    <input
                                      id="training"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={training}
                                      onChange={() => {
                                        if (training === true) {
                                          setTraining(false);
                                        } else {
                                          setTraining(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="training"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Safety Training for Certain Clients
                                    </label>
                                    {/* </div> */}
                                  </li>
                                  <li>
                                    <input
                                      id="markets"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={markets}
                                      onChange={() => {
                                        if (markets === true) {
                                          setMarkets(false);
                                        } else {
                                          setMarkets(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="markets"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Access to Different Markets
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      id="clients"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={clients}
                                      onChange={() => {
                                        if (clients === true) {
                                          setClients(false);
                                        } else {
                                          setClients(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="clients"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Mention Our Clients
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      id="system"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={system}
                                      onChange={() => {
                                        if (system === true) {
                                          setSystem(false);
                                        } else {
                                          setSystem(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="system"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Mention Our System
                                    </label>
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </Container>
                        </section>
                        {/* Routes */}
                        <section className="mb-4 max-w-[600px]">
                          <Container fluid className="no-pad">
                            <Row className="no-pad">
                              <Col className="no-pad">
                                <h1 className="text-xl font-bold text-red-900 w-full">
                                  Routes:
                                </h1>
                                <p>
                                  Please select the mile radius ranges that the
                                  company operates within:
                                </p>
                              </Col>
                            </Row>
                          </Container>

                          <Container fluid className="no-pad">
                            <Row className="no-pad">
                              <Col className="no-pad" sm={12} md={6}>
                                <ul className="list">
                                  <li>
                                    <input
                                      id="0-50"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={fifty}
                                      onChange={() => {
                                        if (fifty === true) {
                                          setFifty(false);
                                        } else {
                                          setFifty(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="0-50"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      0 - 50
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      id="51-100"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={oneHundred}
                                      onChange={() => {
                                        if (oneHundred === true) {
                                          setOneHundred(false);
                                        } else {
                                          setOneHundred(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="51-100"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      51 - 100
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      id="101-250"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={twoFifty}
                                      onChange={() => {
                                        if (twoFifty === true) {
                                          setTwoFifty(false);
                                        } else {
                                          setTwoFifty(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="101-250"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      101 - 250
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      id="251-500"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={fiveHundred}
                                      onChange={() => {
                                        if (fiveHundred === true) {
                                          setFiveHundred(false);
                                        } else {
                                          setFiveHundred(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="251-500"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      251 - 500
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      id="501-800"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={eightHundred}
                                      onChange={() => {
                                        if (eightHundred === true) {
                                          setEightHundred(false);
                                        } else {
                                          setEightHundred(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="501-800"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      501 - 800
                                    </label>
                                  </li>
                                </ul>
                              </Col>
                              <Col className="no-pad" sm={12} md={6}>
                                <ul className="secondary-list">
                                  <li>
                                    <input
                                      id="801-1000"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={oneThousand}
                                      onChange={() => {
                                        if (oneThousand === true) {
                                          setOneThousand(false);
                                        } else {
                                          setOneThousand(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="801-1000"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      801 - 1,000
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      id="1001-1200"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={twelveHundred}
                                      onChange={() => {
                                        if (twelveHundred === true) {
                                          setTwelveHundred(false);
                                        } else {
                                          setTwelveHundred(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="1001-1200"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      1,001 - 1,200
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      id="1201-1500"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={fifteenHundred}
                                      onChange={() => {
                                        if (fifteenHundred === true) {
                                          setFifteenHundred(false);
                                        } else {
                                          setFifteenHundred(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="1201-1500"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      1,201 - 1,500
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      id="1500+"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={overFifteenHundred}
                                      onChange={() => {
                                        if (overFifteenHundred === true) {
                                          setOverFifteenHundred(false);
                                        } else {
                                          setOverFifteenHundred(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="1500+"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      1,501+
                                    </label>
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </Container>
                          <Container fluid className="no-pad">
                            <Row className="no-pad">
                              <Col className="no-pad">
                                <div className="border-2 bg-white p-2 mb-4">
                                  <p>
                                    You need to verify if any units are local or
                                    regional. All others must be at max radius.
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </section>
                      </Col>
                      <Col className="no-pad" sm={12} md={12} lg={7}>
                        {/* Current Coverage */}
                        <section id="current-coverage" className="max-w-[800]">
                          <Container fluid className="no-pad">
                            <Row className="no-pad">
                              <Col className="no-pad" sm={12}>
                                <h1 className="text-xl font-bold text-red-900 w-full">
                                  Current Coverage:
                                </h1>
                                <p>
                                  You need to explore all necessary coverages.{" "}
                                  <b>Make sure</b> they are properly covered.
                                </p>
                              </Col>
                            </Row>
                          </Container>
                          <Container fluid className="no-pad">
                            <Row className="no-pad">
                              <Col className="no-pad" sm={12} md={6}>
                                <ul className="list">
                                  <li>
                                    <input
                                      id="general-liability"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={generalLiability}
                                      onChange={() => {
                                        if (generalLiability === true) {
                                          setGeneralLiability(false);
                                        } else {
                                          setGeneralLiability(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="general-liability"
                                      className="text-lg font-medium inline-ntblock align-top"
                                    >
                                      General Liability Coverage
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="General Liability Coverage Amount"
                                      className="block mt-2 w-full p-2 text-med font-bold"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      value={GLCoverageAmount}
                                      onChange={(e) =>
                                        setGLCoverageAmount(e.target.value)
                                      }
                                      onBlur={(e) => handleBlur(e)}
                                    />
                                  </li>
                                  <li>
                                    <input
                                      id="auto-liability"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={autoLiability}
                                      onChange={() => {
                                        if (autoLiability === true) {
                                          setAutoLiability(false);
                                        } else {
                                          setAutoLiability(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="auto-liability"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Auto Liability Coverage
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Auto Liability Coverage Amount"
                                      className="block mt-2 w-full p-2 text-med font-bold"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      value={ALCoverageAmount}
                                      onChange={(e) =>
                                        setALCoverageAmount(e.target.value)
                                      }
                                      onBlur={(e) => handleBlur(e)}
                                    />
                                  </li>
                                  <li>
                                    <input
                                      id="excess-liability"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={excessLiability}
                                      onChange={() => {
                                        if (excessLiability === true) {
                                          setExcessLiability(false);
                                        } else {
                                          setExcessLiability(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="excess-liability"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Excess Liability Coverage
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Excess Liability Coverage Amount"
                                      className="block mt-2 w-full p-2 text-med font-bold"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      value={ELCoverageAmount}
                                      onChange={(e) =>
                                        setELCoverageAmount(e.target.value)
                                      }
                                      onBlur={(e) => handleBlur(e)}
                                    />
                                    <input
                                      type="text"
                                      placeholder="Excess Liability Deductible Amount"
                                      className="block mt-2 w-full p-2 text-med font-bold"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      value={ELDeductibleAmount}
                                      onChange={(e) =>
                                        setELDeductibleAmount(e.target.value)
                                      }
                                      onBlur={(e) => handleBlur(e)}
                                    />
                                  </li>
                                  <li>
                                    <input
                                      id="cargo"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={cargo}
                                      onChange={() => {
                                        if (cargo === true) {
                                          setCargo(false);
                                        } else {
                                          setCargo(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="cargo"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Cargo Coverage
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Cargo Coverage Amount"
                                      className="block mt-2 w-full p-2 text-med font-bold"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      value={cargoCoverageAmount}
                                      onChange={(e) =>
                                        setCargoCoverageAmount(e.target.value)
                                      }
                                      onBlur={(e) => handleBlur(e)}
                                    />
                                    <input
                                      type="text"
                                      placeholder="Cargo Deductible Amount"
                                      className="block mt-2 w-full p-2 text-med font-bold"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      value={cargoDeductibleAmount}
                                      onChange={(e) =>
                                        setCargoDeductibleAmount(e.target.value)
                                      }
                                      onBlur={(e) => handleBlur(e)}
                                    />
                                  </li>
                                  <li>
                                    <input
                                      id="apd"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={physicalDamage}
                                      onChange={() => {
                                        if (physicalDamage === true) {
                                          setPhysicalDamage(false);
                                        } else {
                                          setPhysicalDamage(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="apd"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Physical Damage Coverage
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Physical Damage Coverage Amount"
                                      className="block mt-2 w-full p-2 text-med font-bold"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      value={APDCoverageAmount}
                                      onChange={(e) =>
                                        setAPDCoverageAmount(e.target.value)
                                      }
                                      onBlur={(e) => handleBlur(e)}
                                    />
                                    <input
                                      type="text"
                                      placeholder="Physical Damage Deductible Amount"
                                      className="block mt-2 w-full p-2 text-med font-bold"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      value={APDDeductbileAmount}
                                      onChange={(e) =>
                                        setAPDDeductibleAmount(e.target.value)
                                      }
                                      onBlur={(e) => handleBlur(e)}
                                    />
                                  </li>
                                </ul>
                              </Col>
                              <Col className="no-pad" sm={12} md={6}>
                                <ul className="secondary-list">
                                  <li>
                                    <input
                                      id="non-owned-truck"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={nonOwnedTruck}
                                      onChange={() => {
                                        if (nonOwnedTruck === true) {
                                          setNonOwnedTruck(false);
                                        } else {
                                          setNonOwnedTruck(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="non-owned-truck"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Non-Owned Truck Coverage
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Non-Owned Coverage Amount"
                                      className="block mt-2 w-full p-2 text-med font-bold"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      value={nonOwnedTruckCoverageAmount}
                                      onChange={(e) =>
                                        setNonOwnedTruckCoverageAmount(
                                          e.target.value
                                        )
                                      }
                                      onBlur={(e) => handleBlur(e)}
                                    />
                                  </li>
                                  <li>
                                    <input
                                      id="non-owned-trailer"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={nonOwnedTrailer}
                                      onChange={() => {
                                        if (nonOwnedTrailer === true) {
                                          setNonOwnedTrailer(false);
                                        } else {
                                          setNonOwnedTrailer(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="non-owned-trailer"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Non-Owned Trailer Coverage
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Non-Owned Trailer Coverage Amount"
                                      className="block mt-2 w-full p-2 text-med font-bold"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      value={nonOwnedTrailerCoverageAmount}
                                      onChange={(e) =>
                                        setNonOwnedTrailerCoverageAmount(
                                          e.target.value
                                        )
                                      }
                                      onBlur={(e) => handleBlur(e)}
                                    />
                                  </li>
                                  <li>
                                    <input
                                      id="trailer-interchange"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={trailerInterchange}
                                      onChange={() => {
                                        if (trailerInterchange === true) {
                                          setTrailerInterchange(false);
                                        } else {
                                          setTrailerInterchange(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="trailer-interchange"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Trailer Interchange Coverage
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Trailer Interchange Coverage Amount"
                                      className="block mt-2 w-full p-2 text-med font-bold"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      value={TICoverageAmount}
                                      onChange={(e) =>
                                        setTICoverageAmount(e.target.value)
                                      }
                                      onBlur={(e) => handleBlur(e)}
                                    />
                                    <input
                                      type="text"
                                      placeholder="Total Trips Per Month"
                                      className="block mt-2 w-full p-2 text-med font-bold"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      value={totalTripsPerMonth}
                                      onChange={(e) =>
                                        setTotalTripsPerMonth(e.target.value)
                                      }
                                      onBlur={(e) => handleBlur(e)}
                                    />
                                    <input
                                      type="text"
                                      placeholder="Trips Requiring TI Coverage Per Month"
                                      className="block mt-2 w-full p-2 text-med font-bold"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      value={tripsRequiringTI}
                                      onChange={(e) =>
                                        setTripsRequiringTI(e.target.value)
                                      }
                                      onBlur={(e) => handleBlur(e)}
                                    />
                                  </li>
                                  <li>
                                    <input
                                      id="reefer"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={reefer}
                                      onChange={() => {
                                        if (reefer === true) {
                                          setReefer(false);
                                        } else {
                                          setReefer(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="reefer"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Reefer Breakdown
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      id="hazmat"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={hazmat}
                                      onChange={() => {
                                        if (hazmat === true) {
                                          setHazmat(false);
                                        } else {
                                          setHazmat(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="hazmat"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Hazmat
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      id="workers-comp"
                                      type="checkbox"
                                      className="large-checkbox"
                                      checked={hasWorkersComp}
                                      onChange={() => {
                                        if (hasWorkersComp === true) {
                                          setHasWorkersComp(false);
                                        } else {
                                          setHasWorkersComp(true);
                                        }
                                      }}
                                    />
                                    <label
                                      for="workers-comp"
                                      className="text-lg font-medium inline-block align-top"
                                    >
                                      Workers Comp
                                    </label>
                                  </li>
                                </ul>
                                <div className="mt-2bg-white p-2 mb-4">
                                  <p>
                                    If they do not want to add trailers to the
                                    APD policy, you should still ask for the
                                    list of equipment to properly calculate the
                                    risk for the TI.
                                  </p>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12}>
                                <input
                                  id="special-coverage"
                                  type="checkbox"
                                  className="large-checkbox"
                                  checked={hasSpecialCoverage}
                                  onChange={() => {
                                    if (hasSpecialCoverage === true) {
                                      setHasSpecialCoverage(false);
                                    } else {
                                      setHasSpecialCoverage(true);
                                    }
                                  }}
                                />
                                <label
                                  for="special-coverage"
                                  className="text-lg font-medium inline-block align-top"
                                >
                                  Check if customer has named shippers that
                                  require Special Coverage
                                </label>
                                <textarea
                                  placeholder="Enter the details here"
                                  className="block mt-2 w-full p-2 text-med font-bold"
                                  disabled={disabled}
                                  onKeyDown={handleEnter}
                                  value={specialCoverageDetails}
                                  onChange={(e) =>
                                    setSpecialCoverageDetails(e.target.value)
                                  }
                                  onBlur={(e) => handleBlur(e)}
                                />
                              </Col>
                            </Row>
                          </Container>
                        </section>
                      </Col>
                    </Row>
                  </Container>
                  <article className="back-next-container">
                    <button
                      className="button-1"
                      onClick={() => setValue(value - 1)}
                      disabled={value === 0}
                    >
                      Back
                    </button>
                    <button
                      onClick={() => setValue(value + 1)}
                      className="button-2"
                    >
                      Next
                    </button>
                  </article>
                </TabPanel>
                {/* Safety */}
                <TabPanel value={value} index={2} className="bg-gray-100">
                  <section>
                    <div
                      id="safetyOfficerInfo"
                      className="max-w-[650px] mx-auto p-2"
                    >
                      <h1 className="text-lg font-bold text-red-900 w-full text-center">
                        Safety Officer Information
                      </h1>

                      <div>
                        <label className="input-label">
                          Safety Officer Name:
                        </label>
                        <input
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => setSafetyOfficer(e.target.value)}
                          onBlur={(e) => handleBlur(e)}
                          className={`text-input w-full ${
                            safetyOfficer === "" && `bg-red-100`
                          }`}
                          type="text"
                          value={safetyOfficer}
                        />
                      </div>
                      <div>
                        <label className="input-label">
                          Safety Officer Title:
                        </label>
                        <input
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) =>
                            setSafetyOfficerTitle(e.target.value)
                          }
                          onBlur={handleBlur}
                          className={`text-input w-full ${
                            safetyOfficerTitle === "" && `bg-red-100`
                          }`}
                          type="text"
                          value={safetyOfficerTitle}
                        />
                      </div>
                      <div>
                        <label className="input-label">
                          Safety Officer Phone:
                        </label>
                        <input
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) =>
                            setSafetyOfficerPhone(e.target.value)
                          }
                          onBlur={handleBlur}
                          className={`text-input w-full ${
                            safetyOfficerPhone === "" && `bg-red-100`
                          }`}
                          type="text"
                          value={safetyOfficerPhone}
                        />
                      </div>
                      <div>
                        <label className="input-label">
                          Safety Officer Email:
                        </label>
                        <input
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) =>
                            setSafetyOfficerEmail(e.target.value)
                          }
                          onBlur={handleBlur}
                          className={`text-input w-full ${
                            safetyOfficerEmail === "" && `bg-red-100`
                          }`}
                          type="text"
                          value={safetyOfficerEmail}
                        />
                      </div>
                    </div>
                  </section>
                  <article className="back-next-container">
                    <button
                      onClick={() => setValue(value - 1)}
                      disabled={value === 0}
                      className="button-1"
                    >
                      Back
                    </button>
                    <button
                      onClick={() => setValue(value + 1)}
                      className="button-2"
                    >
                      Next
                    </button>
                  </article>
                </TabPanel>
                {/* Operations */}
                <TabPanel value={value} index={3} className="bg-gray-100">
                  <section>
                    <div
                      id="operationsInfo"
                      className="max-w-[650px] mx-auto p-2"
                    >
                      <h1 className="title-1">Operations Information</h1>
                      <div id="yearsInBusiness" className="flex flex-col mb-3">
                        <label className="input-label">
                          Full years in business with own authority:
                        </label>
                        <NumericInput
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          style={{
                            input: {
                              width: "100%",
                              padding: "15px",
                              fontWeight: "600",
                              textAlign: "center",
                              fontSize: "20",
                              display: "block",
                            },
                          }}
                          min={0}
                          max={100}
                          value={yearsInBusiness}
                          onChange={(valueAsNumber, valueAsString, input) =>
                            setYearsInBusiness(valueAsNumber)
                          }
                          onBlur={handleBlur}
                        />
                      </div>
                      <div id="dedicatedRoutes">
                        <label className="input-label">
                          Do you have any dedicated routes?:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => setDedicatedRoutes(e.target.value)}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            dedicatedRoutes === "Choose One" && `bg-red-100`
                          }`}
                          value={dedicatedRoutes}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      {dedicatedRoutes === "Yes" && (
                        <>
                          {fields.map((field, i) => (
                            <div
                              key={"route" + field.id}
                              id={"route" + field.id}
                            >
                              <div>
                                <label className="input-label">
                                  Describe Dedicated Route {i + 1}:
                                </label>
                                <input
                                  disabled={disabled}
                                  onKeyDown={handleEnter}
                                  name="routeDescription"
                                  onChange={(e) => handleChangeInput(i, e)}
                                  onBlur={handleBlur}
                                  className={`text-input w-full ${
                                    field.routeDescription === "" &&
                                    `bg-red-100`
                                  }`}
                                  type="text"
                                  value={field.routeDescription}
                                />
                              </div>
                              <button
                                className="text-sky-600"
                                onClick={(e) => handleSubtract(e, i)}
                              >
                                - Remove Route {i + 1}
                              </button>
                            </div>
                          ))}
                          <button
                            className="text-sky-600 mr-10"
                            onClick={() => handleAdd()}
                          >
                            + Add a route
                          </button>
                        </>
                      )}
                      <div id="safetyManual">
                        <label className="input-label">
                          Do you have a safety manual:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setSafetyManual(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            safetyManual === "Choose One" && `bg-red-100`
                          }`}
                          value={safetyManual}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      {/* {safetyManual === "Yes" && (
                        <div>
                          <label className="input-label">
                            When was your safety manual last updated:
                          </label>
                          <input
                            onChange={(e) => setSafetyManualUpdated(e.target.value)}
                            onBlur={handleBlur}
                            onFocus={(e) => (e.target.className = "text-base p-3")}
                            
                            className={`p-3 text-xl font-medium ${
                              safetyManualUpdated === "" && `border-2 bg-red-100`
                            }  ${safetyManualUpdated !== "" && `border-0`}`}
                            type="date"
                            value={safetyManualUpdated}
                          />
                          <div>
                            <label className="input-label">
                              Upload a copy of the safety manual:
                            </label>
                            <input type="file" />
                          </div>
                        </div>
                      )} */}

                      <div
                        id="otherMotorCarriers"
                        className="flex flex-col py-2"
                      >
                        <label className="input-label">
                          Do you lease to other motor carrier(s):
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setOtherMotorCarriers(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            otherMotorCarriers === "Choose One" && `bg-red-100`
                          }`}
                          value={otherMotorCarriers}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      {otherMotorCarriers === "Yes" && (
                        <>
                          {carrierFields.map((field, i) => (
                            <div key={"carrier" + field.id}>
                              <div>
                                <label className="input-label">
                                  Carrier {i + 1} Name:
                                </label>
                                <input
                                  disabled={disabled}
                                  onKeyDown={handleEnter}
                                  name="carrierName"
                                  onChange={(e) =>
                                    handleChangeInputCarrier(i, e)
                                  }
                                  onBlur={handleBlur}
                                  className={`text-input w-full ${
                                    field.carrierName === "" && `bg-red-100`
                                  }`}
                                  type="text"
                                  value={field.carrierName}
                                />
                              </div>
                              <button
                                className="text-sky-600"
                                onClick={(e) => handleSubtractCarrier(e, i)}
                              >
                                - Remove Carrier {i + 1}
                              </button>
                            </div>
                          ))}
                          <button
                            className="text-sky-600 mr-10"
                            onClick={handleAddCarrier}
                          >
                            + Add a carrier
                          </button>
                        </>
                      )}
                      <div id="otherOperations">
                        <label className="input-label">
                          Are there any other operations under your control or
                          authority:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setOtherOperations(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            otherOperations === "Choose One" && `bg-red-100`
                          }`}
                          value={otherOperations}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      {otherOperations === "Yes" && (
                        <>
                          {operationFields.map((field, i) => (
                            <div key={"operation" + field.id}>
                              <div>
                                <label className="input-label">
                                  Operation {i + 1} Name:
                                </label>
                                <input
                                  disabled={disabled}
                                  onKeyDown={handleEnter}
                                  name="operationName"
                                  onChange={(e) =>
                                    handleChangeInputOperation(i, e)
                                  }
                                  onBlur={handleBlur}
                                  className={`text-input w-full ${
                                    field.operationName === "" && `bg-red-100`
                                  }`}
                                  type="text"
                                  value={field.operationName}
                                />
                              </div>
                              <button
                                className="text-sky-600"
                                onClick={(e) => handleSubtractOperation(e, i)}
                              >
                                - Remove Operation {i + 1}
                              </button>
                            </div>
                          ))}
                          <button
                            className="text-sky-600 mr-10"
                            onClick={handleAddOperation}
                          >
                            + Add an operation
                          </button>
                        </>
                      )}
                      <div id="motorCarrier">
                        <label className="input-label">
                          Will you operate as a motor carrier under this policy:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setMotorCarrier(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            motorCarrier === "Choose One" && `bg-red-100`
                          }`}
                          value={motorCarrier}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      <div
                        id="alreadyMotorCarrier"
                        className="flex flex-col py-2"
                      >
                        <label className="input-label">
                          Do you already act as a motor carrier:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setAlreadyMotorCarrier(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            alreadyMotorCarrier === "Choose One" && `bg-red-100`
                          }`}
                          value={alreadyMotorCarrier}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      {alreadyMotorCarrier === "Yes" && (
                        <div>
                          <label className="input-label">
                            When did you become a motor carrier:
                          </label>
                          <DatePicker
                          disabled={disabled}
                            selected={motorCarrierSince}
                            onChange={(date: Date) => {
                              setMotorCarrierSince(date);
                            }}
                            className={`text-input w-full ${
                              motorCarrierSince === "" && `bg-red-100`
                            }`}
                          />
                        </div>
                      )}
                    </div>
                    <article className="back-next-container">
                      <button
                        onClick={() => setValue(value - 1)}
                        disabled={value === 0}
                        className="button-1"
                      >
                        Back
                      </button>
                      <button
                        onClick={() => setValue(value + 1)}
                        className="button-2"
                      >
                        Next
                      </button>
                    </article>
                  </section>
                </TabPanel>
                {/* Employees */}
                <TabPanel value={value} index={4} className="bg-gray-100">
                  <section>
                    <div
                      id="employeeInfo"
                      className="max-w-[650px] mx-auto p-2"
                    >
                      <h1 className="text-lg font-bold text-red-900 w-full text-center">
                        Employee & Hiring Information
                      </h1>
                      <div id="hiringProcedures">
                        <label className="input-label">
                          Do you have formal hiring procedures in place:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setHiringProcedures(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            hiringProcedures === "Choose One" && `bg-red-100`
                          }`}
                          value={hiringProcedures}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      {/* {hiringProcedures === "Yes" && (
                              <div>
                                <label className="input-label">
                                  Upload a copy of your hiring procedures:
                                </label>
                                <input type="file" />
                              </div>
                            )} */}
                      <div id="driverViolations">
                        <label className="input-label">
                          Have any of your drivers had any violations in the
                          past 3 years:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setDriverViolations(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            driverViolations === "Choose One" && `bg-red-100`
                          }`}
                          value={driverViolations}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                        {driverViolations === "Yes" && (
                          <>
                            {violationFields.map((field, i) => (
                              <div key={"violation" + i}>
                                <div>
                                  <label className="py-2 font-bold">
                                    Violation {i + 1}
                                  </label>

                                  <Container fluid className="no-pad">
                                    <Row className="p-2">
                                      <Col md={3}>
                                        <label className="input-label">
                                          Violation Date
                                        </label>
                                      </Col>
                                      <Col>
                                        <DatePicker
                                          disabled={disabled}
                                          onKeyDown={handleEnter}
                                          name="dateOfViolation"
                                          selected={!field.dateOfViolation.seconds ? field.dateOfViolation : convertDate(field.dateOfViolation.seconds)}
                                          onChange={(date: Date) => {
                                            const values = [...violationFields];
                                            values[i].dateOfViolation = date;
                                            setViolationFields(values);
                                          }}
                                          className={`text-input w-full ${
                                            field.dateOfViolation === "" &&
                                            `bg-red-100`
                                          }`}
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="p-2">
                                      <Col md={3}>
                                        <label className="input-label">
                                          Location (State):
                                        </label>
                                      </Col>
                                      <Col>
                                        <input
                                          disabled={disabled}
                                          onKeyDown={handleEnter}
                                          name="locationOfViolation"
                                          onChange={(e) =>
                                            handleChangeInputViolation(i, e)
                                          }
                                          className={`text-input w-full ${
                                            field.locationOfViolation === "" &&
                                            `bg-red-100`
                                          }`}
                                          type="text"
                                          value={field.locationOfViolation}
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="p-2">
                                      <Col md={3}>
                                        <label className="input-label">
                                          Driver Name (First Last):
                                        </label>
                                      </Col>
                                      <Col>
                                        <input
                                          disabled={disabled}
                                          onKeyDown={handleEnter}
                                          name="driverName"
                                          onChange={(e) =>
                                            handleChangeInputViolation(i, e)
                                          }
                                          onBlur={handleBlur}
                                          className={`text-input w-full ${
                                            field.driverName === "" &&
                                            `bg-red-100`
                                          }`}
                                          type="text"
                                          value={field.driverName}
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="p-2">
                                      <Col md={3}>
                                        <label className="input-label">
                                          Moving Violation:
                                        </label>
                                      </Col>
                                      <Col>
                                        <select
                                          disabled={disabled}
                                          onKeyDown={handleEnter}
                                          name="movingViolation"
                                          onChange={(e) =>
                                            handleChangeInputViolation(i, e)
                                          }
                                          onBlur={handleSelectBlur}
                                          className={`text-input w-full ${
                                            field.movingViolation ===
                                              "Choose One" && `bg-red-100`
                                          }`}
                                          value={field.movingViolation}
                                        >
                                          <option>Choose One</option>
                                          <option>Yes</option>
                                          <option>No</option>
                                        </select>
                                      </Col>
                                    </Row>
                                    <Row className="p-2">
                                      <Col md={3}>
                                        <label className="input-label">
                                          Description
                                        </label>
                                      </Col>
                                      <Col>
                                        <textarea
                                          disabled={disabled}
                                          onKeyDown={handleEnter}
                                          name="violationDescription"
                                          onChange={(e) =>
                                            handleChangeInputViolation(i, e)
                                          }
                                          onBlur={handleBlur}
                                          className={`text-input w-full ${
                                            field.violationDescription === "" &&
                                            `bg-red-100`
                                          }`}
                                          type="text"
                                          value={field.violationDescription}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <button
                                          className="text-sky-600"
                                          onClick={(e) =>
                                            handleSubtractViolation(e, i)
                                          }
                                        >
                                          - Remove Violation {i + 1}
                                        </button>
                                      </Col>
                                    </Row>
                                  </Container>
                                </div>
                              </div>
                            ))}
                            <Container fluid className="no-pad">
                              <Row className="p-2">
                                <Col>
                                  <button
                                    className="text-sky-600 mr-10"
                                    onClick={handleAddViolation}
                                  >
                                    + Add a violation
                                  </button>
                                </Col>
                                <Col></Col>
                              </Row>
                            </Container>
                          </>
                        )}
                      </div>

                      <div id="ownerOperators">
                        <label className="input-label">
                          Do you permanently lease to any owner/operator(s):
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setOwnerOperators(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            ownerOperators === "Choose One" && `bg-red-100`
                          }`}
                          value={ownerOperators}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      {ownerOperators === "Yes" && (
                        <div className="flex flex-col">
                          <label className="input-label">
                            How many owner operators do you lease to:
                          </label>
                          <NumericInput
                            style={{
                              input: {
                                width: "100%",
                                padding: "15px",
                                fontWeight: "500",
                                textAlign: "center",
                                fontSize: "18",
                              },
                            }}
                            min={0}
                            max={100}
                            value={howManyOwnerOperators}
                            disabled={disabled}
                            onKeyDown={handleEnter}
                            onChange={(valueAsNumber, valueAsString, input) =>
                              setHowManyOwnerOperators(valueAsNumber)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </section>
                  <article className="back-next-container">
                    <button
                      onClick={() => setValue(value - +1)}
                      className="button-1"
                    >
                      Back
                    </button>
                    <button
                      onClick={() => setValue(value + 1)}
                      className="button-2"
                    >
                      Next
                    </button>
                  </article>
                </TabPanel>
                {/* Equipment */}
                <TabPanel value={value} index={5} className="bg-gray-100">
                  <section>
                    <div
                      id="equipmentInfo"
                      className="max-w-[650px] mx-auto p-2"
                    >
                      <h1 className="text-lg font-bold text-red-900 w-full text-center">
                        Equipment Information
                      </h1>
                      <div id="singlePowerUnit">
                        <label className="input-label">
                          Does your operation only have one single full time
                          revenue generating power unit:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setSinglePowerUnit(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            singlePowerUnit === "Choose One" && `bg-red-100`
                          }`}
                          value={singlePowerUnit}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      <div
                        id="allCommercialEquip"
                        className="flex flex-col py-2"
                      >
                        <label className="input-label">
                          Is all commercial or mobile equipment that you own or
                          operate described in this application:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setAllCommercialEquip(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            allCommercialEquip === "Choose One" && `bg-red-100`
                          }`}
                          value={allCommercialEquip}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      {allCommercialEquip === "No" && (
                        <div>
                          <label className="input-label">
                            Please explain what equipment is not on the
                            application and why:
                          </label>

                          <textarea
                            disabled={disabled}
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              setAllCommercialEquipDetails(e.target.value)
                            }
                            onBlur={handleBlur}
                            className={`text-input w-full ${
                              allCommercialEquipDetails === "" && `bg-red-100`
                            }`}
                            value={allCommercialEquipDetails}
                          />
                        </div>
                      )}
                      <div id="cameras">
                        <label className="input-label">
                          Does your equipment have any cameras:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setCameras(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            cameras === "Choose One" && `bg-red-100`
                          }`}
                          value={cameras}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      {cameras === "Yes" && (
                        <div>
                          <label className="input-label">
                            Please explain where the cameras are placed:
                          </label>

                          <textarea
                            disabled={disabled}
                            onKeyDown={handleEnter}
                            onChange={(e) => setCameraDetails(e.target.value)}
                            onBlur={handleBlur}
                            className={`text-input w-full ${
                              cameraDetails === "" && `bg-red-100`
                            }`}
                            value={cameraDetails}
                          />
                        </div>
                      )}
                      <div id="GPS">
                        <label className="input-label">
                          Does the equipment have GPS systems:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setGPS(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            GPS === "Choose One" && `bg-red-100`
                          }`}
                          value={GPS}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      {GPS === "Yes" && (
                        <div>
                          <label className="input-label">
                            On which equipment:
                          </label>
                          <select
                            disabled={disabled}
                            onKeyDown={handleEnter}
                            onChange={(e) => {
                              setGPSWhere(e.target.value);
                            }}
                            onBlur={handleSelectBlur}
                            className={`text-input w-full ${
                              GPSWhere === "Choose One" && `bg-red-100`
                            }`}
                            value={GPSWhere}
                          >
                            <option>Choose One</option>
                            <option>GPS on Trucks Only</option>
                            <option>GPS on Trailers Only</option>
                            <option>GPS on Trucks & Trailers</option>
                          </select>
                        </div>
                      )}
                      <div id="collisionWarning">
                        <label className="input-label">
                          Does the equipment have Collision Warning systems:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setCollisionWarning(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            collisionWarning === "Choose One" && `bg-red-100`
                          }`}
                          value={collisionWarning}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                    </div>
                  </section>
                  <article className="back-next-container">
                    <button
                      onClick={() => setValue(value - 1)}
                      disabled={value === 0}
                      className="button-1"
                    >
                      Back
                    </button>
                    <button
                      onClick={() => setValue(value + 1)}
                      className="button-2"
                    >
                      Next
                    </button>
                  </article>
                </TabPanel>
                {/* Cargo */}
                <TabPanel value={value} index={6} className="bg-gray-100">
                  <section>
                    <div id="cargoInfo" className="max-w-[650px] mx-auto p-2">
                      <h1 className="text-lg font-bold text-red-900 w-full text-center">
                        Cargo Information
                      </h1>
                      <div id="pullDoubles">
                        <label className="input-label">
                          Do you pull doubles:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setPullDoubles(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            pullDoubles === "Choose One" && `bg-red-100`
                          }`}
                          value={pullDoubles}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      <div id="pullTriples">
                        <label className="input-label">
                          Do you pull triples:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setPullTriples(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            pullTriples === "Choose One" && `bg-red-100`
                          }`}
                          value={pullTriples}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      <div id="hazardousCargo">
                        <label className="input-label">
                          Do you transport hazardous cargo:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setHazardousCargo(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            hazardousCargo === "Choose One" && `bg-red-100`
                          }`}
                          value={hazardousCargo}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      {hazardousCargo === "Yes" && (
                        <div className="flex flex-col">
                          <label className="input-label">
                            Type(s) of hazardous cargo hauled (list all):
                          </label>
                          <textarea
                            disabled={disabled}
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              setHazardousCargoType(e.target.value)
                            }
                            onBlur={handleBlur}
                            className={`text-input w-full ${
                              hazardousCargoType === "" && `bg-red-100`
                            }`}
                            type="text"
                            value={hazardousCargoType}
                          />
                          <label className="input-label">
                            What percentage of your loads contain hazardous
                            cargo:
                          </label>
                          <NumericInput
                            style={{
                              input: {
                                width: "100%",
                                padding: "15px",
                                fontWeight: "500",
                                textAlign: "center",
                                fontSize: "20",
                              },
                            }}
                            min={0}
                            max={100}
                            value={hazardousCargoPercentage}
                            disabled={disabled}
                            onKeyDown={handleEnter}
                            onChange={(valueAsNumber, valueAsString, input) =>
                              setHazardousCargoPercentage(valueAsNumber)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </section>
                  <article className="back-next-container">
                    <button
                      onClick={() => setValue(value - 1)}
                      disabled={value === 0}
                      className="button-1"
                    >
                      Back
                    </button>
                    <button
                      onClick={() => setValue(value + 1)}
                      className="button-2"
                    >
                      Next
                    </button>
                  </article>
                </TabPanel>
                {/* Commodities */}
                <TabPanel value={value} index={7} className="bg-gray-100">
                  <>
                    <Container fluid className="no-pad">
                      <h1 className="text-lg font-bold text-red-900 w-full text-center">
                        Commodity Information
                      </h1>

                      {dedicatedRoutes && (
                        <>
                          {commodityFields.map((field, i) => (
                            <div
                              key={"route" + field.id}
                              id={"route" + field.id}
                            >
                              <Row>
                                <Col>
                                  <div>
                                    <label className="input-label">
                                      Describe Commodity {i + 1}:
                                    </label>
                                    <input
                                      name="commodityDescription"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      onChange={(e) =>
                                        handleChangeInputCommodity(i, e)
                                      }
                                      onBlur={handleBlurCommodity}
                                      className={`text-input w-full ${
                                        field.commodityDescription === "" &&
                                        `bg-red-100`
                                      }`}
                                      type="text"
                                      value={field.commodityDescription}
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div>
                                    <label className="input-label">
                                      Commodity {i + 1} % of Hauls:
                                    </label>
                                    <input
                                      name="commodityLoad"
                                      disabled={disabled}
                                      onKeyDown={handleEnter}
                                      onChange={(e) =>
                                        handleChangeInputCommodity(i, e)
                                      }
                                      onBlur={handleBlurCommodity}
                                      className={`text-input w-full ${
                                        field.commodityLoad === 0 &&
                                        `bg-red-100`
                                      }`}
                                      type="number"
                                      value={field.commodityLoad}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <button
                                className="text-sky-600"
                                onClick={(e) => handleSubtractCommodity(e, i)}
                              >
                                - Remove Commodity {i + 1}
                              </button>
                            </div>
                          ))}
                          <button
                            className="text-sky-600 mr-10"
                            onClick={() => handleAddCommodity()}
                          >
                            + Add a Commodity
                          </button>
                        </>
                      )}
                    </Container>
                    <article className="back-next-container">
                      <button
                        onClick={() => setValue(value - 1)}
                        disabled={value === 0}
                        className="button-1"
                      >
                        Back
                      </button>
                      <button
                        onClick={() => setValue(value + 1)}
                        className="button-2"
                      >
                        Next
                      </button>
                    </article>
                  </>
                </TabPanel>
                {/* Insurance */}
                <TabPanel value={value} index={8} className="bg-gray-100">
                  <section>
                    <div
                      id="insuranceInfo"
                      className="max-w-[650px] mx-auto p-2"
                    >
                      <h1 className="text-lg font-bold text-red-900 w-full text-center">
                        Insurance Information
                      </h1>
                      <div id="workersComp">
                        <label className="input-label">
                          Are employees covered by workers compensation:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setWorkersComp(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            workersComp === "Choose One" && `bg-red-100`
                          }`}
                          value={workersComp}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      {workersComp === "Yes" && (
                        <div>
                          <label className="input-label">
                            Who is the carrier of your worker's compensation
                            policy:
                          </label>

                          <input
                            disabled={disabled}
                            onKeyDown={handleEnter}
                            onChange={(e) =>
                              setWorkersCompCarrier(e.target.value)
                            }
                            onBlur={handleBlur}
                            className={`text-input w-full ${
                              workersCompCarrier === "" && `bg-red-100`
                            }`}
                            type="text"
                            value={workersCompCarrier}
                          />
                        </div>
                      )}
                      <div
                        id="claimsPastTwoYears"
                        className="flex flex-col py-2"
                      >
                        <label className="input-label">
                          Have you had any bodily injury, property damage,
                          collision, or other losses in the past 2 years:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setClaimsPastTwoYears(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            claimsPastTwoYears === "Choose One" && `bg-red-100`
                          }`}
                          value={claimsPastTwoYears}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      {claimsPastTwoYears === "Yes" && (
                        <>
                          {claimFields.map((field, i) => (
                            <div key={"claim" + field.id}>
                              <div>
                                <label className="py-2 font-bold">
                                  Loss {i + 1}
                                </label>

                                <Container fluid className="no-pad">
                                  <Row className="p-2">
                                    <Col md={3}>
                                      <label className="input-label">
                                        Loss Date & Type
                                      </label>
                                    </Col>
                                    <Col>
                                      <DatePicker
                                        disabled={disabled}
                                        onKeyDown={handleEnter}
                                        name="dateOfClaim"
                                        selected={
                                          !field.dateOfClaim.seconds
                                            ? field.dateOfClaim
                                            : convertDate(
                                                field.dateOfClaim.seconds
                                              )
                                        }
                                        onChange={(date: Date) => {
                                          const values = [...claimFields];
                                          values[i].dateOfClaim = date;
                                          setClaimFields(values);
                                        }}
                                        className={`text-input w-full ${
                                          field.dateOfClaim === "" &&
                                          `bg-red-100`
                                        }`}
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="p-2">
                                    <Col>
                                      <input
                                        disabled={disabled}
                                        onKeyDown={handleEnter}
                                        type="checkbox"
                                        name="bodilyInjury"
                                        onChange={(e) => {
                                          if (!e.target.checked) {
                                            field.bodilyInjury = false;
                                          } else {
                                            field.bodilyInjury = true;
                                          }
                                        }}
                                        value="bodilyInjury"
                                      />{" "}
                                      Bodily Injury
                                    </Col>
                                    <Col>
                                      <input
                                        disabled={disabled}
                                        onKeyDown={handleEnter}
                                        type="checkbox"
                                        name="propertyDamage"
                                        onChange={(e) => {
                                          if (!e.target.checked) {
                                            field.propertyDamage = false;
                                          } else {
                                            field.propertyDamage = true;
                                          }
                                        }}
                                        value="propertyDamage"
                                      />{" "}
                                      Property Damage
                                    </Col>
                                  </Row>
                                  <Row className="p-2">
                                    <Col>
                                      <input
                                        disabled={disabled}
                                        onKeyDown={handleEnter}
                                        type="checkbox"
                                        name="collision"
                                        onChange={(e) => {
                                          if (!e.target.checked) {
                                            field.collision = false;
                                          } else {
                                            field.collision = true;
                                          }
                                        }}
                                        value="collision"
                                      />{" "}
                                      Collision
                                    </Col>
                                    <Col>
                                      <input
                                        disabled={disabled}
                                        onKeyDown={handleEnter}
                                        type="checkbox"
                                        name="otherLoss"
                                        onChange={(e) => {
                                          if (!e.target.checked) {
                                            field.otherLoss = false;
                                          } else {
                                            field.otherLoss = true;
                                          }
                                        }}
                                        value="otherLoss"
                                      />{" "}
                                      Other Loss
                                    </Col>
                                  </Row>
                                  <Row className="p-2">
                                    <Col md={3}>
                                      <label className="input-label">
                                        Location
                                      </label>
                                    </Col>
                                    <Col>
                                      <input
                                        disabled={disabled}
                                        onKeyDown={handleEnter}
                                        name="locationOfClaim"
                                        onChange={(e) =>
                                          handleChangeInputClaim(i, e)
                                        }
                                        onBlur={handleBlur}
                                        className={`text-input w-full ${
                                          field.locationOfClaim === "" &&
                                          `bg-red-100`
                                        }`}
                                        type="text"
                                        value={field.locationOfClaim}
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="p-2">
                                    <Col md={3}>
                                      <label className="input-label">
                                        At Fault?
                                      </label>
                                    </Col>
                                    <Col>
                                      <select
                                        disabled={disabled}
                                        onKeyDown={handleEnter}
                                        name="atFault"
                                        onChange={(e) =>
                                          handleChangeInputClaim(i, e)
                                        }
                                        onBlur={handleSelectBlur}
                                        className={`text-input w-full ${
                                          field.atFault === "Choose One" &&
                                          `bg-red-100`
                                        }`}
                                        value={field.atFault}
                                      >
                                        <option>Choose One</option>
                                        <option>Yes</option>
                                        <option>No</option>
                                      </select>
                                    </Col>
                                  </Row>
                                  <Row className="p-2">
                                    <Col md={3}>
                                      <label className="input-label">
                                        Description
                                      </label>
                                    </Col>
                                    <Col>
                                      <textarea
                                        disabled={disabled}
                                        onKeyDown={handleEnter}
                                        name="claimDescription"
                                        onChange={(e) =>
                                          handleChangeInputClaim(i, e)
                                        }
                                        onBlur={handleBlur}
                                        className={`text-input w-full ${
                                          field.claimDescription === "" &&
                                          `bg-red-100`
                                        }`}
                                        type="text"
                                        value={field.claimDescription}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <button
                                        className="text-sky-600"
                                        onClick={(e) =>
                                          handleSubtractClaim(e, i)
                                        }
                                      >
                                        - Remove Claim {i + 1}
                                      </button>
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                            </div>
                          ))}
                          <Container fluid className="no-pad">
                            <Row className="p-2">
                              <Col>
                                <button
                                  className="text-sky-600 mr-10"
                                  onClick={handleAddClaim}
                                >
                                  + Add a claim
                                </button>
                              </Col>
                            </Row>
                          </Container>
                        </>
                      )}
                      <div
                        id="terrorismCoverage"
                        className="flex flex-col py-2"
                      >
                        <label className="input-label">
                          Would you like terrorism coverage:
                        </label>
                        <select
                          disabled={disabled}
                          onKeyDown={handleEnter}
                          onChange={(e) => {
                            setTerrorismCoverage(e.target.value);
                          }}
                          onBlur={handleSelectBlur}
                          className={`text-input w-full ${
                            terrorismCoverage === "Choose One" && `bg-red-100`
                          }`}
                          value={terrorismCoverage}
                        >
                          <option>Choose One</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                    </div>
                  </section>
                  <article className="back-next-container">
                    <button
                      onClick={() => setValue(value - 1)}
                      disabled={value === 0}
                      className="button-1"
                    >
                      Back
                    </button>
                    <button
                      onClick={() => setValue(value + 1)}
                      className="button-2"
                    >
                      Next
                    </button>
                  </article>
                </TabPanel>
                {/* Submit */}
                <TabPanel value={value} index={9} className="bg-gray-100">
                  <section>
                    Alerts:
                    <ul id="issues">
                      {/* Checklist */}
                      {presentation === false && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(1)}
                          >
                            Checklist:
                          </button>{" "}
                          Presentation not given
                        </li>
                      )}
                      {experience === false && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(1)}
                          >
                            Checklist:
                          </button>{" "}
                          Talk about our 50 Years Experience{" "}
                        </li>
                      )}
                      {offices === false && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(1)}
                          >
                            Checklist:
                          </button>{" "}
                          Mention All Our Offices
                        </li>
                      )}
                      {service === false && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(1)}
                          >
                            Checklist:
                          </button>{" "}
                          Talk about our Focus On Customer Service
                        </li>
                      )}
                      {clients === false && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(1)}
                          >
                            Checklist:
                          </button>{" "}
                          Mention Safety Training for Certain Clients
                        </li>
                      )}
                      {markets === false && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(1)}
                          >
                            Checklist:
                          </button>{" "}
                          Advise on our Access to Different Markets
                        </li>
                      )}
                      {clients === false && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(1)}
                          >
                            Checklist:
                          </button>{" "}
                          Mention Our Clients
                        </li>
                      )}
                      {system === false && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(1)}
                          >
                            Checklist:
                          </button>{" "}
                          Mention Our System
                        </li>
                      )}

                      {/* Signer Info */}
                      {signerName === "" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(0)}
                          >
                            Company:
                          </button>{" "}
                          Signer Name field empty.
                        </li>
                      )}
                      {signerEmail === "" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(0)}
                          >
                            Company:
                          </button>{" "}
                          Signer Email field empty.
                        </li>
                      )}

                      {/* Safety Officer Info */}
                      {safetyOfficer === "" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(2)}
                          >
                            Safety:
                          </button>{" "}
                          Safety Officer Name field empty.
                        </li>
                      )}
                      {safetyOfficerTitle === "" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(2)}
                          >
                            Safety:
                          </button>{" "}
                          Safety Officer Title field empty.
                        </li>
                      )}
                      {safetyOfficerPhone === "" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(2)}
                          >
                            Safety:
                          </button>{" "}
                          Safety Officer Phone field empty.
                        </li>
                      )}
                      {safetyOfficerEmail === "" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(2)}
                          >
                            Safety:
                          </button>{" "}
                          Safety Officer Email field empty.
                        </li>
                      )}

                      {/* Operations Info */}
                      {yearsInBusiness === 0 && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(3)}
                          >
                            Operations:
                          </button>{" "}
                          Years In Business field can not be 0.
                        </li>
                      )}
                      {dedicatedRoutes === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(3)}
                          >
                            Operations:
                          </button>{" "}
                          Deidcated Routes field empty.
                        </li>
                      )}
                      {safetyManual === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(3)}
                          >
                            Operations:
                          </button>{" "}
                          Safety Manual field empty.
                        </li>
                      )}
                      {otherMotorCarriers === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(3)}
                          >
                            Operations:
                          </button>{" "}
                          Lease to Other Motor Carriers field empty.
                        </li>
                      )}
                      {otherOperations === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(3)}
                          >
                            Operations:
                          </button>{" "}
                          Other Operations Under Conntrol field empty.
                        </li>
                      )}
                      {motorCarrier === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(3)}
                          >
                            Operations:
                          </button>{" "}
                          Operate as Motor Carrier field empty.
                        </li>
                      )}
                      {alreadyMotorCarrier === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(3)}
                          >
                            Operations:
                          </button>{" "}
                          Already Motor Carrier field empty.
                        </li>
                      )}

                      {/* Employees Information */}
                      {hiringProcedures === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(4)}
                          >
                            Employees:
                          </button>{" "}
                          Hiring Procedures field empty.
                        </li>
                      )}
                      {driverViolations === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(4)}
                          >
                            Employees:
                          </button>{" "}
                          Driver Violations field empty.
                        </li>
                      )}
                      {ownerOperators === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(4)}
                          >
                            Employees:
                          </button>{" "}
                          Owner Operators field empty.
                        </li>
                      )}

                      {/* Equipment Information */}
                      {singlePowerUnit === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(5)}
                          >
                            Equipment:
                          </button>{" "}
                          Single Revenue Generating Unit field empty.
                        </li>
                      )}
                      {allCommercialEquip === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(5)}
                          >
                            Equipment:
                          </button>{" "}
                          All Commercial Equipment Listed field empty.
                        </li>
                      )}
                      {cameras === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(5)}
                          >
                            Equipment:
                          </button>{" "}
                          Cameras field empty.
                        </li>
                      )}
                      {GPS === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(5)}
                          >
                            Equipment:
                          </button>{" "}
                          GPS field empty.
                        </li>
                      )}
                      {collisionWarning === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(5)}
                          >
                            Equipment:
                          </button>{" "}
                          Collision Warning System field empty.
                        </li>
                      )}

                      {/* Cargo Information */}
                      {pullDoubles === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(6)}
                          >
                            Cargo:
                          </button>{" "}
                          Pull Doubles field empty.
                        </li>
                      )}
                      {pullTriples === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(6)}
                          >
                            Cargo:
                          </button>{" "}
                          Pull Triples field empty.
                        </li>
                      )}
                      {hazardousCargo === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(6)}
                          >
                            Cargo:
                          </button>{" "}
                          Transport Hazardous Cargo field empty.
                        </li>
                      )}

                      {/* Commodity */}
                      {commodityTotal !== 100 && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(7)}
                          >
                            Commodities:
                          </button>
                          Commodities Total % of Hauls Must Equal 100. Currently
                          equals {commodityTotal}
                        </li>
                      )}

                      {/* Insurance */}
                      {workersComp === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(8)}
                          >
                            Insurance:
                          </button>{" "}
                          Workers Comp field empty.
                        </li>
                      )}
                      {claimsPastTwoYears === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(8)}
                          >
                            Insurance:
                          </button>{" "}
                          Claims in past two years field empty.
                        </li>
                      )}
                      {terrorismCoverage === "Choose One" && (
                        <li>
                          <button
                            className="text-sky-600"
                            onClick={() => setValue(8)}
                          >
                            Insurance:
                          </button>{" "}
                          Terrorism Coverage field empty.
                        </li>
                      )}
                    </ul>
                  </section>

                  <div className="mx-auto max-w-[700px]">
                    <button
                      disabled={disabled}
                      onKeyDown={handleEnter}
                      onClick={(e) => saveFactfinder(e)}
                      className="bg-yellow-900 hover:bg-yellow-700 w-full p-4 mt-4 mx-auto text-white text-xl text-medium max-w-[700px]"
                    >
                      Save & Exit
                    </button>
                    <button
                      disabled={disabled}
                      onKeyDown={handleEnter}
                      onClick={getSignature}
                      className="bg-green-900 hover:bg-yellow-700 w-full p-4 my-4 mx-auto text-white text-xl text-medium max-w-[700px]"
                    >
                      Request Signature
                    </button>
                  </div>
                </TabPanel>
              </Box>
            </Col>
          </Row>
        </Container>
      </fieldset>
    );
  }
}

export default EditFactfinder;
