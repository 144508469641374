import React, { useEffect, useState } from "react";
import axios from "axios";
import { storage } from "../../firebase.js";
import { ref, uploadBytes } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateDoc,
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  addDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { v4 } from "uuid";
import "../app.css";
import { Container } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function PerformanceReviewsForm(props) {
  // Criteria Modal Stuff
  let subtitle;
  const [criteriaModalIsOpen, setCriteriaModalIsOpen] = React.useState(false);

  function openCriteriaModal(e) {
    e.preventDefault();
    setCriteriaModalIsOpen(true);
  }

  function afterOpenCriteriaModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeCriteriaModal() {
    setCriteriaModalIsOpen(false);
  }

  // Other Stuff
  let navigate = useNavigate();

  // Date Stuff
  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output;
  };
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  const [startDate, setStartDate] = useState(new Date());

  // Tabs Stuff
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    if (!props.newProposal);
    setValue(newValue);
  };

  // Hidden Form Stuff
  const [status, setStatus] = useState("");

  //   Form Stuff
  const [textInput, setTextInput] = useState("");
  const [dateInput, setDateInput] = useState(new Date());
  const [select, setSelect] = useState("Choose One");
  const [performanceReviewComments, setPerformanceReviewComments] = useState([
    {
      description: "",
    },
  ]);
  const [notes, setNotes] = useState([
    {
      description: "",
    },
  ]);
  const [supervisorComments, setSupervisorComments] = useState([
    {
      description: "",
    },
  ]);
  const [newPerformanceReviewTitle, setNewPerformanceReviewTitle] =
    useState("");

  const [sharingKnowledge, setSharingKnowledge] = useState(0);
  const [developSkills, setdevelopSkills] = useState(0);
  const [qualityOfWork, setQualityOfWork] = useState(0);
  const [quantityOfWork, setQuantityOfWork] = useState(0);
  const [reliability, setReliability] = useState(0);
  const [accountability, setAccountability] = useState(0);
  const [initiativeAndCreativity, setInitiativeAndCreativity] = useState(0);
  const [flexibility, setFlexibility] = useState(0);
  const [judgement, setJudgement] = useState(0);
  const [cooperation, setCooperation] = useState(0);
  const [attendance, setAttendance] = useState(0);
  const [attitudeRespectfulness, setAttitudeRespectfulness] = useState(0);
  const [acceptsCriticism, setAcceptsCriticism] = useState(0);
  const [policyAndProcedures, setPolicyAndProcedures] = useState(0);
  const [confidentiality, setConfidentiality] = useState(0);
  const [communicationSkills, setCommunicationSkills] = useState(0);
  const [conflictResolution, setConflictResolution] = useState(0);
  const [organizationalSkills, setOrganizationalSkills] = useState(0);
  const [customerInteraction, setCustomerInteraction] = useState(0);
  const [appearanceDressCode, setAppearanceDressCode] = useState(0);
  const [planningAndOrganizing, setPlanningAndOrganizing] = useState(0);
  const [directingAndControlling, setDirectingAndControlling] = useState(0);
  const [decisionMaker, setDecisionMaker] = useState(0);
  const [appearanceOfWorkArea, setAppearanceOfWorkArea] = useState(0);
  const [jobKnowledge, setJobKnowledge] = useState(0);
  const [noteworthyAccomplishments, setNoteworthyAccomplishments] =
    useState("");
  const [areasRequiringImprovement, setAreasRequiringImprovement] =
    useState("");
  const [improvementActions, setImprovementActions] = useState("");
  const [professionalDevelopmentGoals, setProfessionalDevelopmentGoals] =
    useState("");
  const [explanationOfScore, setExplanationOfScore] = useState("");
  const [jobKnowledgeExplanation, setJobKnowledgeExplanation] = useState("");
  const [sharingKnowledgeExplanation, setSharingKnowledgeExplanation] =
    useState("");
  const [developSkillsExplanation, setDevelopSkillsExplanation] = useState("");
  const [qualityOfWorkExplanation, setQualityOfWorkExplanation] = useState("");
  const [quantityOfWorkExplanation, setQuantityOfWorkExplanation] =
    useState("");
  const [reliabilityExplanation, setReliabilityExplanation] = useState("");
  const [accountabilityExplanation, setAccountabilityExplanation] =
    useState("");
  const [
    initiativeAndCreativityExplanation,
    setInitiativeAndCreativityExplanation,
  ] = useState("");
  const [flexibilityExplanation, setFlexibilityExplanation] = useState("");
  const [judgementExplanation, setJudgementExplanation] = useState("");
  const [cooperationExplanation, setCooperationExplanation] = useState("");
  const [attendanceExplanation, setAttendanceExplanation] = useState("");
  const [
    attitudeRespectfulnessExplanation,
    setAttitudeRespectfulnessExplanation,
  ] = useState("");
  const [acceptsCriticismExplanation, setAcceptsCriticismExplanation] =
    useState("");
  const [policyAndProceduresExplanation, setPolicyAndProceduresExplanation] =
    useState("");
  const [confidentialityExplanation, setConfidentialityExplanation] =
    useState("");
  const [communicationSkillsExplanation, setCommunicationSkillsExplanation] =
    useState("");
  const [conflictResolutionExplanation, setConflictResolutionExplanation] =
    useState("");
  const [organizationalSkillsExplanation, setOrganizationalSkillsExplanation] =
    useState("");
  const [customerInteractionExplanation, setCustomerInteractionExplanation] =
    useState("");
  const [appearanceDressCodeExplanation, setAppearanceDressCodeExplanation] =
    useState("");
  const [appearanceOfWorkAreaExplanation, setAppearanceOfWorkAreaExplanation] =
    useState("");
  const [
    planningAndOrganizingExplanation,
    setPlanningAndOrganizingExplanation,
  ] = useState("");
  const [
    directingAndControllingExplanation,
    setDirectingAndControllingExplanation,
  ] = useState("");
  const [decisionMakerExplanation, setDecisionMakerExplanation] = useState("");
  const totalOfRatings =
    jobKnowledge +
    sharingKnowledge +
    developSkills +
    qualityOfWork +
    quantityOfWork +
    reliability +
    accountability +
    initiativeAndCreativity +
    flexibility +
    judgement +
    cooperation +
    attendance +
    attitudeRespectfulness +
    acceptsCriticism +
    policyAndProcedures +
    confidentiality +
    communicationSkills +
    conflictResolution +
    organizationalSkills +
    customerInteraction +
    appearanceDressCode +
    planningAndOrganizing +
    directingAndControlling +
    decisionMaker +
    appearanceOfWorkArea;
  //   Form Handling
  const formData = {
    explanationOfScore,
    totalOfRatings,
    jobKnowledge,
    sharingKnowledge,
    developSkills,
    qualityOfWork,
    quantityOfWork,
    reliability,
    accountability,
    initiativeAndCreativity,
    flexibility,
    judgement,
    cooperation,
    attendance,
    attitudeRespectfulness,
    acceptsCriticism,
    policyAndProcedures,
    confidentiality,
    communicationSkills,
    conflictResolution,
    organizationalSkills,
    customerInteraction,
    appearanceDressCode,
    planningAndOrganizing,
    directingAndControlling,
    decisionMaker,
    appearanceOfWorkArea,
    lastUpdated: date,
    noteworthyAccomplishments,
    areasRequiringImprovement,
    improvementActions,
    professionalDevelopmentGoals,
    employeeID: props.selectedUserID,
    jobKnowledgeExplanation,
    sharingKnowledgeExplanation,
    developSkillsExplanation,
    qualityOfWorkExplanation,
    quantityOfWorkExplanation,
    reliabilityExplanation,
    accountabilityExplanation,
    initiativeAndCreativityExplanation,
    flexibilityExplanation,
    judgementExplanation,
    cooperationExplanation,
    attendanceExplanation,
    attitudeRespectfulnessExplanation,
    acceptsCriticismExplanation,
    policyAndProceduresExplanation,
    confidentialityExplanation,
    communicationSkillsExplanation,
    conflictResolutionExplanation,
    organizationalSkillsExplanation,
    customerInteractionExplanation,
    appearanceDressCodeExplanation,
    appearanceOfWorkAreaExplanation,
    planningAndOrganizingExplanation,
    directingAndControllingExplanation,
    decisionMakerExplanation,
  };

  const handleBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleSelectBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleBlurPerformanceReviewComment = async (e, i) => {
    console.log("Calculating New Premium");
    // e.preventDefault();
    const values = [...performanceReviewComments];
    values[i].totalPremium = parseFloat(
      (
        values[i].premium +
        values[i].SLtax +
        values[i].companyFee +
        values[i].policyFee +
        values[i].SLstampFee
      ).toFixed(2)
    );
    console.log(formData);
    setPerformanceReviewComments(values);
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const handleChangeInputPerformanceReviewComment = (i, e) => {
    const values = [...performanceReviewComments];
    values[i][e.target.name] = e.target.value;
    setPerformanceReviewComments(values);
  };

  const handleAddPerformanceReviewComment = (e) => {
    e.preventDefault();
    const i = performanceReviewComments.length;
    if (i > 4) return;
    setPerformanceReviewComments([
      ...performanceReviewComments,
      {
        description: "",
      },
    ]);
  };

  const handleSubtractPerformanceReviewComment = async (e, i) => {
    e.preventDefault();
    const values = [...performanceReviewComments];
    values.splice(i, 1);
    setPerformanceReviewComments([...values]);
    if (values.length === 0) {
      setPerformanceReviewComments([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(proposalRef, { performanceReviewComments: values });
  };

  const handleChangeInputNote = (i, e) => {
    const values = [...notes];
    values[i][e.target.name] = e.target.value;
    setNotes(values);
  };

  const handleAddNote = (e) => {
    e.preventDefault();
    const i = notes.length;
    if (i > 4) return;
    setNotes([
      ...notes,
      {
        description: "",
      },
    ]);
  };

  const handleSubtractNote = async (e, i) => {
    e.preventDefault();
    const values = [...notes];
    values.splice(i, 1);
    setNotes([...values]);
    if (values.length === 0) {
      setNotes([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(proposalRef, { notes: values });
  };

  const handleChangeInputSupervisorComment = (i, e) => {
    const values = [...supervisorComments];
    values[i][e.target.name] = e.target.value;
    setSupervisorComments(values);
  };

  const handleAddSupervisorComment = (e) => {
    e.preventDefault();
    const i = supervisorComments.length;
    if (i > 4) return;
    setSupervisorComments([
      ...supervisorComments,
      {
        description: "",
      },
    ]);
  };

  const handleSubtractSupervisorComment = async (e, i) => {
    e.preventDefault();
    const values = [...supervisorComments];
    values.splice(i, 1);
    setSupervisorComments([...values]);
    if (values.length === 0) {
      setSupervisorComments([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(proposalRef, { supervisorComments: values });
  };

  // Show or Hide
  const [showPerformanceReviewsForm, setShowPerformanceReviewsForm] =
    useState(false);

  //   Clear the form
  const clearForm = () => {
    setNewPerformanceReviewTitle("");
    setPerformanceReviewComments([{ description: "" }]);
    setNotes([{ description: "" }]);
    setSupervisorComments([{ description: "" }]);
  };

  //   Submit the performanceReviews & close the form
  const [performanceReviewsSubmitted, setPerformanceReviewsSubmitted] =
    useState(false);
  const submitPerformanceReviews = async (e) => {
    e.preventDefault();
    const docRef = await addDoc(collection(db, "hr-performanceReviews"), {
      ...formData,
      hideOnList: true,
      status: "",
      createdOn: new Date(),
      createdBy: auth.currentUser.uid,
      author: auth.currentUser.displayName,
      notes,
      replies: [],
    });
    console.log("Document written with ID: ", docRef.id);
    clearForm();
    setShowPerformanceReviewsForm(false);
    setPerformanceReviewsSubmitted(true);
    props.setHRperformanceReviews([]);
    props.setGotPerformanceReviews(false);
    props.setPerformanceReviewsUpdated(props.performanceReviewsUpdated + 1);
  };

  const [performanceReviewLocation, setPerformanceReviewLocation] =
    useState("");
  const [performanceReviewDate, setPerformanceReviewDate] = useState();
  const [satisfactionValue, setSatisfactionValue] = React.useState(0);
  const handleChangeSatisfaction = (event, newValue, changer) => {
    changer(newValue);
  };

  useEffect(() => {}, [
    showPerformanceReviewsForm,
    performanceReviewsSubmitted,
  ]);

  return (
    <div className="card">
      <div className="card-header">
        <button
          onClick={(e) => {
            setShowPerformanceReviewsForm(!showPerformanceReviewsForm);
            setPerformanceReviewsSubmitted(false);
          }}
          className="text-lg font-bold text-sky-600"
        >
          Add Performance Review
        </button>
        {performanceReviewsSubmitted && (
          <p className="p-2 w-full bg-green-200 float-right font-bold mb-2">
            Your performance review was successfully submitted.
          </p>
        )}
      </div>
      {showPerformanceReviewsForm && (
        <div className="card-body">
          {/* Ratings */}
          <form>
            {/* Instructions */}
            <article>
              <p>
                <b>Instructions:</b> Rate the employee's performance during the
                review period by checking the most appropriate numerical value
                in each section. To determine the overall performance rating,
                add the numerical values together and divide by eight (or 11 if
                the supervisor section was completed). Prior to the performance
                discussion with the employee, a detailed plan to address areas
                rated "needs improvement" or "unacceptable" must be submitted to
                the department head and human resources for review.
              </p>
            </article>
            {/* Rating Scale */}
            <article className="mt-2 ml-3">
              <p className="font-bold"> Rating scale:</p>
              <p> 5 - Excellent (consistently exceeds standards)</p>
              <p> 4 - Outstanding (frequently exceeds standards)</p>
              <p> 3 - Satisfactory (generally meets standards)</p>
              <p> 2 - Needs improvement (frequently fails to meet standards)</p>
              <p> 1 - Unacceptable (fails to meet standards)</p>
            </article>
            {/* Ratings */}
            <label className="capitalize mt-4 font-bold">job Knowledge</label>
            <p classname="text-black">
              Knowledge of products, policies and procedures; OR knowledge of
              techniques, skills, equipment, procedures, and materials.
            </p>

            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="jobKnowledge"
                defaultValue={0}
                value={jobKnowledge}
                onChange={(e) =>
                  handleChangeSatisfaction(e, e.target.value, setJobKnowledge)
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={jobKnowledgeExplanation}
              onChange={(e) => setJobKnowledgeExplanation(e.target.value)}
              placeholder="Explanation For Job Knowledge Score"
            />
            <label className="capitalize mt-4 font-bold">
              sharing Knowledge
            </label>
            <p classname="text-black">
              The capacity to train the team and share knowledge. Being
              cooperative and open to share.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="sharingKnowledge"
                defaultValue={0}
                value={sharingKnowledge}
                onChange={(e) =>
                  handleChangeSatisfaction(
                    e,
                    e.target.value,
                    setSharingKnowledge
                  )
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={sharingKnowledgeExplanation}
              onChange={(e) => setSharingKnowledgeExplanation(e.target.value)}
              placeholder="Explanation For Sharing Knowledge Score"
            />
            <label className="capitalize mt-4 font-bold">
              Willingness to Develop Skills
            </label>
            <p classname="text-black">
              Desires to take on challenges and learn new techniques.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="developSkills"
                defaultValue={0}
                value={developSkills}
                onChange={(e) =>
                  handleChangeSatisfaction(e, e.target.value, setdevelopSkills)
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={developSkillsExplanation}
              onChange={(e) => setDevelopSkillsExplanation(e.target.value)}
              placeholder="Explanation For Develop Skills Score"
            />
            <label className="capitalize mt-4 font-bold">quality Of Work</label>
            <p classname="text-black">
              Freedom from errors and mistakes. Accuracy, quality of work in
              general.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="qualityOfWork"
                defaultValue={0}
                value={qualityOfWork}
                onChange={(e) =>
                  handleChangeSatisfaction(e, e.target.value, setQualityOfWork)
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={qualityOfWorkExplanation}
              onChange={(e) => setQualityOfWorkExplanation(e.target.value)}
              placeholder="Explanation For Quality of Work Score"
            />
            <label className="capitalize mt-4 font-bold">
              quantity Of Work
            </label>
            <p classname="text-black">Productivity of the employee.</p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="quantityOfWork"
                defaultValue={0}
                value={quantityOfWork}
                onChange={(e) =>
                  handleChangeSatisfaction(e, e.target.value, setQuantityOfWork)
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={quantityOfWorkExplanation}
              onChange={(e) => setQuantityOfWorkExplanation(e.target.value)}
              placeholder="Explanation For Productivity Score"
            />
            <label className="capitalize mt-4 font-bold">reliability</label>
            <p classname="text-black">
              The extent to which the employee can be depended upon to be
              available for work, to complete work properly, and complete work
              on time. The degree to which the employee is reliable,
              trustworthy, and persistent.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="reliability"
                defaultValue={0}
                value={reliability}
                onChange={(e) =>
                  handleChangeSatisfaction(e, e.target.value, setReliability)
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={reliabilityExplanation}
              onChange={(e) => setReliabilityExplanation(e.target.value)}
              placeholder="Explanation For Reliability Score"
            />
            <label className="capitalize mt-4 font-bold">accountability</label>
            <p classname="text-black">
              Accepts responsibility for actions, answerable to consequences.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="accountability"
                defaultValue={0}
                value={accountability}
                onChange={(e) =>
                  handleChangeSatisfaction(e, e.target.value, setAccountability)
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={accountabilityExplanation}
              onChange={(e) => setAccountabilityExplanation(e.target.value)}
              placeholder="Explanation For Accountability Score"
            />
            <label className="capitalize mt-4 font-bold">
              initiative And Creativity
            </label>
            <p classname="text-black">
              The ability to plan work and to proceed with a task without being
              told every detail and the ability to make constructive
              suggestions.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="initiativeAndCreativity"
                defaultValue={0}
                value={initiativeAndCreativity}
                onChange={(e) =>
                  handleChangeSatisfaction(
                    e,
                    e.target.value,
                    setInitiativeAndCreativity
                  )
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={initiativeAndCreativityExplanation}
              onChange={(e) =>
                setInitiativeAndCreativityExplanation(e.target.value)
              }
              placeholder="Explanation For Initiative Score"
            />
            <label className="capitalize mt-4 font-bold">flexibility</label>
            <p classname="text-black">
              Has capacity to respond to changing situations and expectations.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="flexibility"
                defaultValue={0}
                value={flexibility}
                onChange={(e) =>
                  handleChangeSatisfaction(e, e.target.value, setFlexibility)
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={flexibilityExplanation}
              onChange={(e) => setFlexibilityExplanation(e.target.value)}
              placeholder="Explanation For Flexibility Score"
            />
            <label className="capitalize mt-4 font-bold">judgement</label>
            <p classname="text-black">
              The extent to which the employee makes decisions that are sound.
              The ability to base decisions on fact rather than emotion.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="judgement"
                defaultValue={0}
                value={judgement}
                onChange={(e) =>
                  handleChangeSatisfaction(e, e.target.value, setJudgement)
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={judgementExplanation}
              onChange={(e) => setJudgementExplanation(e.target.value)}
              placeholder="Explanation For   Score"
            />
            <label className="capitalize mt-4 font-bold">cooperation</label>
            <p classname="text-black">
              Willingness to work harmoniously with others in getting a job
              done. Readiness to respond positively to instructions and
              procedures.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="cooperation"
                defaultValue={0}
                value={cooperation}
                onChange={(e) =>
                  handleChangeSatisfaction(e, e.target.value, setCooperation)
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={cooperationExplanation}
              onChange={(e) => setCooperationExplanation(e.target.value)}
              placeholder="Explanation For Cooperation  Score"
            />
            <label className="capitalize mt-4 font-bold">attendance</label>
            <p classname="text-black">
              Consistency in coming to work daily and conforming to scheduled
              work hours. Keeping up with tracker process.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="attendance"
                defaultValue={0}
                value={attendance}
                onChange={(e) =>
                  handleChangeSatisfaction(e, e.target.value, setAttendance)
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={attendanceExplanation}
              onChange={(e) => setAttendanceExplanation(e.target.value)}
              placeholder="Explanation For Attendance Score"
            />
            <label className="capitalize mt-4 font-bold">
              attitude and Respectfulness
            </label>
            <p classname="text-black">
              Shows initiative, optimism, and politeness.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="attitudeRespectfulness"
                defaultValue={0}
                value={attitudeRespectfulness}
                onChange={(e) =>
                  handleChangeSatisfaction(
                    e,
                    e.target.value,
                    setAttitudeRespectfulness
                  )
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={attitudeRespectfulnessExplanation}
              onChange={(e) =>
                setAttitudeRespectfulnessExplanation(e.target.value)
              }
              placeholder="Explanation For Attitude & Respectfulness Score"
            />
            <label className="capitalize mt-4 font-bold">
              accepts Criticism
            </label>
            <p classname="text-black">
              Has the ability to learn from suggestions and change behavior.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="acceptsCriticism"
                defaultValue={0}
                value={acceptsCriticism}
                onChange={(e) =>
                  handleChangeSatisfaction(
                    e,
                    e.target.value,
                    setAcceptsCriticism
                  )
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={acceptsCriticismExplanation}
              onChange={(e) => setAcceptsCriticismExplanation(e.target.value)}
              placeholder="Explanation For Accepts Criticism Score"
            />
            <label className="capitalize mt-4 font-bold">
              policy And Procedures
            </label>
            <p classname="text-black">
              Follows organization's policies and procedures.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="policyAndProcedures"
                defaultValue={0}
                value={policyAndProcedures}
                onChange={(e) =>
                  handleChangeSatisfaction(
                    e,
                    e.target.value,
                    setPolicyAndProcedures
                  )
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={policyAndProceduresExplanation}
              onChange={(e) =>
                setPolicyAndProceduresExplanation(e.target.value)
              }
              placeholder="Explanation For Follows Policy & Procedures Score"
            />
            <label className="capitalize mt-4 font-bold">confidentiality</label>
            <p classname="text-black">
              Does not discuss internal events with coworkers
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="confidentiality"
                defaultValue={0}
                value={confidentiality}
                onChange={(e) =>
                  handleChangeSatisfaction(
                    e,
                    e.target.value,
                    setConfidentiality
                  )
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={confidentialityExplanation}
              onChange={(e) => setConfidentialityExplanation(e.target.value)}
              placeholder="Explanation For Confidentiality Score"
            />
            <label className="capitalize mt-4 font-bold">
              communication Skills
            </label>
            <p classname="text-black">
              Conveys information effectively and efficiently.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="communicationSkills"
                defaultValue={0}
                value={communicationSkills}
                onChange={(e) =>
                  handleChangeSatisfaction(
                    e,
                    e.target.value,
                    setCommunicationSkills
                  )
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={communicationSkillsExplanation}
              onChange={(e) =>
                setCommunicationSkillsExplanation(e.target.value)
              }
              placeholder="Explanation For Communication Skills Score"
            />
            <label className="capitalize mt-4 font-bold">
              conflict Resolution
            </label>
            <p classname="text-black">
              Seeks constructive approaches to resolving workplace issues.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="conflictResolution"
                defaultValue={0}
                value={conflictResolution}
                onChange={(e) =>
                  handleChangeSatisfaction(
                    e,
                    e.target.value,
                    setConflictResolution
                  )
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={conflictResolutionExplanation}
              onChange={(e) => setConflictResolutionExplanation(e.target.value)}
              placeholder="Explanation For   Score"
            />
            <label className="capitalize mt-4 font-bold">
              organizational Skills
            </label>
            <p classname="text-black">
              Has capacity to stay on track and use time effectively.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="organizationalSkills"
                defaultValue={0}
                value={organizationalSkills}
                onChange={(e) =>
                  handleChangeSatisfaction(
                    e,
                    e.target.value,
                    setOrganizationalSkills
                  )
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={organizationalSkillsExplanation}
              onChange={(e) =>
                setOrganizationalSkillsExplanation(e.target.value)
              }
              placeholder="Explanation For Organizational Skills Score"
            />
            <label className="capitalize mt-4 font-bold">
              customer Interaction
            </label>
            <p classname="text-black">
              High-quality service, respects clients and goes above and beyond.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="customerInteraction"
                defaultValue={0}
                value={customerInteraction}
                onChange={(e) =>
                  handleChangeSatisfaction(
                    e,
                    e.target.value,
                    setCustomerInteraction
                  )
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={customerInteractionExplanation}
              onChange={(e) =>
                setCustomerInteractionExplanation(e.target.value)
              }
              placeholder="Explanation For Customer Interaction Score"
            />
            <label className="capitalize mt-4 font-bold">
              appearance / Dress Code
            </label>
            <p classname="text-black">
              Demonstrates a professional and well-kept appearance.
            </p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="appearanceDressCode"
                defaultValue={0}
                value={appearanceDressCode}
                onChange={(e) =>
                  handleChangeSatisfaction(
                    e,
                    e.target.value,
                    setAppearanceDressCode
                  )
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={appearanceDressCodeExplanation}
              onChange={(e) =>
                setAppearanceDressCodeExplanation(e.target.value)
              }
              placeholder="Explanation For Appearance & Dress Code Score"
            />
            <label className="capitalize mt-4 font-bold">
              appearance Of Work Area
            </label>
            <p classname="text-black">Keeps the work area neat and orderly.</p>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="appearanceOfWorkArea"
                defaultValue={0}
                value={appearanceOfWorkArea}
                onChange={(e) =>
                  handleChangeSatisfaction(
                    e,
                    e.target.value,
                    setAppearanceOfWorkArea
                  )
                }
                valueLabelDisplay="auto"
                note={1}
                marks={marks}
                min={0}
                max={5}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <input
              asdf
              className="form-control rounded mt-4 mb-2"
              type="text"
              value={appearanceOfWorkAreaExplanation}
              onChange={(e) =>
                setAppearanceOfWorkAreaExplanation(e.target.value)
              }
              placeholder="Explanation For Appearance of Work Area Score"
            />
            {props.selectedUserInfo.supervisor && (
              <article>
                <label className="capitalize mt-4 font-bold">
                  supervising: planning And Organizing
                </label>
                <p classname="text-black">
                  The ability to analyze work, set goals, develop plans of
                  action, utilize time. Consider the amount of supervision
                  required and the extent to which you can trust employees to
                  carry out assignments conscientiously.
                </p>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ mb: 1 }}
                  alignItems="center"
                >
                  <SentimentVeryDissatisfiedIcon />
                  <Slider
                    aria-label="planningAndOrganizing"
                    defaultValue={0}
                    value={planningAndOrganizing}
                    onChange={(e) =>
                      handleChangeSatisfaction(
                        e,
                        e.target.value,
                        setPlanningAndOrganizing
                      )
                    }
                    valueLabelDisplay="auto"
                    note={1}
                    marks={marks}
                    min={0}
                    max={5}
                  />
                  <SentimentSatisfiedAltIcon />
                </Stack>
                <input
                  asdf
                  className="form-control rounded mt-4 mb-2"
                  type="text"
                  value={planningAndOrganizingExplanation}
                  onChange={(e) =>
                    setPlanningAndOrganizingExplanation(e.target.value)
                  }
                  placeholder="Explanation For Planning & Organizing Score"
                />
                <label className="capitalize mt-4 font-bold">
                  supervising: directing And Controlling
                </label>
                <p classname="text-black">
                  The ability to create a motivating climate, achieve teamwork,
                  train and develop, measure work in progress, take corrective
                  action.
                </p>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ mb: 1 }}
                  alignItems="center"
                >
                  <SentimentVeryDissatisfiedIcon />
                  <Slider
                    aria-label="directingAndControlling"
                    defaultValue={0}
                    value={directingAndControlling}
                    onChange={(e) =>
                      handleChangeSatisfaction(
                        e,
                        e.target.value,
                        setDirectingAndControlling
                      )
                    }
                    valueLabelDisplay="auto"
                    note={1}
                    marks={marks}
                    min={0}
                    max={5}
                  />
                  <SentimentSatisfiedAltIcon />
                </Stack>
                <input
                  asdf
                  className="form-control rounded mt-4 mb-2"
                  type="text"
                  value={directingAndControllingExplanation}
                  onChange={(e) =>
                    setDirectingAndControllingExplanation(e.target.value)
                  }
                  placeholder="Explanation For Directing & Controlling Score"
                />
                <label className="capitalize mt-4 font-bold">
                  supervising: decision Maker
                </label>
                <p classname="text-black">
                  The ability to make decisions and the quality and timeliness
                  of those decisions.
                </p>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ mb: 1 }}
                  alignItems="center"
                >
                  <SentimentVeryDissatisfiedIcon />
                  <Slider
                    aria-label="decisionMaker"
                    defaultValue={0}
                    value={decisionMaker}
                    onChange={(e) =>
                      handleChangeSatisfaction(
                        e,
                        e.target.value,
                        setDecisionMaker
                      )
                    }
                    valueLabelDisplay="auto"
                    note={1}
                    marks={marks}
                    min={0}
                    max={5}
                  />
                  <SentimentSatisfiedAltIcon />
                </Stack>
                <input
                  asdf
                  className="form-control rounded mt-4 mb-2"
                  type="text"
                  value={decisionMakerExplanation}
                  onChange={(e) => setDecisionMakerExplanation(e.target.value)}
                  placeholder="Explanation For Decision Maker Score"
                />
              </article>
            )}
          </form>
          {/* Qualitative */}
          <section>
            <label className="capitalize mt-4 font-bold">
              Noteworthy Accomplishments During Review Period:
            </label>
            <textarea
              onChange={(e) => setNoteworthyAccomplishments(e.target.value)}
              onBlur={(e) => handleBlur(e)}
              onKeyDown={handleEnter}
              name="description"
              className="form-control rounded mb-2"
              type="text"
              placeholder="Noteworthy Accomplishments"
              value={noteworthyAccomplishments}
            />
            <label className="capitalize mt-2 font-bold">
              Areas requiring improvement in job performance:
            </label>
            <textarea
              onChange={(e) => setAreasRequiringImprovement(e.target.value)}
              onBlur={(e) => handleBlur(e)}
              onKeyDown={handleEnter}
              name="description"
              className="form-control rounded mb-2"
              type="text"
              placeholder="Areas Requiring Improvement"
              value={areasRequiringImprovement}
            />
            <label className="capitalize mt-2 font-bold">
              Actions taken to improve performance from the previous review:
            </label>
            <textarea
              onChange={(e) => setImprovementActions(e.target.value)}
              onBlur={(e) => handleBlur(e)}
              onKeyDown={handleEnter}
              name="description"
              className="form-control rounded mb-2"
              type="text"
              placeholder="Actions taken to improve performance"
              value={improvementActions}
            />
            <label className="capitalize mt-2 font-bold">
              Professional development goals:
            </label>
            <textarea
              onChange={(e) => setProfessionalDevelopmentGoals(e.target.value)}
              onBlur={(e) => handleBlur(e)}
              onKeyDown={handleEnter}
              name="description"
              className="form-control rounded mb-2"
              type="text"
              placeholder="Professional development goals"
              value={professionalDevelopmentGoals}
            />
            <label className="capitalize mt-2 font-bold">
              Explanation of Overall Performance Rating of {totalOfRatings}:
            </label>
            <textarea
              onChange={(e) => setExplanationOfScore(e.target.value)}
              onBlur={(e) => handleBlur(e)}
              onKeyDown={handleEnter}
              name="description"
              className="form-control rounded mb-2"
              type="text"
              placeholder={
                "Explanation of Overall Performance Rating of " + totalOfRatings
              }
              value={explanationOfScore}
            />
          </section>

          <button
            onClick={(e) => submitPerformanceReviews(e)}
            className="block font-medium text-white my-1 p-2 rounded bg-green-900 hover:bg-green-700"
          >
            Submit Review
          </button>

          <p className="text-xs">
            You may not edit a Performance Review once it is submitted.
          </p>
        </div>
      )}
    </div>
  );
}

export default PerformanceReviewsForm;
