import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  orderBy,
  setDoc,
  where,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import "../app.css";
import WarningsForm from "./WarningsForm";
import Modal from "react-modal";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import axios from "axios";
import Card from "react-bootstrap/Card";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const marks = [
  {
    value: 1,
    label: "1: Unacceptable",
  },
  {
    value: 2,
    label: "2: Needs Improvement",
  },
  {
    value: 3,
    label: "3: Meets Expectations",
  },
  {
    value: 4,
    label: "4: Exceeds Expectations",
  },
  {
    value: 5,
    label: "5: Outstanding",
  },
];

function HumanResourcesWarning(props) {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  // Props
  const selectedUserID = props.selectedUserID;
  // Date Stuff
  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString().slice(3, 15);
  };

  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  console.log("selected user id" + selectedUserID);

  // Get HR Warnings
  const [HRwarnings, setHRwarnings] = useState([]);
  const [gotWarnings, setGotWarnings] = useState(false);
  const [warningsUpdated, setWarningsUpdated] = useState(1);
  const getHRwarnings = async () => {
    setHRwarnings([]);
    setGotWarnings(false);
    const q = query(
      collection(db, "hr-warnings"),
      where("employeeID", "==", props.selectedUserID)
    );

    const querySnapshot = await getDocs(q);

    let values = HRwarnings;
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      doc.data().status !== "Deleted" &&
      values.push({
        ...doc.data(),
        id: doc.id,
        createdOn: convertDate(doc.data().createdOn.seconds),
      });
    });
    console.log(values);
    setHRwarnings(values);
    setGotWarnings(true);
    console.log(HRwarnings);
  };

  const addComment = async () => {};

  const updateWarnings = async (id, array) => {
    const docRef = doc(db, "hr-warnings", id);
    setDoc(docRef, { replies: array }, { merge: true });
    setWarningsUpdated(warningsUpdated + 1);
  };

  const [newWarningTypeStatus, setNewWarningTypeStatus] = useState();
  const [warningID, setWarningID] = useState();
  const [warningIterator, setWarningIterator] = useState();

  const updateWarningStatus = async (e, newStatus, id, i) => {
    e.preventDefault();
    await updateDoc(doc(db, "hr-warnings", id), {
      status: newStatus,
    });
    HRwarnings[i].status = newStatus;
    setGotWarnings(false);
    getHRwarnings();
    closeModal();
  };

  const [rating, setrating] = React.useState(0);
  const handleChangeRating = (event, newValue) => {
    setrating(newValue);
  };

  const [showReviewRequest, setShowReviewRequest] = useState(false);
  const [reason, setReason] = useState();
  const [showResponseSuccess, setShowResponseSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/licona/request-for-warning",
        {
          to: "elpaso_csr@liconainsurance.com",
          cc: ["colt@southernstarmga.com"],
          requester: auth.currentUser.displayName,
          requesterID: auth.currentUser.uid,
          createdOn: new Date().toLocaleString().split(",")[0],
          employeeName:
            props.selectedUserInfo.first + " " + props.selectedUserInfo.last,
          employeeID: props.selectedUserID,
          reason: reason,
          typeOfWarning,
          comments,
          status: "Incomplete",
        }
      )
      .then(async (response) => {
        // handle success
        const docRef = await addDoc(collection(db, "warning-requests"), {
          requester: auth.currentUser.displayName,
          requesterID: auth.currentUser.uid,
          createdOn: new Date(),
          employeeName:
            props.selectedUserInfo.first + " " + props.selectedUserInfo.last,
          employeeID: props.selectedUserID,
          reason: reason,
          typeOfWarning,
          comments,
          status: "Incomplete",
        });
      })
      .then(() => {
        setLoading(false);
        setReason();
        setTypeOfWarning();
        setComments();
        setShowResponseSuccess(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const [comments, setComments] = useState();
  const [typeOfWarning, setTypeOfWarning] = useState();

  const [warningRequestsList, setWarningRequestsList] = useState([]);
  const [gotWarningRequests, setGotWarningRequests] = useState(false);
  const getWarningRequests = async () => {

  };
  const deleteWarningRequests = async (docID) => {
    let verify = prompt(
      "You are about to delete a Warning Request! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "warning-requests", docID));
      getWarningRequests();
      // Log System Activity
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          "A warning request was deleted by: " + auth.currentUser.displayName,
      });
    }
  };

  useEffect(() => {
    !gotWarnings && getHRwarnings();
    !gotWarningRequests && getWarningRequests();
  }, [selectedUserID, gotWarnings, warningsUpdated]);

  return (
    <section>
      {warningRequestsList.map((warningRequests, i) => {
        if (warningRequests.status === "Incomplete" && props.userInfo.admin)
          if (!warningRequests.dismissedByUser)
            return (
              <li key={"warningRequests" + i} className="mb-3">
                <Card>
                  <Card.Header className="bg-red-300">
                    <span className="font-bold text-lg">Warning Request </span>
                    {warningRequests.authorName}{" "}
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <p className="mt-2">
                        A warning request was submitted for{" "}
                        {warningRequests.employeeName} by Supervisor{" "}
                        {warningRequests.requester}:
                      </p>
                      <div className="ml-5">
                        <p>
                          Reason: <i>{warningRequests.reason}</i>
                        </p>
                        <p>
                          Type Of Warning:{" "}
                          <i>{warningRequests.typeOfWarning}</i>
                        </p>
                        <p>
                          Comments: <i>{warningRequests.comments}</i>
                        </p>
                      </div>
                      <p className="my-2">
                        Dismissing this notification will not mark the request
                        as complete, it will only hide the notification.
                      </p>
                      <button
                        onClick={async (e) => {
                          await updateDoc(
                            doc(db, "warning-requests", warningRequests.id),
                            {
                              status: "Complete",
                            }
                          );
                          getWarningRequests();
                        }}
                        className="mt-2 rounded py-2 px-3 bg-sky-600 hover:bg-sky-900 text-white font-[600]"
                      >
                        Mark As Complete
                      </button>
                    </Card.Text>
                  </Card.Body>
                  {props.userInfo.admin && (
                    <Card.Footer>
                      <button
                        onClick={(e) =>
                          deleteWarningRequests(warningRequests.id)
                        }
                        className="text-red-600"
                      >
                        Delete This Request
                      </button>
                    </Card.Footer>
                  )}
                </Card>
              </li>
            );
      })}
      <h1 className="text-2xl font-bold text-red-900">Add Employee Warning</h1>
      <div className="p-3">

        <WarningsForm
          setHRwarnings={setHRwarnings}
          setGotWarnings={setGotWarnings}
          selectedUserID={selectedUserID}
          warningsUpdated={warningsUpdated}
          setWarningsUpdated={setWarningsUpdated}
        />
      </div>
      <ul className="list mt-3">
        {gotWarnings === true && (
          <>
            <h1 className="text-2xl font-bold text-red-900">
              Warning History
            </h1>
            <div className="p-3">
              {HRwarnings.map((warnings, i) => (
                <div key={"warnings-container" + i} className="mb-3 card">
                  <div className="card-header text-lg">
                    <button
                      onClick={(e) => {
                        HRwarnings[i].hideOnList = !HRwarnings[i].hideOnList;
                        setWarningsUpdated(warningsUpdated + 1);
                      }}
                      className="font-bold mr-5 text-sky-600 capitalize"
                    >
                      {warnings.newWarningType} on{" "}
                      {convertDate(warnings.warningDate.seconds)} at{" "}
                      {warnings.warningLocation}
                    </button>
                  </div>
                  {HRwarnings[i]?.hideOnList ? (
                    <></>
                  ) : (
                    <div className="card-body text-lg">
                      {/* Warning Title */}
                      <div className="card">
                        <div className="card-header">
                          <h3 className="font-bold">Warning Type</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {warnings?.newWarningType}
                          </p>
                        </div>
                      </div>
                      {/* Warning Location */}
                      <div className="card mt-3">
                        <div className="card-header">
                          <h3 className="font-bold">Incident Location</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {warnings?.warningLocation}
                          </p>
                        </div>
                      </div>
                      {/* Warning Date */}
                      <div className="card mt-3">
                        <div className="card-header">
                          <h3 className="font-bold">Incident Date</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {convertDate(warnings?.warningDate.seconds)}
                          </p>
                        </div>
                      </div>
                      {/* Incident Description */}
                      <div className="card mt-3">
                        <div className="card-header">
                          <h3 className="font-bold">Incident Description</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {warnings?.incidentDescription}
                          </p>
                        </div>
                      </div>
                      {/* Employee Explanation */}
                      <div className="card mt-3">
                        <div className="card-header">
                          <h3 className="font-bold">Employee Explanation</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {warnings?.employeeExplanation}
                          </p>
                        </div>
                      </div>
                      {/* Improvement Plan */}
                      <div className="card mt-3">
                        <div className="card-header">
                          <h3 className="font-bold">Improvement Plan</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {warnings?.planForImprovement}
                          </p>
                        </div>
                      </div>
                      {/* Next Consequence */}
                      <div className="card mt-3">
                        <div className="card-header">
                          <h3 className="font-bold">Next Consequence</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {warnings?.furtherConsequences}
                          </p>
                        </div>
                      </div>
                      {/* Supervisor's Statement */}
                      <div className="card mt-3">
                        <div className="card-header">
                          <h3 className="font-bold">Supervisor's Statement</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {warnings?.supervisorStatement}
                          </p>
                        </div>
                      </div>

                      <hr className="my-3" />
                      {/* Comment List */}
                      <h3 className="font-bold mt-2">Comments:</h3>
                      <ul className="list ml-2">
                        {warnings.replies?.map((reply, iterator) => {
                          return (
                            <>
                              {!reply.reply ? (
                                <></>
                              ) : (
                                <li
                                  className="test rounded shadow-md m-3"
                                  key={"reply" + iterator}
                                >
                                  <p className="italic">
                                    {reply.sender} on {reply.date}:
                                  </p>
                                  <p className="block">{reply.reply}</p>
                                  {auth.currentUser.uid === reply.createdBy && (
                                    <>
                                      <button
                                        onClick={() => {
                                          HRwarnings[i].replies.splice(
                                            iterator,
                                            1
                                          );
                                          updateWarnings(
                                            warnings.id,
                                            HRwarnings[i].replies
                                          );
                                        }}
                                        className="text-xs m-1 text-red-900"
                                      >
                                        Delete Comment
                                      </button>
                                    </>
                                  )}
                                </li>
                              )}
                            </>
                          );
                        })}
                      </ul>

                      {/* Add Reply */}
                      <div className="ml-2">
                        <hr className="my-2" />
                        <input
                          id={"reply" + i}
                          type="text"
                          className="border-2 border-red-900 p-1 w-full rounded"
                          placeholder="Enter your reply here..."
                        />
                        <button
                          onClick={(e) => {
                            HRwarnings[i].replies.push({
                              date: date,
                              reply: document.getElementById("reply" + i).value,
                              sender: auth.currentUser.displayName,
                              createdBy: auth.currentUser.uid,
                            });
                            updateWarnings(
                              warnings.id,
                              HRwarnings[i].replies
                            );
                            document.getElementById("reply" + i).value = "";
                          }}
                          className="block font-medium text-white my-1 px-2 py-1 rounded bg-green-900 hover:bg-green-700"
                        >
                          Add Comment
                        </button>
                      </div>
                      <hr />
                      {props.userInfo.admin && (
                        <>
                          <button
                            onClick={(e) => {
                              updateWarningStatus(
                                e,
                                "Deleted",
                                warnings.id,
                                i
                              );
                            }}
                            className="m-1 text-red-900"
                          >
                            Delete This Warning
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </ul>
      {/* Modals */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Warning Status Modal"
      >
        <div className="min-w-[200px] p-3">
          <p className="mb-2 font-medium text-lg text-red-900">New Status:</p>
          <form
            onSubmit={(e) =>
              updateWarningStatus(
                e,
                newWarningTypeStatus,
                warningID,
                warningIterator
              )
            }
          >
            <select
              required
              value={newWarningTypeStatus}
              onChange={(e) => setNewWarningTypeStatus(e.target.value)}
              className="w-full"
            >
              <option value="In Progress">In Progress</option>
              <option value="Delayed">Delayed</option>
              <option value="Achieved">Achieved</option>
            </select>
            <div>
              <button
                type="submit"
                className="block w-full mx-auto font-medium text-white mt-3 p-2 rounded bg-green-900 hover:bg-green-700"
              >
                Update Status
              </button>
              <button
                onClick={(e) => {
                  closeModal();
                  setWarningID();
                  setWarningIterator();
                  setNewWarningTypeStatus();
                }}
                className="block w-full mx-auto font-medium text-white mt-3 p-2 rounded bg-red-900 hover:bg-red-700"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </section>
  );
}

export default HumanResourcesWarning;
