import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import TimeOffRequest from "./hr/TimeOffRequest";
import Box from "@mui/material/Box";

export default function SupervisorMenu() {
  const [showPTOModal, setShowPTOModal] = useState(false);
  const handleShowPTOModal = () => setShowPTOModal(true);
  const handleClosePTOModal = () => setShowPTOModal(false);
  return (
    <div>
      <h1 className="mx-auto my-3 text-xl font-bold text-gray-200 text-center">
        Main Menu
      </h1>
      {/* <a
      href="#"
        onClick={handleShowPTOModal}
        className="rounded shadow relative mb-3 min-w-[225px]  max-w-[225px] mx-auto p-2 border-gray-200 bg-[#072a48] text-white block mr-5 text-sky-600 hover:opacity-90 hover:text-[#072a48]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-pencil-square absolute left-7"
          viewBox="0 0 16 16"
        >
          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
          <path
            fillRule="evenodd"
            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
          />
        </svg>
        <span className="relative left-16 font-medium">PTO Request</span>
      </a> */}
      <a
        href="/hr"
        className="rounded shadow relative mb-3  max-w-[225px] mx-auto p-2 border-gray-200 bg-[#072a48] text-white block mr-5 text-sky-600 hover:opacity-90 hover:text-[#072a48]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-person-vcard-fill absolute left-7"
          viewBox="0 0 16 16"
        >
          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5ZM9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8Zm1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5Zm-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96c.026-.163.04-.33.04-.5ZM7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
        </svg>
        <span className="relative left-16 font-medium">HR Portal</span>
      </a>
      <a
        href="/directory"
        className="rounded shadow relative mb-3  max-w-[225px] mx-auto p-2 border-gray-200 bg-[#072a48] text-white block mr-5 text-sky-600 hover:opacity-90 hover:text-[#072a48]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-person-badge-fill absolute left-7"
          viewBox="0 0 16 16"
        >
          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z" />
        </svg>
        <span className="relative left-16 font-medium">Directory</span>
      </a>
      {/* <a
        href="/changelog"
        className="rounded shadow relative mb-3  max-w-[225px] mx-auto p-2 border-gray-200 bg-[#072a48] text-white block mr-5 text-sky-600 hover:opacity-90 hover:text-[#072a48]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-file-earmark-check-fill absolute left-7"
          viewBox="0 0 16 16"
        >
          <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm1.354 4.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
        </svg>
        <span className="relative left-16 font-medium">Change Log</span>
      </a>
      <a
        href="/systemlog"
        className="rounded shadow relative mb-3  max-w-[225px] mx-auto p-2 border-gray-200 bg-[#072a48] text-white block mr-5 text-sky-600 hover:opacity-90 hover:text-[#072a48]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-file-earmark-code-fill absolute left-7"
          viewBox="0 0 16 16"
        >
          <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6.646 7.646a.5.5 0 1 1 .708.708L5.707 10l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2zm2.708 0 2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 10 8.646 8.354a.5.5 0 1 1 .708-.708z" />
        </svg>
        <span className="relative left-16 font-medium">System Log</span>
      </a> */}
            {/* PTO Request Modal */}
            <Modal
        show={showPTOModal}
        onClose={handleClosePTOModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <TimeOffRequest handleClosePTOModal={handleClosePTOModal} />
        </Box>
      </Modal>
    </div>
  );
}
