import React, { useEffect, useState } from "react";
import {
  getDocs,
  getDoc,
  doc,
  collection,
  query,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Container } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function EmployeeDirectory(props) {
  const [pageData, setPageData] = useState([]);
  const [gotData, setGotData] = useState(false);
  const getPageData = async () => {
    setGotData(false);
    const q = query(collection(db, "users"));
    const queryData = await getDocs(q);
    setPageData(queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setGotData(true);
  };

  // Edit Status Modal Stuff

  const [newQuestionLabel, setNewQuestionLabel] = useState("");
  const [newQuestionText, setNewQuestionText] = useState("");
  const [newQuestionType, setNewQuestionType] = useState("");
  const [newQuestionModalIsOpen, setNewQuestionModalIsOpen] =
    React.useState(false);

  function openNewQuestionModal(e) {
    // e.preventDefault();
    setNewQuestionModalIsOpen(true);
  }

  function afterOpenNewQuestionModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeNewQuestionModal() {
    setNewQuestionModalIsOpen(false);
  }

  const select = props.selectedStep;
  const setSelect = props.setSelectedStep;
  const [multipleOptions, setMultipleOptions] = useState([
    {
      label: "",
      text: "",
      type: "",
    },
  ]);
  const formData = multipleOptions;

  const handleBlurMultipleOptions = async (e, i) => {
    console.log("Calculating New Premium");
    // e.preventDefault();
    const values = [...multipleOptions];
    values[i].totalPremium = parseFloat(
      (
        values[i].premium +
        values[i].SLtax +
        values[i].companyFee +
        values[i].policyFee +
        values[i].SLstampFee
      ).toFixed(2)
    );
    console.log(formData);
    setMultipleOptions(values);
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const handleSelectBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleChangeInputOption = (e, i) => {
    const values = [...multipleOptions];
    if (e.target.name === "deductible") {
      values[i][e.target.name] = e.target.value;
    } else {
      values[i][e.target.name] = parseFloat(e.target.value);
    }
    setMultipleOptions(values);
  };

  const handleAddOption = async (e) => {
    e.preventDefault();
    const i = SelectedStepData.questions.length;
    const lastPlace = SelectedStepData.questions[i - 1].id;
    SelectedStepData.questions.push({
      label: newQuestionLabel,
      text: newQuestionText,
      type: newQuestionType,
    });
    const docRef = doc(db, "hiring-steps", select);
    await setDoc(
      docRef,
      { questions: SelectedStepData.questions },
      { merge: true }
    );
    getStep();
    closeNewQuestionModal();
  };

  const handleSubtractOption = async (e, i) => {
    e.preventDefault();
    const values = [...multipleOptions];
    values.splice(i, 1);
    setMultipleOptions([...values]);
    if (values.length === 0) {
      setMultipleOptions([
        {
          label: "",
          text: "",
          type: "",
        },
      ]);
    }
    // await updateDoc(proposalRef, { multipleOptions: values });
  };

  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output;
  };

  // const [stepUpdated, setStepUpdated] = useState(false);
  const stepUpdated = props.stepUpdated;
  // const setStepUpdated = props.setStepUpdated;
  const selectedStep = props.selectedStep;
  const setSelectedStep = props.setSelectedStep;
  const [SelectedStepData, setSelectedStepData] = useState({});
  const getStep = async () => {
    console.log("getting step");
    // setStepUpdated(false);
    setGotData(false)
    const docRef = doc(db, "hiring-steps", props.selectedStep);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setSelectedStepData(docSnap.data());
      setGotData(true);
      // setStepUpdated(true);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  useEffect(() => {
    if (props.stepUpdated !== true) getStep();
    console.log("selected step =====> " + props.selectedStep);
  }, [db, props.stepUpdated, props.selectedStep]);

  return (
    <>
      {/* Choose Step */}
      <div>
        {/* <label className="input-label">Choose A Step:</label>
        <select
          onChange={(e) => {
            setSelect(e.target.value);
          }}
          onBlur={(e) => {
            handleSelectBlur(e);
          }}
          onKeyDown={handleEnter}
          className={`text-input ${select === "Choose One" && `bg-red-100`}`}
          value={select}
        >
          <option value="Choose One">Choose One</option>
          <option value="1oMhI1n9fXKG5lkhEQZz">Pre-Screening</option>
          <option value="Skills Assessment">Skills Assessment</option>
          <option value="Phone Interview">Phone Interview</option>
          <option value="In Person Interview 1">In Person Interview 1</option>
          <option value="In Person Interview 2">In Person Interview 2</option>
          <option value="Background Check">Background Check</option>
          <option value="Onboarding">Onboarding</option>
          <option value="Kept On File">Kept On File</option>
          <option value="Denied">Denied</option>
        </select>
        <button className="bg-primary mb-2 rounded p-2" onClick={getStep}>
          Continue
        </button> */}

        <>
          <hr className="mb-2 mt-3" />
          <h1 className="text-lg font-medium text-red-900">
            {SelectedStepData.name}
          </h1>
          <p> {SelectedStepData.description}</p>
          {gotData === true && (
            <>
              {SelectedStepData.questions.map((question, i) => {
                return (
                  <div key={"question" + i}>
                    <p className="block">{question.label}</p>
                    <p className="block">{question.text}</p>
                    {question.type === "input" && (
                      <div className="mb-3">
                        <input
                          type="text"
                          name="answer"
                          className="mt-2 w-full rounded"
                          // value={question.answer}
                          // onChange={(e) => handleChangeInputOption(e, i)}
                        />
                      </div>
                    )}
                    {question.type === "textarea" && (
                      <div className="mb-3">
                        <textarea
                          className="mt-2 w-full rounded"
                          name="answer"
                          // value={question.answer}
                          onChange={(e) => handleChangeInputOption(e, i)}
                        />
                      </div>
                    )}
                    {question.type === "select" && (
                      <div className="mb-3">
                        <select
                          name="answer"
                          value={question.answer}
                          onChange={(e) => handleChangeInputOption(e, i)}
                          onBlur={(e) => {
                            handleSelectBlur(e);
                          }}
                          onKeyDown={handleEnter}
                          className={`mt-2 w-full text-input ${
                            select === "Choose One" && `bg-red-100`
                          }`}
                        >
                          <option value="Choose One">Choose One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        {/* If user selectes Yes */}
                        {select === "Yes" && (
                          <>
                            <p>Example of dependent element.</p>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}
          <button className="text-sky-600" onClick={openNewQuestionModal}>
            + Add A Question
          </button>
        </>
      </div>
      {/* New Question Modal */}
      <div>
        <Modal
          isOpen={newQuestionModalIsOpen}
          onAfterOpen={afterOpenNewQuestionModal}
          onRequestClose={closeNewQuestionModal}
          style={customStyles}
          contentLabel="New Question"
        >
          <div>Add A New Question</div>
          <label className="block mb-2">Question Title:</label>
          <input
            className="block mb-3 w-full"
            type="text"
            onChange={(e) => setNewQuestionLabel(e.target.value)}
            value={newQuestionLabel}
          />
          <label className="block mb-2">Question Text:</label>

          <textarea
            placeholder="Question Text"
            className="block mb-2 w-full"
            onChange={(e) => setNewQuestionText(e.target.value)}
            value={newQuestionText}
          />

          <label className="block mb-2">Answer Type:</label>

          <select
            className="block mb-2 w-full"
            type="text"
            onChange={(e) => setNewQuestionType(e.target.value)}
            value={newQuestionType}
          >
            <option>Choose One</option>
            <option value="input">Short Answer</option>
            <option value="textarea">Long Answer</option>
            <option value="select">Yes/No</option>
          </select>
          <button className="block" onClick={(e) => handleAddOption(e)}>
            Submit
          </button>
          <button onClick={(e) => closeNewQuestionModal(e)}>Cancel</button>
        </Modal>
      </div>
    </>
  );
}

export default EmployeeDirectory;
