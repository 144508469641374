import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  orderBy,
  setDoc
} from "firebase/firestore";
import { db } from "../../firebase";

function UserManagement() {
    // Date Stuff
    const current = new Date();
    const date = `${
      current.getMonth() + 1
    }/${current.getDate()}/${current.getFullYear()}`;

  // Handle Drop Down Selection
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");
  const [userSelected, setUserSelected] = useState(false);
  const handleChangeUser = (event) => {
    const { value } = event.target;
    setSelectedUserID(value.split("-")[0]);
    setSelectedUserName(value.split("-")[1]);
    setUserSelected(true);
  };

  //   Get users for user dropdown list
  const [userList, setUserList] = useState([]);
  const usersCollectionRef = collection(db, "users");
  const getUsers = async () => {
    const data = await getDocs(query(usersCollectionRef, orderBy("first")));
    setUserList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  //   Get Selected User's Info
  const userID = selectedUserID;
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({
    company: "",
    email: "",
    first: "",
    last: "",
    phone: "",
    role: "",
    userID: "",
  });
  const getUserInfo = async (usersID) => {
    console.log("Getting User Info");

    const docRef = doc(db, "users", usersID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setUserInfo(docSnap.data());
      setUserEmail(userInfo.email);
      setUserPhone(userInfo.phone);
      setUserCompany(userInfo.company);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  // Edit Users
  const [showEditUser, setShowEditUser] = useState(false);
  const [userCompany, setUserCompany] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const updateUserInfo = async () => {
    await setDoc(doc(db, "users", selectedUserID), {
      ...userInfo,
      company: userCompany,
      email: userEmail,
      phone: userPhone
    });
    setShowEditUser(false);
  };

  useEffect(() => {
    if (userID) {
      getUserInfo(userID);
    }
    getUsers();
  }, [db, selectedUserID]);

  return (
    <section className="max-w-[550px] mx-auto border-2 rounded mt-3 p-3">
      <h1 className="text-xl font-bold mt-1 mb-3">User Management</h1>

      <select
        onChange={handleChangeUser}
        className="w-full block mx-auto rounded-md font-medium border-2 p-3 my-2 sm:text-xl md:text-xl"
      >
        <option>Select A User</option>
        {userList.map((userObject) => {
          if (userObject.first) {
            return (
              <option
                key={"id" + userObject.userID}
                value={
                  userObject.userID +
                  "-" +
                  userObject.first +
                  " " +
                  userObject.last
                }
              >
                {userObject.first + " " + userObject.last}
              </option>
            );
          }
        })}
      </select>
      <hr className="my-3" />
      {userSelected && (
        <>
          <div>
            <h3 className="text-2xl font-bold text-red-900">
              {userInfo?.first} {userInfo?.last}
            </h3>
            {showEditUser === false && (
              <>
                <p className="block text-lg font-medium">{userInfo?.company}</p>
                <p className="block text-lg font-medium">{userInfo?.email}</p>
                <p className="block text-lg font-medium">{userInfo?.phone}</p>
              </>
            )}
            {showEditUser === true && (
              <>
                <input
                  type="text"
                  onChange={(e) => setUserCompany(e.target.value)}
                  className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
                  placeholder={userInfo?.company}
                  value={userCompany}
                />
                <input
                  type="text"
                  placeholder={userInfo?.email}
                  onChange={(e) => setUserEmail(e.target.value)}
                  className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
                  value={userEmail}
                />
                <input
                  onChange={(e) => setUserPhone(e.target.value)}
                  type="text"
                  className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
                  placeholder={userInfo?.phone}
                  value={userPhone}
                />
                <button
                  className="w-full block text-lg font-medium bg-green-200 p-1"
                  onClick={updateUserInfo}
                >
                  Save Changes
                </button>
              </>
            )}
            <input
              type="text"
              className="block text-lg font-medium"
              value={userInfo?.role}
            />
            <button
              className="block text-md text-sky-600 font-medium"
              onClick={() => setShowEditUser(true)}
            >
              Edit User Info
            </button>
            <button className="block text-md text-sky-600 font-medium">
              View Factfinder History
            </button>
            <button className="block text-md text-sky-600 font-medium">
              Reset Password
            </button>
          </div>
        </>
      )}
    </section>
  );
}

export default UserManagement;
