import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  setDoc,
  addDoc,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import DatePicker from "react-multi-date-picker";
import { table, Thead, Tbody, tr, td } from "react-super-responsive-table";
import "./app.css";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import HumanResourcesNotes from "./hr/HumanResourcesNotes";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Applicant(props) {
  // Date Stuff
  function toDateTime(secs) {
    var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    t.setSeconds(secs);
    return t.toDateString();
  }

  // NextStep Modal Stuff
  let subtitle;
  const [nextStepModalIsOpen, setNextStepModalIsOpen] = React.useState(false);

  function openNextStepModal(e) {
    e.preventDefault();
    setNextStepModalIsOpen(true);
  }

  function afterOpenNextStepModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeNextStepModal() {
    setNextStepModalIsOpen(false);
  }

  const id = props.id;

  //   Get Selected User's Info
  const [applicantInfo, setApplicantInfo] = useState([]);
  const [gotInfo, setGotInfo] = useState(false);
  const getApplicantInfo = async (applicantID) => {
    setGotInfo(false);
    const docRef = doc(db, "aguila-dorada-claims", applicantID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setApplicantInfo(docSnap.data());
      console.log("Document data:", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
    setGotInfo(true);
  };

  // Edit Next Step
  const [newNextStep, setNewNextStep] = useState("");
  const editNextStep = (e, applicantID) => {
    e.preventDefault();
    const applicantRef = doc(db, "applicants", applicantID);
    setDoc(applicantRef, { nextStep: newNextStep }, { merge: true });
    applicantInfo.nextStep = newNextStep;
    closeNextStepModal();
    setNewNextStep("");
  };

  // Edit Status Modal Stuff
  const [editStatusModalIsOpen, setEditStatusModalIsOpen] =
    React.useState(false);

  function openEditStatusModal(e) {
    // e.preventDefault();
    setEditStatusModalIsOpen(true);
  }

  function afterOpenEditStatusModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeEditStatusModal() {
    setEditStatusModalIsOpen(false);
  }

  // Edit Status
  const [newStatus, setNewStatus] = useState("");
  const editStatus = (e, applicantID) => {
    e.preventDefault();
    const applicantRef = doc(db, "applicants", applicantID);
    setDoc(applicantRef, { status: newStatus }, { merge: true });
    applicantInfo.status = newStatus;
    closeEditStatusModal();
    setNewStatus("");
  };

  // Edit BestFit Modal Stuff
  const [editBestFitModalIsOpen, setBestFitModalIsOpen] = React.useState(false);

  function openBestFitModal(e) {
    // e.preventDefault();
    setBestFitModalIsOpen(true);
  }

  function afterOpenBestFitModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeBestFitModal() {
    setBestFitModalIsOpen(false);
  }

  // Edit Best Fit
  const [newBestFit, setNewBestFit] = useState("");
  const editBestFit = (e, applicantID) => {
    e.preventDefault();
    const applicantRef = doc(db, "applicants", applicantID);
    setDoc(applicantRef, { bestFit: newBestFit }, { merge: true });
    applicantInfo.bestFit = newBestFit;
    closeBestFitModal();
    setNewBestFit("");
  };

  // Edit New Step Modal Stuff
  const [editNewStepModalIsOpen, setNewStepModalIsOpen] = React.useState(false);

  function openNewStepModal(e) {
    // e.preventDefault();
    setNewStepModalIsOpen(true);
  }

  function afterOpenNewStepModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeNewStepModal() {
    setNewStepModalIsOpen(false);
  }

  // Add New Step
  const [newStepTitle, setNewStepTitle] = useState("");
  const [newStepText, setNewStepText] = useState("");
  const [stepUpdated, setStepUpdated] = useState(false);

  const addNewStep = async (e) => {
    e.preventDefault();
    const oldLength = stepList.length;
    const docRef = await addDoc(collection(db, "hiring-steps"), {
      place: oldLength + 1,
      name: newStepTitle,
      description: newStepText,
      questions: [""],
    });
    console.log("Document written with ID: ", docRef.id);
    closeNewStepModal();
    setNewStepTitle("");
    setNewStepText("");
    getSteps();
  };

  const [gotSteps, setGotSteps] = useState(false);
  const [stepList, setStepList] = useState([]);
  const [selectedStep, setSelectedStep] = useState("1oMhI1n9fXKG5lkhEQZz");
  const getSteps = async () => {
    setGotSteps(false);
    const data = await getDocs(
      query(collection(db, "hiring-steps"), orderBy("place"))
    );
    setStepList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setGotSteps(true);
  };

  const handleChangeStep = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setSelectedStep(e.target.value);
    setStepUpdated(false);
  };

  const [customQuestions, setCustomQuestions] = useState([
    { description: "", answer: "" },
  ]);
  const [gotCustomQuestions, setGotCustomQuestions] = useState(false);
  const [showCustomQuestions, setShowCustomQuestions] = useState(true);
  const getCustomQuestions = () => {
    const entries = Object.entries(applicantInfo);
    console.log(entries);
    const filteredEntries = entries.filter((entry) => {
      return entry[0].startsWith("Custom-Question:");
    });
    filteredEntries.forEach((entry, i) => {
      const newEntry = entry[0].slice(17);
      console.log(newEntry);
      filteredEntries[i][0] = newEntry;
    });
    console.log(filteredEntries);
    setCustomQuestions(filteredEntries);
    setGotCustomQuestions(true);
  };

  const removePrefix = (question) => {};

  useEffect(() => {
    if (gotInfo === false) getApplicantInfo(id);
    if (gotInfo === true) getCustomQuestions();
  }, [db, gotInfo, gotSteps, id]);

  return (
    <>
      <h2 className="text-red-900 font-bold text-xl mt-2 mb-3">
        Quote Request Details
      </h2>
      <Container className="mx-auto">
        {/* Applicant Information */}
        <Row className="mt-1 p-3 bg-gray-200 rounded">
          <p className="block font-bold text-xl mb-2">
            Status: {applicantInfo.status}
          </p>
          <Col>
          {/* Insured's Information */}
            <table className="mb-3 w-full">
              <tr>
                <td colspan="2">
                  <h2 className="font-bold mb-2">
                    Insured's Information:
                  </h2>
                </td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>{applicantInfo.email}</td>
              </tr>
              <tr>
                <td>Resident Phone:</td>
                <td>{applicantInfo.residentPhone}</td>
              </tr>
              <tr>
                <td>Business Phone:</td>
                <td>{applicantInfo.businessPhone}</td>
              </tr>
              <tr>
                <td>Street Address:</td>
                <td>{applicantInfo.address}</td>
              </tr>
              <tr>
                <td>City, State, Zip</td>
                <td>
                  {applicantInfo.city}
                  {", "}
                  {applicantInfo.state}
                  {", "}
                  {applicantInfo.zip}
                </td>
              </tr>
            </table>
          {/* Policy Information */}
            <table className="mb-3 w-full">
              <tr>
                <td colspan="2">
                  <h2 className="font-bold mb-2">Policy Information:</h2>
                </td>
              </tr>
              <tr>
                <td>Policy Number:</td>
                <td>{applicantInfo.policyNumber}</td>
              </tr>
            </table>
          {/* Loss Information */}
            <table className="mb-3 w-full">
              <tr>
                <td colspan="2">
                  <h2 className="font-bold mb-2">Loss Information:</h2>
                </td>
              </tr>
              <tr>
                <td>Location of Accident:</td>
                <td>{applicantInfo.accidentLocation}</td>
              </tr>
              <tr>
                <td>Authority Contacted:</td>
                <td>{applicantInfo.authorityContacted}</td>
              </tr>
              <tr>
                <td>Report Number:</td>
                <td>{applicantInfo.reportNumber}</td>
              </tr>
              <tr>
                <td>Violations/Citations:</td>
                <td>{applicantInfo.violationsCitations}</td>
              </tr>
              <tr>
                <td>Description of Accident:</td>
                <td>{applicantInfo.accidentDescription}</td>
              </tr>
            </table>
            </Col>
            <Col>
          {/* Driver & Vehicle Information */}
            <table className="mb-3 w-full">
              <tr>
                <td colspan="2">
                  <h2 className="font-bold mb-2">
                    Driver & Vehicle Information:
                  </h2>
                </td>
              </tr>
              <tr>
                <td>Driver's Name:</td>
                <td>{applicantInfo.driversName}</td>
              </tr>
              <tr>
                <td>Driver's Address:</td>
                <td>{applicantInfo.driversAddress}</td>
              </tr>
              <tr>
                <td>Driver's Phone Number:</td>
                <td>{applicantInfo.driversPhone}</td>
              </tr>
              <tr>
                <td>Driver's Driver's License Number:</td>
                <td>{applicantInfo.driversDL}</td>
              </tr>
              <tr>
                <td>Driver's Relationship to Insured:</td>
                <td>{applicantInfo.driversRelationship}</td>
              </tr>
              <tr>
                <td>Purpose of Use:</td>
                <td>{applicantInfo.purposeOfUse}</td>
              </tr>
              <tr>
                <td>Was the vehicle used with permission:</td>
                <td>{applicantInfo.usedWithPermission}</td>
              </tr>
              <tr>
                <td>Year:</td>
                <td>{applicantInfo.vehicleYear}</td>
              </tr>
              <tr>
                <td>Make:</td>
                <td>{applicantInfo.vehicleMake}</td>
              </tr>
              <tr>
                <td>Model:</td>
                <td>{applicantInfo.vehicleModel}</td>
              </tr>
              <tr>
                <td>VIN:</td>
                <td>{applicantInfo.vehicleVIN}</td>
              </tr>
              <tr>
                <td>Describe Damage:</td>
                <td>{applicantInfo.damageDescription}</td>
              </tr>
              <tr>
                <td>Estimate Amount:</td>
                <td>{applicantInfo.estimateAmount}</td>
              </tr>
              <tr>
                <td>Where Can Vehicle Be Seen:</td>
                <td>{applicantInfo.whereCanVehicleBeSeen}</td>
              </tr>
              <tr>
                <td>When Can Vehicle Be Seen:</td>
                <td>{applicantInfo.whenCanVehicleBeSeen}</td>
              </tr>
              <tr>
                <td>Other Insurance:</td>
                <td>{applicantInfo.otherInsurance}</td>
              </tr>
              <tr>
                <td>Was another vehicle damaged:</td>
                <td>{applicantInfo.anotherVehicleDamaged}</td>
              </tr>
              <tr>
                <td>Other Vehicle Year, Make, & Model:</td>
                <td>{applicantInfo.otherVehicleYearMakeModel}</td>
              </tr>
              <tr>
                <td>Other Vehicle License Plate Number:</td>
                <td>{applicantInfo.otherVehiclePlateNumber}</td>
              </tr>
              <tr>
                <td>If not a vehicle, property damaged:</td>
                <td>{applicantInfo.whatPropertyDamaged}</td>
              </tr>
              <tr>
                <td>Owner's Name:</td>
                <td>{applicantInfo.otherVehicleOwnerName}</td>
              </tr>
              <tr>
                <td>Owner's Address:</td>
                <td>{applicantInfo.otherVehicleOwnerAddress}</td>
              </tr>
              <tr>
                <td>Owner's Phone:</td>
                <td>{applicantInfo.otherVehicleOwnerPhone}</td>
              </tr>
              <tr>
                <td>Other Vehicle Driver's Name:</td>
                <td>{applicantInfo.otherVehicleDriverName}</td>
              </tr>
              <tr>
                <td>Other Vehicle Driver's Address:</td>
                <td>{applicantInfo.otherVehicleDriverAddress}</td>
              </tr>
              <tr>
                <td>Other Vehicle Driver's Phone:</td>
                <td>{applicantInfo.otherVehicleOwnerPhone}</td>
              </tr>
              <tr>
                <td>Other Vehicle Damage Description:</td>
                <td>{applicantInfo.otherVehicleDamageDescription}</td>
              </tr>
              <tr>
                <td>Is the property insured:</td>
                <td>{applicantInfo.propertyInsured}</td>
              </tr>
              <tr>
                <td>Company or Agency Name:</td>
                <td>{applicantInfo.otherVehicleInsurer}</td>
              </tr>
              <tr>
                <td>Policy Number:</td>
                <td>{applicantInfo.otherVehiclePolicyNumber}</td>
              </tr>
            </table>
            </Col>
            <Col>
          {/* Injured Persons */}
            <table className="mb-3 w-full">
              <tr>
                <td colspan="2">
                  <h2 className="font-bold mb-2">Injured Persons:</h2>
                </td>
              </tr>
              <tr>
                <td>Where was person 1 when injured:</td>
                <td>{applicantInfo.wherePersonOne}</td>
              </tr>
              <tr>
                <td>Injured Person 1 Address:</td>
                <td>{applicantInfo.personOneAddress}</td>
              </tr>
              <tr>
                <td>Injured Person 1 Phone:</td>
                <td>{applicantInfo.personOnePhone}</td>
              </tr>
              <tr>
                <td>Injured Person 1 Age:</td>
                <td>{applicantInfo.personOneAge}</td>
              </tr>
              <tr>
                <td>Injured Person 1 Extent of Injury:</td>
                <td>{applicantInfo.personOneExtentInjury}</td>
              </tr>
              <tr>
                <td>Where was person 2 when injured:</td>
                <td>{applicantInfo.wherePersonTwo}</td>
              </tr>
              <tr>
                <td>Injured Person 2 Address:</td>
                <td>{applicantInfo.personTwoAddress}</td>
              </tr>
              <tr>
                <td>Injured Person 2 Phone:</td>
                <td>{applicantInfo.personTwoPhone}</td>
              </tr>
              <tr>
                <td>Injured Person 2 Age:</td>
                <td>{applicantInfo.personTwoAge}</td>
              </tr>
              <tr>
                <td>Injured Person 2 Extent of Injury:</td>
                <td>{applicantInfo.personTwoExtentInjury}</td>
              </tr>
              <tr>
                <td>Where was person 3 when injured:</td>
                <td>{applicantInfo.wherePersonThree}</td>
              </tr>
              <tr>
                <td>Injured Person 3 Address:</td>
                <td>{applicantInfo.personThreeAddress}</td>
              </tr>
              <tr>
                <td>Injured Person 3 Phone:</td>
                <td>{applicantInfo.personThreePhone}</td>
              </tr>
              <tr>
                <td>Injured Person 3 Age:</td>
                <td>{applicantInfo.personThreeAge}</td>
              </tr>
              <tr>
                <td>Injured Person 3 Extent of Injury:</td>
                <td>{applicantInfo.personThreeExtentInjury}</td>
              </tr>
            </table>
          {/* Witnesses */}
            <table className="mb-3 w-full">
              <tr>
                <td colspan="2">
                  <h2 className="font-bold mb-2">Witnesses:</h2>
                </td>
              </tr>
              <tr>
                <td>Where was witness 1:</td>
                <td>{applicantInfo.whereWitnessOne}</td>
              </tr>
              <tr>
                <td>Witness 1 Address:</td>
                <td>{applicantInfo.witnessOneAddress}</td>
              </tr>
              <tr>
                <td>Witness 1 Name:</td>
                <td>{applicantInfo.witnessOneName}</td>
              </tr>
              <tr>
                <td>Witness 1 Phone:</td>
                <td>{applicantInfo.witnessOnePhone}</td>
              </tr>
              <tr>
                <td>Witness 1 Age:</td>
                <td>{applicantInfo.witnessOneAge}</td>
              </tr>
              <tr>
                <td>Where was witness 2:</td>
                <td>{applicantInfo.whereWitnessTwo}</td>
              </tr>
              <tr>
                <td>Witness 2 Address:</td>
                <td>{applicantInfo.witnessTwoAddress}</td>
              </tr>
              <tr>
                <td>Witness 2 Name:</td>
                <td>{applicantInfo.witnessTwoName}</td>
              </tr>
              <tr>
                <td>Witness 2 Phone:</td>
                <td>{applicantInfo.witnessTwoPhone}</td>
              </tr>
              <tr>
                <td>Witness 2 Age:</td>
                <td>{applicantInfo.witnessTwoAge}</td>
              </tr>

              <tr>
                <td>Where was witness 3:</td>
                <td>{applicantInfo.whereWitnessThree}</td>
              </tr>
              <tr>
                <td>Witness 3 Address:</td>
                <td>{applicantInfo.witnessThreeAddress}</td>
              </tr>
              <tr>
                <td>Witness 3 Name:</td>
                <td>{applicantInfo.witnessThreeName}</td>
              </tr>
              <tr>
                <td>Witness 3 Phone:</td>
                <td>{applicantInfo.witnessThreePhone}</td>
              </tr>
              <tr>
                <td>Witness 3 Age:</td>
                <td>{applicantInfo.witnessThreeAge}</td>
              </tr>
            </table>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h2 className="text-red-900 font-bold text-lg mb-2">Notes:</h2>
            <HumanResourcesNotes selectedUserID={id}></HumanResourcesNotes>
          </Col>
        </Row>
      </Container>
      {/* Next Step Modal */}
      <div>
        <Modal
          isOpen={nextStepModalIsOpen}
          onAfterOpen={afterOpenNextStepModal}
          onRequestClose={closeNextStepModal}
          style={customStyles}
          contentLabel="Add NextStep"
        >
          <div>Update Next Step</div>
          <label className="block">New Next Step:</label>
          <input
            type="text"
            onChange={(e) => setNewNextStep(e.target.value)}
            value={newNextStep}
          />
          <button className="block" onClick={(e) => editNextStep(e, id)}>
            Submit
          </button>
          <button onClick={(e) => closeNextStepModal(e)}>Cancel</button>
        </Modal>
      </div>
      {/* Edit Status Modal */}
      <div>
        <Modal
          isOpen={editStatusModalIsOpen}
          onAfterOpen={afterOpenEditStatusModal}
          onRequestClose={closeEditStatusModal}
          style={customStyles}
          contentLabel="Edit Status"
        >
          <div>Update Status</div>
          <label className="block">New Status:</label>
          <input
            type="text"
            onChange={(e) => setNewStatus(e.target.value)}
            value={newStatus}
          />
          <button className="block" onClick={(e) => editStatus(e, id)}>
            Submit
          </button>
          <button onClick={(e) => closeEditStatusModal(e)}>Cancel</button>
        </Modal>
      </div>
      {/* Best Fit Modal */}
      <div>
        <Modal
          isOpen={editBestFitModalIsOpen}
          onAfterOpen={afterOpenBestFitModal}
          onRequestClose={closeBestFitModal}
          style={customStyles}
          contentLabel="Edit Best Fit"
        >
          <div>Update BestFit</div>
          <label className="block">New Best Fit:</label>
          <input
            type="text"
            onChange={(e) => setNewBestFit(e.target.value)}
            value={newBestFit}
          />
          <button className="block" onClick={(e) => editBestFit(e, id)}>
            Submit
          </button>
          <button onClick={(e) => closeBestFitModal(e)}>Cancel</button>
        </Modal>
      </div>

      {/* New Question Modal */}
      <div>
        <Modal
          isOpen={editNewStepModalIsOpen}
          onAfterOpen={afterOpenNewStepModal}
          onRequestClose={closeNewStepModal}
          style={customStyles}
          contentLabel="Add A Step"
        >
          <div>Add A Step</div>
          <label className="block">Step Name:</label>
          <input
            type="text"
            onChange={(e) => setNewStepTitle(e.target.value)}
            value={newStepTitle}
          />
          <label className="block">Description:</label>

          <textarea
            onChange={(e) => setNewStepText(e.target.value)}
            value={newStepText}
          />
          <button className="block" onClick={(e) => addNewStep(e)}>
            Submit
          </button>
          <button onClick={(e) => closeNewStepModal(e)}>Cancel</button>
        </Modal>
      </div>
    </>
  );
}

export default Applicant;
