import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  where,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import Container from "react-bootstrap/Container";
import UserManagement from "./admin/UserManagement";
import FactfinderManagement from "./admin/FactfinderManagement";

function Admin() {
  // Get the date
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  

    // Handle Factfinder Display Drop Down Selection
    const [factfinderDisplay, setFactfinderDisplay] = useState("all");
    const handleChangeFactfinderDisplay = () => {};

    // Get Factfinder History


  useEffect(() => {
    
    
  }, [db]);

  return (
    <Container id="main">
      <h1 className="text-2xl font-bold mt-2 mb-3">Adminstrator Panel</h1>

      {/* User Management Panel */}
      <UserManagement />

      {/* Factfinder Management Panel */}
      <FactfinderManagement />

    </Container>
  );
}

export default Admin;
