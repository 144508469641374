import { useEffect, useState } from "react";
import * as React from "react";
import {
  getDocs,
  collection,
  doc,
  updateDoc,
  where,
  getDoc,
  query,
  orderBy,
  setDoc,
} from "firebase/firestore";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { db } from "../firebase";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import "./app.css";
import ClaimDetails from "./ClaimDetails";

// Tab Stuff
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Exported Function
function AguilaDoradaClaims() {
  // Date Stuff
  function toDateTime(secs) {
    var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    t.setSeconds(secs);
    return t.toDateString();
  }

  //  Date Converter
  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toDateString();
  };

  // Tabs stuff
  const [value, setValue] = React.useState(0);

  // Modal Stuff
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Handle Drop Down Selection
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");
  const [userSelected, setUserSelected] = useState(false);
  const handleChangeUser = (event) => {
    const { value } = event.target;
    setSelectedUserID(value.split("-")[0]);
    setSelectedUserName(value.split("-")[1]);
    setUserSelected(true);
    setValue(0);
  };

  //   Get users for user dropdown list
  const [userList, setUserList] = useState([]);
  const usersCollectionRef = collection(db, "users");
  const getUsers = async () => {
    const data = await getDocs(query(usersCollectionRef, orderBy("first")));
    setUserList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  //   Get Selected User's Info
  const userID = selectedUserID;
  const [userInfo, setUserInfo] = useState({
    company: "",
    email: "",
    first: "",
    last: "",
    phone: "",
    extension: "",
    role: "",
    title: "",
    department: "",
    supervisor: "",
    office: "",
    hireDate: "",
    birthday: "",
    userID: "",
    hrNotes: [],
    pto: {},
  });

  const getUserInfo = async (usersID) => {
    console.log("Getting Employee HR File");

    const docRef = doc(db, "users", usersID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setUserInfo(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  // Edit Users
  const [showEditUser, setShowEditUser] = useState(false);
  const updateUserInfo = () => {
    const userRef = doc(db, "users", selectedUserID);
    setDoc(
      userRef,
      {
        company: userInfo.company,
        email: userInfo.email,
        phone: userInfo.phone,
        extension: userInfo.extension,
        title: userInfo.title,
        department: userInfo.department,
        supervisor: userInfo.supervisor,
        office: userInfo.office,
        hireDate: userInfo?.hireDate,
        birthday: userInfo?.birthday,
      },
      { merge: true }
    );
    handleClose();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Notes
  const [displayNotes, setDisplayNotes] = useState(false);

  // Show what?
  const [showEmployees, setShowEmployees] = useState(true);
  const [showRequests, setShowRequests] = useState(false);
  const [showApplicants, setShowApplicants] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showJobListings, setShowJobListings] = useState(false);
  const [showCreateJobListing, setShowCreateJobListing] = useState(false);

  const showNothing = () => {
    setShowEmployees(false);
    setShowRequests(false);
    setShowApplicants(false);
    setShowCalendar(false);
    setShowJobListings(false);
    setShowCreateJobListing(false);
  };

  // PTO Tab Display
  const [displayRequests, setDisplayRequests] = useState(false);
  const [displayHistory, setDisplayHistory] = useState(false);

  const displayNothing = () => {
    setDisplayRequests(false);
    setDisplayHistory(false);
  };

  const [selectedClaim, setSelectedClaim] = useState("");
  const [chosenStatus, setChosenStatus] = useState("Pending");
  const [pageData, setPageData] = useState([]);
  const [gotData, setGotData] = useState(false);
  const getPageData = async (status) => {
    setGotData(false);
    const q = query(
      collection(db, "aguila-dorada-claims"),
      where("status", "==", status || "Pending")
    );
    const queryData = await getDocs(q);
    setPageData(queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

    setGotData(true);
  };

  const updateStatus = async (id, e) => {
    await updateDoc(doc(db, "aguila-dorada-claims", id), {
      status: e.target.value,
    });
    getPageData();
  };

  useEffect(() => {
    if (userID) {
      getUserInfo(userID);
    }
    if (!gotData) getPageData(chosenStatus);
    console.log(pageData);
    getUsers();
  }, [db, selectedUserID, showEmployees, gotData, chosenStatus, selectedClaim]);

  return (
    <Container className="bg-white" fluid>
      {/* Header */}
      <Container
        id="user-Info"
        className="mt-3 mx-auto border-1 pt-1 bg-red-900 rounded overflow-hidden shadow-md z-50"
        fluid
      >
        <h1 className="text-white text-2xl font-bold pt-3 mb-3">
          Aguila Dorada Claims
        </h1>
        {/* HR Menu Bar */}
        <Row className="border-2 p-4 bg-gray-200 text-xl">
          <Container fluid>
            <Row className="mx-auto">
              <Col className="text-center">
                <button
                  className="text-sky-600"
                  onClick={() => {
                    setSelectedClaim("");
                    setChosenStatus("Pending");
                    getPageData(chosenStatus);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="inline mr-1 align-bottom bi bi-calendar3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                    <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                  </svg>
                  Pending Claims
                </button>
              </Col>
              <Col className="text-center">
                <button
                  className="text-sky-600"
                  onClick={() => {
                    setSelectedClaim("");
                    setChosenStatus("Closed");
                    getPageData(chosenStatus);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="inline align-bottom mr-1 bi bi-people-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  </svg>
                  Closed Claims
                </button>
              </Col>
              <Col className="text-center">
                <a
                  className="text-sky-600"
                  href="/aguila-dorada-quotes"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="inline align-bottom mr-1 bi bi-calendar2-heart-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5Zm-2 4v-1c0-.276.244-.5.545-.5h10.91c.3 0 .545.224.545.5v1c0 .276-.244.5-.546.5H2.545C2.245 5 2 4.776 2 4.5Zm6 3.493c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
                  </svg>
                  View Quotes
                </a>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>

      {/* Details */}
      {selectedClaim !== "" && (
        <>
          <a className="mt-2" href="#list">
            Jump To Claim List
          </a>
          <button className="block" onClick={() => setSelectedClaim("")}>
            Hide Details
          </button>
          <ClaimDetails id={selectedClaim} />
        </>
      )}

      <h2 className="text-red-900 text-xl font-bold mt-3">{chosenStatus} Claims</h2>
      {/* Claims Table */}
      <Container fluid id="list">
        {pageData.length > 0 && (
          <Table className="data-table mt-3">
            <Thead>
              <Tr className="font-medium bg-black text-white">
                <Td>Name</Td>
                <Td>Submitted Date</Td>
                <Td>Email</Td>
                <Td>Phone</Td>
                <Td>Status</Td>
              </Tr>
            </Thead>
            <Tbody>
              {pageData.map((myData, i) => (
                <Tr key={"row" + i} className="border-2 border-black">
                  <Td>
                    <button onClick={() => setSelectedClaim(myData.id)}>
                      {myData.first} {myData.last}
                    </button>
                  </Td>
                  <Td>{convertDate(myData.date.seconds)}</Td>
                  <Td>
                    <a href={"mailto:" + myData.email}>{myData.email}</a>
                  </Td>
                  <Td>
                    <a href={"tel:" + myData.phone}>{myData.primaryPhone}</a>
                  </Td>
                  <Td>
                    <select
                      value={myData.status}
                      onChange={(e) => updateStatus(myData.id, e)}
                    >
                      <option value="Pending">Pending</option>
                      <option value="Closed">Closed</option>
                    </select>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Container>

      {/* Data Insights */}
      {showApplicants === "asdf" && (<>
        Total Claims
        Open Claims
        Closed Claims 
        Claims by Men 
        Claims by Women 
        Average Claims Received Per Day Over Time   
      </>)}
    </Container>
  );
}

export default AguilaDoradaClaims;
