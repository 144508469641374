import React, { useEffect, useState } from "react";
import axios from "axios";
import { storage } from "../../firebase.js";
import { ref, uploadBytes } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateDoc,
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  addDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { v4 } from "uuid";
import "../app.css";
import { Container } from "react-bootstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function JobListing(props) {
  // Get ID from Params
  const [newListing, setNewListing] = useState(false);
  let listingRef;
  const { id } = useParams();

  // Submit Listing
  const submitListing = async () => {
    if (newListing === true) {
      await addDoc(collection(db, "job-listings"), {...formData, Applicants: 0, createdOn: new Date()});
      props.setShowCreateJobListing(false);
      props.setShowJobListings(true);
      
    } else {
      await setDoc(doc(db, "job-listings", id), formData, { merge: true });
      navigate('/hr')
    }
  };
  // Criteria Modal Stuff
  let subtitle;
  const [criteriaModalIsOpen, setCriteriaModalIsOpen] = React.useState(false);

  function openCriteriaModal(e) {
    e.preventDefault();
    setCriteriaModalIsOpen(true);
  }

  function afterOpenCriteriaModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeCriteriaModal() {
    setCriteriaModalIsOpen(false);
  }

  // Other Stuff
  let navigate = useNavigate();

  // Date Stuff
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  const [startDate, setStartDate] = useState(new Date());

  // Tabs Stuff
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    if (!props.newProposal);
    setValue(newValue);
  };

  // Hidden Form Stuff
  const [status, setStatus] = useState("");

  //   Form Stuff
  const [title, setTitle] = useState("");
  const [overview, setOverview] = useState("");
  const [deadline, setDeadline] = useState(new Date());
  const [department, setDepartment] = useState("");
  const [responsibilities, setResponsibilities] = useState([]);
  const [competencies, setCompetencies] = useState([]);
  const [criticalReqs, setCriticalReqs] = useState([]);
  const [beneficialReqs, setBeneficialReqs] = useState([]);
  const [education, setEducation] = useState([]);
  const [customQuestions, setCustomQuestions] = useState([]);

  //   Form Handling
  let formData = {
    title,
    overview,
    deadline,
    department,
    responsibilities,
    competencies,
    criticalReqs,
    beneficialReqs,
    education,
    customQuestions
  };

  const handleBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleSelectBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output;
  };

  // Responsibility Stuff
  const handleAddResponsibility = async (e) => {
    e.preventDefault();
    const i = responsibilities.length;
    setResponsibilities([
      ...responsibilities,
      {
        description: "",
      },
    ]);
    // await updateDoc(listingRef, { responsibilities: values });
  };

  const handleSubtractResponsibility = async (e, i) => {
    e.preventDefault();
    const values = [...responsibilities];
    values.splice(i, 1);
    setResponsibilities([...values]);
    if (values.length === 0) {
      setResponsibilities([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(listingRef, { responsibilities: values });
  };

  // Competency Stuff
  const handleAddCompetency = async (e) => {
    e.preventDefault();
    const i = competencies.length;
    setCompetencies([
      ...competencies,
      {
        description: "",
      },
    ]);
    // await updateDoc(listingRef, { competencies: values });
  };

  const handleSubtractCompetency = async (e, i) => {
    e.preventDefault();
    const values = [...competencies];
    values.splice(i, 1);
    setCompetencies([...values]);
    if (values.length === 0) {
      setCompetencies([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(listingRef, { competencies: values });
  };

  // Critical Req Stuff
  const handleAddCriticalReq = async (e) => {
    e.preventDefault();
    const i = criticalReqs.length;
    setCriticalReqs([
      ...criticalReqs,
      {
        description: "",
      },
    ]);
    // await updateDoc(listingRef, { criticalReqs: values });
  };

  const handleSubtractCriticalReq = async (e, i) => {
    e.preventDefault();
    const values = [...criticalReqs];
    values.splice(i, 1);
    setCriticalReqs([...values]);
    if (values.length === 0) {
      setCriticalReqs([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(listingRef, { criticalReqs: values });
  };

  // Beneficial Req Stuff
  const handleAddBeneficialReq = async (e) => {
    e.preventDefault();
    const i = beneficialReqs.length;
    setBeneficialReqs([
      ...beneficialReqs,
      {
        description: "",
      },
    ]);
    // await updateDoc(listingRef, { beneficialReqs: values });
  };

  const handleSubtractBeneficialReq = async (e, i) => {
    e.preventDefault();
    const values = [...beneficialReqs];
    values.splice(i, 1);
    setBeneficialReqs([...values]);
    if (values.length === 0) {
      setBeneficialReqs([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(listingRef, { beneficialReqs: values });
  };

  // Education Stuff
  const handleAddEducationReq = async (e) => {
    e.preventDefault();
    const i = education.length;
    setEducation([
      ...education,
      {
        description: "",
      },
    ]);
    // await updateDoc(listingRef, { education: values });
  };

  const handleSubtractEducationReq = async (e, i) => {
    e.preventDefault();
    const values = [...education];
    values.splice(i, 1);
    setEducation([...values]);
    if (values.length === 0) {
      setEducation([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(listingRef, { education: values });
  };

    // Custom Question Stuff
    const handleAddcustomQuestion = async (e) => {
      e.preventDefault();
      const i = customQuestions.length;
      setCustomQuestions([
        ...customQuestions,
        {
          description: "",
          answer: "",
          type: ""
        },
      ]);
      // await updateDoc(listingRef, { customQuestions: values });
    };
  
    const handleSubtractcustomQuestion = async (e, i) => {
      e.preventDefault();
      const values = [...customQuestions];
      values.splice(i, 1);
      setCustomQuestions([...values]);
      if (values.length === 0) {
        setCustomQuestions([
          {
            description: "",
            answer: "",
            type: ""
          },
        ]);
      }
      // await updateDoc(listingRef, { customQuestions: values });
    };

  const handleChangeInput = (i, e, stateName, updaterName) => {
    const values = [...stateName];
    values[i][e.target.name] = e.target.value;
    updaterName(values);
  };

  // Populate Department List
  const [departmentList, setDepartmentList] = useState([]);
  const getDepartmentList = async () => {
    const querySnapshot = await getDocs(collection(db, "departments"));
    querySnapshot.forEach((doc) => {
      setDepartmentList([...departmentList, doc.data()]);
    });
  };

  // Fix empty arrays so they map
  const noEmptyArrays = () => {
    if (responsibilities.length === 0)
      setResponsibilities([
        ...responsibilities,
        {
          description: "",
        },
      ]);
    if (competencies.length === 0)
      setCompetencies([
        ...competencies,
        {
          description: "",
        },
      ]);
    if (criticalReqs.length === 0)
      setCriticalReqs([
        ...criticalReqs,
        {
          description: "",
        },
      ]);
    if (beneficialReqs.length === 0)
      setBeneficialReqs([
        ...beneficialReqs,
        {
          description: "",
        },
      ]);
    if (education.length === 0)
      setEducation([
        ...education,
        {
          description: "",
        },
      ]);
      if (customQuestions.length === 0)
      setCustomQuestions([
        ...customQuestions,
        {
          description: "",
          answer: "",
          type: ""
        },
      ]);
  };

  // Get existing list data
  const [listDataUpdated, setListDataUpdated] = useState(false);
  const getExistingListData = async () => {
    const docRef = doc(db, "job-listings", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      setTitle(data.title);
      setOverview(data.overview);
      setDepartment(data.department);
      setDeadline(convertDate(data.deadline.seconds));
      setResponsibilities(data.responsibilities);
      setCompetencies(data.competencies);
      setCriticalReqs(data.criticalReqs);
      setBeneficialReqs(data.beneficialReqs);
      setEducation(data.education);
      setCustomQuestions(data.customQuestions);
      setListDataUpdated(true);
    } else {
      // doc.data() will be undefined in this case
      console.log("Error: No such document!");
    }
  };

  useEffect(() => {
    noEmptyArrays();
    if (departmentList.length === 0) getDepartmentList();
    if (newListing === false) {
      if (!id) {
        setNewListing(true);
      } else {
        listingRef = doc(db, "job-listings", id);
        if (listDataUpdated === false) getExistingListData();
        return;
      }
    }
  }, [db, listDataUpdated]);

  return (
    <Container className="h-screen w-full bg-gray-100" fluid>
      <form onSubmit={() => submitListing()}>
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Factfinder Tabs"
              // disable the tab indicator because it doesn't work well with wrapped container
              // TabIndicatorProps={{ sx: { display: "none" } }}
              indicatorColor="black"
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                  backgroundColor: "lightGray",
                  marginTop: "5px",
                  justifyContent: "center",
                },
              }}
            >
              <Tab label="Listing Info" {...a11yProps(0)} />
              <Tab label="Responsibilities" {...a11yProps(1)} />
              <Tab label="Competencies" {...a11yProps(2)} />
              <Tab label="Critical" {...a11yProps(3)} />
              <Tab label="Beneficial" {...a11yProps(4)} />
              <Tab label="Education" {...a11yProps(5)} />
              <Tab label="Application" {...a11yProps(6)} />
              <Tab label="Submit" {...a11yProps(7)} />
            </Tabs>
          </Box>
          {/* Listing Info */}
          <TabPanel value={value} index={0} className="bg-gray-100">
            <h1 className="text-red-900 text-xl font-bold mt-1 mb-3">
              Listing Information
            </h1>
            {/* Department */}
            <div>
              <label className="input-label">Department:</label>
              <button className="text-sky-600">+ Add A Department</button>
              <select
                required
                placeholder="Choose One"
                onChange={(e) => {
                  setDepartment(e.target.value);
                }}
                onBlur={(e) => {
                  handleSelectBlur(e);
                }}
                onKeyDown={handleEnter}
                className={`text-input w-full ${
                  department === "" && `bg-red-100`
                }`}
                value={department}
              >
                {departmentList.map((department) => {
                  return (
                    <option value={department.name}>{department.name}</option>
                  );
                })}
              </select>
            </div>
            {/* Position/Title */}
            <div>
              <label className="input-label">Position/Title:</label>
              <input
               required
                onChange={(e) => setTitle(e.target.value)}
                onBlur={(e) => handleBlur(e)}
                onKeyDown={handleEnter}
                className={`text-input w-full
                ${title === "" && `bg-red-100`}`}
                type="text"
                value={title}
              />
            </div>
            {/* Overview */}
            <div>
              <label className="input-label">Overview:</label>
              <textarea
              required
                onChange={(e) => setOverview(e.target.value)}
                onBlur={(e) => handleBlur(e)}
                onKeyDown={handleEnter}
                className={`text-input w-full
                ${overview === "" && `bg-red-100`}`}
                type="text"
                value={overview}
              />
            </div>
            {/* Deadline */}
            <div>
              <label className="input-label">Deadline:</label>
              <DatePicker
                selected={deadline}
                onChange={(date: Date) => {
                  setDeadline(date);
                }}
                className={`text-input w-full`}
              />
            </div>
            {/* Back/Next Buttons */}
            <article className="back-next-container">
              <button
                onClick={() => setValue(value - 1)}
                className="button-1"
                disabled={value === 0}
              >
                Back
              </button>
              <button onClick={() => setValue(value + 1)} className="button-2">
                Next
              </button>
            </article>
          </TabPanel>
          {/* Responsibilities */}
          <TabPanel value={value} index={1} className="bg-gray-100">
            <h1 className="text-red-900 text-xl font-bold mt-1 mb-3">
              Responsibilities
            </h1>
            <ul>
              {responsibilities.map((responsibility, i) => {
                return (
                  <>
                    <li className="list-item">
                      <input
                        type="text"
                        className="list-input"
                        name="description"
                        onChange={(e) =>
                          handleChangeInput(
                            i,
                            e,
                            responsibilities,
                            setResponsibilities
                          )
                        }
                        value={responsibility.description}
                        placeholder="Enter a Responsibility"
                      />
                      <button
                        onClick={e => handleSubtractResponsibility(e, i)}
                        className="text-sky-600"
                      >
                        - Delete This
                      </button>
                    </li>
                  </>
                );
              })}
            </ul>
            <button
              className="block text-sky-600"
              onClick={(e) => handleAddResponsibility(e)}
            >
              + Add Another Responsibility
            </button>

            <article className="back-next-container">
              <button
                onClick={() => setValue(value - 1)}
                className="button-1"
                disabled={value === 0}
              >
                Back
              </button>
              <button onClick={() => setValue(value + 1)} className="button-2">
                Next
              </button>
            </article>
          </TabPanel>
          {/* Competencies */}
          <TabPanel value={value} index={2} className="bg-gray-100">
            <h1 className="text-red-900 text-xl font-bold mt-1 mb-3">
              Competencies
            </h1>
            <ul>
              {competencies.map((competency, i) => {
                return (
                  <>
                    <li className="list-item">
                      <input
                        type="text"
                        className="list-input"
                        name="description"
                        onChange={(e) =>
                          handleChangeInput(i, e, competencies, setCompetencies)
                        }
                        value={competency.description}
                        placeholder="Enter a Competency"
                      />
                      <button
                        onClick={e => handleSubtractCompetency(e, i)}
                        className="text-sky-600"
                      >
                        - Delete This
                      </button>
                    </li>
                  </>
                );
              })}
            </ul>
            <button
              className="block text-sky-600"
              onClick={(e) => handleAddCompetency(e)}
            >
              + Add Another Competency
            </button>

            <article className="back-next-container">
              <button
                onClick={() => setValue(value - 1)}
                className="button-1"
                disabled={value === 0}
              >
                Back
              </button>
              <button onClick={() => setValue(value + 1)} className="button-2">
                Next
              </button>
            </article>
          </TabPanel>
          {/* Critical Requirements */}
          <TabPanel value={value} index={3} className="bg-gray-100">
            <h1 className="text-red-900 text-xl font-bold mt-1 mb-3">
              Critical Requirements
            </h1>
            <ul>
              {criticalReqs.map((criticalReq, i) => {
                return (
                  <>
                    <li className="list-item">
                      <input
                        type="text"
                        className="list-input"
                        name="description"
                        onChange={(e) =>
                          handleChangeInput(i, e, criticalReqs, setCriticalReqs)
                        }
                        value={criticalReq.description}
                        placeholder="Enter a Critical Requirement"
                      />
                      <button
                        onClick={e => handleSubtractCriticalReq(e, i)}
                        className="text-sky-600"
                      >
                        - Delete This
                      </button>
                    </li>
                  </>
                );
              })}
            </ul>
            <button
              className="block text-sky-600"
              onClick={(e) => handleAddCriticalReq(e)}
            >
              + Add Another Critical Requirement
            </button>

            <article className="back-next-container">
              <button
                onClick={() => setValue(value - 1)}
                className="button-1"
                disabled={value === 0}
              >
                Back
              </button>
              <button onClick={() => setValue(value + 1)} className="button-2">
                Next
              </button>
            </article>
          </TabPanel>
          {/* Beneficial Requirements */}
          <TabPanel value={value} index={4} className="bg-gray-100">
            <h1 className="text-red-900 text-xl font-bold mt-1 mb-3">
              Beneficial Requirements
            </h1>
            <ul>
              {beneficialReqs.map((beneficialReq, i) => {
                return (
                  <>
                    <li className="list-item">
                      <input
                        type="text"
                        className="list-input"
                        name="description"
                        onChange={(e) =>
                          handleChangeInput(
                            i,
                            e,
                            beneficialReqs,
                            setBeneficialReqs
                          )
                        }
                        value={beneficialReq.description}
                        placeholder="Enter a Beneficial Requirement"
                      />
                      <button
                        onClick={e => handleSubtractBeneficialReq(e, i)}
                        className="text-sky-600"
                      >
                        - Delete This
                      </button>
                    </li>
                  </>
                );
              })}
            </ul>
            <button
              className="block text-sky-600"
              onClick={(e) => handleAddBeneficialReq(e)}
            >
              + Add Another Beneficial Requirement
            </button>

            <article className="back-next-container">
              <button
                onClick={() => setValue(value - 1)}
                className="button-1"
                disabled={value === 0}
              >
                Back
              </button>
              <button onClick={() => setValue(value + 1)} className="button-2">
                Next
              </button>
            </article>
          </TabPanel>
          {/* Education Requirements */}
          <TabPanel value={value} index={5} className="bg-gray-100">
            <h1 className="text-red-900 text-xl font-bold mt-1 mb-3">
              Education Requirements
            </h1>
            <ul>
              {education.map((educationReq, i) => {
                return (
                  <>
                    <li className="list-item">
                      <input
                        type="text"
                        className="list-input"
                        name="description"
                        onChange={(e) =>
                          handleChangeInput(i, e, education, setEducation)
                        }
                        value={educationReq.description}
                        placeholder="Enter an Education Requirement"
                      />
                      <button
                        onClick={e => handleSubtractEducationReq(e, i)}
                        className="text-sky-600"
                      >
                        - Delete This
                      </button>
                    </li>
                  </>
                );
              })}
            </ul>
            <button
              className="block text-sky-600"
              onClick={(e) => handleAddEducationReq(e)}
            >
              + Add Another Education Requirement
            </button>

            <article className="back-next-container">
              <button
                onClick={() => setValue(value - 1)}
                className="button-1"
                disabled={value === 0}
              >
                Back
              </button>
              <button onClick={() => setValue(value + 1)} className="button-2">
                Next
              </button>
            </article>
          </TabPanel>
          {/* Education Requirements */}
          <TabPanel value={value} index={6} className="bg-gray-100">
            <h1 className="text-red-900 text-xl font-bold mt-1 mb-3">
              Customize Application Questions
            </h1>
            <ul>
              {customQuestions.map((customQuestion, i) => {
                return (
                  <>
                    <li className="list-item">
                      <input
                        type="text"
                        className="list-input"
                        name="description"
                        onChange={(e) =>
                          handleChangeInput(i, e, customQuestions, setCustomQuestions)
                        }
                        value={customQuestion.description}
                        placeholder="Enter a Custom Question"
                      />
                      <select
                        name="type"
                        className="list-input"
                        onChange={(e) =>
                          handleChangeInput(i, e, customQuestions, setCustomQuestions)
                        }
                        value={customQuestion.type}
                        >
                          <option>Choose A Question Type</option>
                          <option value="text">Short Answer</option>
                          <option value="textarea">Long Answer</option>
                          <option value="dropdown">Yes/No Dropdown</option>
                        </select>
                      <button
                        onClick={e => handleSubtractcustomQuestion(e, i)}
                        className="text-sky-600"
                      >
                        - Delete This
                      </button>
                    </li>
                  </>
                );
              })}
            </ul>
            <button
              className="block text-sky-600"
              onClick={(e) => handleAddcustomQuestion(e)}
            >
              + Add Another Custom Question
            </button>

            <article className="back-next-container">
              <button
                onClick={() => setValue(value - 1)}
                className="button-1"
                disabled={value === 0}
              >
                Back
              </button>
              <button onClick={() => setValue(value + 1)} className="button-2">
                Next
              </button>
            </article>
          </TabPanel>
          {/* Submit Page*/}
          <TabPanel value={value} index={7} className="bg-gray-100">
            <h1 className="text-red-900 text-xl font-bold mt-1 mb-3">
              Review & Submit
            </h1>
            {title === "" && <p>No Position/Title Entered</p>}
            {overview === "" && <p>No Overview Entered</p>}
            {department === "" && <p>No Department Selected</p>}
            {responsibilities.length === 0 && <p>No Responsibilities Added</p>}
            {competencies.length === 0 && <p>No Competencies Added</p>}
            {criticalReqs.length === 0 && <p>No Critical Requirements Added</p>}
            {beneficialReqs.length === 0 && (
              <p>No Beneficial Requirements Added</p>
            )}
            {education.length === 0 && <p>No Education Requirements Added</p>}
            {/* Submit Button */}
            <button type="submit" className="button-2">
              {newListing === true && <>Submit Job Listing</>}
              {newListing === false && <>Save Listing</>}
            </button>
          </TabPanel>
        </Box>
      </form>
    </Container>
  );
}

export default JobListing;
