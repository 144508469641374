import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import {
  setDoc,
  doc,
  getDoc,
  addDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { getAuth, updateProfile } from "firebase/auth";
import axios from "axios";
import { UserAuth } from "../../Context/AuthContent";
import DatePicker from "react-datepicker";

function EditProfile(props) {
  const [phone, setPhone] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [title, setTitle] = useState("");
  const [department, setDepartment] = useState("");
  const [office, setOffice] = useState("");
  const [birthday, setBirthday] = useState("");
  const [status, setStatus] = useState("Unappointed");

  const navigate = useNavigate();

  const sendEmail = async () => {
    axios
      .post(
        "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/new-licona-user-created",
        {
          to: "colt@southernstarmga.com",
          cc: "elpaso_csr@liconainsurance.com",
          name: first + " " + last,
          title: title,
          department: department,
          phone: phone,
          email: auth.currentUser.email,
        }
      )
      .then(function (response) {
        // handle success
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };
  const { user } = UserAuth();
  const userID = user.uid;
  const [gotExistingData, setGotExistingData] = useState(false);
  const getExistingData = async (e) => {
    setGotExistingData(false);
    const docRef = doc(db, "users", userID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Existing data:", docSnap.data());
      setFirst(docSnap.data().first);
      setLast(docSnap.data().last);
      setOffice(docSnap.data().office);
      setTitle(docSnap.data().title);
      setDepartment(docSnap.data().department);
      setBirthday(convertDate(docSnap.data().birthday.seconds));
      setPhone(docSnap.data().phone);
      if (docSnap.data().status) setStatus(docSnap.data().status);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
    setGotExistingData(true);
  };

  const setProfile = async (e) => {
    e.preventDefault();
    await setDoc(
      doc(db, "users", userID),
      {
        email: auth.currentUser.email,
        first: first,
        last: last,
        phone: phone,
        title: title,
        department: department,
        birthday: birthday,
        admin: false,
        role: "New User",
        hireDate: new Date(),
        pto: { total: 0, used: 0 },
        ptoIncrease: 0,
      },
      { merge: true }
    );
    // Send Notification to Administrator
    sendEmail();
    // Log System Activity
    await addDoc(collection(db, "system-activity"), {
      activityDate: new Date(),
      authorID: auth.currentUser.uid,
      authorName: auth.currentUser.displayName,
      description: "New User Created: " + first + " " + last + ", " + title,
    });
    makeChange(first + " " + last);
    navigate("/signin");
  };

  //   Update display name
  const auth = getAuth();
  const makeChange = (newName) => {
    updateProfile(auth.currentUser, {
      displayName: newName,
    })
      .then(() => {
        // Profile updated!
        console.log(auth.currentUser?.displayName + `'s Profile Updated`);
        // ...
      })
      .catch((error) => {
        // An error occurred
        alert("Error");
        // ...
      });
  };

  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output;
  };

  const [gotDepartmentList, setGotDepartmentList] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const getDepartmentList = async () => {
    setGotDepartmentList(false);
    const querySnapshot = await getDocs(collection(db, "departments"));
    let values = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      values.push({ name: doc.data().name, id: doc.id });
    });
    setDepartmentList(values);
    setGotDepartmentList(true);
  };

  useEffect(() => {
    if (userID) {
      !gotExistingData && getExistingData();
    }
    !gotDepartmentList && getDepartmentList();
  }, [gotExistingData, userID, gotDepartmentList]);

  return (
    <div className="max-w-[700px] mx-auto my-16 p-1">
      <div>
        <h1 className="text-center text-3xl font-bold">
          Edit Your Account Details
        </h1>
        <form onSubmit={setProfile}>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">First Name</label>
            <input
              required
              onChange={(e) => setFirst(e.target.value)}
              className="border p-3 rounded"
              type="text"
              value={first}
            />
          </div>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">Last Name</label>
            <input
              required
              onChange={(e) => setLast(e.target.value)}
              className="border p-3 rounded"
              type="text"
              value={last}
            />
          </div>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">Title</label>
            <input
              required
              onChange={(e) => setTitle(e.target.value)}
              className="border p-3 rounded"
              type="text"
              value={title}
            />
          </div>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">Department</label>
            <select onChange={(e) => setDepartment (e.target.value)} value={department} className="p-3 rounded">
              <option value="">Choose One</option>
              {departmentList.map((department) => {
                return (
                  <option value={department.name}>{department.name}</option>
                )
              })}
            </select>
          </div>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">Office</label>
            <select
              onChange={(e) => {
                setOffice(e.target.value);
              }}
              className={`border p-3 rounded`}
              value={office}
            >
              <option value="Choose One">Choose One</option>
              <option value="Juarez">Juarez</option>
              <option value="Mexico City">Mexico City</option>
              <option value="McAllen">McAllen</option>
              <option value="El Paso">El Paso</option>
              <option value="San Antonio">San Antonio</option>
              <option value="Laredo">Laredo</option>
              <option value="Remote">Remote</option>
            </select>
          </div>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">Phone Number</label>
            <input
              required
              onChange={(e) => setPhone(e.target.value)}
              className="border p-3 rounded"
              type="phone"
              value={phone}
            />
          </div>

          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">Birthday (This Year)</label>
            <DatePicker
              required
              selected={birthday}
              onChange={(date: Date) => {
                setBirthday(date);
                console.log("new date" + date);
              }}
              className={`border p-3 rounded w-full`}
            />
          </div>
          <button
            type="submit"
            className="bg-red-900 hover:bg-blue-700 rounded w-full p-4 my-2 text-white"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditProfile;
