import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { db, auth } from "../firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function NavItems() {
  const navigate = useNavigate();

  const logout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log("Signed Out");
        navigate("/signin");
      })
      .catch((error) => {
        // An error happened.
        console.log("Error trying to sign out");
      });
    this.setState({ isLoggedIn: false });
  };

  useEffect(() => {
    
  }, [db, auth.currentUser])
  

  return (
    <>{auth.currentUser !== null && (
    <Nav className="me-auto my-2 my-lg-0">
      <NavDropdown title="New" id="navbarScrollingDropdown">
        <NavDropdown.Item href="/choosecompany">Factfinder</NavDropdown.Item>
      </NavDropdown>
      {/* <Nav.Link href={"/kanban/" + auth.currentUser.uid}>Kanban</Nav.Link> */}
      <Nav.Link href="/account"> Dashboard </Nav.Link>
      {/* <Nav.Link href="/calendar">Calendar</Nav.Link> */}
      {/* <Nav.Link href="/messages">Messages</Nav.Link> */}
      <Nav.Link href="/new-support-ticket"> Support </Nav.Link>
<Nav.Link href="#" onClick={logout}>
        Logout
      </Nav.Link>
    </Nav>
    )}</>
  );
}
