import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  query,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Container } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import { Link, useNavigate } from "react-router-dom";

function FactfinderHistory(props) {
  const userID = props.userID;
  const setSubmittedCount = props.setSubmittedCount;
  const setUnsubmittedCount = props.setUnsubmittedCount;

  // Get User's Factfinder History
  const [userHistory, setUserHistory] = useState([]);
  const [doneGettingFactfinders, setDoneGettingFactfinders] = useState(false);
  const getUserHistory = async (usersID) => {
    setUserHistory([]);
    console.log("Getting User Factfinder History");
    const q = query(
      collection(db, "Factfinders"),
      where("authorID", "==", usersID)
    );
    const queryData = await getDocs(q);
    setUserHistory(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
    const unsubmitted = queryData.docs.filter(
      (entry) => entry.data().factfinderStatus === "Unsubmitted"
    );
    const submitted = queryData.docs.filter(
      (entry) => entry.data().factfinderStatus === "Submitted"
    );
    setDoneGettingFactfinders(true);
    setUnsubmittedCount(unsubmitted.length);
    setSubmittedCount(submitted.length);
  };

  // Delete A Factfinder From History

  const deleteThisFactfinder = async (thisID) => {
    let verify = prompt(
      "You are about to delete a Factfinder! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "Factfinders", thisID));
      getUserHistory(userID);
    }
  };

  const [displayFFHistory, setDisplayFFHistory] = useState(false);

  useEffect(() => {
    if (doneGettingFactfinders === false && userID) {
      getUserHistory(userID);
    }
  }, [db, userID]);

  return (
    <>
      <div className="card mb-3">
        <div className="card-header bg-gray-200">
          <button
            onClick={(e) => setDisplayFFHistory(!displayFFHistory)}
            className="text-xl font-bold"
          >
            Factfinder History
          </button>
        </div>
        {displayFFHistory && (
          <div className="card-body">
            <Table className="data-table">
              <Thead>
                <Tr>
                  <Td className="bg-red-900 text-white">Status</Td>
                  <Td className="bg-red-900 text-white">Named Insured</Td>
                  <Td className="bg-red-900 text-white">Last Edited</Td>
                  <Td className="bg-red-900 text-white">Author</Td>
                  <Td className="bg-red-900 text-white">View/Edit</Td>
                  <Td className="bg-red-900 text-white">Delete</Td>
                </Tr>
              </Thead>
              <Tbody>
                {userHistory.map((myData, i) => (
                  <Tr key={myData.id}>
                    <Td>{myData.factfinderStatus}</Td>
                    <Td>{myData.companyName}</Td>
                    <Td>{myData.lastUpdated}</Td>
                    <Td>{myData.authorName}</Td>
                    <Td>
                      <Link
                        to={
                          "../editfactfinder/" +
                          myData.id +
                          "/" +
                          myData.company
                        }
                        className="mx-auto font-medium text-red-900 border-red-900 hover:bg-red-100"
                      >
                        View/Edit
                      </Link>
                    </Td>
                    <Td>
                      <button
                        onClick={(e) => deleteThisFactfinder(myData.id)}
                        className="mx-auto font-medium text-red-900 border-red-900 hover:bg-red-100"
                      >
                        Delete
                      </button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        )}
      </div>
    </>
  );
}

export default FactfinderHistory;
