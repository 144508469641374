import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  orderBy,
  setDoc,
  where,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import "../app.css";
import WarningsForm from "./WarningsForm";
import axios from "axios";

function EmployeeWarningsView(props) {
  // Props
  const selectedUserID = props.selectedUserID;
  // Date Stuff
  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString().slice(3, 15);
  };

  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  // Get HR Warnings
  const [HRwarnings, setHRwarnings] = useState([]);
  const [gotWarnings, setGotWarnings] = useState(false);
  const [warningsUpdated, setWarningsUpdated] = useState(1);
  const getHRwarnings = async () => {
    console.log("Getting HR Warnings");
    setHRwarnings([]);
    setGotWarnings(false);
    const q = query(
      collection(db, "hr-warnings"),
      where("employeeID", "==", props.selectedUserID)
    );

    const querySnapshot = await getDocs(q);

    let values = [];
    querySnapshot.forEach((doc) => {
      console.log(doc.data());
      // doc.data() is never undefined for query doc snapshots
      values.push({
        ...doc.data(),
        id: doc.id,
        createdOn: convertDate(doc.data().createdOn.seconds),
      });
    });
    console.log(values);
    setHRwarnings(values);
    setGotWarnings(true);
    console.log(HRwarnings);
  };

  const addComment = async () => {};

  const updateWarnings = async (id, array) => {
    const docRef = doc(db, "hr-warnings", id);
    setDoc(docRef, { replies: array }, { merge: true });
    setWarningsUpdated(warningsUpdated + 1);
  };

  const addWarnings = async () => {
    await setDoc(doc(db, "hr-warnings", selectedUserID), {
      warnings: [
        {
          date: date,
          sender: auth.currentUser.displayName,
          createdBy: auth.currentUser.uid,
          warningsReason: document.getElementById("newWarningsReason").value,
          warningsConstructive: document.getElementById(
            "newWarningsConstructive"
          ).value,
          warningsSolution: document.getElementById("newWarningsSolution")
            .value,
          warningsPositive: document.getElementById("newWarningsPositive")
            .value,
          replies: [],
        },
      ],
    });

    setWarningsUpdated(warningsUpdated + 1);
  };

  const deleteWarnings = async (id) => {
    let verify = prompt(
      "You are about to delete warnings! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await updateDoc(doc(db, "hr-warnings", id), { status: "Deleted" });
      setGotWarnings(false);
      getHRwarnings();
    }
  };

  const [showReviewRequest, setShowReviewRequest] = useState(false);
  const [reason, setReason] = useState();
  const [warningsReqRecipient, setWarningsReqRecipient] = useState();

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  // Handle Drop Down Selection
  const [selectedRecipientID, setSelectedRecipientID] = useState("");
  const [selectedRecipientName, setSelectedRecipientName] = useState("");
  const [selectedRecipientEmail, setSelectedRecipientEmail] = useState("");
  const [selectedIterator, setSelectedIterator] = useState("");
  const [userSelected, setUserSelected] = useState(false);
  const handleChangeRecipient = (event) => {
    setUserSelected(false);
    const { value } = event.target;
    const iterator = value.split("-")[1];
    setSelectedRecipientID(value.split("-")[0]);
    setSelectedIterator(iterator);
    setSelectedRecipientName(
      userList[iterator].first + " " + userList[iterator].last
    );
    setSelectedRecipientEmail(userList[iterator].email);
    setUserSelected(true);
  };

  const [showResponseSuccess, setShowResponseSuccess] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
    axios
      .post(
        "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/licona/request-for-warnings",
        {
          to: selectedRecipientEmail,
          cc: ["elpaso_csr@liconainsurance.com", "colt@southernstarmga.com"],
          requester: auth.currentUser.displayName,
          requesterID: auth.currentUser.uid,
          createdOn: new Date().toLocaleString().split(",")[0],
          employeeName: auth.currentUser.displayName,
          employeeID: auth.currentUser.uid,
          reason: reason,
          reviewerEmail: selectedRecipientEmail,
          reviewer: selectedRecipientName,
          reviewerID: selectedRecipientID,
          email: auth.currentUser.email,
          status: "Incomplete",
        }
      )
      .then(async (response) => {
        // handle success
        await addDoc(collection(db, "warnings-requests"), {
          to: selectedRecipientEmail,
          cc: ["elpaso_csr@liconainsurance.com", "colt@southernstarmga.com"],
          requester: auth.currentUser.displayName,
          requesterID: auth.currentUser.uid,
          createdOn: new Date(),
          employeeName: auth.currentUser.displayName,
          employeeID: auth.currentUser.uid,
          reason: reason,
          email: auth.currentUser.email,
          reviewerEmail: selectedRecipientEmail,
          reviewer: selectedRecipientName,
          reviewerID: selectedRecipientID,
          status: "Incomplete",
        });
      })
      .then(() => {
        setSelectedRecipientID();
        setSelectedRecipientEmail();
        setSelectedRecipientName();
        setReason();
        setSelectedIterator();
        setShowResponseSuccess(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  //   Get users for user dropdown list
  const [userList, setUserList] = useState([]);
  const [gotUsers, setGotUsers] = useState(false);
  const usersCollectionRef = collection(db, "users");
  const getUsers = async () => {
    setGotUsers(false);
    const data = await getDocs(query(usersCollectionRef, orderBy("first")));
    setUserList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setGotUsers(true);
  };

  const [display, setDisplay] = useState(false);

  useEffect(() => {
    !gotWarnings && getHRwarnings();
    !gotUsers && getUsers();
  }, [selectedUserID, gotWarnings, warningsUpdated]);

  return (
    <div className="card mb-3">
      <div className="card-header bg-gray-200">
        <button
          onClick={(e) => setDisplay(!display)}
          className="text-xl font-bold"
        >
          Your Warnings
        </button>
      </div>
      {display && (
        <div className="card-body">
          <main>
            {/* Warnings History */}
            <section>
              <h1 className="text-2xl font-bold text-red-900">
                Your Warnings History
              </h1>
              <div className="p-3">
                <ul className="list">
                  {gotWarnings === true && (
                    <>
                      {HRwarnings.length === 0 && (
                        <p>
                          You have no warnings to display at this time. You may
                          request warnings using the request warnings form.
                        </p>
                      )}
                                    {HRwarnings.map((warnings, i) => (
                <div key={"warnings-container" + i} className="mb-3 card">
                  <div className="card-header text-lg">
                    <button
                      onClick={(e) => {
                        HRwarnings[i].hideOnList = !HRwarnings[i].hideOnList;
                        setWarningsUpdated(warningsUpdated + 1);
                      }}
                      className="font-bold mr-5 text-sky-600 capitalize"
                    >
                      {warnings.newWarningType} on{" "}
                      {convertDate(warnings.warningDate.seconds)} at{" "}
                      {warnings.warningLocation}
                    </button>
                  </div>
                  {HRwarnings[i]?.hideOnList ? (
                    <></>
                  ) : (
                    <div className="card-body text-lg">
                      {/* Warning Title */}
                      <div className="card">
                        <div className="card-header">
                          <h3 className="font-bold">Warning Type</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {warnings?.newWarningType}
                          </p>
                        </div>
                      </div>
                      {/* Warning Location */}
                      <div className="card mt-3">
                        <div className="card-header">
                          <h3 className="font-bold">Incident Location</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {warnings?.warningLocation}
                          </p>
                        </div>
                      </div>
                      {/* Warning Date */}
                      <div className="card mt-3">
                        <div className="card-header">
                          <h3 className="font-bold">Incident Date</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {convertDate(warnings?.warningDate.seconds)}
                          </p>
                        </div>
                      </div>
                      {/* Incident Description */}
                      <div className="card mt-3">
                        <div className="card-header">
                          <h3 className="font-bold">Incident Description</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {warnings?.incidentDescription}
                          </p>
                        </div>
                      </div>
                      {/* Employee Explanation */}
                      <div className="card mt-3">
                        <div className="card-header">
                          <h3 className="font-bold">Employee Explanation</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {warnings?.employeeExplanation}
                          </p>
                        </div>
                      </div>
                      {/* Improvement Plan */}
                      <div className="card mt-3">
                        <div className="card-header">
                          <h3 className="font-bold">Improvement Plan</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {warnings?.planForImprovement}
                          </p>
                        </div>
                      </div>
                      {/* Next Consequence */}
                      <div className="card mt-3">
                        <div className="card-header">
                          <h3 className="font-bold">Next Consequence</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {warnings?.furtherConsequences}
                          </p>
                        </div>
                      </div>
                      {/* Supervisor's Statement */}
                      <div className="card mt-3">
                        <div className="card-header">
                          <h3 className="font-bold">Supervisor's Statement</h3>
                        </div>
                        <div className="card-body">
                          <p className="block px-11">
                            {warnings?.supervisorStatement}
                          </p>
                        </div>
                      </div>

                      <hr className="my-3" />
                      {/* Comment List */}
                      <h3 className="font-bold mt-2">Comments:</h3>
                      <ul className="list ml-2">
                        {warnings.replies?.map((reply, iterator) => {
                          return (
                            <>
                              {!reply.reply ? (
                                <></>
                              ) : (
                                <li
                                  className="test rounded shadow-md m-3"
                                  key={"reply" + iterator}
                                >
                                  <p className="italic">
                                    {reply.sender} on {reply.date}:
                                  </p>
                                  <p className="block">{reply.reply}</p>
                                  {auth.currentUser.uid === reply.createdBy && (
                                    <>
                                      <button
                                        onClick={() => {
                                          HRwarnings[i].replies.splice(
                                            iterator,
                                            1
                                          );
                                          updateWarnings(
                                            warnings.id,
                                            HRwarnings[i].replies
                                          );
                                        }}
                                        className="text-xs m-1 text-red-900"
                                      >
                                        Delete Comment
                                      </button>
                                    </>
                                  )}
                                </li>
                              )}
                            </>
                          );
                        })}
                      </ul>

                      {/* Add Reply */}
                      <div className="ml-2">
                        <hr className="my-2" />
                        <input
                          id={"reply" + i}
                          type="text"
                          className="border-2 border-red-900 p-1 w-full rounded"
                          placeholder="Enter your reply here..."
                        />
                        <button
                          onClick={(e) => {
                            HRwarnings[i].replies.push({
                              date: date,
                              reply: document.getElementById("reply" + i).value,
                              sender: auth.currentUser.displayName,
                              createdBy: auth.currentUser.uid,
                            });
                            updateWarnings(
                              warnings.id,
                              HRwarnings[i].replies
                            );
                            document.getElementById("reply" + i).value = "";
                          }}
                          className="block font-medium text-white my-1 px-2 py-1 rounded bg-green-900 hover:bg-green-700"
                        >
                          Add Comment
                        </button>
                      </div>
                      <hr />
                    </div>
                  )}
                </div>
              ))}
                    </>
                  )}
                </ul>
              </div>
            </section>
          </main>
        </div>
      )}
    </div>
  );
}

export default EmployeeWarningsView;
