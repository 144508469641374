import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  where,
  orderBy,
  setDoc,
  updateDoc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import DatePicker from "react-multi-date-picker";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import "../app.css";
import axios from "axios";

function PTOhistory(props) {
  const userInfo = props.userInfo;
  // Date Stuff
  function toDateTime(secs) {
    var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    t.setSeconds(secs);
    return t.toDateString();
  }

  //   Get Selected User's Info
  const [PTORequests, setPTORequests] = useState([]);
  const [gotPTOHistory, setGotPTOHistory] = useState(false);
  const getPTOHistory = async (usersID) => {
    setPTORequests([]);
    setGotPTOHistory(false);
    const q = query(
      collection(db, "pto-requests"),
      where("requesterID", "==", usersID)
    );

    const querySnapshot = await getDocs(q);
    let values = [];
    querySnapshot.forEach((doc) => {
      values.push({
        ...doc.data(),
        id: doc.id,
        startDate: convertDateToShortString(doc.data().startDate.seconds),
        endDate: convertDateToShortString(doc.data().endDate.seconds),
      });
    });
    setPTORequests(values);
    setGotPTOHistory(true);
  };

  console.log(PTORequests);

  const [showResponseSuccess, setShowResponseSuccess] = useState(false);
  const sendEmail = async (e, newStatus) => {
    e.preventDefault();
    axios
      .post(
        "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/licona/time-off-request-updated",
        {
          to: props.selectedUserInfo.email,
          cc: ["colt@southernstarmga.com", "elpaso_csr@liconainsurance.com"],
          reviewedBy: auth.currentUser.displayName,
          status: newStatus,
          requesterName:
            props.selectedUserInfo.first + " " + props.selectedUserInfo.last,
        }
      )
      .then(async (response) => {
        // handle success
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });

    await addDoc(collection(db, "system-activity"), {
      activityDate: new Date(),
      authorID: auth.currentUser.uid,
      authorName: auth.currentUser.displayName,
      description:
        "A new time off request was submitted by  " +
        auth.currentUser.displayName,
    });
    getPTOHistory();
    props.getPTOHistory();
  };
  const [ptoUpdated, setPtoUpdated] = useState(1);
  const updatePTO = async (e, requestID, newStatus) => {
    const ptoRef = doc(db, "pto-requests", requestID);

    setDoc(
      ptoRef,
      {
        status: newStatus,
        dismissedByUser: false,
        reviewedBy: auth.currentUser.displayName,
        reviewedOn: new Date(),
      },
      { merge: true }
    );
    sendEmail(e, newStatus);
    await addDoc(collection(db, "system-activity"), {
      activityDate: new Date(),
      authorID: auth.currentUser.uid,
      authorName: auth.currentUser.displayName,
      description:
        "A time off request for " +
        props.selectedUserInfo.first +
        " " +
        props.selectedUserInfo.last +
        " was " +
        newStatus +
        " by " +
        auth.currentUser.displayName,
    });
  };

  const convertDateToShortString = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString().slice(3, 15);
  };

  const deleteThisPTORequest = async (thisID) => {
    let verify = prompt(
      "You are about to delete a PTO entry! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "pto-requests", thisID));
      getPTOHistory(props.selectedUserID);
    }
  };

  useEffect(() => {
    !gotPTOHistory && getPTOHistory(props.selectedUserID);
  }, [db, ptoUpdated, gotPTOHistory]);

  return (
    <section>
      {PTORequests.filter((e) => e.status === "Pending").length > 0 && (
        <p className="p-3 bg-red-300 my-3 font-[600]">
          This user has pending time off requests. Please approve or decline
          these requests in a timely manner.
        </p>
      )}
      {gotPTOHistory === true && (
        <>
          <div className="card mb-3 mt-3">
            <div className="card-header bg-gray-200">
              <button
                onClick={(e) =>
                  props.setDisplayUsersPTORequestsData(
                    !props.displayUsersPTORequestsData
                  )
                }
                className="text-lg font-bold"
              >
                {props.selectedUserInfo?.first} {props.selectedUserInfo?.last}
                's Time Off Requests
              </button>
            </div>
            {props.displayUsersPTORequestsData && (
              <div className="card-body">
                <Table className="data-table">
                  <Thead>
                    <Tr>
                      <Td className="bg-red-900 text-white">Submitted</Td>
                      <Td className="bg-red-900 text-white">Reason</Td>
                      <Td className="bg-red-900 text-white">Start/End</Td>
                      <Td className="bg-red-900 text-white">Half Day</Td>
                      <Td className="bg-red-900 text-white">Total Days</Td>
                      <Td className="bg-red-900 text-white">Status</Td>
                      {userInfo.admin === true && (
                        <Td className="bg-red-900 text-white">Delete</Td>
                      )}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {/* Map User Request History */}
                    {PTORequests.map((ptoRequest, i) => {
                      return (
                        <Tr key={"ptoRequest" + ptoRequest.id}>
                          <Td>
                            {convertDateToShortString(
                              ptoRequest.requestDate.seconds
                            )}
                          </Td>
                          <Td>{ptoRequest.reason}</Td>

                          {/* Map Request Dates */}
                          <Td>
                            <p className="d-block">{ptoRequest.startDate}</p>
                            <p className="d-block">{ptoRequest.endDate}</p>
                          </Td>
                          <Td>{ptoRequest.halfDay}</Td>
                          <Td>{ptoRequest.totalDays}</Td>
                          <Td>
                            <p id={"cell" + i}></p>
                            <select
                              className={`rounded ${
                                ptoRequest.status === "Declined" && `bg-red-200`
                              } ${
                                ptoRequest.status === "Approved" &&
                                `bg-green-200`
                              }`}
                              id={"select" + i}
                              onChange={(e) => {
                                updatePTO(e, ptoRequest.id, e.target.value);
                                document.getElementById("select" + i).remove();
                                document.getElementById(
                                  "cell" + i
                                ).textContent =
                                  "Status changed to " + e.target.value;
                                props.setDisplayUsersPTORequestsData(false);
                                getPTOHistory(props.selectedUserID);
                              }}
                              value={ptoRequest.status}
                            >
                              <option value="Pending">Pending</option>
                              <option value="Approved">Approved</option>
                              <option value="Declined">Declined</option>
                            </select>
                          </Td>
                          {userInfo.admin === true && (
                            <Td>
                              <button
                                onClick={(e) =>
                                  deleteThisPTORequest(ptoRequest.id)
                                }
                                className="text-red-600"
                              >
                                Delete
                              </button>
                            </Td>
                          )}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
}

export default PTOhistory;
