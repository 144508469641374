import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  addDoc,
  orderBy,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "../app.css";
import axios from "axios";
import { UserAuth } from "../../Context/AuthContent";

function TimeOffRequest(props) {
  // Date Stuff
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [dates, setDates] = useState([]);
  const convertDates = () => {
    let t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    // t.setSeconds(secs);
    // return t.toDateString();
    for (let i = 0; i < manualDates.length; i++) {
      dates.push({ seconds: manualDates[i].valueOf() / 1000 });
    }
    console.log(dates);
  };

  const [reason, setReason] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [manualDates, setManualDates] = useState();
  const [halfDay, setHalfDay] = useState("");

  const submitRequest = async (e) => {
    e.preventDefault();
    // Submitting Request
    const manualPTORequestData = {
      requesterID: auth.currentUser.uid,
      requesterName: auth.currentUser.displayName,
      supervisor: userInfo.supervisor,
      supervisorName: userInfo.supervisorName,
      halfDay,
      requestDate: new Date(),
      reason,
      startDate: new Date(manualDates[0]),
      endDate: new Date(manualDates[1]),
      totalDays:
        (new Date(manualDates[1]).getTime() -
          new Date(manualDates[0]).getTime()) /
          (1000 * 3600 * 24) +
        1,
      status: "Pending",
    };
    if (
      ((new Date(manualDates[1]).getTime() -
        new Date(manualDates[0]).getTime()) /
        (1000 * 3600 * 24) +
        1 >
        1) &
      (halfDay === "Yes")
    )
      return alert(
        "You must enter half day requests one at a time. If you need to request full days and half days at the same time, submit a request for all full days you want off and another request for the half day."
      );
    await addDoc(collection(db, "pto-requests"), manualPTORequestData);
    sendEmail(e);
    setSubmitSuccess(true);
    setDates([]);
    setManualDates();
    setHalfDay("");
  };

  const [showResponseSuccess, setShowResponseSuccess] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
    // Get Supervisor's Email, if found send request, if not, send it to HR
    const docRef = doc(db, "users", userInfo.supervisor);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      axios
        .post(
          "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/licona/new-time-off-request",
          {
            to: docSnap.data().email,
            cc: ["colt@southernstarmga.com", "elpaso_csr@liconainsurance.com"],
            requesterName: auth.currentUser.displayName,
            supervisorName: docSnap.data().first + " " + docSnap.data().last,
            halfDay,
            startDate: new Date(manualDates[0]).toLocaleString().split(",")[0],
            endDate: new Date(manualDates[1]).toLocaleString().split(",")[0],
            reason,
            requestDate: new Date().toLocaleString().split(",")[0],
          }
        )
        .then(async (response) => {
          // handle success
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No supervisor found, sending to HR");
      axios
        .post(
          "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/licona/new-time-off-request",
          {
            to: "elpaso_csr@liconainsurance.com",
            cc: ["colt@southernstarmga.com", "elpaso_csr@liconainsurance.com"],
            supervisorName: "Human Resources Department",
            requesterName: auth.currentUser.displayName,
            halfDay,
            startDate: new Date(manualDates[0]).toLocaleString().split(",")[0],
            endDate: new Date(manualDates[1]).toLocaleString().split(",")[0],
            reason,
            requestDate: new Date().toLocaleString().split(",")[0],
          }
        )
        .then(async (response) => {
          // handle success
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    }
    await addDoc(collection(db, "system-activity"), {
      activityDate: new Date(),
      authorID: auth.currentUser.uid,
      authorName: auth.currentUser.displayName,
      description:
        "A new time off request was submitted by  " +
        auth.currentUser.displayName,
    });
  };

  const { user } = UserAuth();
  const userID = user.uid;
  // Check User's Role
  const [userInfo, setUserInfo] = useState({});
  const [gotRole, setGotRole] = useState(false);
  const getRole = async (usersID) => {
    setGotRole(false);
    console.log("Getting User Info & Checking Role");
    const docRef = doc(db, "users", usersID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUserInfo(docSnap.data());
      setGotRole(true);
    } else {
      // doc.data() will be undefined in this case
      console.log("No role info found!");
      return null;
    }
  };

  useEffect(() => {
    userID && getRole(userID);
  }, [db, userID]);

  return (
    <main>
      {!gotRole ? (
        <p>Loading...</p>
      ) : (
        <section className="card">
          <div className="card-header">
            <h1 className="text-xl font-bold pt-2 mb-3">
              New Time Off Request 
            </h1>
          </div>
          <div className="card-body">
            {submitSuccess === false ? (
              <form>
                <label for="reason">(Optional) Reason For Request:</label>
                <textarea
                  id="reason"
                  placeholder="Enter the reason for your request here"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  className="block w-full p-2 border mb-3 mt-1"
                />

                {/* Dates */}
                <p className="block mb-1">
                  Choose start and end dates, if you are only requesting one day
                  off, select the same day twice:
                </p>

                <label className="d-block mt-1">Dates:</label>
                <DatePicker
                  value={manualDates}
                  onChange={setManualDates}
                  range
                />
                <p className="my-1">Is this a half day?</p>
                <select
                  required
                  className="rounded p-2 w-75"
                  value={halfDay}
                  onChange={(e) => setHalfDay(e.target.value)}
                >
                  <option value="">Choose One</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                <button
                  onClick={(e) => submitRequest(e)}
                  type="submit"
                  className="block button mb-2 mt-3"
                >
                  Submit My Request
                </button>
              </form>
            ) : (
              <p className="block w-full p-2 border mb-3 mt-1">
                {/* Submission Success Message */}
                This request was sent to your supervisor and HR for approval.
                You will receive a message when it is approved or declined. You
                may close this window.
              </p>
            )}
          </div>
          <div className="card-footer">
            <button
              onClick={props.handleClosePTOModal}
              className="button-red mt-2 mb-2"
            >
              Close
            </button>
          </div>
        </section>
      )}
    </main>
  );
}

export default TimeOffRequest;
