import React, { useState, useEffect } from "react";
import { UserAuth } from "../../../Context/AuthContent";
import { Link, useNavigate } from "react-router-dom";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { Container, Row, Col } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { collection, query, where, getDocs, getDoc } from "firebase/firestore";
import UserInfo from "./UserInfo";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../../app.css";
import TimeOffRequest from "../../hr/TimeOffRequest";
import HumanResourcesDashboard from "../../hr/HumanResourcesDashboard";
import ProposalHistory from "../../proposals/ProposalHistory";
import FactfinderHistory from "../../factfinders/FactfinderHistory";
import ManageAnnouncements from "../../announcements/ManageAnnouncements";
import EditProfile from "../EditProfile";
import avatar from "../../../images/default-avatar.png";
import HRAtAGlance from "../../HRAtAGlance";
import AdminAtAGlance from "../../AdminAtAGlance";
import SupervisorAtAGlance from "../../SupervisorAtAGlance";
import HRMenu from "../../HRMenu";
import AdminMenu from "../../AdminMenu";
import SupervisorMenu from "../../SupervisorMenu";
import EmployeeMenu from "../../EmployeeMenu";
import HRDash from "../../hr/HRDash";
import EmployeeFeedbackView from "../../hr/EmployeeFeedbackView";
import EmployeePerformanceReviewsView from "../../hr/EmployeePerformanceView";
import EmployeeWarningsView from "../../hr/EmployeeWarningsView";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Account() {
  const navigate = useNavigate();

  // Modal Stuff
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Page Stuff
  const { user } = UserAuth();
  const userID = user.uid;
  const [loading, setLoading] = useState(true);
  const [tabular, setTabular] = useState(true);

  // Check User's Role
  const [userInfo, setUserInfo] = useState({});
  const [userPhoto, setUserPhoto] = useState(avatar);
  const [gotRole, setGotRole] = useState(false);
  const getRole = async (usersID) => {
    setGotRole(false);
    console.log("Getting User Info & Checking Role");
    const docRef = doc(db, "users", usersID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUserInfo(docSnap.data());
      setGotRole(true);
      setNoProfile(false);
    } else {
      // doc.data() will be undefined in this case
      console.log("No role info found!");
      setNoProfile(true);
      return null;
    }
  };

  const [unsubmittedCount, setUnsubmittedCount] = useState(0);
  const [submittedCount, setSubmittedCount] = useState(0);

  // Count User's Unread Messages
  const [messageCount, setMessageCount] = useState(0);
  const [doneCountingMessages, setDoneCountingMessages] = useState(false);
  const getMessageCount = async (usersID) => {
    console.log("Counting Unread messages");
    setLoading(true);
    const q = query(
      collection(db, "messages"),
      where("recipient", "==", usersID),
      where("status", "==", "Unread")
    );
    const queryData = await getDocs(q);
    const unread = queryData.docs.filter(
      (entry) => entry.data().status === "Unread"
    );
    setMessageCount(unread.length);
    setDoneCountingMessages(true);
    setLoading(false);
  };

  const [noProfile, setNoProfile] = useState();
  const [pendingFeedbackRequests, setPendingFeedbackRequests] = useState(0);

  const [unpublishedFeedbackRequests, setUnpublishedFeedbackRequests] =
    useState(0);
  useEffect(() => {
    if (userID) {
      if (gotRole === false) getRole(userID);
      if (doneCountingMessages === false) getMessageCount(userID);
    }
    if (gotRole === true && !userInfo.first) setNoProfile(true);
  }, [
    db,
    userID,
    unsubmittedCount,
    messageCount,
    userInfo.role,
    noProfile,
    pendingFeedbackRequests,
    unpublishedFeedbackRequests,
  ]);

  return (
    <div>
      {noProfile === true && <EditProfile userID={userID} />}
      {noProfile === false && (
        <div className=" h-100 overflow-none">
          {userInfo.admin && (
            <div className="mt-2">
              <a href="/admin-panel" className="text-sky-600">
                View Admin Panel
              </a>
            </div>
          )}
          {/* User Panel */}
          <div id="user-Info" className="card mt-3">
            {/* Header */}
            <div className="card-header bg-red-900">
              <h1 className="text-white text-2xl font-bold pt-3 mb-3">
                {userInfo.role === "HR" && "Human Resources "}
                {userInfo.role === "Admin" && "Administrator "}
                {userInfo.role === "Supervisor" && "Supervisor "}
                Dashboard
              </h1>
            </div>
            <div className="card-body p-0 bg-gray-200">
              <Container>
                <Row>
                  <Col>
                    <Container>
                      <Row className="border-2 mt-2">
                        <Col sm={12} md={6}>
                          {/* User Info */}
                          <UserInfo userInfo={userInfo} userPhoto={userPhoto} />
                        </Col>
                        <Col sm={12} md={6}>
                          {/* At A Glance */}
                          {userInfo.role === "Super-Admin" ||
                          userInfo.role === "Admin" ? (
                            <AdminAtAGlance />
                          ) : (
                            <></>
                          )}
                          {userInfo.role === "Supervisor" && (
                            <SupervisorAtAGlance
                              unpublishedFeedbackRequests={
                                unpublishedFeedbackRequests
                              }
                              pendingFeedbackRequests={pendingFeedbackRequests}
                            />
                          )}
                          {userInfo.role === "HR" && (
                            <>
                              <HRAtAGlance />
                            </>
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col md={3} className="p-0 bg-[#116582] rounded-br ">
                    {/* If user is Supervisor, show appointment/submission buttons
                        according to their status. */}
                    {userInfo.role === "Supervisor" && (
                      <SupervisorMenu userInfo={userInfo} userID={userID} />
                    )}
                    {/* If user is Admin or Admin, show Admin Menu */}
                    {userInfo.role === "Super-Admin" ||
                    userInfo.role === "Admin" ? (
                      <AdminMenu />
                    ) : (
                      <></>
                    )}
                    {/* If user is an employee, show employee Menu */}
                    {userInfo.role === "Employee" && (
                      <EmployeeMenu userInfo={userInfo} userID={userID} />
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          {/* Announcements */}
          {userInfo.role !== "New User" && (
            <div className="mt-3" id="announcements">
              <ManageAnnouncements
                userID={userID}
                userInfo={userInfo}
                name={userInfo.first + " " + userInfo.last}
                role={userInfo.role}
                setPendingFeedbackRequests={setPendingFeedbackRequests}
                setUnpublishedFeedbackRequests={setUnpublishedFeedbackRequests}
              />
            </div>
          )}

          {/* If user is a supervisor or HR, show HRDash */}
          {userInfo.role === "Supervisor" || userInfo.role === "Admin" ? (
            <div>
              {/* <HRDash userInfo={userInfo} /> */}
            </div>
          ) : (
            <></>
          )}
          {/* If has permission, show factfinder history */}
          {userInfo.role === "Admin" && (
            <>
              <FactfinderHistory
                userID={userID}
                setSubmittedCount={setSubmittedCount}
                setUnsubmittedCount={setUnsubmittedCount}
              />
            </>
          )}
          {/* If user is employee, show employee feedback view */}
          <EmployeeFeedbackView userInfo={userInfo} selectedUserID={userID} />
          <EmployeePerformanceReviewsView
            userInfo={userInfo}
            selectedUserID={userID}
          />
          <EmployeeWarningsView userInfo={userInfo} selectedUserID={userID} />
          {/* PTO Request Modal */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <TimeOffRequest userInfo={userInfo} userID={userID} />
              <button onClick={handleClose} className="button-red mt-2 mb-2">
                Close
              </button>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default Account;
