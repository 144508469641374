import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { deleteDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Container } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import CompanyInfo from "./CompanyInfo";
import EditFactfinder from "./EditFactfinder";
import { Link } from "react-router-dom";

function Company() {

  const navigate = useNavigate();

  // Date Stuff
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  // Company Info
  const [pAddress, setPAddress] = useState("");
  const [pCity, setPCity] = useState("");
  const [pState, setPState] = useState("");
  const [pZip, setPZip] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [dot, setDot] = useState("");
  const [mc, setMc] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");

  // Delete A Factfinder From History
  const deleteThisFactfinder = async (thisID) => {
    let verify = prompt(
      "You are about to delete a Factfinder! If you are sure, type DELETE below:"
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "Factfinders", thisID));
      getCompanyHistory(id);
    }
  };

  // Get Company Info
  const { id } = useParams(); 

  // Get Company Factfinder History
  const [companyHistory, setCompanyHistory] = useState([]);
  const [gotCompanyHistory, setGotCompanyHistory] = useState(false);
  const [loading, setLoading] = useState(true);
  const getCompanyHistory = async (companyID) => {
    if (gotCompanyHistory === false) {
      console.log("Getting Company Factfinder History");
      setLoading(true);
      const q = query(
        collection(db, "Factfinders"),
        where("company", "==", companyID)
      );
      const queryData = await getDocs(q);
      setCompanyHistory(
        queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      setGotCompanyHistory(true);
      setLoading(false);
    } else {return}
  };

const [childData, setChildData] = useState({});
const [hideFactfinder, setHideFactfinder] = useState(true);

  const [displayFactfinder, setDisplayFactfinder] = useState(false);

  const formData = {
    companyName,
    pAddress,
    pCity,
    pState,
    pZip,
    mc,
    dot,
    phone,
    companyEmail,
  };

  useEffect(() => {
    if (gotCompanyHistory === false) {
    getCompanyHistory(id)
    }
  }, [db, childData.companyName]); 

  return (
    <Container className="mt-2 text-center" fluid>
      {!displayFactfinder && (
        <CompanyInfo companyID={id} passChildData={setChildData} />
      )}
      
      {/* BUG: Company name disappears sometimes when re render */}

      {!displayFactfinder && (
        <button
          onClick={(e) => setDisplayFactfinder(true)}
          className="m-3 text-base text-white bg-red-900 hover:bg-red-700 pt-1 pb-2 px-3 rounded-md"
        >
          Create New Factfinder
        </button>
      )}

      {displayFactfinder && (
        <Container className="text-left" fluid>
          <EditFactfinder
            newFactfinder="true"
            companyID={id}
            companyName={childData.companyName}
            pAddress={childData.pAddress}
            pCity={childData.pCity}
            pState={childData.pState}
            pZip={childData.pZip}
            phone={childData.phone}
            mc={childData.mc}
            dot={childData.dot}
            hideFactfinder={hideFactfinder}
            getCompanyHistory={getCompanyHistory}
          />
        </Container>
      )}

      {!displayFactfinder && (
        <>
          {companyHistory && (
            <>
              
              <Container className="max-w-[1100px] ">
              <h3 className="text-lg pt-3 text-red-900 text-left">
                Factfinder history:
              </h3>
              <Table className="my-4 mx-auto border-2 border-black">
                
                    <Thead>
                      <Tr className="font-medium bg-black text-white">
                        <Td>Status</Td>
                        <Td>Company Name</Td>
                        <Td>Last Edited</Td>
                        <Td>Author</Td>
                        <Td>View/Edit</Td>
                        <Td>Delete</Td>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {companyHistory.map((myData, i) => (
                        <Tr key={"factfinder" + i} className="border-2 border-black">
                          <Td>{myData.factfinderStatus}</Td>
                          <Td>{myData.companyName}</Td>
                          <Td>{myData.lastUpdated}</Td>
                          <Td>{myData.authorName}</Td>
                          <Td>
                            <Link
                            to={"../editfactfinder/" + myData.id + "/" + myData.company}
                              className="mx-auto  font-medium text-red-900 border-red-900 hover:bg-red-100"
                            >
                              View/Edit
                            </Link>
                          </Td>
                          <Td>
                            <button
                              onClick={(e) => deleteThisFactfinder(myData.id)}
                              className="mx-auto  font-medium text-red-900 border-red-900 hover:bg-red-100"
                            >
                              Delete
                            </button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
              </Container>
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default Company;
