import React, { useEffect, useState } from "react";
import axios from "axios";
import { storage } from "../firebase.js";
import { ref, uploadBytes } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateDoc,
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  addDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { v4 } from "uuid";
import "./app.css";
import { Container } from "react-bootstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function GenericForm(props) {
  // Criteria Modal Stuff
  let subtitle;
  const [criteriaModalIsOpen, setCriteriaModalIsOpen] = React.useState(false);

  function openCriteriaModal(e) {
    e.preventDefault();
    setCriteriaModalIsOpen(true);
  }

  function afterOpenCriteriaModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeCriteriaModal() {
    setCriteriaModalIsOpen(false);
  }

  // Other Stuff
  let navigate = useNavigate();

  // Date Stuff
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  const [startDate, setStartDate] = useState(new Date());

  // Tabs Stuff
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    if (!props.newProposal);
    setValue(newValue);
  };

  // Hidden Form Stuff
  const [status, setStatus] = useState("");

  //   Form Stuff
  const [textInput, setTextInput] = useState("");
  const [dateInput, setDateInput] = useState(new Date());
  const [select, setSelect] = useState("Choose One");
  const [multipleOptions, setMultipleOptions] = useState([
    {
      id: 1,
      deductible: "Select",
      rate: 0,
    },
  ]);

  //   Form Handling
  const formData = {
    status,
    lastUpdated: date,
    textInput,
    dateInput,
    select,
    multipleOptions,
  };

  const handleBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleSelectBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleBlurMultipleOptions = async (e, i) => {
    console.log("Calculating New Premium");
    // e.preventDefault();
    const values = [...multipleOptions];
    values[i].totalPremium = parseFloat(
      (
        values[i].premium +
        values[i].SLtax +
        values[i].companyFee +
        values[i].policyFee +
        values[i].SLstampFee
      ).toFixed(2)
    );
    console.log(formData);
    setMultipleOptions(values);
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const handleChangeInputOption = (i, e) => {
    const values = [...multipleOptions];
    if (e.target.name === "deductible") {
      values[i][e.target.name] = e.target.value;
    } else {
      values[i][e.target.name] = parseFloat(e.target.value);
    }
    setMultipleOptions(values);
  };

  const handleAddOption = (e) => {
    e.preventDefault();
    const i = multipleOptions.length;
    if (i > 4) return;
    const lastID = multipleOptions[i - 1].id;
    setMultipleOptions([
      ...multipleOptions,
      {
        id: lastID + 1,
        deductible: "Select",
        rate: 0,
      },
    ]);
  };

  const handleSubtractOption = async (e, i) => {
    e.preventDefault();
    const values = [...multipleOptions];
    values.splice(i, 1);
    setMultipleOptions([...values]);
    if (values.length === 0) {
      setMultipleOptions([
        {
          id: 1,
          deductible: "Select",
          rate: 0,
        },
      ]);
    }
    // await updateDoc(proposalRef, { multipleOptions: values });
  };

  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output;
  };

  useEffect(() => {}, [db]);

  return (
    <Container className="h-screen w-full bg-gray-100" fluid>
      <fieldset>
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Factfinder Tabs"
              // disable the tab indicator because it doesn't work well with wrapped container
              // TabIndicatorProps={{ sx: { display: "none" } }}
              indicatorColor="black"
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                  backgroundColor: "lightGray",
                  marginTop: "5px",
                  justifyContent: "center",
                },
              }}
            >
              <Tab label="First Tab" {...a11yProps(0)} />
              <Tab label="Second Tab" {...a11yProps(1)} />
            </Tabs>
          </Box>
          {/* Policy Info */}
          <TabPanel value={value} index={0} className="bg-gray-100">
            <h1 className="title-1">First Tab Title</h1>
            {/* Generic Text Input */}
            <div>
              <label className="input-label">Text Example:</label>
              <input
                onChange={(e) => setTextInput(e.target.value)}
                onBlur={(e) => handleBlur(e)}
                onKeyDown={handleEnter}
                className={`text-input
                ${textInput === "" && `bg-red-100`}`}
                type="text"
                value={textInput}
              />
            </div>
            {/* Date Picker Example */}
            <div>
              <label className="input-label">Date Example:</label>
              <DatePicker
                selected={dateInput}
                onChange={(date: Date) => {
                  setDateInput(date);
                  console.log("new date" + date);
                }}
                className={`text-input`}
              />
            </div>
            {/* Dropdown Example */}
            <div>
              <label className="input-label">Dropdown Example:</label>
              <select
                onChange={(e) => {
                  setSelect(e.target.value);
                }}
                onBlur={(e) => {
                  handleSelectBlur(e);
                }}
                onKeyDown={handleEnter}
                className={`text-input ${
                  select === "Choose One" && `bg-red-100`
                }`}
                value={select}
              >
                <option value="Choose One">Choose One</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {/* If user selectes Yes */}
              {select === "Yes" && (
                <>
                  <p>Example of dependent element.</p>
                </>
              )}
            </div>
            {/* Back/Next Buttons */}
            <article className="back-next-container">
              <button
                onClick={() => setValue(value - 1)}
                className="button-1"
                disabled={value === 0}
              >
                Back
              </button>
              <button onClick={() => setValue(value + 1)} className="button-2">
                Next
              </button>
            </article>
          </TabPanel>
          {/* Auto Physical Damage */}
          <TabPanel value={value} index={1} className="bg-gray-100">
            <h1 className="title-1">Second Tab Title</h1>
            <article className="back-next-container">
              <button
                onClick={() => setValue(value - 1)}
                className="button-1"
                disabled={value === 0}
              >
                Back
              </button>
              <button onClick={() => setValue(value + 1)} className="button-2">
                Next
              </button>
            </article>
          </TabPanel>
        </Box>
      </fieldset>
    </Container>
  );
}

export default GenericForm;
