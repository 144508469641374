import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../../Context/AuthContent";
import { addDoc, collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";


function AddEmployee(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { createUser } = UserAuth();

  const [userInfo, setUserInfo] = useState({
    email,
  });
  const [hideForm, setHideForm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in 
        const user = userCredential.user;
        console.log(user);
        await setDoc(doc(db, "users", user.uid), userInfo);
        setHideForm(true);
        setShowSuccess(true)
        setUserInfo({});
        setEmail("");
        setPassword("");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage);
        // ..
      });
    // try {
    //   await createUser(email, password);
    //   
    //   setHideForm(true);
    //   setShowSuccess(true);
    //   props.getUsers();
    // } catch (e) {
    //   alert(e.message);
    // }
  };

  const [departmentList, setDepartmentList] = useState([]);
  const [gotDepartmentList, setGotDepartmentList] = useState(false);
  const getDepartmentList = async () => {
    if (gotDepartmentList) return;
    setDepartmentList([]);
    const querySnapshot = await getDocs(collection(db, "departments"));
    let values = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      values.push({ ...doc.data(), id: doc.id });
    });
    console.log(values);
    setDepartmentList(values);
    setGotDepartmentList(true);
  };

  const [department, setDepartment] = useState("");

  useEffect(() => {
    getDepartmentList();
  }, [gotDepartmentList]);

  return (
    <div className="max-w-[700px] mx-auto">
      {!hideForm && (
        <div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="flex flex-col py-2">
              <label className="py-2 font-medium">
                Email Address (Required)
              </label>
              <input
                required
                onChange={(e) => setEmail(e.target.value)}
                className="border p-3 rounded"
                type="email"
              />
              <label className="py-2 font-medium">Password (Required)</label>
              <input
                required
                onChange={(e) => setPassword(e.target.value)}
                className="border p-3 rounded"
                type="password"
              />
              <label className="py-2 font-medium">First Name (Required)</label>
              <input
                required
                placeholder="First Name"
                type="text"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, first: e.target.value })
                }
                className="border p-3 rounded"
                value={userInfo?.first}
              />
              <label className="py-2 font-medium">Last Name (Required)</label>
              <input
                required
                placeholder="Last Name"
                type="text"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, last: e.target.value })
                }
                className="border p-3 rounded"
                value={userInfo?.last}
              />
              <label className="py-2 font-medium">Company</label>
              <select
                onChange={(e) =>
                  setUserInfo({ ...userInfo, company: e.target.value })
                }
                value={userInfo?.company}
              >
                <option value={"Licona Insurance Group"}>
                  Licona Insurance Group
                </option>
                <option value={"Southern Star MGA"}>Southern Star MGA</option>
              </select>

              <label className="py-2 font-medium">Phone</label>
              <input
                placeholder="Phone"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, phone: e.target.value })
                }
                type="text"
                className="border p-3 rounded"
                value={userInfo?.phone}
              />
              <label className="py-2 font-medium">Extension</label>
              <input
                placeholder="Extension"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, extension: e.target.value })
                }
                type="text"
                className="border p-3 rounded"
                value={userInfo?.extension}
              />
              <label className="py-2 font-medium">Title</label>
              <input
                placeholder="Title"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, title: e.target.value })
                }
                type="text"
                className="border p-3 rounded"
                value={userInfo?.title}
              />
              <label className="py-2 font-medium">Department</label>
              <select
                onChange={(e) =>
                  setUserInfo({ ...userInfo, department: e.target.value })
                }
                value={userInfo?.department}
              >
                <option value="">Choose One</option>
                {departmentList.map((dept, i) => {
                  return (
                    <option value={dept.name} key={dept.id}>
                      {dept.name}
                    </option>
                  );
                })}
              </select>
                  <label className="py-2 font-medium">Supervisor</label>
                  <input
                placeholder="Supervisor"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, supervisor: e.target.value })
                }
                type="text"
                className="border p-3 rounded"
                value={userInfo?.supervisor}
              />
              <label className="py-2 font-medium">Office Location</label>
              <input
                placeholder="Office"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, office: e.target.value })
                }
                type="text"
                className="border p-3 rounded"
                value={userInfo?.office}
              />
              <label className="py-2 font-medium">Hire Date</label>
              <DatePicker
                selected={userInfo?.hireDate}
                onChange={(date: Date) => {
                    setUserInfo({ ...userInfo, hireDate: date });
                }}
                className={`text-input w-full p-3`}
              />
              <label className="py-2 font-medium">Birthday</label>
              <DatePicker
                selected={userInfo?.birthday}
                onChange={(date: Date) => {
                    setUserInfo({ ...userInfo, birthday: date });
                }}
                className={`text-input w-full p-3`}
              />
            </div>
            <button
              type="submit"
              className="bg-red-900 hover:bg-red-700 rounded w-full p-4 my-2 text-white"
            >
              Sign Up
            </button>
          </form>
        </div>
      )}
      {showSuccess && (
        <>
        <h2 className="text-center text-xl bg-green-200 w-full p-3">
            Employee successfully added.
        </h2>
        <a className="text-center w-full text-sky-600 my-3" href="/hr">Click Here To Refresh</a>
        </>
      )}
    </div>
  );
}

export default AddEmployee;
