import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  query,
  orderBy,
  setDoc
} from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";

function NewMessage() {
  // Get the date
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  let navigate = useNavigate();

  const auth = getAuth();

  const [userList, setUserList] = useState([]);
  const usersCollectionRef = collection(db, "users");

  const getUsers = async () => {
    const data = await getDocs(query(usersCollectionRef, orderBy("first")));
    setUserList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const [recipientName, setRecipientName] = useState("");
  const [recipient, setRecipient] = useState("");
  const handleChangeRecipient = (event) => {
    const { value } = event.target;
    setRecipient(value.split('-')[0]);
    setRecipientName(value.split('-')[1]);
  };

  const [subject, setSubject] = useState("");
  const handleChangeSubject = (event) => {
    const { value } = event.target;
    setSubject(value);
  };

  const [message, setMessage] = useState("");
  const handleChangeMessage = (event) => {
    const { value } = event.target;
    setMessage(value);
  };

  const sendMessage = async (e) => {
    await setDoc(doc(db, "messages", Math.floor(Math.random() * 9999999) + '-' + auth.currentUser.uid + '-' + Math.floor(Math.random() * 9999999)), {
        date,
        senderID: auth.currentUser.uid,
        sender: auth.currentUser.displayName,
        status: "Unread",
        recipientName,
        recipient,
        subject,
        message,
      });
    navigate("/account");
    console.log("Message Sent");
  };

  useEffect(() => {
    getUsers();
  }, [db]);

  return (
    <main className="mx-auto max-w-[650]">
      <select
        className="block w-full mx-auto rounded-md font-medium border-2 p-3 mt-4 sm:text-xl md:text-xl"
        onChange={handleChangeRecipient}
      >
        <option>Select A Recipient</option>

        {userList.map((userObject) => {
          if (userObject.first) {
            return (
              <option key={"id" + userObject.userID} value={userObject.userID + '-' + userObject.first + ' ' + userObject.last}>
                {userObject.first + " " + userObject.last}
              </option>
            );
          }
        })}
      </select>
      <input
        className="block w-full mx-auto rounded-md font-medium border-2 p-3 mt-4 sm:text-xl md:text-xl"
        placeholder="Subject"
        type="text"
        onChange={handleChangeSubject}
        value={subject}
      />
      <textarea
        className="block w-full mx-auto rounded-md font-medium border-2 p-3 mt-4 sm:text-xl md:text-xl"
        type="text"
        value={message}
        placeholder="Message Body"
        onChange={handleChangeMessage}
      />
      <button 
      onClick={sendMessage}
      className="block mx-auto text-xl text-white bg-red-900 hover:bg-red-700 py-2 px-4 mt-3 mb-3 rounded-md">
        Send Message
      </button>
      <div className="mx-auto text-center max-w-[100px]">
      <a 
      href="/messages"
      className="text-red-900 font-medium">Cancel</a>
      </div>
    </main>
  );
}

export default NewMessage;
