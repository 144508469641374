import React, { useState, useEffect } from "react";
import { UserAuth } from "../Context/AuthContent";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Container, Row, Col } from "react-bootstrap";
import Factfinder from "./trash/Factfinder_deprecated";
import ReactDOM from "react-dom";
import Modal from "react-modal";

function CompanyInfo(props) {
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  const { user, logout } = UserAuth();
  const [userData, setUserData] = useState({});
  const [pAddress, setPAddress] = useState("");
  const [pCity, setPCity] = useState("");
  const [pState, setPState] = useState("");
  const [pZip, setPZip] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [dot, setDot] = useState("");
  const [mc, setMc] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const navigate = useNavigate();

  // Modal Stuff
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "375px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement("#root");

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    setLoading(true);
    const companyRef = doc(db, "companies", id);
    setDoc(companyRef, { ...formData, lastUpdated: date }, { merge: true });
    setLoading(false);
  }

  // Get Company Info
  const [myData, setMyData] = useState({});
  const [gotCompanyInfo, setGotCompanyInfo] = useState(false);
  const id = props.companyID;
  const companyRef = doc(db, "companies", id);
  const getCompanyInfo = async () => {
    if (id && !gotCompanyInfo) {
      try {
        console.log("Getting Company Info");
        const docSnap = await getDoc(companyRef);
        const data = docSnap.data();
        setMyData(data);
        setGotCompanyInfo(true);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const setInfo = () => {
    setCompanyName(myData?.company);
    setDot(myData?.dot);
    setMc(myData?.mc);
    setPAddress(myData?.pAddress);
    setPCity(myData?.pCity);
    setPState(myData?.pState);
    setPZip(myData?.pZip);
    setPhone(myData?.phone);
    setCompanyEmail(myData?.companyEmail);
  };


  const [loading, setLoading] = useState(true);

  const [displayFactfinder, setDisplayFactfinder] = useState(false);
  const hideFactfinder = () => {
    setDisplayFactfinder(false);
  };

  const handleBlur = async (e) => {
    e.preventDefault();
    const fieldName = e.target.name;
    if (e.target.value === "") {
      e.target.className = "border p-1 text-base font-medium w-full block";
    } else {
      e.target.className = "text-base border-0 p-1 w-full block";
    }
    const companyRef = doc(db, "companies", id);
    setDoc(companyRef, { ...formData, lastUpdated: date }, { merge: true });
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const formData = {
    companyName,
    pAddress,
    pCity,
    pState,
    pZip,
    mc,
    dot,
    phone,
    companyEmail, 
  };

  useEffect(() => {
    // getUserInfo();
    getCompanyInfo(id)
    .then(setInfo)
    .then(props.passChildData(formData));
    
  }, [db, modalIsOpen, formData.companyName]);

  return (
    <div className="text-center">
      <h3 className="text-lg text-red-900 text-bold">Company Information</h3>
      {/* BUG: Company name disappears sometimes when re render */}
      <h3 className="text-3xl text-black font-bold">{myData?.company}</h3>
      <p>{myData?.pStreet}</p>
      <p>
        {myData?.pCity}, {myData?.pState} {myData?.pZip}
      </p>
      {/* @TODO: Make Phone & Email Clickable */}
      <p>{myData?.phone}</p>
      <p>{myData?.companyEmail}</p>
      <Container className="max-w-[300px]">
        <Row>
          <Col>DOT: {myData.dot}</Col>
          <Col className="text-right">MC: {myData.mc}</Col>
        </Row>
      </Container>
      <div>
        <button className="mt-3 px-2 py-1 border-red-900 border-2 text-red-900" onClick={openModal}>Edit Company Info</button>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Company Info Modal"
        >
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
          <div className="w-full">
            <h2 className="font-bold text-base text-red-900">
              Edit Company Info
            </h2>
          </div>
          <form>
      <fieldset id="company-info">
      <input
              onChange={(e) => setCompanyName(e.target.value)}
              onBlur={(e) => handleBlur(e)}
              onFocus={(e) => (e.target.className = "w-full text-base p-1")}
              onKeyDown={handleEnter}
              className={`text-base border-0 block w-full ${
                companyName === "" && `border-2 bg-red-100`
              }  ${companyName != "" && `border-0`}`}
              type="text"
              placeholder="Company Name"
              value={companyName}
              disabled
            />
            <input
              onChange={(e) => setPAddress(e.target.value)}
              onBlur={(e) => handleBlur(e)}
              onFocus={(e) => (e.target.className = "w-full text-base p-1")}
              onKeyDown={handleEnter}
              className={`text-base border-0 block w-full ${
                pAddress === "" && `border-2 bg-red-100`
              }  ${pAddress != "" && `border-0`}`}
              type="text"
              placeholder="Address"
              value={pAddress}
            />
            <input
              onChange={(e) => setPCity(e.target.value)}
              onBlur={(e) => handleBlur(e)}
              onFocus={(e) => (e.target.className = "w-full text-base p-1")}
              onKeyDown={handleEnter}
              className={`text-base border-0 block w-full ${
                pCity === "" && `border-2 bg-red-100`
              }  ${pCity != "" && `border-0`}`}
              type="text"
              placeholder="City"
              value={pCity}
            />
            <input
              onChange={(e) => setPState(e.target.value)}
              onBlur={(e) => handleBlur(e)}
              onFocus={(e) => (e.target.className = "w-full text-base p-1")}
              onKeyDown={handleEnter}
              className={`text-base border-0 block w-full ${
                pState === "" && `border-2 bg-red-100`
              }  ${pState != "" && `border-0`}`}
              type="text"
              placeholder="State"
              value={pState}
            />
            <input
              onChange={(e) => setPZip(e.target.value)}
              onBlur={(e) => handleBlur(e)}
              onFocus={(e) => (e.target.className = "w-full text-base p-1")}
              onKeyDown={handleEnter}
              className={`text-base border-0 block w-full ${
                pZip === "" && `border-2 bg-red-100`
              }  ${pZip != "" && `border-0`}`}
              type="text"
              placeholder="Zip"
              value={pZip}
            />
            <input
              onChange={(e) => setMc(e.target.value)}
              onBlur={(e) => handleBlur(e)}
              onFocus={(e) => (e.target.className = "w-full text-base p-1")}
              onKeyDown={handleEnter}
              className={`text-base border-0 block w-full ${
                mc === "" && `border-2 bg-red-100`
              }  ${mc != "" && `border-0`}`}
              type="text"
              placeholder="MC Number"
              value={mc}
            />
            <input
              onChange={(e) => setDot(e.target.value)}
              onBlur={(e) => handleBlur(e)}
              onFocus={(e) => (e.target.className = "w-full text-base p-1")}
              onKeyDown={handleEnter}
              className={`text-base border-0 block w-full ${
                dot === "" && `border-2 bg-red-100`
              }  ${dot != "" && `border-0`}`}
              type="text"
              placeholder="DOT Number"
              value={dot}
            />
            <input
              onChange={(e) => setPhone(e.target.value)}
              onBlur={(e) => handleBlur(e)}
              onFocus={(e) => (e.target.className = "w-full text-base p-1")}
              onKeyDown={handleEnter}
              className={`text-base border-0 block w-full ${
                phone === "" && `border-2 bg-red-100`
              }  ${phone != "" && `border-0`}`}
              type="text"
              placeholder="Phone"
              value={phone}
            />
            <input
              onChange={(e) => setCompanyEmail(e.target.value)}
              onBlur={(e) => handleBlur(e)}
              onFocus={(e) => (e.target.className = "w-full text-base p-1")}
              onKeyDown={handleEnter}
              className={`text-base border-0 block w-full ${
                companyEmail === "" && `border-2 bg-red-100`
              }  ${companyEmail != "" && `border-0`}`}
              type="text"
              placeholder="Company Email"
              value={companyEmail}
            />
            </fieldset>
      

            <button className="w-full align-center mt-2 font-bold text-red-900" onClick={closeModal}>
              Save & Close
            </button>
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default CompanyInfo;
