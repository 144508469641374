import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { getAuth, updateProfile } from "firebase/auth";

function UpdateDisplayName() {
  
  const auth = getAuth();
console.log(auth.currentUser)
  const makeChange = (newName) => {
    updateProfile(auth.currentUser, {
      displayName: newName
    }).then(() => {
      // Profile updated!
      alert('Profile Updated');
      console.log(auth.currentUser?.displayName);

      // ...
    }).catch((error) => {
      // An error occurred
      alert('Error')
      // ...
    });
  }


  const [nameForChange, setNameForChange] = useState("");
  const handleChangeName = (event) => {
    const { value } = event.target;
    setNameForChange(value);
  };

  useEffect(() => {}, [db]);

  return (
    <>
      <input
        className="block mx-auto rounded-md font-medium border-2 p-3 mt-4 sm:text-xl md:text-xl"
        placeholder="New Display Name"
        type="text"
        onChange={handleChangeName}
      />
      <button
        className="block mx-auto text-xl text-white bg-red-900 hover:bg-red-700 py-2 px-4 mt-3 mb-10 rounded-md"
        onClick={() => makeChange(nameForChange)}
      >
        Update Display Name
      </button>
    </>
  );
}

export default UpdateDisplayName;
