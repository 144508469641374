import React, { useEffect, useState } from "react";
import { UserAuth } from "../Context/AuthContent";
import {
  getFirestore,
  getDocs,
  collection,
  deleteDoc,
  doc,
  where,
  query,
  getDoc,
  addDoc,
  orderBy,
  setDoc
} from "firebase/firestore";
import { db, auth } from "../firebase";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { useNavigate, useParams } from "react-router-dom";
import AddCustomer from "../Components/AddCompany";
import { async } from "@firebase/util";
import Modal from "react-bootstrap/Modal";
import FormGroup from "react-bootstrap/esm/FormGroup";
import { getAuth, updateProfile } from "firebase/auth";

function NewMessage() {
  // Get the date
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  let navigate = useNavigate();

  const auth = getAuth();

  const [messageData, setMessageData] = useState({});
  const { id } = useParams();
  const messageRef = doc(db, "messages", id);
  const getMessageData = async () => {
    if (id) {
      try {
        console.log("Getting message data");
        const docSnap = await getDoc(messageRef);
        const data = docSnap.data();
        setMessageData(data);
      } catch (err) {
        console.log(err);
      }
    }
    console.log(messageData);
  };

  const deleteThis = async () => {
    let verify = prompt(
      "You are about to delete a message for the sender and recipient! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "messages", id));
      navigate("/messages");
    }
  };

  useEffect(() => {
    getMessageData();
  }, [db]);

  return (
    <main className="mx-auto max-w-[650]">
              <a href="/messages" className="text-base text-sky-600 font-medium d-block">View Inbox</a>
              <button onClick={() => deleteThis()} className="text-base text-sky-600 font-medium d-block">Delete This Message</button>

              <h1 className="text-2xl font-bold mt-1 mb-1">View Message</h1>

        <table width="100%">
        <tr>
                <td width="75px">Status:</td>
                <td>{messageData.status}</td>
            </tr>
            <tr>
                <td>Date:</td>
                <td>{messageData.date}</td>
            </tr>
            <tr>
                <td>Sender:</td>
                <td>{messageData.sender}</td>
            </tr>
            <tr>
                <td>Recipient:</td>
                <td>{messageData.recipientName}</td>
            </tr>
            <tr>
                <td>Subject:</td>
                <td>{messageData.subject}</td>
            </tr>
            <tr>
                <td colSpan={2}>
                Message:
                </td>
            </tr>      
            <tr>
                <td colSpan={2}>
                {messageData.message}
                </td>
            </tr>      
        </table>
        
    </main>
  );
}

export default NewMessage;
