import React, { useEffect } from "react";

export default function AtAGlance(props) {

  
  return (
    <div>
      <div className="card mb-2 shadow">
        <div className="card-header bg-[#116582]">
          <h2 className="font-medium text-white">PTO Requests</h2>
        </div>
        <div className="card-body py-2">
          <h1 className="text-center">
            <span className="text-2xl mr-2">
              0
            </span>
            Pending PTO Requests
          </h1>
        </div>
        <div className="card-footer">
          <h4 className="text-red-900 font-medium">
            0 Declined
            <span className="float-right text-green-900">
              0 Approved
            </span>
          </h4>
        </div>
      </div>
      <div className="card mb-2 shadow">
        <div className="card-header bg-[#072a48]">
          <h2 className="font-medium text-white">Feedback</h2>
        </div>
        <div className="card-body py-2">
          <h1 className="text-center">
            <span className="text-2xl mr-2">
              {props.pendingFeedbackRequests}
            </span>
            Pending Feedback Requests
          </h1>
        </div>
        <div className="card-footer">
          <h4 className="text-center text-red-900 font-medium">
           
            {props.unpublishedFeedbackRequests} Unpublished Feedback
          </h4>
        </div>
      </div>
      <div className="card mb-4 shadow">
        <div className="card-header bg-red-900">
          <h2 className="font-medium text-white">Performance Review Requests</h2>
        </div>
        <div className="card-body py-2">
          <h1 className="text-center">
            <span className="text-2xl mr-2">0</span>
            Pending Performance Review Requests
          </h1>
        </div>
        <div className="card-footer">
          <h4 className="text-center text-red-900 font-medium">
            
            <span className="text-green-900">
              Please complete requests in a timely manner.
            </span>
          </h4>
        </div>
      </div>
    </div>
  );
}
