import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  setDoc,
  addDoc,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import DatePicker from "react-multi-date-picker";
import { table, Thead, Tbody, tr, td } from "react-super-responsive-table";
import "../app.css";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import HumanResourcesNotes from "./HumanResourcesNotes";
import Modal from "react-modal";
import HiringSteps from "./HiringSteps";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Applicant() {
  // Date Stuff
  function toDateTime(secs) {
    var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    t.setSeconds(secs);
    return t.toDateString();
  }

  // NextStep Modal Stuff
  let subtitle;
  const [nextStepModalIsOpen, setNextStepModalIsOpen] = React.useState(false);

  function openNextStepModal(e) {
    e.preventDefault();
    setNextStepModalIsOpen(true);
  }

  function afterOpenNextStepModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeNextStepModal() {
    setNextStepModalIsOpen(false);
  }

  const { id } = useParams();

  //   Get Selected User's Info
  const [applicantInfo, setApplicantInfo] = useState([]);
  const [gotInfo, setGotInfo] = useState(false);
  const getApplicantInfo = async (applicantID) => {
    setGotInfo(false);
    const docRef = doc(db, "applicants", applicantID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setApplicantInfo(docSnap.data());
      console.log("Document data:", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
    setGotInfo(true);
  };

  // Edit Next Step
  const [newNextStep, setNewNextStep] = useState("");
  const editNextStep = (e, applicantID) => {
    e.preventDefault();
    const applicantRef = doc(db, "applicants", applicantID);
    setDoc(applicantRef, { nextStep: newNextStep }, { merge: true });
    applicantInfo.nextStep = newNextStep;
    closeNextStepModal();
    setNewNextStep("");
  };

  // Edit Status Modal Stuff
  const [editStatusModalIsOpen, setEditStatusModalIsOpen] =
    React.useState(false);

  function openEditStatusModal(e) {
    // e.preventDefault();
    setEditStatusModalIsOpen(true);
  }

  function afterOpenEditStatusModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeEditStatusModal() {
    setEditStatusModalIsOpen(false);
  }

  // Edit Status
  const [newStatus, setNewStatus] = useState("");
  const editStatus = (e, applicantID) => {
    e.preventDefault();
    const applicantRef = doc(db, "applicants", applicantID);
    setDoc(applicantRef, { status: newStatus }, { merge: true });
    applicantInfo.status = newStatus;
    closeEditStatusModal();
    setNewStatus("");
  };

  // Edit BestFit Modal Stuff
  const [editBestFitModalIsOpen, setBestFitModalIsOpen] = React.useState(false);

  function openBestFitModal(e) {
    // e.preventDefault();
    setBestFitModalIsOpen(true);
  }

  function afterOpenBestFitModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeBestFitModal() {
    setBestFitModalIsOpen(false);
  }

  // Edit Best Fit
  const [newBestFit, setNewBestFit] = useState("");
  const editBestFit = (e, applicantID) => {
    e.preventDefault();
    const applicantRef = doc(db, "applicants", applicantID);
    setDoc(applicantRef, { bestFit: newBestFit }, { merge: true });
    applicantInfo.bestFit = newBestFit;
    closeBestFitModal();
    setNewBestFit("");
  };

  // Edit New Step Modal Stuff
  const [editNewStepModalIsOpen, setNewStepModalIsOpen] = React.useState(false);

  function openNewStepModal(e) {
    // e.preventDefault();
    setNewStepModalIsOpen(true);
  }

  function afterOpenNewStepModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeNewStepModal() {
    setNewStepModalIsOpen(false);
  }

  // Add New Step
  const [newStepTitle, setNewStepTitle] = useState("");
  const [newStepText, setNewStepText] = useState("");
  const [stepUpdated, setStepUpdated] = useState(false);

  const addNewStep = async (e) => {
    e.preventDefault();
    const oldLength = stepList.length;
    const docRef = await addDoc(collection(db, "hiring-steps"), {
      place: oldLength + 1,
      name: newStepTitle,
      description: newStepText,
      questions: [""],
    });
    console.log("Document written with ID: ", docRef.id);
    closeNewStepModal();
    setNewStepTitle("");
    setNewStepText("");
    getSteps();
  };

  const [gotSteps, setGotSteps] = useState(false);
  const [stepList, setStepList] = useState([]);
  const [selectedStep, setSelectedStep] = useState("1oMhI1n9fXKG5lkhEQZz");
  const getSteps = async () => {
    setGotSteps(false);
    const data = await getDocs(
      query(collection(db, "hiring-steps"), orderBy("place"))
    );
    setStepList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setGotSteps(true);
  };

  const handleChangeStep = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setSelectedStep(e.target.value);
    setStepUpdated(false);
  };

  const [customQuestions, setCustomQuestions] = useState([
    { description: "", answer: "" },
  ]);
  const [gotCustomQuestions, setGotCustomQuestions] = useState(false);
  const [showCustomQuestions, setShowCustomQuestions] = useState(true);
  const getCustomQuestions = () => {
    const entries = Object.entries(applicantInfo);
    console.log(entries);
    const filteredEntries = entries.filter((entry) => {
      return entry[0].startsWith("Custom-Question:");
    });
    filteredEntries.forEach((entry, i) => {
      const newEntry = entry[0].slice(17);
      console.log(newEntry);
      filteredEntries[i][0] = newEntry;
    });
    console.log(filteredEntries);
    setCustomQuestions(filteredEntries);
    setGotCustomQuestions(true);
  };

  const removePrefix = (question) => {};

  useEffect(() => {
    if (gotInfo === false) getApplicantInfo(id);
    if (gotInfo === true) getCustomQuestions();
    if (gotSteps === false) getSteps();
  }, [db, gotInfo, gotSteps]);

  return (
    <>
      <h2 className="text-red-900 font-bold text-2xl mt-2 mb-3">
        Applicant Profile
      </h2>
      <Container className="mx-auto">
        {/* Applicant Information */}
        <Row className="mt-1 p-3 bg-gray-200 rounded">
          <p className="block font-bold text-xl mb-2">
            {applicantInfo.first + " " + applicantInfo.last}
          </p>
          <Col xs={12} md={3}>
            <p className="block">
              <i>Desired Position:</i> {applicantInfo.desiredPosition}
            </p>
            <a
              className="text-sky-600"
              href={applicantInfo.resume}
              target="new"
            >
              View Resume
            </a>{" "}
            {" | "}
            <a
              className="text-sky-600"
              href={applicantInfo.coverLetter}
              target="new"
            >
              View Cover Letter
            </a>
          </Col>
          <Col xs={12} md={3}>
            <p className="block">
              <i>Best Fit:</i>{" "}
              <button
                className="text-sky-600"
                onClick={(e) => openBestFitModal(e)}
              >
                {applicantInfo.bestFit}
              </button>
            </p>

            <p className="block">
              Status:{" "}
              <button
                className="text-sky-600"
                onClick={(e) => openEditStatusModal(e)}
              >
                {applicantInfo.status}
              </button>
            </p>
          </Col>
          <Col xs={12} md={3}>
            <p className="block">
              Cell:{" "}
              <a href={"phone:" + applicantInfo.phone}>{applicantInfo.phone}</a>
            </p>
            <p className="block">
              Email:{" "}
              <a href={"mailto:" + applicantInfo.email}>
                {applicantInfo.email}
              </a>
            </p>
          </Col>
          <Col xs={12} md={3}>
            <p className="block">{applicantInfo.address}</p>
            <p className="block">
              {applicantInfo.city +
                ", " +
                applicantInfo.state +
                ", " +
                applicantInfo.zip}
            </p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h2 className="text-red-900 font-bold text-xl">Hiring Process:</h2>

            <div className="bg-gray-200 p-2 rounded my-2">
              
                <h2 className="text-lg font-medium">Application Questions & Answers</h2>
                {showCustomQuestions === false && (
                  <button
                    className="text-sky-600 mb-2"
                    onClick={(e) => setShowCustomQuestions(true)}
                  >
                    (Click To Show)
                  </button>
                )}
              
              {showCustomQuestions === true && (
                <>
                  <button
                    className="text-sky-600 mb-2"
                    onClick={(e) => setShowCustomQuestions(false)}
                  >
                    (Click To Hide)
                  </button>
                  <ul>
                    {customQuestions.map((question, i) => {
                      return (
                        <li>
                          <p className="text-red-900">{question[0]}</p>
                          <p>{question[1]}</p>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>

            <p className="block">
              Next Step:{" "}
              <button
                className="text-sky-600"
                onClick={(e) => {
                  openNextStepModal(e);
                }}
              >
                {applicantInfo.nextStep}
              </button>
            </p>
            {/* Steps */}
            <select
              value={selectedStep}
              onChange={(e) => {
                handleChangeStep(e);
              }}
              className="rounded block w-full mb-2"
            >
              {stepList.map((step, i) => {
                return (
                  <>
                    <option
                      disabled={selectedStep === step.id}
                      stepUpdated={stepUpdated}
                      setStepUpdated={setStepUpdated}
                      value={step.id}
                    >
                      {step.name}
                    </option>
                  </>
                );
              })}
            </select>
            <button
              className="text-sky-600 block mb-1 mt-2"
              onClick={openNewStepModal}
            >
              + Add A Step
            </button>
            <Button className="bg-sky-600" variant="primary">
              Continue To This Step
            </Button>
            <Button className="block my-1 bg-red-600" variant="danger">
              Edit This Step
            </Button>

            <HiringSteps
              selectedStep={selectedStep}
              setSelectedStep={setSelectedStep}
            />
          </Col>
          <Col>
          <h2 className="text-red-900 font-bold text-xl mb-2">Notes:</h2>
            <HumanResourcesNotes selectedUserID={id}></HumanResourcesNotes>
          </Col>
        </Row>
      </Container>
      {/* Next Step Modal */}
      <div>
        <Modal
          isOpen={nextStepModalIsOpen}
          onAfterOpen={afterOpenNextStepModal}
          onRequestClose={closeNextStepModal}
          style={customStyles}
          contentLabel="Add NextStep"
        >
          <div>Update Next Step</div>
          <label className="block">New Next Step:</label>
          <input
            type="text"
            onChange={(e) => setNewNextStep(e.target.value)}
            value={newNextStep}
          />
          <button className="block" onClick={(e) => editNextStep(e, id)}>
            Submit
          </button>
          <button onClick={(e) => closeNextStepModal(e)}>Cancel</button>
        </Modal>
      </div>
      {/* Edit Status Modal */}
      <div>
        <Modal
          isOpen={editStatusModalIsOpen}
          onAfterOpen={afterOpenEditStatusModal}
          onRequestClose={closeEditStatusModal}
          style={customStyles}
          contentLabel="Edit Status"
        >
          <div>Update Status</div>
          <label className="block">New Status:</label>
          <input
            type="text"
            onChange={(e) => setNewStatus(e.target.value)}
            value={newStatus}
          />
          <button className="block" onClick={(e) => editStatus(e, id)}>
            Submit
          </button>
          <button onClick={(e) => closeEditStatusModal(e)}>Cancel</button>
        </Modal>
      </div>
      {/* Best Fit Modal */}
      <div>
        <Modal
          isOpen={editBestFitModalIsOpen}
          onAfterOpen={afterOpenBestFitModal}
          onRequestClose={closeBestFitModal}
          style={customStyles}
          contentLabel="Edit Best Fit"
        >
          <div>Update BestFit</div>
          <label className="block">New Best Fit:</label>
          <input
            type="text"
            onChange={(e) => setNewBestFit(e.target.value)}
            value={newBestFit}
          />
          <button className="block" onClick={(e) => editBestFit(e, id)}>
            Submit
          </button>
          <button onClick={(e) => closeBestFitModal(e)}>Cancel</button>
        </Modal>
      </div>

      {/* New Question Modal */}
      <div>
        <Modal
          isOpen={editNewStepModalIsOpen}
          onAfterOpen={afterOpenNewStepModal}
          onRequestClose={closeNewStepModal}
          style={customStyles}
          contentLabel="Add A Step"
        >
          <div>Add A Step</div>
          <label className="block">Step Name:</label>
          <input
            type="text"
            onChange={(e) => setNewStepTitle(e.target.value)}
            value={newStepTitle}
          />
          <label className="block">Description:</label>

          <textarea
            onChange={(e) => setNewStepText(e.target.value)}
            value={newStepText}
          />
          <button className="block" onClick={(e) => addNewStep(e)}>
            Submit
          </button>
          <button onClick={(e) => closeNewStepModal(e)}>Cancel</button>
        </Modal>
      </div>
    </>
  );
}

export default Applicant;
