// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore"; 
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBZFI_21UqkeHtT44Om65DKvkvsT1XHYwk",
  authDomain: "sstools-d4ca8.firebaseapp.com",
  projectId: "sstools-d4ca8",
  storageBucket: "sstools-d4ca8.appspot.com",
  messagingSenderId: "345271383109",
  appId: "1:345271383109:web:e0b9b42cd34ffbef0adf2c"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app)

export default app 