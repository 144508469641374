import React, { useEffect, useState } from "react";
import axios from "axios";
import { storage } from "../../firebase.js";
import { ref, uploadBytes } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateDoc,
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  addDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { v4 } from "uuid";
import "../app.css";
import { Container } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const marks = [
    {
      value: 1,
      label: "1: Unacceptable",
    },
    {
      value: 2,
      label: "2: Needs Improvement",
    },
    {
      value: 3,
      label: "3: Meets Expectations",
    },
    {
      value: 4,
      label: "4: Exceeds Expectations",
    },
    {
      value: 5,
      label: "5: Outstanding",
    },
  ];

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TrainingsForm(props) {
  // Criteria Modal Stuff
  let subtitle;
  const [criteriaModalIsOpen, setCriteriaModalIsOpen] = React.useState(false);

  function openCriteriaModal(e) {
    e.preventDefault();
    setCriteriaModalIsOpen(true);
  }

  function afterOpenCriteriaModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeCriteriaModal() {
    setCriteriaModalIsOpen(false);
  }

  // Other Stuff
  let navigate = useNavigate();

  // Date Stuff
  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output;
  };
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  const [startDate, setStartDate] = useState(new Date());

  // Tabs Stuff
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    if (!props.newProposal);
    setValue(newValue);
  };

  // Hidden Form Stuff
  const [status, setStatus] = useState("");

  //   Form Stuff
  const [textInput, setTextInput] = useState("");
  const [dateInput, setDateInput] = useState(new Date());
  const [select, setSelect] = useState("Choose One");
  const [trainingComments, setTrainingComments] = useState([
    {
      description: "",
    },
  ]);
  const [notes, setNotes] = useState([
    {
      description: "",
    },
  ]);
  const [supervisorComments, setSupervisorComments] = useState([
    {
      description: "",
    },
  ]);
  const [newTrainingTitle, setNewTrainingTitle] = useState("");

  //   Form Handling
  const formData = {
    status,
    lastUpdated: date,
    textInput,
    dateInput,
    select,
    trainingComments,
  };

  const handleBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleSelectBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleBlurTrainingComment = async (e, i) => {
    console.log("Calculating New Premium");
    // e.preventDefault();
    const values = [...trainingComments];
    values[i].totalPremium = parseFloat(
      (
        values[i].premium +
        values[i].SLtax +
        values[i].companyFee +
        values[i].policyFee +
        values[i].SLstampFee
      ).toFixed(2)
    );
    console.log(formData);
    setTrainingComments(values);
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const handleChangeInputTrainingComment = (i, e) => {
    const values = [...trainingComments];
    values[i][e.target.name] = e.target.value;
    setTrainingComments(values);
  };

  const handleAddTrainingComment = (e) => {
    e.preventDefault();
    const i = trainingComments.length;
    if (i > 4) return;
    setTrainingComments([
      ...trainingComments,
      {
        description: "",
      },
    ]);
  };

  const handleSubtractTrainingComment = async (e, i) => {
    e.preventDefault();
    const values = [...trainingComments];
    values.splice(i, 1);
    setTrainingComments([...values]);
    if (values.length === 0) {
      setTrainingComments([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(proposalRef, { trainingComments: values });
  };

  const handleChangeInputNote = (i, e) => {
    const values = [...notes];
    values[i][e.target.name] = e.target.value;
    setNotes(values);
  };

  const handleAddNote = (e) => {
    e.preventDefault();
    const i = notes.length;
    if (i > 4) return;
    setNotes([
      ...notes,
      {
        description: "",
      },
    ]);
  };

  const handleSubtractNote = async (e, i) => {
    e.preventDefault();
    const values = [...notes];
    values.splice(i, 1);
    setNotes([...values]);
    if (values.length === 0) {
      setNotes([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(proposalRef, { notes: values });
  };

  const handleChangeInputSupervisorComment = (i, e) => {
    const values = [...supervisorComments];
    values[i][e.target.name] = e.target.value;
    setSupervisorComments(values);
  };

  const handleAddSupervisorComment = (e) => {
    e.preventDefault();
    const i = supervisorComments.length;
    if (i > 4) return;
    setSupervisorComments([
      ...supervisorComments,
      {
        description: "",
      },
    ]);
  };

  const handleSubtractSupervisorComment = async (e, i) => {
    e.preventDefault();
    const values = [...supervisorComments];
    values.splice(i, 1);
    setSupervisorComments([...values]);
    if (values.length === 0) {
      setSupervisorComments([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(proposalRef, { supervisorComments: values });
  };

  // Show or Hide
  const [showTrainingsForm, setShowTrainingsForm] = useState(false);

  //   Clear the form
  const clearForm = () => {
    setNewTrainingTitle("");
    setTrainingComments([{ description: "" }]);
    setNotes([{ description: "" }]);
    setSupervisorComments([{ description: "" }]);
  };

  //   Submit the trainings & close the form
  const [trainingsSubmitted, setTrainingsSubmitted] = useState(false);
  const submitTrainings = async (e) => {
    e.preventDefault();
    const docRef = await addDoc(
      collection(db, "hr-trainings"),
      {
        hideOnList: true,
        status: "",
        createdOn: new Date(),
        createdBy: auth.currentUser.uid,
        author: auth.currentUser.displayName,
        trainingDate,
        newTrainingTitle,
        trainingLocation,
        employeeID: props.selectedUserID,
        trainingComments,
        notes,
        supervisorComments,
        rating: satisfactionValue,
        replies: [],
      }
    );
    console.log("Document written with ID: ", docRef.id);
    clearForm();
    setShowTrainingsForm(false);
    setTrainingsSubmitted(true);
    props.setHRtrainings([]);
    props.setGotTrainings(false);
    props.setTrainingsUpdated(props.trainingsUpdated + 1);
  };

  const [trainingLocation, setTrainingLocation] = useState("");
  const [trainingDate, setTrainingDate] = useState();
  const [satisfactionValue, setSatisfactionValue] = React.useState(0);
  const handleChangeSatisfaction = (event, newValue) => {
    setSatisfactionValue(newValue);
  };

  useEffect(() => {}, [showTrainingsForm, trainingsSubmitted]);

  return (
    <div className="card">
      <div className="card-header">
        <button
          onClick={(e) => {
            setShowTrainingsForm(!showTrainingsForm);
            setTrainingsSubmitted(false);
          }}
          className="text-lg font-bold text-sky-600"
        >
          Add Training
        </button>
        {trainingsSubmitted && (
          <p className="p-2 w-full bg-green-200 float-right font-bold mb-2">
            Your training was successfully submitted.
          </p>
        )}
      </div>
      {showTrainingsForm && (
        <div className="card-body">
          <form onSubmit={(e) => submitTrainings(e)}>
          {/* Training Title */}
          <section className="card bg-gray-100 m-3">
            <div className="card-header w-full">
              <h2 className="font-bold text-2xl text-red-900">
                Training Title
              </h2>
            </div>
            <div className="card-body">
              <div>
                <input
                  onChange={(e) => setNewTrainingTitle(e.target.value)}
                  onBlur={(e) => handleBlur(e)}
                  onKeyDown={handleEnter}
                  name="description"
                  className="form-control rounded"
                  type="text"
                  placeholder="Training Title"
                  value={newTrainingTitle}
                />
              </div>
            </div>
          </section>
          {/* Training Location */}
          <section className="card bg-gray-100 m-3">
            <div className="card-header w-full">
              <h2 className="font-bold text-2xl text-red-900">
                Training Location
              </h2>
            </div>
            <div className="card-body">
              <div>
                <input
                  onChange={(e) => setTrainingLocation(e.target.value)}
                  onBlur={(e) => handleBlur(e)}
                  onKeyDown={handleEnter}
                  name="description"
                  className="form-control rounded"
                  type="text"
                  placeholder="Training Location"
                  value={trainingLocation}
                />
              </div>
            </div>
          </section>
{/* Training Date */}
          <section className="card bg-gray-100 m-3">
            <div className="card-header w-full">
              <h2 className="font-bold text-2xl text-red-900">Training Date</h2>
            </div>
            <div className="card-body pl-10">
              <DatePicker
                selected={trainingDate}
                onChange={(date: Date) => {
                  setTrainingDate(date);
                }}
                className={`text-input`}
              />
            </div>
          </section>

          {/* Rating */}
          <section className="card bg-gray-100 m-3">
            <div className="card-header w-full">
              <h2 className="font-bold text-2xl text-red-900">Rating</h2>
            </div>
            <div className="card-body pl-10">
              <Stack
                spacing={2}
                direction="row"
                sx={{ mb: 1 }}
                alignItems="center"
              >
                <SentimentVeryDissatisfiedIcon />
                <Slider
                  aria-label="Satsifaction"
                  defaultValue={0}
                  value={satisfactionValue}
                  onChange={handleChangeSatisfaction}
                  valueLabelDisplay="auto"
                  note={1}
                  marks={marks}
                  min={0}
                  max={5}
                />
                <SentimentSatisfiedAltIcon />
              </Stack>
            </div>
          </section>
          {/* Notes */}
          <section className="card bg-gray-100 m-3">
            <div className="card-header w-full">
              <h2 className="font-bold text-2xl text-red-900">Notes</h2>
            </div>
            <div className="card-body pl-10">
              {notes.map((note, i) => {
                return (
                  <div>
                    <label className="input-label">Note {i + 1}:</label>
                    <input
                      onChange={(e) => handleChangeInputNote(i, e)}
                      onBlur={(e) => handleBlur(e)}
                      onKeyDown={handleEnter}
                      placeholder="Note"
                      name="description"
                      className="form-control rounded"
                      type="text"
                      value={note.description}
                    />
                    <button
                      onClick={(e) => handleSubtractNote(e, i)}
                      className="block mt-1 mb-2 sm text-red-600"
                    >
                      - Delete Note {i + 1}
                    </button>
                  </div>
                );
              })}
            </div>
            <button
              onClick={handleAddNote}
              className="block mt-1 mb-2 pl-5 text-sky-600 text-left"
            >
              + Add A Note
            </button>
          </section>
          {/* Supervisor Comments */}
          <section className="card bg-gray-100 m-3">
            <div className="card-header w-full">
              <h2 className="font-bold text-2xl text-red-900">Supervisor Comments</h2>
            </div>
            <div className="card-body pl-10">
              {supervisorComments.map((supervisorComment, i) => {
                return (
                  <div>
                    <label className="input-label">Supervisor Comment {i + 1}:</label>
                    <input
                      onChange={(e) => handleChangeInputSupervisorComment(i, e)}
                      onBlur={(e) => handleBlur(e)}
                      onKeyDown={handleEnter}
                      placeholder="Supervisor Comment"
                      name="description"
                      className="form-control rounded"
                      type="text"
                      value={supervisorComment.description}
                    />
                    <button
                      onClick={(e) => handleSubtractSupervisorComment(e, i)}
                      className="block mt-1 mb-2 sm text-red-600"
                    >
                      - Delete Supervisor Comment {i + 1}
                    </button>
                  </div>
                );
              })}
            </div>
            <button
              onClick={handleAddSupervisorComment}
              className="block mt-1 mb-2 pl-5 text-sky-600 text-left"
            >
              + Add A Supervisor Comment
            </button>
          </section>
          {/* Training Comments */}
          <section className="card bg-gray-100 m-3">
            <div className="card-header w-full">
              <h2 className="font-bold text-2xl text-red-900">
                Training Comments
              </h2>
            </div>
            <div className="card-body pl-10">
              {trainingComments.map((trainingComment, i) => {
                return (
                  <div>
                    <label className="input-label">Training Comment {i + 1}:</label>
                    <input
                      onChange={(e) => handleChangeInputTrainingComment(i, e)}
                      onBlur={(e) => handleBlur(e)}
                      onKeyDown={handleEnter}
                      name="description"
                      className="form-control rounded"
                      placeholder="Training Comment"
                      type="text"
                      value={trainingComment.description}
                    />
                    <button
                      onClick={(e) => handleSubtractTrainingComment(e, i)}
                      className="block mt-1 mb-2 sm text-red-600"
                    >
                      - Delete Training Comment {i + 1}
                    </button>
                  </div>
                );
              })}
            </div>
            <button
              onClick={handleAddTrainingComment}
              className="block mt-1 mb-2 pl-5 text-sky-600 text-left"
            >
              + Add A Training Comment
            </button>
          </section>

          <button
          type="submit"
            
            className="block font-medium text-white my-1 p-2 rounded bg-green-900 hover:bg-green-700"
          >
            Submit Training
          </button>
          <p className="text-xs">
            You may not edit a training once it is submitted.
          </p>
          </form>
        </div>
      )}
    </div>
  );
}

export default TrainingsForm;
