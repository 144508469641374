import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  orderBy,
  setDoc,
  where,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import "../app.css";
import PerformanceReviewsForm from "./PerformanceReviewsForm";
import axios from "axios";

function EmployeePerformanceReviewsView(props) {
  // Props
  const selectedUserID = props.selectedUserID;
  // Date Stuff
  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString().slice(3, 15);
  };

  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  // Get HR PerformanceReviews
  const [HRperformanceReviews, setHRperformanceReviews] = useState([]);
  const [gotPerformanceReviews, setGotPerformanceReviews] = useState(false);
  const [performanceReviewsUpdated, setPerformanceReviewsUpdated] = useState(1);
  const getHRperformanceReviews = async () => {
    console.log("Getting Performance Reviews");
    setHRperformanceReviews([]);
    setGotPerformanceReviews(false);
    const q = query(
      collection(db, "hr-performanceReviews"),
      where("employeeID", "==", props.selectedUserID)
    );

    const querySnapshot = await getDocs(q);

    let values = [];
    querySnapshot.forEach((doc) => {
      console.log(doc.data());
      // doc.data() is never undefined for query doc snapshots
      values.push({
        ...doc.data(),
        id: doc.id,
        createdOn: convertDate(doc.data().createdOn.seconds),
      });
    });
    console.log(values);
    setHRperformanceReviews(values);
    setGotPerformanceReviews(true);
    console.log(HRperformanceReviews);
  };

  const addComment = async () => {};

  const updatePerformanceReviews = async (id, array) => {
    const docRef = doc(db, "hr-performanceReviews", id);
    setDoc(docRef, { replies: array }, { merge: true });
    setPerformanceReviewsUpdated(performanceReviewsUpdated + 1);
  };

  const addPerformanceReviews = async () => {
    await setDoc(doc(db, "hr-performanceReviews", selectedUserID), {
      performanceReviews: [
        {
          date: date,
          sender: auth.currentUser.displayName,
          createdBy: auth.currentUser.uid,
          performanceReviewsReason: document.getElementById(
            "newPerformanceReviewsReason"
          ).value,
          performanceReviewsConstructive: document.getElementById(
            "newPerformanceReviewsConstructive"
          ).value,
          performanceReviewsSolution: document.getElementById(
            "newPerformanceReviewsSolution"
          ).value,
          performanceReviewsPositive: document.getElementById(
            "newPerformanceReviewsPositive"
          ).value,
          replies: [],
        },
      ],
    });

    setPerformanceReviewsUpdated(performanceReviewsUpdated + 1);
  };

  const deletePerformanceReviews = async (id) => {
    let verify = prompt(
      "You are about to delete performanceReviews! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await updateDoc(doc(db, "hr-performanceReviews", id), {
        status: "Deleted",
      });
      setGotPerformanceReviews(false);
      getHRperformanceReviews();
    }
  };

  const [showReviewRequest, setShowReviewRequest] = useState(false);
  const [reason, setReason] = useState();
  const [performanceReviewsReqRecipient, setPerformanceReviewsReqRecipient] =
    useState();

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  // Handle Drop Down Selection
  const [selectedRecipientID, setSelectedRecipientID] = useState("");
  const [selectedRecipientName, setSelectedRecipientName] = useState("");
  const [selectedRecipientEmail, setSelectedRecipientEmail] = useState("");
  const [selectedIterator, setSelectedIterator] = useState("");
  const [userSelected, setUserSelected] = useState(false);
  const handleChangeRecipient = (event) => {
    setUserSelected(false);
    const { value } = event.target;
    const iterator = value.split("-")[1];
    setSelectedRecipientID(value.split("-")[0]);
    setSelectedIterator(iterator);
    setSelectedRecipientName(
      userList[iterator].first + " " + userList[iterator].last
    );
    setSelectedRecipientEmail(userList[iterator].email);
    setUserSelected(true);
  };

  const [showResponseSuccess, setShowResponseSuccess] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
    axios
      .post(
        "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/licona/request-for-performanceReviews",
        {
          to: selectedRecipientEmail,
          cc: ["elpaso_csr@liconainsurance.com", "colt@southernstarmga.com"],
          requester: auth.currentUser.displayName,
          requesterID: auth.currentUser.uid,
          createdOn: new Date().toLocaleString().split(",")[0],
          employeeName: auth.currentUser.displayName,
          employeeID: auth.currentUser.uid,
          reason: reason,
          reviewerEmail: selectedRecipientEmail,
          reviewer: selectedRecipientName,
          reviewerID: selectedRecipientID,
          email: auth.currentUser.email,
          status: "Incomplete",
        }
      )
      .then(async (response) => {
        // handle success
        await addDoc(collection(db, "performanceReviews-requests"), {
          to: selectedRecipientEmail,
          cc: ["elpaso_csr@liconainsurance.com", "colt@southernstarmga.com"],
          requester: auth.currentUser.displayName,
          requesterID: auth.currentUser.uid,
          createdOn: new Date(),
          employeeName: auth.currentUser.displayName,
          employeeID: auth.currentUser.uid,
          reason: reason,
          email: auth.currentUser.email,
          reviewerEmail: selectedRecipientEmail,
          reviewer: selectedRecipientName,
          reviewerID: selectedRecipientID,
          status: "Incomplete",
        });
      })
      .then(() => {
        setSelectedRecipientID();
        setSelectedRecipientEmail();
        setSelectedRecipientName();
        setReason();
        setSelectedIterator();
        setShowResponseSuccess(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  //   Get users for user dropdown list
  const [userList, setUserList] = useState([]);
  const [gotUsers, setGotUsers] = useState(false);
  const usersCollectionRef = collection(db, "users");
  const getUsers = async () => {
    setGotUsers(false);
    const data = await getDocs(query(usersCollectionRef, orderBy("first")));
    setUserList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setGotUsers(true);
  };

  const [display, setDisplay] = useState(false);

  useEffect(() => {
    !gotPerformanceReviews && getHRperformanceReviews();
    !gotUsers && getUsers();
  }, [selectedUserID, gotPerformanceReviews, performanceReviewsUpdated]);

  return (
    <div className="card mb-3">
      <div className="card-header bg-gray-200">
        <button
          onClick={(e) => setDisplay(!display)}
          className="text-xl font-bold"
        >
          Your Performance Reviews
        </button>
      </div>
      {display && (
        <div className="card-body">
          <main>
            {/* PerformanceReviews History */}
            <section>
              <h1 className="text-2xl font-bold text-red-900">
                Your Performance Review History
              </h1>
              <div className="p-3">
                <ul className="list">
                  {gotPerformanceReviews === true && (
                    <>
                      {HRperformanceReviews.length === 0 && (
                        <p>
                          You have no performance reviews to display at this
                          time.
                        </p>
                      )}
                      {HRperformanceReviews.map((performanceReviews, i) => {
                        if (performanceReviews.status !== "Deleted")
                          return (
                            <div
                              key={"performanceReviews-container" + i}
                              className="mb-3 card"
                            >
                              <div className="card-header text-lg">
                                <button
                                  onClick={(e) => {
                                    HRperformanceReviews[i].hideOnList =
                                      !HRperformanceReviews[i].hideOnList;
                                    setPerformanceReviewsUpdated(
                                      performanceReviewsUpdated + 1
                                    );
                                  }}
                                  className="font-bold mr-5 text-sky-600"
                                >
                                  Performance Review {i + 1}
                                </button>
                                (Submitted {performanceReviews.createdOn} by{" "}
                                {performanceReviews.author})
                              </div>
                              {HRperformanceReviews[i]?.hideOnList ? (
                                <></>
                              ) : (
                                <div className="card-body text-lg">
                                  <div className="card mb-3">
                                    <div className="card-body">
                                      {/* Instructions */}
                                      {/* Rating Scale */}
                                      <article className="ml-3">
                                        <p className="font-bold">
                                          {" "}
                                          Rating scale:
                                        </p>
                                        <p>
                                          {" "}
                                          5 - Excellent (consistently exceeds
                                          standards)
                                        </p>
                                        <p>
                                          {" "}
                                          4 - Outstanding (frequently exceeds
                                          standards)
                                        </p>
                                        <p>
                                          {" "}
                                          3 - Satisfactory (generally meets
                                          standards)
                                        </p>
                                        <p>
                                          {" "}
                                          2 - Needs improvement (frequently
                                          fails to meet standards)
                                        </p>
                                        <p>
                                          {" "}
                                          1 - Unacceptable (fails to meet
                                          standards)
                                        </p>
                                      </article>
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Job Knowledge
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.jobKnowledge}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.jobKnowledgeExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Sharing knowledge
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.sharingKnowledge}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.sharingKnowledgeExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Willingness to Develop Skills
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.developSkills}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.developSkillsExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Quality of work
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.qualityOfWork}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.qualityOfWorkExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Quantity of work
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.quantityOfWork}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.quantityOfWorkExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">Reliability</h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.reliability}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.reliabilityExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Accountability
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.accountability}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.accountabilityExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Initiative and creativity
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.initativeAndCreativity
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.initiativeAndCreativityExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Flexibility{" "}
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.flexibility}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.flexibilityExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">Judgment</h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.judgement}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.judgementExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">Cooperation</h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.cooperation}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.cooperationExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">Attendance</h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.attendance}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.attendanceExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Attitude / Respectfulness{" "}
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.attitudeRespectfulness
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.attitudeRespectfulnessExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Accepts Criticism{" "}
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.acceptsCriticism}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.acceptsCriticismExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Policy & Procedures
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.policyAndProcedures
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.policyAndProceduresExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Confidentiality
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.confidentiality}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.confidentialityExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Communication Skills
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.communicationSkills
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.communicationSkillsExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Conflict Resolution
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.conflictResolution}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.conflictResolutionExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Organizational Skills
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.organizationalSkills
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.organizationalSkillsExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Customer Interaction{" "}
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.customerInteraction
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.customerInteractionExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Appearance / Dress Code
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.appearanceDressCode
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.appearanceDressCodeExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Appearance of Work Area
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.appearanceOfWorkArea
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.appearanceOfWorkAreaExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Supervising: Planning and Organizing
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.planningAndOrganizing
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.planningAndOrganizingExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Supervising: Directing and Controlling
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.directingAndControlling
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.directingAndControllingExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Supervising: Decision Maker
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.decisionMaker}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.decisionMakerExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>

                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Noteworthy Accomplishments
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.noteworthyAccomplishments
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Areas For Improvement
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.areasRequiringImprovement
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Actions Taken
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.improvementActions}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Development Goals
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.professionalDevelopmentGoals
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Explanation of Overall Score of{" "}
                                        {performanceReviews?.totalOfRatings}
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.explanationOfScore}
                                      </p>
                                    </div>
                                  </div>

                                  <hr className="my-3" />
                                  {/* Comment List */}
                                  <h3 className="font-bold mt-2">Comments:</h3>
                                  <ul className="list ml-2">
                                    {performanceReviews.replies?.map(
                                      (reply, iterator) => {
                                        return (
                                          <>
                                            {!reply.reply ? (
                                              <></>
                                            ) : (
                                              <li
                                                className="test rounded shadow-md m-3"
                                                key={"reply" + iterator}
                                              >
                                                <p className="italic">
                                                  {reply.sender} on {reply.date}
                                                  :
                                                </p>
                                                <p className="block">
                                                  {reply.reply}
                                                </p>
                                                {auth.currentUser.uid ===
                                                  reply.createdBy && (
                                                  <>
                                                    <button
                                                      onClick={() => {
                                                        HRperformanceReviews[
                                                          i
                                                        ].replies.splice(
                                                          iterator,
                                                          1
                                                        );
                                                        updatePerformanceReviews(
                                                          performanceReviews.id,
                                                          HRperformanceReviews[
                                                            i
                                                          ].replies
                                                        );
                                                      }}
                                                      className="text-xs m-1 text-red-900"
                                                    >
                                                      Delete Comment
                                                    </button>
                                                  </>
                                                )}
                                              </li>
                                            )}
                                          </>
                                        );
                                      }
                                    )}
                                  </ul>

                                  {/* Add Reply */}
                                  <div className="ml-2">
                                    <hr className="my-2" />
                                    <input
                                      id={"reply" + i}
                                      type="text"
                                      className="border-2 border-red-900 p-1 w-full rounded"
                                      placeholder="Enter your reply here..."
                                    />
                                    <button
                                      onClick={(e) => {
                                        HRperformanceReviews[i].replies.push({
                                          date: date,
                                          reply: document.getElementById(
                                            "reply" + i
                                          ).value,
                                          sender: auth.currentUser.displayName,
                                          createdBy: auth.currentUser.uid,
                                        });
                                        updatePerformanceReviews(
                                          performanceReviews.id,
                                          HRperformanceReviews[i].replies
                                        );
                                        document.getElementById(
                                          "reply" + i
                                        ).value = "";
                                      }}
                                      className="block font-medium text-white my-1 px-2 py-1 rounded bg-green-900 hover:bg-green-700"
                                    >
                                      Add Comment
                                    </button>
                                  </div>
                                  <hr />
                                </div>
                              )}
                            </div>
                          );
                      })}
                    </>
                  )}
                </ul>
              </div>
            </section>
          </main>
        </div>
      )}
    </div>
  );
}

export default EmployeePerformanceReviewsView;
