import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  query,
  addDoc,
  orderBy,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import FormGroup from "react-bootstrap/esm/FormGroup";

function ChooseCompany() {
 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    company: "",
    dba: "",
    dot: "",
    mailingAddress: "",
    physicalAddress: "",
    mc: "",
    phone: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [dotNumberForAdd, setDotNumberForAdd] = useState("");

  const handleChangeDot = (event) => {
    const { value } = event.target;

    setDotNumberForAdd(value);
  };

  const postsCollectionRef = collection(db, "companies");

  const [customerAdded, setCustomerAdded] = useState(false);
  const AddCustomer = async (e) => {
    e.preventDefault();
    await addDoc(postsCollectionRef, {
      ...formData,
      author: { id: auth.currentUser.uid },
      dateCreated: Date.now(),
    });
    setShow(false);
    setCustomerAdded(true);
  };

  let navigate = useNavigate();
  const [companyList, setCompanyList] = useState([]);
  const companiesCollectionRef = collection(db, "companies");

  const [gotCompanies, setGotCompanies] = useState(false);
  const getCompanies = async () => {
    if (gotCompanies) return;
    const data = await getDocs(
      query(companiesCollectionRef, orderBy("company"))
    );
    setCompanyList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setGotCompanies(true);
  };

  const [companyInfoUpdated, setCompanyInfoUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const fetchCompanyInfo = async (dot) => {
    setLoading(true);
    const myInfo = { dotNumber: dot };
    fetch("https://sstools-api-d4ca8.uc.r.appspot.com/safer/", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(myInfo),
    })
      .then((response) => {
        console.log("response =>" + response);
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setFormData({
          company: data.legalName,
          dba: data.dbaName,
          dot: data.dotNumber,
          mailingAddress: data.mailingAddress,
          physicalAddress: data.physicalAddress,
          mc: data.mcNumber,
          phone: data.phone,
        });
        setCompanyInfoUpdated(true);
        console.log("success metric =>" + formData.company);
      })
      .then((json) => {
        handleShow();
        setLoading(false);
        setDotNumberForAdd("");
        return json;
      });
  };

  useEffect(() => {
    getCompanies();
  }, [db, customerAdded, companyInfoUpdated, loading]);

  return (
    <div className="hero container max-w-screen-lg mx-auto pb-10">
      <div className="ml-5 mx-auto border-1 mt-5 pt-1 bg-gray-100 max-w-lg rounded overflow-hidden shadow-lg">
        <div className="p-3 text-center mt-5">
          <h1 className="text-center text-red-900 text-3xl font-bold">
            Select a Customer
          </h1>
          <select
            className="rounded-md font-medium border-2 p-3 mt-4 sm:text-xl md:text-xl"
            onChange={(e) => {
              if (e.target.value != "Choose One") {
                const companyID = e.target.value;
                navigate("../company/" + companyID);
              }
            }}
          >
            <option>Choose One</option>

            {companyList.map((company) => {
              if (company.company) {
                return (
                  <option key={"id" + company.id} value={company.id}>
                    {company.company.substring(0, 25)}
                  </option>
                );
              }
            })}
          </select>
          <h1 className="text-center text-red-900 text-2xl font-bold mt-5">
            Or add a new customer
          </h1>
          <>
            {customerAdded && <div>Customer Successfully Added</div>}
            {!customerAdded && (
              <>
                <input
                  className="block mx-auto rounded-md font-medium border-2 p-3 mt-4 sm:text-xl md:text-xl"
                  placeholder="Enter DOT Number"
                  type="text"
                  onChange={handleChangeDot}
                />
                {!loading ? (
                <button
                  className="text-xl text-white bg-red-900 hover:bg-red-700 py-2 px-4 mt-3 mb-10 rounded-md"
                  onClick={() => fetchCompanyInfo(dotNumberForAdd)}
                >
                  Add A Customer
                </button>
                ) : (
                  <button
                  disabled
                  className="text-xl text-white bg-green-900 hover:bg-green-800 py-2 px-4 mt-3 mb-10 rounded-md"
                  onClick={() => fetchCompanyInfo(dotNumberForAdd)}
                >
                  Loading... Please Wait
                </button>
                )}
              </>
            )}

            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Add a Customer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="createPostContainer">
                  <Form>
                    <div className="inputGroup">
                      <FormGroup>
                        <label className="mb-2">Customer Information:</label>
                        <Form.Control
                          type="text"
                          name="company"
                          placeholder="Company Name"
                          className="form-input mb-2"
                          onChange={handleChange}
                          value={formData.company}
                        />
                        <Form.Control
                          type="text"
                          name="address"
                          placeholder="Full Physical Address"
                          className="form-input mb-2"
                          onChange={handleChange}
                          value={formData.physicalAddress}
                        />
                        <Form.Control
                          type="text"
                          name="address"
                          placeholder="Full Mailing Address"
                          className="form-input mb-2"
                          onChange={handleChange}
                          value={formData.mailingAddress}
                        />

                        <Form.Control
                          type="text"
                          name="phone"
                          placeholder="Main Phone"
                          className="form-input mb-2"
                          onChange={handleChange}
                          value={formData.phone}
                        />
                        <Form.Control
                          type="text"
                          name="dot"
                          placeholder="DOT Number"
                          className="form-input mb-2"
                          onChange={handleChange}
                          value={formData.dot}
                        />
                        <Form.Control
                          type="text"
                          name="mc"
                          placeholder="MC Number"
                          className="form-input mb-2"
                          onChange={handleChange}
                          value={formData.mc}
                        />
                      </FormGroup>
                    </div>

                    <button
                      className="block ml-2 text-xl text-white bg-red-900 hover:bg-red-700 pt-1 pb-2 px-3 mt-3"
                      onClick={AddCustomer}
                    >
                      Add Customer
                    </button>
                  </Form>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="block text-xl text-white bg-gray-900 hover:bg-gray-700 pt-1 pb-2 px-3 mt-3"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </Modal.Footer>
            </Modal>
          </>
        </div>
      </div>
    </div>
  );
}

export default ChooseCompany;
