import React, { useEffect, useState } from "react";
import axios from "axios";
import { storage } from "../../firebase.js";
import { ref, uploadBytes } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateDoc,
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  addDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { v4 } from "uuid";
import "../app.css";
import { Container } from "react-bootstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function GoalsForm(props) {
  // Criteria Modal Stuff
  let subtitle;
  const [criteriaModalIsOpen, setCriteriaModalIsOpen] = React.useState(false);

  function openCriteriaModal(e) {
    e.preventDefault();
    setCriteriaModalIsOpen(true);
  }

  function afterOpenCriteriaModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeCriteriaModal() {
    setCriteriaModalIsOpen(false);
  }

  // Other Stuff
  let navigate = useNavigate();

  // Date Stuff
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  const [startDate, setStartDate] = useState(new Date());

  // Tabs Stuff
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    if (!props.newProposal);
    setValue(newValue);
  };

  // Hidden Form Stuff
  const [status, setStatus] = useState("");

  //   Form Stuff
  const [textInput, setTextInput] = useState("");
  const [dateInput, setDateInput] = useState(new Date());
  const [select, setSelect] = useState("Choose One");
  const [metrics, setMetrics] = useState([
    {
      description: "",
    },
  ]);
  const [steps, setSteps] = useState([
    {
      description: "",
    },
  ]);
  const [challenges, setChallenges] = useState([
    {
      description: "",
    },
  ]);
  const [newGoal, setNewGoal] = useState("");

  //   Form Handling
  const formData = {
    status,
    lastUpdated: date,
    textInput,
    dateInput,
    select,
    metrics,
  };

  const handleBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleSelectBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleBlurMetric = async (e, i) => {
    console.log("Calculating New Premium");
    // e.preventDefault();
    const values = [...metrics];
    values[i].totalPremium = parseFloat(
      (
        values[i].premium +
        values[i].SLtax +
        values[i].companyFee +
        values[i].policyFee +
        values[i].SLstampFee
      ).toFixed(2)
    );
    console.log(formData);
    setMetrics(values);
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const handleChangeInputMetric = (i, e) => {
    const values = [...metrics];
    values[i][e.target.name] = e.target.value;
    setMetrics(values);
  };

  const handleAddMetric = (e) => {
    e.preventDefault();
    const i = metrics.length;
    if (i > 4) return;
    setMetrics([
      ...metrics,
      {
        description: "",
      },
    ]);
  };

  const handleSubtractMetric = async (e, i) => {
    e.preventDefault();
    const values = [...metrics];
    values.splice(i, 1);
    setMetrics([...values]);
    if (values.length === 0) {
      setMetrics([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(proposalRef, { metrics: values });
  };

  const handleChangeInputStep = (i, e) => {
    const values = [...steps];
    values[i][e.target.name] = e.target.value;
    setSteps(values);
  };

  const handleAddStep = (e) => {
    e.preventDefault();
    const i = steps.length;
    if (i > 4) return;
    setSteps([
      ...steps,
      {
        description: "",
      },
    ]);
  };

  const handleSubtractStep = async (e, i) => {
    e.preventDefault();
    const values = [...steps];
    values.splice(i, 1);
    setSteps([...values]);
    if (values.length === 0) {
      setSteps([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(proposalRef, { steps: values });
  };

  const handleChangeInputChallenge = (i, e) => {
    const values = [...challenges];
    values[i][e.target.name] = e.target.value;
    setChallenges(values);
  };

  const handleAddChallenge = (e) => {
    e.preventDefault();
    const i = challenges.length;
    if (i > 4) return;
    setChallenges([
      ...challenges,
      {
        description: "",
      },
    ]);
  };

  const handleSubtractChallenge = async (e, i) => {
    e.preventDefault();
    const values = [...challenges];
    values.splice(i, 1);
    setChallenges([...values]);
    if (values.length === 0) {
      setChallenges([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(proposalRef, { challenges: values });
  };

  // Show or Hide
  const [showGoalsForm, setShowGoalsForm] = useState(false);

  //   Clear the form
  const clearForm = () => {
    setNewGoal("");
    setMetrics([{ description: "" }]);
    setSteps([{ description: "" }]);
    setChallenges([{ description: "" }]);
  };

  //   Submit the goals & close the form
  const [goalsSubmitted, setGoalsSubmitted] = useState(false);
  const submitGoals = async () => {
    const docRef = await addDoc(
      collection(db, "hr-goals"),
      {
        hideOnList: true,
        status: "In Progress",
        createdOn: new Date(),
        createdBy: auth.currentUser.uid,
        author: auth.currentUser.displayName,
        employeeID: props.selectedUserID,
        newGoal,
        metrics,
        steps,
        challenges,
        replies: [],
      }
    );
    console.log("Document written with ID: ", docRef.id);
    clearForm();
    setShowGoalsForm(false);
    setGoalsSubmitted(true);
    props.setHRgoals([]);
    props.setGotGoals(false);
    props.setGoalsUpdated(props.goalsUpdated + 1);
  };

  useEffect(() => {}, [showGoalsForm, goalsSubmitted]);

  return (
    <div className="card">
      <div className="card-header">
        <button
          onClick={(e) => {
            setShowGoalsForm(!showGoalsForm);
            setGoalsSubmitted(false);
          }}
          className="text-lg font-bold text-sky-600"
        >
          Create New Goal
        </button>
        {goalsSubmitted && (
          <p className="p-2 w-full bg-green-200 float-right font-bold mb-2">
            Your goal was successfully submitted.
          </p>
        )}
      </div>
      {showGoalsForm && (
        <div className="card-body">
          {/* NewGoal */}
          <section className="card bg-gray-100 m-3">
            <div className="card-header w-full">
              <h2 className="font-bold text-2xl text-red-900">
                Goal & Purpose
              </h2>
            </div>
            <div className="card-body">
              <div>
                <input
                  onChange={(e) => setNewGoal(e.target.value)}
                  onBlur={(e) => handleBlur(e)}
                  onKeyDown={handleEnter}
                  name="description"
                  className="form-control rounded"
                  type="text"
                  placeholder="I want to achieve ____ so that ____"
                  value={newGoal}
                />
              </div>
            </div>
          </section>
          {/* Steps */}
          <section className="card bg-gray-100 m-3">
            <div className="card-header w-full">
              <h2 className="font-bold text-2xl text-red-900">Steps</h2>
            </div>
            <div className="card-body pl-10">
              {steps.map((step, i) => {
                return (
                  <div>
                    <label className="input-label">Step {i + 1}:</label>
                    <input
                      onChange={(e) => handleChangeInputStep(i, e)}
                      onBlur={(e) => handleBlur(e)}
                      onKeyDown={handleEnter}
                      placeholder="Step"
                      name="description"
                      className="form-control rounded"
                      type="text"
                      value={step.description}
                    />
                    <button
                      onClick={(e) => handleSubtractStep(e, i)}
                      className="block mt-1 mb-2 sm text-red-600"
                    >
                      - Delete Step {i + 1}
                    </button>
                  </div>
                );
              })}
            </div>
            <button
              onClick={handleAddStep}
              className="block mt-1 mb-2 pl-5 text-sky-600 text-left"
            >
              + Add A Step
            </button>
          </section>
          {/* Challenges */}
          <section className="card bg-gray-100 m-3">
            <div className="card-header w-full">
              <h2 className="font-bold text-2xl text-red-900">Challenges</h2>
            </div>
            <div className="card-body pl-10">
              {challenges.map((challenge, i) => {
                return (
                  <div>
                    <label className="input-label">Challenge {i + 1}:</label>
                    <input
                      onChange={(e) => handleChangeInputChallenge(i, e)}
                      onBlur={(e) => handleBlur(e)}
                      onKeyDown={handleEnter}
                      placeholder="Challenge"
                      name="description"
                      className="form-control rounded"
                      type="text"
                      value={challenge.description}
                    />
                    <button
                      onClick={(e) => handleSubtractChallenge(e, i)}
                      className="block mt-1 mb-2 sm text-red-600"
                    >
                      - Delete Challenge {i + 1}
                    </button>
                  </div>
                );
              })}
            </div>
            <button
              onClick={handleAddChallenge}
              className="block mt-1 mb-2 pl-5 text-sky-600 text-left"
            >
              + Add A Challenge
            </button>
          </section>
          {/* Metrics */}
          <section className="card bg-gray-100 m-3">
            <div className="card-header w-full">
              <h2 className="font-bold text-2xl text-red-900">
                Metrics of Success
              </h2>
            </div>
            <div className="card-body pl-10">
              {metrics.map((metric, i) => {
                return (
                  <div>
                    <label className="input-label">Metric {i + 1}:</label>
                    <input
                      onChange={(e) => handleChangeInputMetric(i, e)}
                      onBlur={(e) => handleBlur(e)}
                      onKeyDown={handleEnter}
                      name="description"
                      className="form-control rounded"
                      placeholder="Metric"
                      type="text"
                      value={metric.description}
                    />
                    <button
                      onClick={(e) => handleSubtractMetric(e, i)}
                      className="block mt-1 mb-2 sm text-red-600"
                    >
                      - Delete Metric {i + 1}
                    </button>
                  </div>
                );
              })}
            </div>
            <button
              onClick={handleAddMetric}
              className="block mt-1 mb-2 pl-5 text-sky-600 text-left"
            >
              + Add A Metric
            </button>
          </section>

          <button
            onClick={(e) => submitGoals()}
            className="block font-medium text-white my-1 p-2 rounded bg-green-900 hover:bg-green-700"
          >
            Submit Goal
          </button>
          <p className="text-xs">
            You may not edit a goal once it is submitted.
          </p>
        </div>
      )}
    </div>
  );
}

export default GoalsForm;
