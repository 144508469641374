import React from "react";

export default function AtAGlance(props) {
  return (
    <div>
      <div className="card mb-2 shadow">
        <div className="card-header bg-[#116582]">
          <h2 className="font-medium text-white">Submissions Summary</h2>
        </div>
        <div className="card-body py-2">
          <h1 className="text-center">
            <span className="text-2xl mr-2">
              0
            </span>
            Pending Business Submissions
          </h1>
        </div>
        <div className="card-footer">
          <h4 className="text-red-900 font-medium">
            0 Incomplete
            <span className="float-right text-green-900">
              0 Approved
            </span>
          </h4>
        </div>
      </div>
      <div className="card mb-2 shadow">
        <div className="card-header bg-[#072a48]">
          <h2 className="font-medium text-white">Appointments Summary</h2>
        </div>
        <div className="card-body py-2">
          <h1 className="text-center">
            <span className="text-2xl mr-2">
              0
            </span>
            Pending Appointment Applications
          </h1>
        </div>
        <div className="card-footer">
          <h4 className="text-red-900 font-medium">
            0 Incomplete
            <span className="float-right text-green-900">
              0 Approved
            </span>
          </h4>
        </div>
      </div>
      <div className="card mb-4 shadow">
        <div className="card-header bg-red-900">
          <h2 className="font-medium text-white">Loss Run Requests Summary</h2>
        </div>
        <div className="card-body py-2">
          <h1 className="text-center">
            <span className="text-2xl mr-2">0</span>
            Submitted Loss Run Requests
          </h1>
        </div>
        <div className="card-footer">
          <h4 className="text-red-900 font-medium">
            0 In Process
            <span className="float-right text-green-900">
              0 Sent
            </span>
          </h4>
        </div>
      </div>
    </div>
  );
}
