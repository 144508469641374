import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../firebase";
import { doc, setDoc, addDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";

const UserContext = createContext();


export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  const [userID, setUserID] = useState('');

  const createUser = (email, password, phone, first, last) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((result) => {
        setUserID = result.user.uid;
        createUserDocument(first, last, email, phone, userID);
      })
      .catch((e) => console.log(e.message));
  };

  const createUserDocument = async (first, last, email, phone, userID) => {
    try {
      const docRef = await addDoc(collection(db, "users"), {
        email: email,
        first: first,
        last: last,
        phone: phone,
        company: "",
        permissions: {
          isSysAdmin: false,
          isAdmin: false,
          isUser: true,
          isReadOnly: false,
          isBanned: false,
        },
        userID: userID,
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const signIn = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };



  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      // console.log(currentUser);
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{ createUser, user, logout, signIn, createUserDocument }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
