import React, { useEffect, useState } from "react";
import { UserAuth } from "../Context/AuthContent";
import {
  getDocs,
  collection,
  deleteDoc,
  doc,
  where,
  query,
} from "firebase/firestore";
import { db } from "../firebase";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import "./app.css";


function SentMessages() {
  const { user } = UserAuth();

  const userID = user.uid;

  // Get the date
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const messagesCollectionRef = collection(db, "messages");

  const getMessages = async (usersID) => {
    setLoading(true);
    console.log("Getting User's Sent Messages");
    const q = query(
      collection(db, "messages"),
      where("senderID", "==", usersID)
    );
    const queryData = await getDocs(q);
    setMessageList(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
    setLoading(false);
  };

  const deleteThis = async (thisID) => {
    let verify = prompt(
      "You are about to delete a message for the sender and recipient! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "messages", thisID));
      getMessages(userID);
    }
  };

  useEffect(() => {
    if (userID) {
        getMessages(userID);
    }
    
  }, [db, userID]);

  return (
    <Container>
      <a href="/newmessage" className="text-base text-sky-600 font-medium mt-1 d-block">Compose A Message</a>
      <a href="/messages" className="text-base text-sky-600 font-medium d-block">View Inbox</a>
      <h1 className="text-2xl font-bold mt-1 mb-3">Sent Messages</h1>
      
      <Table>
        <Thead>
          <Tr className="font-medium bg-black text-white border-2 border-black">
            <Td width="10%">Status</Td>
            <Td width="15%">Date</Td>
            <Td width="20%">Sender</Td>
            <Td width="40%">Subject</Td>
            <Td width="15%">Actions</Td>
          </Tr>
        </Thead>
        <Tbody>
          {messageList?.map((myData, i) => (
            <Tr key={myData.id} className="border-2 border-black">
              <Td>{myData.status}</Td>
              <Td>{myData.date}</Td>
              <Td>{myData.sender}</Td>
              <Td>{myData.subject}</Td>
              <Td><a href="" 
              onClick={(e) => navigate("../ReadMessage/" + myData.id)}>
                Read</a> | <a href="" onClick={() => deleteThis(myData.id)}>Delete</a></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Container>
  );
}

export default SentMessages;
