import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  orderBy,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import "../app.css"
import axios from "axios";


function SupervisorNotes(props) {
// Props
const selectedUserID = props.selectedUserID;
  // Date Stuff
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  console.log("selected user id" + selectedUserID);

  // Get Supervisor Notes
  const [supervisorNotes, setSupervisorNotes] = useState([]);
  const [gotNotes, setGotNotes] = useState(false);
  const [notesUpdated, setNotesUpdated] = useState(1);
  const getSupervisorNotes = async () => {
    const docRef = doc(db, "supervisor-notes", selectedUserID);
    const docSnap = await getDoc(docRef);
    setSupervisorNotes([]);
    setGotNotes(false);
    if (docSnap.exists()) {
      console.log("Supervisor Notes:", docSnap.data());
      setSupervisorNotes(docSnap.data());
      setGotNotes(true);
      console.log(supervisorNotes.notes[0])
    } else {
      // doc.data() will be undefined in this case
      console.log("No Supervisor Notes Found");
    }
  };

  const updateSupervisorNotes = async (e) => {
    await setDoc(doc(db, "supervisor-notes", selectedUserID), supervisorNotes);
    await addDoc(collection(db, "system-activity"), {
      activityDate: new Date(),
      authorID: auth.currentUser.uid,
      authorName: auth.currentUser.displayName,
      description:
        "New Supervisor Note submitted for Employee " +
        props.selectedUserInfo.first + " " + props.selectedUserInfo.last +
        " by author " +
        auth.currentUser.displayName,
    });
    sendEmail(e, document.getElementById("newNote").value);
    setNotesUpdated(notesUpdated + 1);
  };

  const addNote = async (e) => {
    e.preventDefault();
    await setDoc(doc(db, "supervisor-notes", selectedUserID), {
        notes: [
            {
                date: date,
                sender: auth.currentUser.displayName,
                senderID: auth.currentUser.uid,
                note: document.getElementById("newNote").value,
                replies: []
            }
        ]
      });
      
      // Log System Activity
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          "New Supervisor Note submitted for Employee " +
          props.selectedUserInfo.first + " " + props.selectedUserInfo.last +
          " by author " +
          auth.currentUser.displayName,
      });
      sendEmail(e, document.getElementById("newNote").value);
    setNotesUpdated(notesUpdated + 1);
  };

  const [showResponseSuccess, setShowResponseSuccess] = useState(false);
  const sendEmail = async (e, noteValue) => {
    e.preventDefault();
    axios
      .post(
        "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/licona/new-hr-submission",
        {
          to: "elpaso_csr@liconainsurance.com",
          cc: "colt@southernstarmga.com",
          type: "Supervisor Note",
          author: auth.currentUser.displayName,
          createdOn: new Date().toLocaleString().split(",")[0],
          employeeName:
            props.selectedUserInfo.first + " " + props.selectedUserInfo.last,
          employeeID: props.selectedUserID,
          details: noteValue
        }
      )
      .then(async (response) => {
        // handle success
        document.getElementById("newNote").value = ''
        })
      .then(() => {
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  useEffect(() => {
    getSupervisorNotes();
  }, [db, selectedUserID, gotNotes, notesUpdated]);

  return (
    <section>
      {/* Add Note */}
      <div className="p-2 bg-gray-200 mb-3 mt-1 rounded">
                <h2 className="font-bold mb-1">Add a new note:</h2>
                <input
                id={"newNote"}
                type="text"
                className="border-2 border-red-900 p-1 w-full"
                placeholder="Enter your note here..."
                />
                <button 
                onClick={(e) => {
                        if (gotNotes === true) {
                        supervisorNotes.notes.push({
                            date: date, 
                            note: document.getElementById("newNote").value,
                            replies: [],
                            sender: auth.currentUser.displayName,
                            senderID: auth.currentUser.uid
                        })
                        updateSupervisorNotes(e);
                    } else {
                        addNote(e);
                    }
                    
                }}
                className="block font-medium text-sky-600 my-1">
                Add Note
                </button>
            </div>  
      <ul className="list">
        {gotNotes === true && (<>
            {supervisorNotes.notes.map((note, i) => (
            <div key={"note-container" + i} className="mb-3">
                <p className="block font-bold bg-red-900 text-white p-2 rounded-top">Note {i + 1}</p>
          <li className="border p-2" key={"note" + i}>
            
          <p className="italic">{note.sender} on {note.date}:</p>
            <p className="block">{note.note}</p>
            
            {/* Comment List */}
            <h3 className="font-medium mt-2 ml-2">Comments:</h3>
            <ul className="list ml-2">
              {note.replies?.map((reply, iterator) => (
                <li className="test" key={"reply" + iterator}>
                  <p className="italic">{reply.sender} on {reply.date}:</p>
                  <p className="block">{reply.reply}</p>
                  {auth.currentUser.uid === reply.senderID && (<>
                    <button 
                        onClick={(e) => {
                            supervisorNotes.notes[i].replies.splice(iterator, 1);
                            updateSupervisorNotes(e);
                        }}
                        className="text-xs m-1 text-red-900">Delete Comment</button>
                </>)}
                </li>
              ))}
            </ul>

            {/* Add Reply */}
            <div className="ml-2">
                <hr className="my-2" />
                <input
                id={"reply" + i}
                type="text"
                className="border-2 border-red-900 p-1 w-full rounded"
                placeholder="Enter your reply here..."
                />
                <button 
                onClick={(e) => {
                    supervisorNotes.notes[i].replies.push({
                        date: date,
                        reply: document.getElementById("reply" + i).value,
                        sender: auth.currentUser.displayName,
                        senderID: auth.currentUser.uid
                    })
                    console.log(supervisorNotes.notes[i]);
                    updateSupervisorNotes(e);
                    document.getElementById("reply" + i).value = "";
                }}
                className="block font-medium text-sky-600 my-1">
                Add Comment
                </button>
            </div>
            <hr />
            {auth.currentUser.uid === note.senderID && (<>
                <button 
                    onClick={(e) => {
                        supervisorNotes.notes.splice(i, 1);
                        updateSupervisorNotes(e);
                    }}
                    className="m-1 text-red-900">Delete This Note</button>
            </>)}
          </li>
          </div>
        ))}
        </>)}
        
      </ul>
    </section>
  );
}

export default SupervisorNotes;
