import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  orderBy,
  setDoc,
  where,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import "../app.css";
import FeedbackForm from "./FeedbackForm";
import axios from "axios";

function EmployeeFeedbackView(props) {
  // Props
  const selectedUserID = props.selectedUserID;
  // Date Stuff
  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString().slice(3, 15);
  };

  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  // Get HR Feedback
  const [HRfeedback, setHRfeedback] = useState([]);
  const [gotFeedback, setGotFeedback] = useState(false);
  const [feedbackUpdated, setFeedbackUpdated] = useState(1);
  const getHRfeedback = async () => {
    console.log("Getting HR Feedback");
    setHRfeedback([]);
    setGotFeedback(false);
    const q = query(
      collection(db, "hr-feedback"),
      where("employeeID", "==", props.selectedUserID)
    );

    const querySnapshot = await getDocs(q);

    let values = [];
    querySnapshot.forEach((doc) => {
      console.log(doc.data());
      // doc.data() is never undefined for query doc snapshots
      values.push({
        ...doc.data(),
        id: doc.id,
        createdOn: convertDate(doc.data().createdOn.seconds),
      });
    });
    console.log(values);
    setHRfeedback(values);
    setGotFeedback(true);
    console.log(HRfeedback);
  };

  const addComment = async () => {};

  const updateFeedback = async (id, array) => {
    const docRef = doc(db, "hr-feedback", id);
    setDoc(docRef, { replies: array }, { merge: true });
    setFeedbackUpdated(feedbackUpdated + 1);
  };

  const addFeedback = async () => {
    await setDoc(doc(db, "hr-feedback", selectedUserID), {
      feedback: [
        {
          date: date,
          sender: auth.currentUser.displayName,
          createdBy: auth.currentUser.uid,
          feedbackReason: document.getElementById("newFeedbackReason").value,
          feedbackConstructive: document.getElementById(
            "newFeedbackConstructive"
          ).value,
          feedbackSolution: document.getElementById("newFeedbackSolution")
            .value,
          feedbackPositive: document.getElementById("newFeedbackPositive")
            .value,
          replies: [],
        },
      ],
    });

    setFeedbackUpdated(feedbackUpdated + 1);
  };

  const deleteFeedback = async (id) => {
    let verify = prompt(
      "You are about to delete feedback! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await updateDoc(doc(db, "hr-feedback", id), { status: "Deleted" });
      setGotFeedback(false);
      getHRfeedback();
    }
  };

  const [showReviewRequest, setShowReviewRequest] = useState(false);
  const [reason, setReason] = useState();
  const [feedbackReqRecipient, setFeedbackReqRecipient] = useState();

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  // Handle Drop Down Selection
  const [selectedRecipientID, setSelectedRecipientID] = useState("");
  const [selectedRecipientName, setSelectedRecipientName] = useState("");
  const [selectedRecipientEmail, setSelectedRecipientEmail] = useState("");
  const [selectedIterator, setSelectedIterator] = useState("");
  const [userSelected, setUserSelected] = useState(false);
  const handleChangeRecipient = (event) => {
    setUserSelected(false);
    const { value } = event.target;
    const iterator = value.split("-")[1];
    setSelectedRecipientID(value.split("-")[0]);
    setSelectedIterator(iterator);
    setSelectedRecipientName(
      userList[iterator].first + " " + userList[iterator].last
    );
    setSelectedRecipientEmail(userList[iterator].email);
    setUserSelected(true);
  };

  const [showResponseSuccess, setShowResponseSuccess] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
    axios
      .post(
        "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/licona/request-for-feedback",
        {
          to: selectedRecipientEmail,
          cc: ["elpaso_csr@liconainsurance.com", "colt@southernstarmga.com"],
          requester: auth.currentUser.displayName,
          requesterID: auth.currentUser.uid,
          createdOn: new Date().toLocaleString().split(",")[0],
          employeeName: auth.currentUser.displayName,
          employeeID: auth.currentUser.uid,
          reason: reason,
          reviewerEmail: selectedRecipientEmail,
          reviewer: selectedRecipientName,
          reviewerID: selectedRecipientID,
          email: auth.currentUser.email,
          status: "Incomplete",
        }
      )
      .then(async (response) => {
        // handle success
        await addDoc(collection(db, "feedback-requests"), {
          to: selectedRecipientEmail,
          cc: ["elpaso_csr@liconainsurance.com", "colt@southernstarmga.com"],
          requester: auth.currentUser.displayName,
          requesterID: auth.currentUser.uid,
          createdOn: new Date(),
          employeeName: auth.currentUser.displayName,
          employeeID: auth.currentUser.uid,
          reason: reason,
          email: auth.currentUser.email,
          reviewerEmail: selectedRecipientEmail,
          reviewer: selectedRecipientName,
          reviewerID: selectedRecipientID,
          status: "Incomplete",
        });
      })
      .then(() => {
        setSelectedRecipientID();
        setSelectedRecipientEmail();
        setSelectedRecipientName();
        setReason();
        setSelectedIterator();
        setShowResponseSuccess(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  //   Get users for user dropdown list
  const [userList, setUserList] = useState([]);
  const [gotUsers, setGotUsers] = useState(false);
  const usersCollectionRef = collection(db, "users");
  const getUsers = async () => {
    setGotUsers(false);
    const data = await getDocs(query(usersCollectionRef, orderBy("first")));
    setUserList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setGotUsers(true);
  };

  const [display, setDisplay] = useState(false);

  useEffect(() => {
    !gotFeedback && getHRfeedback();
    !gotUsers && getUsers();
  }, [selectedUserID, gotFeedback, feedbackUpdated]);

  return (
    <div className="card mb-3">
      <div className="card-header bg-gray-200">
        <button
          onClick={(e) => setDisplay(!display)}
          className="text-xl font-bold"
        >
          Your Feedback
        </button>
      </div>
      {display && (
        <div className="card-body">
          <main>
            {/* New Feedback */}
            <section>
              <h1 className="text-2xl font-bold text-red-900">
                Request Feedback
              </h1>
              <div className="p-3 mb-2">
                {/* Review Request */}
                <div className="card mb-3">
                  <div className="card-header">
                    <button
                      onClick={(e) => setShowReviewRequest(!showReviewRequest)}
                      className="text-lg text-sky-600 font-bold"
                    >
                      Request Feedback
                    </button>
                  </div>
                  {showReviewRequest && (
                    <>
                      {showResponseSuccess ? (
                        <div className="card-body">
                          <div>
                            <p className="text-green-600 font-bold text-xl">
                              Request successfully sent.
                            </p>
                            <button
                              onClick={(e) => setShowResponseSuccess(false)}
                              className="mt-2 text-sky-600"
                            >
                              Return To Request Menu
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="card-body">
                          <form>
                            <p className="mb-2">
                              Here you may request feedback from your supervisor
                              or other members of the management team.
                            </p>
                            <p className="text-lg font-medium">
                              Recipient of Request:
                            </p>
                            <select
                              required
                              onChange={handleChangeRecipient}
                              value={
                                selectedRecipientID + "-" + selectedIterator
                              }
                              className="w-full rounded mb-3"
                            >
                              <option>Select A User</option>
                              {userList.map((userObject, i) => {
                                if (
                                  props.userInfo.supervisor === userObject.id
                                ) {
                                  return (
                                    <option
                                      key={"id" + userObject.id}
                                      value={userObject.id + "-" + i}
                                    >
                                      {toTitleCase(
                                        userObject.first + " " + userObject.last
                                      )}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                            <p className="text-lg font-medium">
                              Reason for Feedback Request:
                            </p>
                            <textarea
                              required
                              value={reason}
                              onChange={(e) => setReason(e.target.value)}
                              className="w-full rounded mb-2"
                              placeholder="Reason"
                            ></textarea>
                            <button
                              onClick={(e) => sendEmail(e)}
                              type="submit"
                              className="font-medium text-white m-1 px-2 py-1 rounded bg-green-900 hover:bg-green-700"
                            >
                              Send Request
                            </button>
                          </form>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </section>

            {/* Feedback History */}
            <section>
              <h1 className="text-2xl font-bold text-red-900">
                Your Feedback History
              </h1>
              <div className="p-3">
                <ul className="list">
                  {gotFeedback === true && (
                    <>
                      {HRfeedback.length === 0 && (
                        <p>
                          You have no feedback to display at this time. You may
                          request feedback using the request feedback form.
                        </p>
                      )}
                      {HRfeedback.map((feedback, i) => {
                        if (feedback.status !== "Deleted" && feedback.published)
                          return (
                            <div
                              key={"feedback-container" + i}
                              className="mb-3 card"
                            >
                              <div className="card-header text-lg">
                                <button
                                  onClick={(e) => {
                                    HRfeedback[i].hideOnList =
                                      !HRfeedback[i].hideOnList;
                                    setFeedbackUpdated(feedbackUpdated + 1);
                                  }}
                                  className="font-bold mr-5 text-sky-600"
                                >
                                  Feedback {i + 1}
                                </button>
                                (Submitted {feedback.createdOn} by{" "}
                                {feedback.author})
                                <span className="float-right">
                                  {feedback.published ? (
                                    <button
                                      disabled
                                      onClick={async (e) => {
                                        if (
                                          window.confirm(
                                            "You are choosing to unpublish a feedback, to continue, click OK. To Cancel, click Cancel."
                                          )
                                        ) {
                                          await updateDoc(
                                            doc(db, "hr-feedback", feedback.id),
                                            {
                                              published: false,
                                            }
                                          );
                                        } else {
                                          return;
                                        }
                                      }}
                                      className="text-green-600"
                                    >
                                      Published
                                    </button>
                                  ) : (
                                    <button
                                      disabled
                                      onClick={async (e) => {
                                        if (
                                          window.confirm(
                                            "You are choosing to publish a feedback, to continue, click OK. To Cancel, click Cancel."
                                          )
                                        ) {
                                          await updateDoc(
                                            doc(db, "hr-feedback", feedback.id),
                                            {
                                              published: true,
                                            }
                                          );
                                        } else {
                                          return;
                                        }
                                      }}
                                      className="text-red-600"
                                    >
                                      Unpublished
                                    </button>
                                  )}
                                </span>
                              </div>
                              {HRfeedback[i]?.hideOnList ? (
                                <></>
                              ) : (
                                <div className="card-body text-lg">
                                  <div className="card">
                                    {/* Reason */}
                                    <div className="card-header">
                                      <h3 className="font-bold">Reason</h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block px-11">
                                        {feedback?.reason}
                                      </p>
                                    </div>
                                  </div>
                                  {/* Constructives */}
                                  <div className="card mt-3">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Constructives:
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <ol className="list-decimal pl-10">
                                        {feedback?.constructives.map(
                                          (constructive, i) => {
                                            return (
                                              <>
                                                <li className="test rounded shadow-md m-3">
                                                  {constructive.description}
                                                </li>
                                              </>
                                            );
                                          }
                                        )}
                                      </ol>
                                    </div>
                                  </div>
                                  {/* Solutions */}
                                  <div className="card mt-3">
                                    <div className="card-header">
                                      <h3 className="font-bold">Solutions:</h3>
                                    </div>
                                    <div className="card-body">
                                      <ol className="list-decimal pl-10">
                                        {feedback?.solutions.map(
                                          (solution, i) => {
                                            return (
                                              <>
                                                <li className="test rounded shadow-md m-3">
                                                  {solution.description}
                                                </li>
                                              </>
                                            );
                                          }
                                        )}
                                      </ol>
                                    </div>
                                  </div>
                                  {/* Positives */}
                                  <div className="card mt-3">
                                    <div className="card-header">
                                      <h3 className="font-bold">Positives:</h3>
                                    </div>
                                    <div className="card-body">
                                      <ol className="list-decimal pl-10">
                                        {feedback?.positives.map(
                                          (positive, i) => {
                                            return (
                                              <>
                                                <li className="test rounded shadow-md m-3">
                                                  {positive.description}
                                                </li>
                                              </>
                                            );
                                          }
                                        )}
                                      </ol>
                                    </div>
                                  </div>

                                  <hr className="my-3" />
                                  {/* Comment List */}
                                  <h3 className="font-bold mt-2">Comments:</h3>
                                  <ul className="list ml-2">
                                    {feedback.replies?.map(
                                      (reply, iterator) => {
                                        return (
                                          <>
                                            {!reply.reply ? (
                                              <></>
                                            ) : (
                                              <li
                                                className="test rounded shadow-md m-3"
                                                key={"reply" + iterator}
                                              >
                                                <p className="italic">
                                                  {reply.sender} on {reply.date}
                                                  :
                                                </p>
                                                <p className="block">
                                                  {reply.reply}
                                                </p>
                                                {auth.currentUser.uid ===
                                                  reply.createdBy && <></>}
                                              </li>
                                            )}
                                          </>
                                        );
                                      }
                                    )}
                                  </ul>

                                  {/* Add Reply */}
                                  <div className="ml-2">
                                    <hr className="my-2" />
                                    <input
                                      id={"reply" + i}
                                      type="text"
                                      className="border-2 border-red-900 p-1 w-full rounded"
                                      placeholder="Enter your reply here..."
                                    />
                                    <button
                                      onClick={(e) => {
                                        HRfeedback[i].replies.push({
                                          date: date,
                                          reply: document.getElementById(
                                            "reply" + i
                                          ).value,
                                          sender: auth.currentUser.displayName,
                                          createdBy: auth.currentUser.uid,
                                        });
                                        updateFeedback(
                                          feedback.id,
                                          HRfeedback[i].replies
                                        );
                                        document.getElementById(
                                          "reply" + i
                                        ).value = "";
                                      }}
                                      className="block font-medium text-white my-1 px-2 py-1 rounded bg-green-900 hover:bg-green-700"
                                    >
                                      Add Comment
                                    </button>
                                  </div>
                                  <hr />
                                  {auth.currentUser.uid ===
                                    feedback.createdBy && (
                                    <>
                                      <button
                                        onClick={() => {
                                          deleteFeedback(feedback.id);
                                        }}
                                        className="m-1 text-red-900"
                                      >
                                        Delete This Feedback
                                      </button>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          );
                      })}
                    </>
                  )}
                </ul>
              </div>
            </section>
          </main>
        </div>
      )}
    </div>
  );
}

export default EmployeeFeedbackView;
