import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  query,
  orderBy,
  setDoc,
  getDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { UserAuth } from "../../Context/AuthContent";
import axios from "axios";

function NewSupportTicket() {
  let { paramRefID } = useParams();
  const userID = paramRefID;

  // Get the date
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  let navigate = useNavigate();

  const auth = getAuth();

  const [userInfo, setUserInfo] = useState({});
  const [gotRole, setGotRole] = useState(false);
  const [noProfile, setNoProfile] = useState(false);
  const getRole = async (usersID) => {
    setGotRole(false);
    const docRef = doc(db, "users", usersID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUserInfo(docSnap.data());
      setGotRole(true);
      setNoProfile(false);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document! User must create a profile to proceed.");
      setNoProfile(true);

      return null;
    }
  };

  const [gotUserList, setGotUserList] = useState(false);

  const [subject, setSubject] = useState("");
  const handleChangeSubject = (event) => {
    const { value } = event.target;
    setSubject(value);
  };

  const sendEmail = async (to, subject, text) => {
    axios
      .post("https://sstools-api-d4ca8.uc.r.appspot.com/send-email", {
        to: to,
        subject: subject,
        text: text,
      })
      .then(function (response) {
        // handle success
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const [message, setMessage] = useState("");
  const handleChangeMessage = (event) => {
    const { value } = event.target;
    setMessage(value);
  };

  const sendMessage = async (e) => {
    await setDoc(
      doc(
        db,
        "support-tickets",
        Math.floor(Math.random() * 9999999) +
          "-" +
          auth.currentUser.uid +
          "-" +
          Math.floor(Math.random() * 9999999)
      ),
      {
        date,
        senderID: auth.currentUser.uid,
        sender: auth.currentUser.displayName,
        status: "Unread",
        subject,
        message,
      }
    );
    sendEmail(
      "colt@southernstarmga.com",
      "New Support Ticket - Southern Star Portal",
      `A new support ticket was submitted
      \n By ${auth.currentUser.displayName} 
      \n ID: ${auth.currentUser.uid} 
      \n Subject: ${subject} Message: ${message}`
    );
    navigate("/account");
    console.log("Ticket Submitted");
  };

  useEffect(() => {
    if (gotRole === false) getRole(userID);
  }, [db, gotUserList]);

  return (
    <main className="mx-auto max-w-[650]">
      <h1 className="title-1">New Support Ticket</h1>
      <input
        className="block w-full mx-auto rounded-md font-medium border-2 p-3 mt-4 sm:text-xl md:text-xl"
        placeholder="Subject"
        type="text"
        onChange={handleChangeSubject}
        value={subject}
      />
      <textarea
        className="block w-full mx-auto rounded-md font-medium border-2 p-3 mt-4 sm:text-xl md:text-xl"
        type="text"
        value={message}
        placeholder="Message Body"
        onChange={handleChangeMessage}
      />
      <button
        onClick={sendMessage}
        className="block mx-auto text-xl text-white bg-red-900 hover:bg-red-700 py-2 px-4 mt-3 mb-3 rounded-md"
      >
        Submit Ticket
      </button>
    </main>
  );
}

export default NewSupportTicket;
