import React, { useEffect, useState } from "react";
import { getDocs, collection, where, query, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import Container from "react-bootstrap/Container";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import { Link } from "react-router-dom";

function FactfinderManagement() {
  // Get the date
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  // Handle Factfinder Display Drop Down Selection
  const [factfinderDisplay, setFactfinderDisplay] = useState("");
  const handleChangeFactfinderDisplay = (event) => {
    const { value } = event.target;
    if (value === "All") { 
        setFactfinderDisplay(value);
        getfactfinderHistory(); 
    } else if (value === "Choose One") {
        setFactfinderDisplay("")
    } else if (value === "Display No Data") {
        setFactfinderDisplay("");
        setFactfinderHistory([]);
    } else {
        setFactfinderDisplay(value);
        updateFactfinderHistory(value);
    }
  };

  // Get All Factfinder History
  const [factfinderHistory, setFactfinderHistory] = useState([]);
  const getfactfinderHistory = async () => {
      console.log("Getting All Factfinder History");
      const q = query(
        collection(db, "Factfinders")
      );
      const queryData = await getDocs(q);
      console.log(queryData.docs);
      setFactfinderHistory(
        queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
  };

  const updateFactfinderHistory = async (status) => {
      console.log("Getting " + status + " Factfinder History");
      const q = query(
        collection(db, "Factfinders"),
        where("factfinderStatus", "==", status)
      );
      const queryData = await getDocs(q);
      console.log(queryData.docs);
      setFactfinderHistory(
        queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    
    }


  // Delete A Factfinder From History
  const deleteThis = async (thisID) => {
    let verify = prompt(
      "You are about to delete a Factfinder! If you are sure, type DELETE below:"
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "Factfinders", thisID));
    }
  };

  useEffect(() => {
    // getfactfinderHistory();
  }, [db, factfinderDisplay]);

  return (
    <section className="max-w-[750px] mx-auto border-2 rounded mt-3 p-3">
      <h1 className="text-xl font-bold mt-1 mb-3">Factfinder Management</h1>

      <select
        onChange={(e) => handleChangeFactfinderDisplay(e)}
        className="w-full block mx-auto rounded-md font-medium border-2 p-3 my-2 sm:text-xl md:text-xl"
      >
        <option value="Choose One">Choose One</option>
        <option value="All">Display All Factfinders</option>
        <option value="Unsubmitted">Display Unsubmitted</option>
        <option value="Submitted">Display Submitted</option>
        <option value="Display No Data">Display No Data</option>
        <option></option>
      </select>


      
        <>
          <Container className="max-w-[1100px] ">
            <h3 className="text-lg pt-3 text-red-900 text-left">
              Factfinder history:
            </h3>
            <Table className="my-4 mx-auto border-2 border-black">
              <Thead>
                <Tr className="font-medium bg-black text-white">
                  <Td>Status</Td>
                  <Td>Company Name</Td>
                  <Td>Last Edited</Td>
                  <Td>Author</Td>
                  <Td>View/Edit</Td>
                  <Td>Delete</Td>
                </Tr>
              </Thead>
              <Tbody>
                {factfinderHistory.map((myData, i) => (
                  <Tr className="border-2 border-black">
                    <Td>{myData.factfinderStatus}</Td>
                    <Td>{myData.companyName}</Td>
                    <Td>{myData.lastUpdated}</Td>
                    <Td>{myData.authorName}</Td>
                    <Td>
                      <Link
                        to={
                          "../editfactfinder/" +
                          myData.id +
                          "/" +
                          myData.company
                        }
                        className="mx-auto  font-medium text-red-900 border-red-900 hover:bg-red-100"
                      >
                        View/Edit
                      </Link>
                    </Td>
                    <Td>
                      <button
                        onClick={(e) => deleteThis(myData.id)}
                        className="mx-auto  font-medium text-red-900 border-red-900 hover:bg-red-100"
                      >
                        Delete
                      </button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Container>
        </>
      
    </section>
  );
}

export default FactfinderManagement;
