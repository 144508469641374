import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  orderBy,
  setDoc,
  where,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import "../app.css";
import PerformanceReviewsForm from "./PerformanceReviewsForm";
import Modal from "react-modal";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import axios from "axios";
import Card from "react-bootstrap/Card";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const marks = [
  {
    value: 1,
    label: "1: Unacceptable",
  },
  {
    value: 2,
    label: "2: Needs Improvement",
  },
  {
    value: 3,
    label: "3: Meets Expectations",
  },
  {
    value: 4,
    label: "4: Exceeds Expectations",
  },
  {
    value: 5,
    label: "5: Outstanding",
  },
];

function HumanResourcesPerformanceReview(props) {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  // Props
  const selectedUserID = props.selectedUserID;
  // Date Stuff
  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString().slice(3, 15);
  };

  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  console.log("selected user id" + selectedUserID);

  // Get HR PerformanceReviews
  const [HRperformanceReviews, setHRperformanceReviews] = useState([]);
  const [gotPerformanceReviews, setGotPerformanceReviews] = useState(false);
  const [performanceReviewsUpdated, setPerformanceReviewsUpdated] = useState(1);
  const getHRperformanceReviews = async () => {
    setHRperformanceReviews([]);
    setGotPerformanceReviews(false);
    const q = query(
      collection(db, "hr-performanceReviews"),
      where("employeeID", "==", props.selectedUserID)
    );

    const querySnapshot = await getDocs(q);

    let values = HRperformanceReviews;
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      doc.data().status !== "Deleted" &&
      values.push({
        ...doc.data(),
        id: doc.id,
        createdOn: convertDate(doc.data().createdOn.seconds),
      });
    });
    console.log(values);
    setHRperformanceReviews(values);
    setGotPerformanceReviews(true);
    console.log(HRperformanceReviews);
  };

  const addComment = async () => {};

  const updatePerformanceReviews = async (id, array) => {
    const docRef = doc(db, "hr-performanceReviews", id);
    setDoc(docRef, { replies: array }, { merge: true });
    setPerformanceReviewsUpdated(performanceReviewsUpdated + 1);
  };

  const [newPerformanceReviewTitleStatus, setNewPerformanceReviewTitleStatus] =
    useState();
  const [performanceReviewID, setPerformanceReviewID] = useState();
  const [performanceReviewIterator, setPerformanceReviewIterator] = useState();

  const updatePerformanceReviewStatus = async (e, newStatus, id, i) => {
    e.preventDefault();
    await updateDoc(doc(db, "hr-performanceReviews", id), {
      status: newStatus,
    });
    HRperformanceReviews[i].status = newStatus;
    setGotPerformanceReviews(false);
    getHRperformanceReviews();
    closeModal();
  };

  const [rating, setrating] = React.useState(0);
  const handleChangeRating = (event, newValue) => {
    setrating(newValue);
  };

  const [showReviewRequest, setShowReviewRequest] = useState(false);
  const [reason, setReason] = useState();
  const [showResponseSuccess, setShowResponseSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/licona/request-for-performanceReview",
        {
          to: "elpaso_csr@liconainsurance.com",
          cc: ["colt@southernstarmga.com"],
          requester: auth.currentUser.displayName,
          requesterID: auth.currentUser.uid,
          createdOn: new Date().toLocaleString().split(",")[0],
          employeeName:
            props.selectedUserInfo.first + " " + props.selectedUserInfo.last,
          employeeID: props.selectedUserID,
          reason: reason,
          typeOfPerformanceReview,
          comments,
          status: "Incomplete",
        }
      )
      .then(async (response) => {
        // handle success
        const docRef = await addDoc(
          collection(db, "performanceReview-requests"),
          {
            requester: auth.currentUser.displayName,
            requesterID: auth.currentUser.uid,
            createdOn: new Date(),
            employeeName:
              props.selectedUserInfo.first + " " + props.selectedUserInfo.last,
            employeeID: props.selectedUserID,
            reason: reason,
            typeOfPerformanceReview,
            comments,
            status: "Incomplete",
          }
        );
      })
      .then(() => {
        setLoading(false);
        setReason();
        setTypeOfPerformanceReview();
        setComments();
        setShowResponseSuccess(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const [comments, setComments] = useState();
  const [typeOfPerformanceReview, setTypeOfPerformanceReview] = useState();

  const [performanceReviewRequestsList, setPerformanceReviewRequestsList] =
    useState([]);
  const [gotPerformanceReviewRequests, setGotPerformanceReviewRequests] =
    useState(false);
  const getPerformanceReviewRequests = async () => {

  };
  const deletePerformanceReviewRequests = async (docID) => {
    let verify = prompt(
      "You are about to delete a PerformanceReview Request! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "performanceReview-requests", docID));
      getPerformanceReviewRequests();
      // Log System Activity
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          "A performanceReview request was deleted by: " +
          auth.currentUser.displayName,
      });
    }
  };

  useEffect(() => {
    !gotPerformanceReviews && getHRperformanceReviews();
    !gotPerformanceReviewRequests && getPerformanceReviewRequests();
  }, [selectedUserID, gotPerformanceReviews, performanceReviewsUpdated]);

  return (
    <section>
      {performanceReviewRequestsList.map((performanceReviewRequests, i) => {
        if (
          performanceReviewRequests.status === "Incomplete" &&
          props.userInfo.admin
        )
          if (!performanceReviewRequests.dismissedByUser)
            return (
              <li key={"performanceReviewRequests" + i} className="mb-3">
                <Card>
                  <Card.Header className="bg-red-300">
                    <span className="font-bold text-lg">
                      PerformanceReview Request{" "}
                    </span>
                    {performanceReviewRequests.authorName}{" "}
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <p className="mt-2">
                        A performanceReview request was submitted for{" "}
                        {performanceReviewRequests.employeeName} by Supervisor{" "}
                        {performanceReviewRequests.requester}:
                      </p>
                      <div className="ml-5">
                        <p>
                          Reason: <i>{performanceReviewRequests.reason}</i>
                        </p>
                        <p>
                          Type Of PerformanceReview:{" "}
                          <i>
                            {performanceReviewRequests.typeOfPerformanceReview}
                          </i>
                        </p>
                        <p>
                          Comments: <i>{performanceReviewRequests.comments}</i>
                        </p>
                      </div>
                      <p className="my-2">
                        Dismissing this notification will not mark the request
                        as complete, it will only hide the notification.
                      </p>
                      <button
                        onClick={async (e) => {
                          await updateDoc(
                            doc(
                              db,
                              "performanceReview-requests",
                              performanceReviewRequests.id
                            ),
                            {
                              status: "Complete",
                            }
                          );
                          getPerformanceReviewRequests();
                        }}
                        className="mt-2 rounded py-2 px-3 bg-sky-600 hover:bg-sky-900 text-white font-[600]"
                      >
                        Mark As Complete
                      </button>
                    </Card.Text>
                  </Card.Body>
                  {props.userInfo.admin && (
                    <Card.Footer>
                      <button
                        onClick={(e) =>
                          deletePerformanceReviewRequests(
                            performanceReviewRequests.id
                          )
                        }
                        className="text-red-600"
                      >
                        Delete This Request
                      </button>
                    </Card.Footer>
                  )}
                </Card>
              </li>
            );
      })}
      <h1 className="text-2xl font-bold text-red-900">
        Add Employee Performance Review
      </h1>
      <div className="p-3">
        <PerformanceReviewsForm
          setHRperformanceReviews={setHRperformanceReviews}
          setGotPerformanceReviews={setGotPerformanceReviews}
          selectedUserInfo={props.selectedUserInfo}
          selectedUserID={selectedUserID}
          performanceReviewsUpdated={performanceReviewsUpdated}
          setPerformanceReviewsUpdated={setPerformanceReviewsUpdated}
        />
      </div>
      <ul className="list mt-3">
        {gotPerformanceReviews === true && (
          <>
            <h1 className="text-2xl font-bold text-red-900">
              Performance Review History
            </h1>
            <div className="p-3">
              {HRperformanceReviews.map((performanceReviews, i) => (
                <div
                  key={"performanceReviews-container" + i}
                  className="mb-3 card"
                >
                  <div className="card-header text-lg">
                    <button
                      onClick={(e) => {
                        HRperformanceReviews[i].hideOnList =
                          !HRperformanceReviews[i].hideOnList;
                        setPerformanceReviewsUpdated(
                          performanceReviewsUpdated + 1
                        );
                      }}
                      className="font-bold mr-5 text-sky-600 capitalize"
                    >
                      {"("}
                      {performanceReviews.totalOfRatings}
                      {")"} {"By "}
                      {performanceReviews.author} on{" "}
                      {performanceReviews.createdOn}
                    </button>
                  </div>
                  {HRperformanceReviews[i]?.hideOnList ? (
                    <></>
                  ) : (
                    <> 
                    <div className="card-body text-lg">
                                  <div className="card mb-3">
                                    <div className="card-body">
                                      {/* Instructions */}
                                      {/* Rating Scale */}
                                      <article className="ml-3">
                                        <p className="font-bold">
                                          {" "}
                                          Rating scale:
                                        </p>
                                        <p>
                                          {" "}
                                          5 - Excellent (consistently exceeds
                                          standards)
                                        </p>
                                        <p>
                                          {" "}
                                          4 - Outstanding (frequently exceeds
                                          standards)
                                        </p>
                                        <p>
                                          {" "}
                                          3 - Satisfactory (generally meets
                                          standards)
                                        </p>
                                        <p>
                                          {" "}
                                          2 - Needs improvement (frequently
                                          fails to meet standards)
                                        </p>
                                        <p>
                                          {" "}
                                          1 - Unacceptable (fails to meet
                                          standards)
                                        </p>
                                      </article>
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Job Knowledge
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.jobKnowledge}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.jobKnowledgeExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Sharing knowledge
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.sharingKnowledge}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.sharingKnowledgeExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Willingness to Develop Skills
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.developSkills}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.developSkillsExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Quality of work
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.qualityOfWork}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.qualityOfWorkExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Quantity of work
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.quantityOfWork}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.quantityOfWorkExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">Reliability</h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.reliability}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.reliabilityExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Accountability
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.accountability}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.accountabilityExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Initiative and creativity
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.initativeAndCreativity
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.initiativeAndCreativityExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Flexibility{" "}
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.flexibility}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.flexibilityExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">Judgment</h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.judgement}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.judgementExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">Cooperation</h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.cooperation}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.cooperationExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">Attendance</h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.attendance}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.attendanceExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Attitude / Respectfulness{" "}
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.attitudeRespectfulness
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.attitudeRespectfulnessExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Accepts Criticism{" "}
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.acceptsCriticism}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.acceptsCriticismExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Policy & Procedures
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.policyAndProcedures
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.policyAndProceduresExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Confidentiality
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.confidentiality}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.confidentialityExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Communication Skills
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.communicationSkills
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.communicationSkillsExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Conflict Resolution
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.conflictResolution}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.conflictResolutionExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Organizational Skills
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.organizationalSkills
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.organizationalSkillsExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Customer Interaction{" "}
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.customerInteraction
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.customerInteractionExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Appearance / Dress Code
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.appearanceDressCode
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.appearanceDressCodeExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Appearance of Work Area
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.appearanceOfWorkArea
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.appearanceOfWorkAreaExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Supervising: Planning and Organizing
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.planningAndOrganizing
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.planningAndOrganizingExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Supervising: Directing and Controlling
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.directingAndControlling
                                        }
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.directingAndControllingExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Supervising: Decision Maker
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.decisionMaker}
                                      </p>
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.decisionMakerExplanation
                                        }
                                      </p>
                                    </div>
                                  </div>

                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Noteworthy Accomplishments
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.noteworthyAccomplishments
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Areas For Improvement
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.areasRequiringImprovement
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Actions Taken
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.improvementActions}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Development Goals
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {
                                          performanceReviews?.professionalDevelopmentGoals
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card mt-2">
                                    <div className="card-header">
                                      <h3 className="font-bold">
                                        Explanation of Overall Score of{" "}
                                        {performanceReviews?.totalOfRatings}
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p className="block ml-3 w-full">
                                        {performanceReviews?.explanationOfScore}
                                      </p>
                                    </div>
                                  </div>

                                  <hr className="my-3" />
                                  {/* Comment List */}
                                  <h3 className="font-bold mt-2">Comments:</h3>
                                  <ul className="list ml-2">
                                    {performanceReviews.replies?.map(
                                      (reply, iterator) => {
                                        return (
                                          <>
                                            {!reply.reply ? (
                                              <></>
                                            ) : (
                                              <li
                                                className="test rounded shadow-md m-3"
                                                key={"reply" + iterator}
                                              >
                                                <p className="italic">
                                                  {reply.sender} on {reply.date}
                                                  :
                                                </p>
                                                <p className="block">
                                                  {reply.reply}
                                                </p>
                                                {auth.currentUser.uid ===
                                                  reply.createdBy && (
                                                  <>
                                                    <button
                                                      onClick={() => {
                                                        HRperformanceReviews[
                                                          i
                                                        ].replies.splice(
                                                          iterator,
                                                          1
                                                        );
                                                        updatePerformanceReviews(
                                                          performanceReviews.id,
                                                          HRperformanceReviews[
                                                            i
                                                          ].replies
                                                        );
                                                      }}
                                                      className="text-xs m-1 text-red-900"
                                                    >
                                                      Delete Comment
                                                    </button>
                                                  </>
                                                )}
                                              </li>
                                            )}
                                          </>
                                        );
                                      }
                                    )}
                                  </ul>

                                  {/* Add Reply */}
                                  <div className="ml-2">
                                    <hr className="my-2" />
                                    <input
                                      id={"reply" + i}
                                      type="text"
                                      className="border-2 border-red-900 p-1 w-full rounded"
                                      placeholder="Enter your reply here..."
                                    />
                                    <button
                                      onClick={(e) => {
                                        HRperformanceReviews[i].replies.push({
                                          date: date,
                                          reply: document.getElementById(
                                            "reply" + i
                                          ).value,
                                          sender: auth.currentUser.displayName,
                                          createdBy: auth.currentUser.uid,
                                        });
                                        updatePerformanceReviews(
                                          performanceReviews.id,
                                          HRperformanceReviews[i].replies
                                        );
                                        document.getElementById(
                                          "reply" + i
                                        ).value = "";
                                      }}
                                      className="block font-medium text-white my-1 px-2 py-1 rounded bg-green-900 hover:bg-green-700"
                                    >
                                      Add Comment
                                    </button>
                                  </div>
                                  <hr />
                                </div>
                    
                    </>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </ul>
      {/* Modals */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="PerformanceReview Status Modal"
      >
        <div className="min-w-[200px] p-3">
          <p className="mb-2 font-medium text-lg text-red-900">New Status:</p>
          <form
            onSubmit={(e) =>
              updatePerformanceReviewStatus(
                e,
                newPerformanceReviewTitleStatus,
                performanceReviewID,
                performanceReviewIterator
              )
            }
          >
            <select
              required
              value={newPerformanceReviewTitleStatus}
              onChange={(e) =>
                setNewPerformanceReviewTitleStatus(e.target.value)
              }
              className="w-full"
            >
              <option value="In Progress">In Progress</option>
              <option value="Delayed">Delayed</option>
              <option value="Achieved">Achieved</option>
            </select>
            <div>
              <button
                type="submit"
                className="block w-full mx-auto font-medium text-white mt-3 p-2 rounded bg-green-900 hover:bg-green-700"
              >
                Update Status
              </button>
              <button
                onClick={(e) => {
                  closeModal();
                  setPerformanceReviewID();
                  setPerformanceReviewIterator();
                  setNewPerformanceReviewTitleStatus();
                }}
                className="block w-full mx-auto font-medium text-white mt-3 p-2 rounded bg-red-900 hover:bg-red-700"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </section>
  );
}

export default HumanResourcesPerformanceReview;
