import React, { Component, useEffect, useState } from "react";
import Signin from "./Components/Signin";
import Signup from "./Components/Signup";
import Account from "./Components/user/dashboard/Account";
import Dashboard from "./Components/Dashboard";
import { Routes, Route, Link, Navigate, useNavigate } from "react-router-dom";
import { AuthContextProvider } from "./Context/AuthContent";
import ProtectedRoute from "./Components/ProtectedRoute";
import ChooseCompany from "./Components/ChooseCompany";
import Company from "./Components/Company";
import { signOut } from "firebase/auth";
import EditFactfinder from "./Components/EditFactfinder";
import UpdateDisplayName from "./Components/UpdateDisplayName";
import Messages from "./Components/Messages";
import NewMessage from "./Components/NewMessage";
import SentMessages from "./Components/SentMessages";
import ReadMessage from "./Components/ReadMessage";
import Admin from "./Components/Admin";
import HumanResourcesDashboard from "./Components/hr/HRDash";
import "flowbite";
import "flowbite-react";
import { Dropdown } from "flowbite-react";
import { auth } from "./firebase";
import Applicant from "./Components/hr/Applicant";
import AccountHR from "./Components/hr/AccountHR";
import Home from "./Components/Home";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Col, Container, Row } from "react-bootstrap";
import EmployeeDirectory from "./Components/EmployeeDirectory";
import "./Components/app.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import CompanyDropdown from "./Components/companies/CompanyDropdown";
import NavItems from "./Components/NavItems";
import { useAuthState } from 'react-firebase-hooks/auth';
import Calendar from './Components/calendar/Calendar';
import NewJobListing from './Components/hr/NewJobListing';
import Kanban from "./Components/kanban/Kanban";
import AguilaDoradaQuotes from "./Components/AguilaDoradaQuotes";
import AguilaDoradaClaims from "./Components/AguilaDoradaClaims";
import FeedbackRequest from "./Components/hr/FeedbackRequest";
import AdminPanel from "./Components/admin/AdminPanel";
import NewSupportTicket from "./Components/support/NewSupportTicket";
import SetProfile from "./Components/user/EditProfile";

function App() {
  const [user, loading, error] = useAuthState(auth);

  const navigate = useNavigate();
  
  const [apiResponse, setApiResponse] = useState("");
  const [navbar, setNavbar] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const logout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log("Signed Out");
        navigate('/signin')
      })
      .catch((error) => {
        // An error happened.
        console.log("Error trying to sign out");
      });
    this.setState({ isLoggedIn: false });
  };

  useEffect(() => {
    
  }, [])


  return (<>

    <div>
        <Navbar bg="red-900" variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand href="/account">Licona Toolkit</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <NavItems />
            <Form className="d-flex">
              {/* <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            /> */}
              {/* <CompanyDropdown /> */}
            </Form>
          </Navbar.Collapse>
        </Container>
        </Navbar>
      
      
      <Container fluid>
        <Row>
          {/* Old Sidebar */}
          {/* <Col xs={"auto"}>
                <Sidebar defaultCollapsed={false} collapsedWidth={"10px"}>
                  <Menu>
                    <h1 className="mt-3 mb-1 font-medium">Main Menu</h1>
                    <MenuItem href="/directory"> Employee Directory </MenuItem>
                    <MenuItem> Time Off Requests</MenuItem>
                    <h2 className="mt-3 mb-1 font-medium">
                      Departmental Links
                    </h2>
                    <MenuItem href="/choosecompany"> Factfinders </MenuItem>
                    <MenuItem href="/choosecompany"> Proposals </MenuItem>
                    <MenuItem> Loss Control </MenuItem>
                    <MenuItem> Calendar </MenuItem>
                  </Menu>
                </Sidebar>
              </Col> */}

          <Col lg={{ span: 10, offset: 1 }} className="main-container">
            <AuthContextProvider>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Signin
                      changeAuthStatus={(val) =>
                        this.setState({ isLoggedIn: val })
                      }
                    />
                  }
                />
                <Route
                  path="/signin"
                  element={
                    <Signin
                      changeAuthStatus={(val) =>
                        this.setState({ isLoggedIn: val })
                      }
                    />
                  }
                />
                <Route path="/signup" element={<Signup />} />
                <Route
                  path="/choosecompany"
                  element={
                    <ProtectedRoute>
                      <ChooseCompany />
                    </ProtectedRoute>
                  }
                />
                                  <Route
                    path="/new-support-ticket"
                    element={
                      <ProtectedRoute>
                        <NewSupportTicket />
                      </ProtectedRoute>
                    }
                  />
<Route
                  path="/kanban/:id"
                  element={
                    <ProtectedRoute>
                      <Kanban />
                    </ProtectedRoute>
                  }
                />
                  <Route
                    path="/user-details"
                    element={
                      <ProtectedRoute>
                        <SetProfile />
                      </ProtectedRoute>
                    }
                  />
                <Route
                  path="/feedback-request/:requestID"
                  element={
                    <ProtectedRoute>
                      <FeedbackRequest />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin-panel"
                  element={
                    <ProtectedRoute>
                      <AdminPanel />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/account"
                  element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  }
                />                
                <Route
                path="/aguila-dorada-claims"
                element={
                  <ProtectedRoute>
                    <AguilaDoradaClaims />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/aguila-dorada-quotes"
                element={
                  <ProtectedRoute>
                    <AguilaDoradaQuotes />
                  </ProtectedRoute>
                }
              />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/calendar"
                  element={
                    <ProtectedRoute>
                      <Calendar />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/home"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="company/:id"
                  element={
                    <ProtectedRoute>
                      <Company />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="factfinder/:companyID"
                  element={
                    <ProtectedRoute>
                      <EditFactfinder />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="editfactfinder/:id/:companyID"
                  element={
                    <ProtectedRoute>
                      <EditFactfinder />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="JobListing/:id"
                  element={
                    <ProtectedRoute>
                      <NewJobListing />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="updatedisplayname"
                  element={
                    <ProtectedRoute>
                      <UpdateDisplayName />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="messages"
                  element={
                    <ProtectedRoute>
                      <Messages />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="newmessage"
                  element={
                    <ProtectedRoute>
                      <NewMessage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="sentmessages"
                  element={
                    <ProtectedRoute>
                      <SentMessages />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="admin"
                  element={
                    <ProtectedRoute>
                      <Admin />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="readmessage/:id"
                  element={
                    <ProtectedRoute>
                      <ReadMessage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="hr"
                  element={
                    <ProtectedRoute>
                      <HumanResourcesDashboard />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="applicant/:id"
                  element={
                    <ProtectedRoute>
                      <Applicant />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/directory"
                  element={
                    <ProtectedRoute>
                      <EmployeeDirectory />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </AuthContextProvider>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
}

export default App;
