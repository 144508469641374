import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  orderBy,
  setDoc,
  where,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import "../app.css";
import GoalsForm from "./GoalsForm";
import Modal from "react-modal";
import axios from "axios";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");

function HumanResourcesGoals(props) {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  // Props
  const selectedUserID = props.selectedUserID;
  // Date Stuff
  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString().slice(3, 15);
  };

  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  console.log("selected user id" + selectedUserID);

  // Get HR Goals
  const [HRgoals, setHRgoals] = useState([]);
  const [gotGoals, setGotGoals] = useState(false);
  const [goalsUpdated, setGoalsUpdated] = useState(1);
  const getHRgoals = async () => {
    setHRgoals([]);
    setGotGoals(false);
    const q = query(
      collection(db, "hr-goals"),
      where("employeeID", "==", props.selectedUserID)
    );

    const querySnapshot = await getDocs(q);

    let values = HRgoals;
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      doc.data().status !== "Deleted" &&
      values.push({
        ...doc.data(),
        id: doc.id,
        createdOn: convertDate(doc.data().createdOn.seconds),
      });
    });
    console.log(values);
    setHRgoals(values);
    setGotGoals(true);
    console.log(HRgoals);
  };

  const addComment = async () => {};

  const updateGoals = async (id, array) => {
    const docRef = doc(db, "hr-goals", id);
    setDoc(docRef, { replies: array }, { merge: true });
    setGoalsUpdated(goalsUpdated + 1);
  };

  const addGoals = async () => {
    await setDoc(doc(db, "hr-goals", selectedUserID), {
      goals: [
        {
          date: date,
          sender: auth.currentUser.displayName,
          createdBy: auth.currentUser.uid,
          goalsNewGoal: document.getElementById("newGoalsNewGoal").value,
          goalsMetric: document.getElementById("newGoalsMetric").value,
          goalsStep: document.getElementById("newGoalsStep").value,
          goalsChallenge: document.getElementById("newGoalsChallenge").value,
          replies: [],
        },
      ],
    });

    setGoalsUpdated(goalsUpdated + 1);
  };

  const deleteGoals = async (id) => {
    let verify = prompt(
      "You are about to delete goals! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "hr-goals", id));
      setGotGoals(false);
      setHRgoals([]);
      setGoalsUpdated(goalsUpdated + 1);
    }
  };

  const [newGoalStatus, setNewGoalStatus] = useState();
  const [goalID, setGoalID] = useState();
  const [goalIterator, setGoalIterator] = useState();

  const updateGoalStatus = async (e, newStatus, id, i) => {
    e.preventDefault();
    await updateDoc(doc(db, "hr-goals", id), {
      status: newStatus,
    });
    HRgoals[i].status = newStatus;
    setGotGoals(false);
    getHRgoals();
    closeModal();
  };

  useEffect(() => {
    !gotGoals && getHRgoals();
  }, [selectedUserID, gotGoals, goalsUpdated]);

  return (
    <section>
      <GoalsForm
        setHRgoals={setHRgoals}
        setGotGoals={setGotGoals}
        selectedUserID={selectedUserID}
        goalsUpdated={goalsUpdated}
        setGoalsUpdated={setGoalsUpdated}
      />

      <ul className="list mt-3">
        {gotGoals === true && (
          <>
            {HRgoals.map((goals, i) => (
              <div key={"goals-container" + i} className="mb-3 card">
                <div className="card-header text-lg">
                  <button
                    onClick={(e) => {
                      HRgoals[i].hideOnList = !HRgoals[i].hideOnList;
                      setGoalsUpdated(goalsUpdated + 1);
                    }}
                    className="font-bold mr-5 text-sky-600"
                  >
                    Goal {i + 1}
                  </button>
                  <span className="text-sm">Created {goals.createdOn}</span>
                  <span className="float-right">
                    Status:
                    <button
                      onClick={(e) => {
                        openModal();
                        setGoalIterator(i);
                        setGoalID(goals.id);
                        setNewGoalStatus(goals.status);
                      }}
                      className="ml-1 text-sky-600"
                    >
                      {goals.status}
                    </button>
                  </span>
                </div>
                {HRgoals[i]?.hideOnList ? (
                  <></>
                ) : (
                  <div className="card-body text-lg">
                    <div className="card">
                      {/* NewGoal */}
                      <div className="card-header">
                        <h3 className="font-bold">New Goal</h3>
                      </div>
                      <div className="card-body">
                        <p className="block px-11">{goals?.newGoal}</p>
                      </div>
                    </div>
                    {/* Metrics */}
                    <div className="card mt-3">
                      <div className="card-header">
                        <h3 className="font-bold">Metrics:</h3>
                      </div>
                      <div className="card-body">
                        <ol className="list-decimal pl-10">
                          {goals?.metrics.map((metric, i) => {
                            return (
                              <>
                                <li className="test rounded shadow-md m-3">
                                  {metric.description}
                                </li>
                              </>
                            );
                          })}
                        </ol>
                      </div>
                    </div>
                    {/* Steps */}
                    <div className="card mt-3">
                      <div className="card-header">
                        <h3 className="font-bold">Steps:</h3>
                      </div>
                      <div className="card-body">
                        <ol className="list-decimal pl-10">
                          {goals?.steps.map((step, i) => {
                            return (
                              <>
                                <li className="test rounded shadow-md m-3">
                                  {step.description}
                                </li>
                              </>
                            );
                          })}
                        </ol>
                      </div>
                    </div>
                    {/* Challenges */}
                    <div className="card mt-3">
                      <div className="card-header">
                        <h3 className="font-bold">Challenges:</h3>
                      </div>
                      <div className="card-body">
                        <ol className="list-decimal pl-10">
                          {goals?.challenges.map((challenge, i) => {
                            return (
                              <>
                                <li className="test rounded shadow-md m-3">
                                  {challenge.description}
                                </li>
                              </>
                            );
                          })}
                        </ol>
                      </div>
                    </div>

                    <hr className="my-3" />
                    {/* Comment List */}
                    <h3 className="font-bold mt-2">Comments:</h3>
                    <ul className="list ml-2">
                      {goals.replies?.map((reply, iterator) => {
                        return (
                          <>
                            {!reply.reply ? (
                              <></>
                            ) : (
                              <li
                                className="test rounded shadow-md m-3"
                                key={"reply" + iterator}
                              >
                                <p className="italic">
                                  {reply.sender} on {reply.date}:
                                </p>
                                <p className="block">{reply.reply}</p>
                                {auth.currentUser.uid === reply.createdBy && (
                                  <>
                                    <button
                                      onClick={() => {
                                        HRgoals[i].replies.splice(iterator, 1);
                                        updateGoals(
                                          goals.id,
                                          HRgoals[i].replies
                                        );
                                      }}
                                      className="text-xs m-1 text-red-900"
                                    >
                                      Delete Comment
                                    </button>
                                  </>
                                )}
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>

                    {/* Add Reply */}
                    <div className="ml-2">
                      <hr className="my-2" />
                      <input
                        id={"reply" + i}
                        type="text"
                        className="border-2 border-red-900 p-1 w-full rounded"
                        placeholder="Enter your reply here..."
                      />
                      <button
                        onClick={(e) => {
                          HRgoals[i].replies.push({
                            date: date,
                            reply: document.getElementById("reply" + i).value,
                            sender: auth.currentUser.displayName,
                            createdBy: auth.currentUser.uid,
                          });
                          updateGoals(goals.id, HRgoals[i].replies);
                          document.getElementById("reply" + i).value = "";
                        }}
                        className="block font-medium text-white my-1 px-2 py-1 rounded bg-green-900 hover:bg-green-700"
                      >
                        Add Comment
                      </button>
                    </div>
                    <hr />
                    {props.userInfo.admin && (
                      <>
                        <button
                          onClick={(e) => {
                            updateGoalStatus(e, "Deleted", goals.id, i);
                          }}
                          className="m-1 text-red-900"
                        >
                          Delete This Goal
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </ul>
      {/* Modals */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Goal Status Modal"
      >
        <div className="min-w-[200px] p-3">
          <p className="mb-2 font-medium text-lg text-red-900">New Status:</p>
          <form onSubmit={(e) => updateGoalStatus(e, newGoalStatus, goalID, goalIterator)}>
          <select
          required
          
            value={newGoalStatus}
            onChange={(e) => setNewGoalStatus(e.target.value)}
            className="w-full"
          >
            <option value="In Progress">In Progress</option>
            <option value="Delayed">Delayed</option>
            <option value="Achieved">Achieved</option>
          </select>
          <div>
            <button
              type="submit"
              className="block w-full mx-auto font-medium text-white mt-3 p-2 rounded bg-green-900 hover:bg-green-700"
            >
              Update Status
            </button>
            <button
              onClick={(e) => {closeModal(); setGoalID(); setGoalIterator(); setNewGoalStatus();}}
              className="block w-full mx-auto font-medium text-white mt-3 p-2 rounded bg-red-900 hover:bg-red-700"
            >
              Cancel
            </button>
          </div>
          </form>
        </div>
      </Modal>
    </section>
  );
}

export default HumanResourcesGoals;
