import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  query,
} from "firebase/firestore";
import { db } from "../firebase";
import { Container } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";

function EmployeeDirectory() {
  const [pageData, setPageData] = useState([]);
  const [gotData, setGotData] = useState(false);
  const getPageData = async () => {
    setGotData(false);
    const q = query(collection(db, "users"));
    const queryData = await getDocs(q);
    setPageData(queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setGotData(true);
  };

  const fillInTheBlanks = (data) => {
    const values = [...data];
    values.forEach((field) => {
      if (!field.title) field.title = "";
      if (!field.first) field.first = "";
      if (!field.last) field.last = "";
      if (!field.email) field.email = "";
      if (!field.phone) field.phone = "";
      if (!field.department) field.department = "";
    })
  };

  const sortData = (column) => {
    fillInTheBlanks(pageData);
    // Name
      if (column === "name") {
        const data2 = [...pageData].sort((a, b) =>
        a.first.toLowerCase() < b.first.toLowerCase() ? -1 : 1
        );

        setPageData(data2);
      }
    // Title
      if (column === "title") {
        const data2 = [...pageData].sort((a, b) =>
        a.title?.toLowerCase() < b.title?.toLowerCase() ? -1 : 1
        );

        setPageData(data2);
      }
      // Department
      if (column === "department") {
        const data2 = [...pageData].sort((a, b) =>
        a.department?.toLowerCase() < b.department?.toLowerCase() ? -1 : 1
        );

        setPageData(data2);
      }
      // Email
      if (column === "email") {
        const data2 = [...pageData].sort((a, b) =>
        a.email?.toLowerCase() < b.email?.toLowerCase() ? -1 : 1
        );

        setPageData(data2);
      }
      // Title
      if (column === "phone") {
        const data2 = [...pageData].sort((a, b) =>
        a.phone < b.phone ? -1 : 1
        );

        setPageData(data2);
      }
  }

  useEffect(() => {
    if (!gotData) getPageData();
  }, [db, pageData]);

  return (
    <>
      <h1 className="title-1">Employee Directory</h1>
      <hr className="divider-1" />
      <Container>
        {/* If no data found */}
      {pageData.length < 1 && (
        <p>Loading....</p>
      )}
      {/* If data found */}
      {pageData.length > 1 && (
        <div className="data-table-container">
        <Table className="data-table">
          <Thead>
            <Tr className="data-table-header">
              <Td><button onClick={() => sortData("name")}>Name</button></Td>
              <Td><button onClick={() => sortData("title")}>Title</button></Td>
              <Td><button onClick={() => sortData("department")}>Department</button></Td>
              <Td><button onClick={() => sortData("email")}>Email</button></Td>
              <Td><button onClick={() => sortData("phone")}>Phone</button></Td>
              <Td>Actions</Td>
            </Tr>
          </Thead>
          <Tbody>
            {pageData.map((myData, i) => (
              <Tr key={"row" + i} className="border-2 border-black">
                <Td>
                  {myData.first} {myData.last}
                </Td>
                <Td>{myData.title}</Td>
                <Td>{myData.department || ""}</Td>
                <Td><a href={"mailto:" + myData.email || ""}>{myData.email || ""}</a></Td>
                <Td><a href={"tel:" + myData.phone || ""}>{myData.phone || ""}</a></Td>
                <Td>
                  <a href="">
                    Message {myData.first}
                  </a>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        </div>
      )}
      </Container>
    </>
  );
}

export default EmployeeDirectory;
