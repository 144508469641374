import React, { useState, useEffect } from "react";
import { UserAuth } from "../Context/AuthContent";
import { Link, useNavigate } from "react-router-dom";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Container, Row, Col } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { collection, query, where, getDocs, getDoc } from "firebase/firestore";
import "./app.css";
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import Dashboard from "./Dashboard";
import TemplateDataTable from "./Template-Data-Table";
import EmployeeDirectory from "./EmployeeDirectory";
import TemplateGenericForm from "./Template-Generic-Form";

function Account() {
  const { collapseSidebar } = useProSidebar();
  const navigate = useNavigate();
  const { user } = UserAuth();
  const userID = user.uid;

  // Check User's Role
  const [userInfo, setUserInfo] = useState({
    company: "",
    email: "",
    first: "",
    last: "",
    role: "",
    permissions: {},
    phone: "",
    userID: ""
  });

  const getRole = async (usersID) => {
    console.log("Getting User Info & Checking Role");
    const docRef = doc(db, "users", usersID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log('Got Role' + docSnap.data().role)
      setUserInfo(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      return null;
    }
  };

  useEffect(() => {
    getRole(userID);
  }, [db, userInfo.role]);

  return (
    
    <Container className="siteWrap" fluid>
        
            <TemplateGenericForm
                role={userInfo.role} 
                company={userInfo.company}
                first={userInfo.first}
                last={userInfo.last}
                userID={userInfo.userID}
                email={userInfo.email}
                phone={userInfo.phone}
            />
    </Container>
  );
}

export default Account;
