import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  orderBy,
  setDoc,
  where,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import "../app.css";
import FeedbackForm from "./FeedbackForm";
import axios from "axios";
import Card from "react-bootstrap/Card";

function HumanResourcesFeedback(props) {
  // Props
  const selectedUserID = props.selectedUserID;
  // Date Stuff
  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString().slice(3, 15);
  };

  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  // Get HR Feedback
  const [HRfeedback, setHRfeedback] = useState([]);
  const [gotFeedback, setGotFeedback] = useState(false);
  const [feedbackUpdated, setFeedbackUpdated] = useState(1);
  const getHRfeedback = async () => {
    setHRfeedback([]);
    setGotFeedback(false);
    const q = query(
      collection(db, "hr-feedback"),
      where("status", "!=", "Deleted")
    );

    const querySnapshot = await getDocs(q);

    let values = HRfeedback;
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      if (doc.data().employeeID === props.selectedUserID) {
        values.push({
          ...doc.data(),
          id: doc.id,
          createdOn: convertDate(doc.data().createdOn.seconds),
        });
      }
    });
    setHRfeedback(values);
    setGotFeedback(true);
  };

  const addComment = async () => {};

  const updateFeedback = async (id, array) => {
    const docRef = doc(db, "hr-feedback", id);
    setDoc(docRef, { replies: array }, { merge: true });
    setFeedbackUpdated(feedbackUpdated + 1);
  };

  const deleteFeedback = async (id) => {
    let verify = prompt(
      "You are about to delete feedback! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await updateDoc(
        doc(db, "hr-feedback", props.selectedUserID, "feedback", id),
        { status: "Deleted" }
      );
      setGotFeedback(false);
      getHRfeedback();
    }
  };

  const [showReviewRequest, setShowReviewRequest] = useState(false);
  const [reason, setReason] = useState();
  const [feedbackReqRecipient, setFeedbackReqRecipient] = useState();

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  // Handle Drop Down Selection
  const [selectedRecipientID, setSelectedRecipientID] = useState("");
  const [selectedRecipientName, setSelectedRecipientName] = useState("");
  const [selectedRecipientEmail, setSelectedRecipientEmail] = useState("");
  const [selectedIterator, setSelectedIterator] = useState("");
  const [userSelected, setUserSelected] = useState(false);
  const handleChangeRecipient = (event) => {
    setUserSelected(false);
    const { value } = event.target;
    const iterator = value.split("-")[1];
    setSelectedRecipientID(value.split("-")[0]);
    setSelectedIterator(iterator);
    setSelectedRecipientName(
      props.userList[iterator].first + " " + props.userList[iterator].last
    );
    setSelectedRecipientEmail(props.userList[iterator].email);
    setUserSelected(true);
  };
  const [loading, setLoading] = useState(false);
  const [showResponseSuccess, setShowResponseSuccess] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/licona/request-for-feedback",
        {
          to: selectedRecipientEmail,
          cc: ["elpaso_csr@liconainsurance.com", "colt@southernstarmga.com"],
          requester: auth.currentUser.displayName,
          requesterID: auth.currentUser.uid,
          createdOn: new Date().toLocaleString().split(",")[0],
          employeeName:
            props.selectedUserInfo.first + " " + props.selectedUserInfo.last,
          employeeID: props.selectedUserID,
          reason: reason,
          reviewerEmail: selectedRecipientEmail,
          reviewer: selectedRecipientName,
          reviewerID: selectedRecipientID,
          email: auth.currentUser.email,
          status: "Incomplete",
        }
      )
      .then(async (response) => {
        // handle success
        const docRef = await addDoc(collection(db, "feedback-requests"), {
          to: selectedRecipientEmail,
          cc: ["elpaso_csr@liconainsurance.com", "colt@southernstarmga.com"],
          requester: auth.currentUser.displayName,
          requesterID: auth.currentUser.uid,
          createdOn: new Date(),
          employeeName:
            props.selectedUserInfo.first + " " + props.selectedUserInfo.last,
          employeeID: props.selectedUserID,
          reason: reason,
          reviewerEmail: selectedRecipientEmail,
          reviewer: selectedRecipientName,
          reviewerID: selectedRecipientID,
          status: "Incomplete",
        });
      })
      .then(() => {
        setLoading(false);
        setSelectedRecipientID();
        setSelectedRecipientEmail();
        setSelectedRecipientName();
        setReason();
        setSelectedIterator();
        setShowResponseSuccess(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const [feedbackRequestList, setFeedbackRequestList] = useState([]);
  const [gotFeedbackRequests, setGotFeedbackRequests] = useState(false);
  const getFeedbackRequests = async () => {
    console.log("Getting Feedback Requests");
    setGotFeedbackRequests(false);

    const q = query(
      collection(db, "feedback-requests"),
      where("employeeID", "==", props.selectedUserID)
    );
    const queryData = await getDocs(q);
    setFeedbackRequestList(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
    setGotFeedbackRequests(true);
  };
  const deleteFeedbackRequest = async (docID) => {
    let verify = prompt(
      "You are about to delete a Feedback Request! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "feedback-requests", docID));
      getFeedbackRequests();
      // Log System Activity
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          "A feedback request was deleted by: " + auth.currentUser.displayName,
      });
    }
  };

  const createNotification = async (recipient, recipientName, content, feedbackName) => {
    await addDoc(collection(db, "notifications"), {
      activityDate: new Date(),
      authorID: auth.currentUser.uid,
      authorName: auth.currentUser.displayName,
      recipient,
      recipientName,
      dismissedByUser: false,
      description:
        "New comment on " + feedbackName + "'s feedback by " + auth.currentUser.displayName + ": " + content,
    });
  }

  useEffect(() => {
    !gotFeedback && getHRfeedback();
    !gotFeedbackRequests && getFeedbackRequests();
  }, [selectedUserID, gotFeedback, gotFeedbackRequests, feedbackUpdated]);

  return (
    <main>
      {/* New Feedback */}
      <section>
        {feedbackRequestList.map((feedbackRequest, i) => {
          if (feedbackRequest.status === "Incomplete")
            return (
              <li key={"feedbackRequest" + i} className="mb-3">
                <Card>
                  <Card.Header className="bg-red-300">
                    <span className="font-bold text-lg">Feedback Request </span>
                    {feedbackRequest.authorName}{" "}
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>{feedbackRequest.employeeName}</Card.Title>
                    <Card.Text>
                      <p className="mt-2">
                        You have received a request to submit feedback for{" "}
                        {feedbackRequest.employeeName} for the following reason:
                      </p>
                      <p className="ml-5 italic">{feedbackRequest.reason}</p>
                      <p className="my-3">
                        Please complete feedback requests in a timely manner.
                        Click the button below to complete the feedback request
                        now.
                      </p>
                      <a
                        href={"/feedback-request/" + feedbackRequest.id}
                        className="mt-2 rounded py-2 px-3 bg-sky-600 hover:bg-sky-900 text-white font-[600]"
                      >
                        Submit Feedback
                      </a>
                    </Card.Text>
                  </Card.Body>
                  {props.userInfo.admin && (
                    <Card.Footer>
                      <button
                        onClick={(e) =>
                          deleteFeedbackRequest(feedbackRequest.id)
                        }
                        className="text-red-600"
                      >
                        Delete This Request
                      </button>
                    </Card.Footer>
                  )}
                </Card>
              </li>
            );
        })}
        <h1 className="text-2xl font-bold text-red-900">New Feedback</h1>
        <div className="p-3 mb-2">
          {/* Review Request */}
          {props.userInfo.admin && (
            <div className="card mb-3">
              <div className="card-header">
                <button
                  onClick={(e) => setShowReviewRequest(!showReviewRequest)}
                  className="text-lg text-sky-600 font-bold"
                >
                  Request Feedback
                </button>
              </div>
              {showReviewRequest && (
                <>
                  {showResponseSuccess ? (
                    <div className="card-body">
                      <div>
                        <p className="text-green-600 font-bold text-xl">
                          Request successfully sent.
                        </p>
                        <button
                          onClick={(e) => setShowResponseSuccess(false)}
                          className="mt-2 text-sky-600"
                        >
                          Return To Request Menu
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="card-body">
                      <form>
                        <p className="text-lg font-medium">
                          Recipient of Request:
                        </p>
                        <select
                          onChange={handleChangeRecipient}
                          value={selectedRecipientID + "-" + selectedIterator}
                          className="w-full rounded mb-3"
                        >
                          <option>Select A User</option>
                          {props.userList.map((userObject, i) => {
                            if (userObject.role === "Supervisor") {
                              return (
                                <option
                                  key={"id" + userObject.id}
                                  value={userObject.id + "-" + i}
                                >
                                  {toTitleCase(
                                    userObject.first + " " + userObject.last
                                  )}{" "}
                                  {props.selectedUserInfo.supervisor ===
                                    userObject.id && <>(Supervisor)</>}
                                </option>
                              );
                            }
                          })}
                        </select>
                        <p className="text-lg font-medium">
                          Reason for Feedback:
                        </p>
                        <textarea
                          required
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                          className="w-full rounded mb-2"
                          placeholder="Reason"
                        ></textarea>
                        <button
                          onClick={(e) => sendEmail(e)}
                          type="submit"
                          className="font-medium text-white m-1 px-2 py-1 rounded bg-green-900 hover:bg-green-700"
                        >
                          {!loading ? <>Send Request</> : <>Sending....</>}
                        </button>
                      </form>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {/* New Feedback */}
          <FeedbackForm
            selectedUserInfo={props.selectedUserInfo}
            setHRfeedback={setHRfeedback}
            setGotFeedback={setGotFeedback}
            selectedUserID={selectedUserID}
            feedbackUpdated={feedbackUpdated}
            setFeedbackUpdated={setFeedbackUpdated}
          />
        </div>
      </section>

      {/* Feedback History */}
      <section>
        <h1 className="text-2xl font-bold text-red-900">Feedback History</h1>
        <div className="p-3">
          <ul className="list">
            {gotFeedback === true && (
              <>
                {HRfeedback.map((feedback, i) => {
                  if (feedback.status !== "Deleted")
                    return (
                      <div key={"feedback-container" + i} className="mb-3 card">
                        <div className="card-header text-lg">
                          <button
                            onClick={(e) => {
                              HRfeedback[i].hideOnList =
                                !HRfeedback[i].hideOnList;
                              setFeedbackUpdated(feedbackUpdated + 1);
                            }}
                            className="font-bold mr-5 text-sky-600 capitalize"
                          >
                            Feedback {i + 1}
                          </button>
                          (Submitted {feedback.createdOn} by {feedback.author})
                          <span className="float-right">
                            {feedback.published ? (
                              <button
                                onClick={async (e) => {
                                  if (
                                    window.confirm(
                                      "You are choosing to unpublish a feedback, to continue, click OK. To Cancel, click Cancel."
                                    )
                                  ) {
                                    await updateDoc(
                                      doc(db, "hr-feedback", feedback.id),
                                      {
                                        published: false,
                                      }
                                    );
                                    getHRfeedback();
                                  } else {
                                    return;
                                  }
                                }}
                                className="text-green-600"
                              >
                                Published
                              </button>
                            ) : (
                              <button
                                onClick={async (e) => {
                                  if (
                                    window.confirm(
                                      "You are choosing to publish a feedback, to continue, click OK. To Cancel, click Cancel."
                                    )
                                  ) {
                                    await updateDoc(
                                      doc(db, "hr-feedback", feedback.id),
                                      {
                                        published: true,
                                      }
                                    );
                                    getHRfeedback();
                                  } else {
                                    return;
                                  }
                                }}
                                className="text-red-600"
                              >
                                Unpublished
                              </button>
                            )}
                          </span>
                        </div>
                        {HRfeedback[i]?.hideOnList ? (
                          <></>
                        ) : (
                          <div className="card-body text-lg">
                            <div className="card">
                              {/* Reason */}
                              <div className="card-header">
                                <h3 className="font-bold">Reason</h3>
                              </div>
                              <div className="card-body">
                                <p className="block px-11">
                                  {feedback?.reason}
                                </p>
                              </div>
                            </div>
                            {/* Constructives */}
                            <div className="card mt-3">
                              <div className="card-header">
                                <h3 className="font-bold">Constructives:</h3>
                              </div>
                              <div className="card-body">
                                <ol className="list-decimal pl-10">
                                  {feedback?.constructives.map(
                                    (constructive, i) => {
                                      return (
                                        <>
                                          <li className="test rounded shadow-md m-3">
                                            {constructive.description}
                                          </li>
                                        </>
                                      );
                                    }
                                  )}
                                </ol>
                              </div>
                            </div>
                            {/* Solutions */}
                            <div className="card mt-3">
                              <div className="card-header">
                                <h3 className="font-bold">Solutions:</h3>
                              </div>
                              <div className="card-body">
                                <ol className="list-decimal pl-10">
                                  {feedback?.solutions.map((solution, i) => {
                                    return (
                                      <>
                                        <li className="test rounded shadow-md m-3">
                                          {solution.description}
                                        </li>
                                      </>
                                    );
                                  })}
                                </ol>
                              </div>
                            </div>
                            {/* Positives */}
                            <div className="card mt-3">
                              <div className="card-header">
                                <h3 className="font-bold">Positives:</h3>
                              </div>
                              <div className="card-body">
                                <ol className="list-decimal pl-10">
                                  {feedback?.positives.map((positive, i) => {
                                    return (
                                      <>
                                        <li className="test rounded shadow-md m-3">
                                          {positive.description}
                                        </li>
                                      </>
                                    );
                                  })}
                                </ol>
                              </div>
                            </div>

                            <hr className="my-3" />
                            {/* Comment List */}
                            <h3 className="font-bold mt-2">Comments:</h3>
                            <ul className="list ml-2">
                              {feedback.replies?.map((reply, iterator) => {
                                return (
                                  <>
                                    {!reply.reply ? (
                                      <></>
                                    ) : (
                                      <li
                                        className="test rounded shadow-md m-3"
                                        key={"reply" + iterator}
                                      >
                                        <p className="italic">
                                          {reply.sender} on {reply.date}:
                                        </p>
                                        <p className="block">{reply.reply}</p>
                                        {auth.currentUser.uid ===
                                          reply.createdBy && (
                                          <>
                                            <button
                                              onClick={() => {
                                                HRfeedback[i].replies.splice(
                                                  iterator,
                                                  1
                                                );
                                                updateFeedback(
                                                  feedback.id,
                                                  HRfeedback[i].replies
                                                );
                                              }}
                                              className="text-xs m-1 text-red-900"
                                            >
                                              Delete Comment
                                            </button>
                                          </>
                                        )}
                                      </li>
                                    )}
                                  </>
                                );
                              })}
                            </ul>

                            {/* Add Reply */}
                            <div className="ml-2">
                              <hr className="my-2" />
                              <input
                                id={"reply" + i}
                                type="text"
                                className="border-2 border-red-900 p-1 w-full rounded"
                                placeholder="Enter your reply here..."
                              />
                              <button
                                onClick={(e) => {
                                  HRfeedback[i].replies.push({
                                    date: date,
                                    reply: document.getElementById("reply" + i)
                                      .value,
                                    sender: auth.currentUser.displayName,
                                    createdBy: auth.currentUser.uid,
                                  });
                                  updateFeedback(
                                    feedback.id,
                                    HRfeedback[i].replies
                                  );
                                  createNotification(feedback.createdBy, feedback.author, document.getElementById("reply" + i).value, feedback.employeeName);
                                  document.getElementById("reply" + i).value =
                                    "";
                                }}
                                className="block font-medium text-white my-1 px-2 py-1 rounded bg-green-900 hover:bg-green-700"
                              >
                                Add Comment
                              </button>
                            </div>
                            <hr />
                            {props.userInfo.admin ? (
                              <>
                                <button
                                  onClick={() => {
                                    deleteFeedback(feedback.id);
                                  }}
                                  className="m-1 text-red-900"
                                >
                                  Delete This Feedback
                                </button>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                      </div>
                    );
                })}
              </>
            )}
          </ul>
        </div>
      </section>
    </main>
  );
}

export default HumanResourcesFeedback;
