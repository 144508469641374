import React, { useEffect, useState } from "react";
import axios from "axios";
import { storage } from "../../firebase.js";
import { ref, uploadBytes } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateDoc,
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  addDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { v4 } from "uuid";
import "../app.css";
import { Container } from "react-bootstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function FeedbackRequest() {
  let { requestID } = useParams();
  // Criteria Modal Stuff
  let subtitle;
  const [criteriaModalIsOpen, setCriteriaModalIsOpen] = React.useState(false);

  function openCriteriaModal(e) {
    e.preventDefault();
    setCriteriaModalIsOpen(true);
  }

  function afterOpenCriteriaModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeCriteriaModal() {
    setCriteriaModalIsOpen(false);
  }

  // Other Stuff
  let navigate = useNavigate();

  // Date Stuff
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  const [constructives, setConstructives] = useState([
    {
      description: "",
    },
  ]);
  const [solutions, setSolutions] = useState([
    {
      description: "",
    },
  ]);
  const [positives, setPositives] = useState([
    {
      description: "",
    },
  ]);
  const [reason, setReason] = useState("");

  //   Form Handling
  const formData = {};

  const handleBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleSelectBlur = async (e) => {
    e.preventDefault();
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleBlurConstructive = async (e, i) => {
    console.log("Calculating New Premium");
    // e.preventDefault();
    const values = [...constructives];
    values[i].totalPremium = parseFloat(
      (
        values[i].premium +
        values[i].SLtax +
        values[i].companyFee +
        values[i].policyFee +
        values[i].SLstampFee
      ).toFixed(2)
    );
    console.log(formData);
    setConstructives(values);
    // if (IDfound === true) {
    //   setDoc(proposalRef, { ...formData, lastUpdated: date }, { merge: true });
    // }
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const handleChangeInputConstructive = (i, e) => {
    const values = [...constructives];
    values[i][e.target.name] = e.target.value;
    setConstructives(values);
  };

  const handleAddConstructive = (e) => {
    e.preventDefault();
    const i = constructives.length;
    if (i > 4) return;
    setConstructives([
      ...constructives,
      {
        description: "",
      },
    ]);
  };

  const handleSubtractConstructive = async (e, i) => {
    e.preventDefault();
    const values = [...constructives];
    values.splice(i, 1);
    setConstructives([...values]);
    if (values.length === 0) {
      setConstructives([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(proposalRef, { constructives: values });
  };

  const handleChangeInputSolution = (i, e) => {
    const values = [...solutions];
    values[i][e.target.name] = e.target.value;
    setSolutions(values);
  };

  const handleAddSolution = (e) => {
    e.preventDefault();
    const i = solutions.length;
    if (i > 4) return;
    setSolutions([
      ...solutions,
      {
        description: "",
      },
    ]);
  };

  const handleSubtractSolution = async (e, i) => {
    e.preventDefault();
    const values = [...solutions];
    values.splice(i, 1);
    setSolutions([...values]);
    if (values.length === 0) {
      setSolutions([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(proposalRef, { solutions: values });
  };

  const handleChangeInputPositive = (i, e) => {
    const values = [...positives];
    values[i][e.target.name] = e.target.value;
    setPositives(values);
  };

  const handleAddPositive = (e) => {
    e.preventDefault();
    const i = positives.length;
    if (i > 4) return;
    setPositives([
      ...positives,
      {
        description: "",
      },
    ]);
  };

  const handleSubtractPositive = async (e, i) => {
    e.preventDefault();
    const values = [...positives];
    values.splice(i, 1);
    setPositives([...values]);
    if (values.length === 0) {
      setPositives([
        {
          description: "",
        },
      ]);
    }
    // await updateDoc(proposalRef, { positives: values });
  };

  // Show or Hide
  const [showFeedbackForm, setShowFeedbackForm] = useState(true);

  //   Clear the form
  const clearForm = () => {
    setReason("");
    setConstructives([{ description: "" }]);
    setSolutions([{ description: "" }]);
    setPositives([{ description: "" }]);
  };

  //   Submit the feedback & close the form
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const submitFeedback = async (e) => {
    e.preventDefault();
    const docRef = await addDoc(
      collection(db, "hr-feedback"),
      {
        hideOnList: true,
        status: "Active",
        createdOn: new Date(),
        createdBy: auth.currentUser.uid,
        author: auth.currentUser.displayName,
        reason: requestData.reason,
        employeeID: requestData.employeeID,
        employeeName: requestData.employeeName,
        requestData,
        constructives,
        solutions,
        positives,
        replies: [],
      }
    );
    console.log("Document written with ID: ", docRef.id);
    sendEmail(e)
    // Log System Activity
    await addDoc(collection(db, "system-activity"), {
      activityDate: new Date(),
      authorID: auth.currentUser.uid,
      authorName: auth.currentUser.displayName,
      description:
        "Feedback Request Completed For " +
        requestData.employee +
        " by author " +
        requestData.reviewer,
    });

    // Set the "capital" field of the city 'DC'
    await updateDoc(doc(db, "feedback-requests", requestID), {
      status: "Complete",
    });
    clearForm();
    setShowFeedbackForm(false);
    alert("Feedback submitted.");
    navigate("/account");
  };

  const [showResponseSuccess, setShowResponseSuccess] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
    axios
      .post(
        "https://sstools-api-d4ca8.uc.r.appspot.com/send-email/licona/new-hr-submission",
        {
          to: "elpaso_csr@liconainsurance.com",
          cc: ["colt@southernstarmga.com", requestData.email],
          type: "Complete Feedback Request",
          author: auth.currentUser.displayName,
          createdOn: new Date().toLocaleString().split(",")[0],
          employeeName:
            requestData.employeeName,
          employeeID: requestData.employeeID,
          details: requestData.reason
        }
      )
      .then(async (response) => {
        // handle success
        })
      .then(() => {
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const [gotRequestData, setGotRequestData] = useState(false);
  const [requestData, setRequestData] = useState();
  const getRequestData = async () => {
    setGotRequestData(false);
    const docRef = doc(db, "feedback-requests", requestID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setRequestData(docSnap.data());
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
    setGotRequestData(true);
  };

  useEffect(() => {
    !gotRequestData && getRequestData();
  }, [showFeedbackForm, feedbackSubmitted]);

  return (
    <main>
      {gotRequestData && (
        <div className="card mt-3">
          <div className="card-header">
            <button
              onClick={(e) => {
                setShowFeedbackForm(!showFeedbackForm);
                setFeedbackSubmitted(false);
              }}
              className="text-xl font-bold text-sky-600"
            >
              Feedback For {requestData.employee}
            </button>
            {feedbackSubmitted && (
              <p className="p-2 w-full bg-green-200  font-bold mb-2">
                Your feedback was successfully submitted.
              </p>
            )}
          </div>
          {showFeedbackForm && (
            <div className="card-body">
              <p className="font-bold">Reason For Feedback Request: </p>
              <p className="ml-5 italic">{requestData.reason}</p>
              <p className="my-2">
                All fields are required. Please fill out feedback forms in a
                timely manner.
              </p>
              <form onSubmit={(e) => submitFeedback(e)}>
              {/* Constructives */}
              <section className="card bg-gray-100 m-3">
                <div className="card-header w-full">
                  <h2 className="font-bold text-2xl text-red-900">
                    Constructives
                  </h2>
                </div>
                <div className="card-body pl-10">
                  {constructives.map((constructive, i) => {
                    return (
                      <div>
                        <label className="input-label">
                          Constructive {i + 1}:
                        </label>
                        <input
                          required
                          onChange={(e) => handleChangeInputConstructive(i, e)}
                          onBlur={(e) => handleBlur(e)}
                          onKeyDown={handleEnter}
                          name="description"
                          className="form-control rounded"
                          placeholder="Constructive"
                          type="text"
                          value={constructive.description}
                        />
                        <button
                          onClick={(e) => handleSubtractConstructive(e, i)}
                          className="block mt-1 mb-2 sm text-red-600"
                        >
                          - Delete Constructive {i + 1}
                        </button>
                      </div>
                    );
                  })}
                </div>
                <button
                  onClick={handleAddConstructive}
                  className="block mt-1 mb-2 pl-5 text-sky-600 text-left"
                >
                  + Add A Constructive
                </button>
              </section>
              {/* Solutions */}
              <section className="card bg-gray-100 m-3">
                <div className="card-header w-full">
                  <h2 className="font-bold text-2xl text-red-900">Solutions</h2>
                </div>
                <div className="card-body pl-10">
                  {solutions.map((solution, i) => {
                    return (
                      <div>
                        <label className="input-label">Solution {i + 1}:</label>
                        <input
                          required
                          onChange={(e) => handleChangeInputSolution(i, e)}
                          onBlur={(e) => handleBlur(e)}
                          onKeyDown={handleEnter}
                          placeholder="Solution"
                          name="description"
                          className="form-control rounded"
                          type="text"
                          value={solution.description}
                        />
                        <button
                          onClick={(e) => handleSubtractSolution(e, i)}
                          className="block mt-1 mb-2 sm text-red-600"
                        >
                          - Delete Solution {i + 1}
                        </button>
                      </div>
                    );
                  })}
                </div>
                <button
                  onClick={handleAddSolution}
                  className="block mt-1 mb-2 pl-5 text-sky-600 text-left"
                >
                  + Add A Solution
                </button>
              </section>
              {/* Positives */}
              <section className="card bg-gray-100 m-3">
                <div className="card-header w-full">
                  <h2 className="font-bold text-2xl text-red-900">Positives</h2>
                </div>
                <div className="card-body pl-10">
                  {positives.map((positive, i) => {
                    return (
                      <div>
                        <label className="input-label">Positive {i + 1}:</label>
                        <input
                          required
                          onChange={(e) => handleChangeInputPositive(i, e)}
                          onBlur={(e) => handleBlur(e)}
                          onKeyDown={handleEnter}
                          placeholder="Positive"
                          name="description"
                          className="form-control rounded"
                          type="text"
                          value={positive.description}
                        />
                        <button
                          onClick={(e) => handleSubtractPositive(e, i)}
                          className="block mt-1 mb-2 sm text-red-600"
                        >
                          - Delete Positive {i + 1}
                        </button>
                      </div>
                    );
                  })}
                </div>
                <button
                  onClick={handleAddPositive}
                  className="block mt-1 mb-2 pl-5 text-sky-600 text-left"
                >
                  + Add A Positive
                </button>
              </section>
              <button
                type="submit"
                className="block font-medium text-white my-1 p-2 rounded bg-green-900 hover:bg-green-700"
              >
                Submit Feedback
              </button>
              <p className="text-sm">
                You may not edit feedback once it is submitted.
              </p>
              </form>
            </div>
          )}
        </div>
      )}
    </main>
  );
}

export default FeedbackRequest;
