import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import { auth, db } from "../../firebase";
import PTOhistory from "./PTOhistory";
import MultiDatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { Container, Row, Col } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import SickDayHistory from "./SickDayHistory";

export default function PTOPanel(props) {
  const selectedUserInfo = props.selectedUserInfo;
  const selectedUserID = props.selectedUserID;
  const setSelectedUserInfo = props.setSelectedUserInfo;
  const getSelectedUserInfo = props.getSelectedUserInfo;
  const userSelected = props.userSelected;
  const fixedData = props.fixedData;
  const setFixedData = props.setFixedData;
  const userInfo = props.userInfo;
  const setGotInfo = props.setGotInfo;
  const setDisplayPTORequestsData = props.setDisplayPTORequestsData;
  const setPTOadjustment = props.setPTOadjustment;
  const PTOadjustment = props.PTOadjustment;
  const [halfDay, setHalfDay] = useState("");
  // Date Stuff
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [manualDates, setManualDates] = useState();
  const [manualSickDates, setManualSickDates] = useState();

  const [dates, setDates] = useState([]);
  const [sickDates, setSickDates] = useState([]);
  const convertDates = () => {
    let t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    // t.setSeconds(secs);
    // return t.toDateString();
    for (let i = 0; i < manualDates.length; i++) {
      dates.push(new Date(manualDates[i]));
    }
    console.log("dates: " + dates);
  };

  const [sickDaysUsed, setSickDaysUsed] = useState();
  const [daysUsed, setDaysUsed] = useState(0);
  //   Get Selected User's PTO HIstory
  const [PTORequestsData, setPTORequestsData] = useState([]);
  const [gotPTOHistory, setGotPTOHistory] = useState(false);
  const [displayModifyPTO, setDisplayModifyPTO] = useState(false);
  const getPTOHistory = async () => {
    console.log("getting pto history");
    setPTORequestsData([]);
    setDaysUsed(0);
    setGotPTOHistory(false);
    const q = query(
      collection(db, "pto-requests"),
      where("requesterID", "==", selectedUserID)
    );

    const querySnapshot = await getDocs(q);
    let values = [];
    let daysUsedCount = 0;
    querySnapshot.forEach((doc) => {
      console.log(doc.data().dates);
      if (doc.data().status === "Approved") {
        if (doc.data().halfDay === "Yes") {
          daysUsedCount = daysUsedCount + 0.5;
        } else {
          daysUsedCount = daysUsedCount + doc.data().totalDays;
        }
        values.push({ ...doc.data(), id: doc.id });
      }
    });
    setPTORequestsData(values);
    setDaysUsed(daysUsedCount);
    setGotPTOHistory(true);
  };
  // Count Days Used
  const countDaysUsed = () => {};
  // PTO Tab Display
  const [displayModifySickDays, setDisplayModifySickDays] = useState(false);

  const [displayHistory, setDisplayHistory] = useState(false);

  const [sickDaysAdjustment, setSickDaysAdjustment] = useState();
  const [halfSickDay, setHalfSickDay] = useState("");
  const [displayUsersPTORequestsData, setDisplayUsersPTORequestsData] =
    useState(false);
    const [displayUsersSickDaysData, setDisplayUsersSickDaysData] =
    useState(false);
  const submitRequest = async (e) => {
    e.preventDefault();
    const manualPTORequestData = {
      author: auth.currentUser.displayName,
      requesterID: selectedUserID,
      requesterName: userInfo.first + " " + userInfo.last,
      halfDay: halfDay,
      requestDate: new Date(),
      reason: "Manual Entry by " + auth.currentUser.displayName,
      startDate: new Date(manualDates[0]),
      endDate: new Date(manualDates[1]),
      totalDays:
        (new Date(manualDates[1]).getTime() -
          new Date(manualDates[0]).getTime()) /
          (1000 * 3600 * 24) +
        1,
      status: "Approved",
    };
    if (
      ((new Date(manualDates[1]).getTime() -
        new Date(manualDates[0]).getTime()) /
        (1000 * 3600 * 24) +
        1 >
        1) &
      (halfDay === "Yes")
    )
      return alert(
        "You must enter half day requests one at a time. If you need to request full days and half days at the same time, submit a request for all full days you want off and another request for the half day."
      );
    const docRef = await addDoc(
      collection(db, "pto-requests"),
      manualPTORequestData
    );
    await addDoc(collection(db, "system-activity"), {
      activityDate: new Date(),
      authorID: auth.currentUser.uid,
      authorName: auth.currentUser.displayName,
      description:
        "A new manual PTO entry was entered for employee " +
        selectedUserInfo.first +
        " " +
        selectedUserInfo.last +
        " by " +
        auth.currentUser.displayName,
    });
    setDates([]);
    setManualDates();
    setDisplayPTORequestsData(false);
    setDisplayUsersPTORequestsData(false);
    setHalfDay("");
    getPTOHistory();
  };

  const adjustUsersTotalPTO = async (e) => {
    e.preventDefault();
    const totalPTO = selectedUserInfo.pto.total;
    const newTotalPTO = parseInt(totalPTO) + parseInt(PTOadjustment);
    setSelectedUserInfo({
      ...selectedUserInfo,
      pto: { ...selectedUserInfo.pto, total: newTotalPTO },
    });
    const userRef = doc(db, "users", selectedUserID);
    await setDoc(
      userRef,
      {
        ...selectedUserInfo,
        pto: { ...selectedUserInfo.pto, total: newTotalPTO },
      },
      { merge: true }
    );
    setGotInfo(false);
    setFixedData(false);
    getSelectedUserInfo();
    setPTOadjustment();
    console.log(selectedUserInfo);
  };

  const adjustUsersTotalSickDays = async (e) => {
    e.preventDefault();
    const totalPTO = selectedUserInfo.sickDays.total;
    const newTotalPTO = parseInt(totalPTO) + parseInt(PTOadjustment);
    setSelectedUserInfo({
      ...selectedUserInfo,
      sickDays: { ...selectedUserInfo.sickDays, total: newTotalPTO },
    });
    const userRef = doc(db, "users", selectedUserID);
    await setDoc(
      userRef,
      {
        ...selectedUserInfo,
        sickDays: { ...selectedUserInfo.sickDays, total: newTotalPTO },
      },
      { merge: true }
    );
    setGotInfo(false);
    setFixedData(false);
    getSelectedUserInfo();
    setPTOadjustment();
    console.log(selectedUserInfo);
  };
  const [sickReason, setSickReason] = useState();
  const [noteProvided, setNoteProvided] = useState("");
  const submitSickDays = async (e) => {
    e.preventDefault();
    const manualPTORequestData = {
      author: auth.currentUser.displayName,
      requesterID: selectedUserID,
      requesterName: userInfo.first + " " + userInfo.last,
      halfSickDay,
      requestDate: new Date(),
      noteProvided,
      reason: sickReason,
      startDate: new Date(manualSickDates[0]),
      endDate: new Date(manualSickDates[1]),
      totalDays:
        (new Date(manualSickDates[1]).getTime() -
          new Date(manualSickDates[0]).getTime()) /
          (1000 * 3600 * 24) +
        1,
      status: "Approved",
    };
    if (
      ((new Date(manualSickDates[1]).getTime() -
        new Date(manualSickDates[0]).getTime()) /
        (1000 * 3600 * 24) +
        1 >
        1) &
      (halfSickDay === "Yes")
    )
      return alert(
        "You must enter half day requests one at a time. If you need to request full days and half days at the same time, submit a request for all full days you want off and another request for the half day."
      );
    const docRef = await addDoc(
      collection(db, "sickDays-requests"),
      manualPTORequestData
    );
    await addDoc(collection(db, "system-activity"), {
      activityDate: new Date(),
      authorID: auth.currentUser.uid,
      authorName: auth.currentUser.displayName,
      description:
        "A new Sick Day entry was entered for employee " +
        selectedUserInfo.first +
        " " +
        selectedUserInfo.last +
        " by " +
        auth.currentUser.displayName,
    });
    setSickDates([]);
    setManualSickDates();
    setDisplayPTORequestsData(false);
    setDisplayUsersPTORequestsData(false);
    setHalfSickDay("");
    setNoteProvided("");
    setSickReason();
    getPTOHistory();
  };

  useEffect(() => {
    userSelected && !gotPTOHistory && getPTOHistory();
    gotPTOHistory && countDaysUsed();
  }, [daysUsed, gotPTOHistory]);

  return (
    <div>
      <h1 className="text-2xl text-red-900 font-bold">Paid Time Off</h1>
      <p className="block text-lg">
        PTO Reset Date:
        <span className="text-black font-medium">
          {/* Date Shortened to Jun 01 */}
          {new Date(selectedUserInfo.hireDate).toDateString().slice(3, 10)}
        </span>
      </p>
      <Table className="data-table mt-2">
        <Thead>
          <Tr>
            <Td className="bg-red-900 text-white">Total PTO</Td>
            <Td className="bg-red-900 text-white">PTO Used</Td>
            <Td className="bg-red-900 text-white">PTO Remaining</Td>
            <Td className="bg-red-900 text-white">Sick Days Used</Td>
            <Td className="bg-red-900 text-white">Sick Days Remaining</Td>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{selectedUserInfo?.pto?.total} days</Td>
            <Td>{daysUsed} days</Td>
            <Td>{selectedUserInfo?.pto?.total - daysUsed} days</Td>
            <Td>{selectedUserInfo?.sickDays?.used}</Td>
            <Td></Td>
          </Tr>
        </Tbody>
      </Table>
      {/* Selected User PTO Request History */}
      <PTOhistory
        getPTOHistory={getPTOHistory}
        selectedUserInfo={props.selectedUserInfo}
        setDisplayUsersPTORequestsData={setDisplayUsersPTORequestsData}
        displayUsersPTORequestsData={displayUsersPTORequestsData}
        daysUsed={daysUsed}
        setDaysUsed={setDaysUsed}
        userInfo={userInfo}
        selectedUserID={selectedUserID}
      />
      {/* Selected User Sick Day History */}
      <SickDayHistory
        getPTOHistory={getPTOHistory}
        selectedUserInfo={props.selectedUserInfo}
        setDisplayUserSickDayssData={setDisplayUsersSickDaysData}
        displayUsersSickDaysData={displayUsersSickDaysData}
        sickDaysUsed={sickDaysUsed}
        setSickDaysUsed={setSickDaysUsed}
        userInfo={userInfo}
        selectedUserID={selectedUserID}
      />

      {/* Modify PTO */}
      {userInfo.admin && (
        <div className="card mb-3 mt-3">
          <div className="card-header bg-gray-200">
            <button
              onClick={(e) => setDisplayModifyPTO(!displayModifyPTO)}
              className="text-lg font-bold"
            >
              Modify {selectedUserInfo?.first} {selectedUserInfo?.last}
              's Time Off
            </button>
          </div>
          {displayModifyPTO && (
            <div className="card-body">
              <Container fluid>
                <Row>
                  <Col sm={4}>
                    <p className="font-bold text-lg my-1">Manual PTO Entry</p>
                    <p className="mr-5">
                      Manual PTO entries may be submitted for this user by
                      entering the dates in the field below, and clicking the
                      "Submit Entry" button. Manual entries will automatically
                      be set to approved, the reason will be set to "Manual
                      Entry".
                    </p>
                    <form onSubmit={(e) => submitRequest(e)}>
                      <label className="d-block mt-1">Dates:</label>
                      <DatePicker
                        value={manualDates}
                        onChange={setManualDates}
                        range
                      />
                      <p className="my-1">Is this a half day?</p>
                      <select
                        required
                        className="rounded p-2 w-75"
                        value={halfDay}
                        onChange={(e) => setHalfDay(e.target.value)}
                      >
                        <option value="">Choose One</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <button className="d-block py-2 px-3 rounded bg-red-900 text-white w-75 mt-3">
                        Submit Entry
                      </button>
                    </form>
                  </Col>
                  <Col sm={4} className="position-relative">
                    <p className="font-bold text-lg my-1">Adjust Total PTO</p>
                    <p className="mr-5">
                      To adjust a user's total PTO, enter the number of days you
                      would like to increase or decrease the PTO by in the
                      textbox below and click the Adjust PTO button. Negative
                      numbers will deduct days from the user's total PTO.
                    </p>
                    <form onSubmit={(e) => adjustUsersTotalPTO(e)}>
                      <input
                        required
                        type="number"
                        value={PTOadjustment}
                        onChange={(e) => setPTOadjustment(e.target.value)}
                        className="my-3 rounded p-2 w-75"
                      />
                      <button className="d-block py-2 px-3 rounded bg-red-900 text-white w-75">
                        Adjust PTO
                      </button>
                    </form>
                  </Col>
                  <Col sm={4} className="position-relative">
                    <p className="font-bold text-lg my-1">Reset PTO</p>
                    <p className="mr-5">
                      PTO resets must be done manually. If you are ready to
                      reset a user's PTO because the reset date has passed and
                      you have no other need for the data, you may reset the PTO
                      using the button below.
                    </p>
                    <button className="d-block py-2 px-3 rounded bg-red-900 text-white w-75 mt-5">
                      Reset PTO
                    </button>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </div>
      )}

      {/* Modify Sick Day */}
      {userInfo.admin && (
        <div className="card mb-3 mt-3">
          <div className="card-header bg-gray-200">
            <button
              onClick={(e) => setDisplayModifySickDays(!displayModifySickDays)}
              className="text-lg font-bold"
            >
              Modify {selectedUserInfo?.first} {selectedUserInfo?.last}
              's Sick Days
            </button>
          </div>
          {displayModifySickDays && (
            <div className="card-body">
              <Container fluid>
                <Row>
                <Col sm={6}>
                    <p className="font-bold text-lg my-1">Manual Sick Day Entry</p>
                    <p className="mr-5">
                      Manual Sick Day entries may be submitted for this user by
                      entering the dates in the field below, and clicking the
                      "Submit Entry" button. Manual entries will automatically
                      have their reason set to "Manual Entry".
                    </p>
                    <form onSubmit={(e) => submitSickDays(e)}>
                      <label className="d-block mt-1">Dates:</label>
                      <DatePicker
                        value={manualSickDates}
                        onChange={setManualSickDates}
                        range
                      />
                      <p className="my-1">Is this a half day?</p>
                      <select
                        required
                        className="rounded p-2 w-full"
                        value={halfSickDay}
                        onChange={(e) => setHalfSickDay(e.target.value)}
                      >
                        <option value="">Choose One</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <p className="my-1">Reason For Absence:</p>
                      <textarea onChange={(e) => setSickReason(e.target.value)} value={sickReason} className="w-full rounded" />
                                            <p className="my-1">Was a doctors note provided?</p>
                      <select
                        required
                        className="rounded p-2 w-full"
                        value={noteProvided}
                        onChange={(e) => setNoteProvided(e.target.value)}
                      >
                        <option value="">Choose One</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <button className="d-block py-2 px-3 rounded bg-red-900 text-white w-full mt-3">
                        Submit Entry
                      </button>
                    </form>
                  </Col>
                  <Col sm={6} className="position-relative">
                    <p className="font-bold text-lg my-1">Sick Day Resets</p>
                    <p className="mr-5">
                      Sick Day resets must be done manually. If you are ready to
                      reset a user's Sick Day because the reset date has passed and
                      you have no other need for the data, you may reset the Sick Day
                      using the button below.
                    </p>
                    <button className="d-block py-2 px-3 rounded bg-red-900 text-white w-75 mt-5 mx-auto">
                      Reset Sick Days
                    </button>
                  </Col>

                </Row>
              </Container>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
