import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  setDoc,
  addDoc,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import DatePicker from "react-multi-date-picker";
import { table, Thead, Tbody, tr, td } from "react-super-responsive-table";
import "./app.css";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import HumanResourcesNotes from "./hr/HumanResourcesNotes";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Applicant(props) {
  // Date Stuff
  function toDateTime(secs) {
    var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    t.setSeconds(secs);
    return t.toDateString();
  }

  // NextStep Modal Stuff
  let subtitle;
  const [nextStepModalIsOpen, setNextStepModalIsOpen] = React.useState(false);

  function openNextStepModal(e) {
    e.preventDefault();
    setNextStepModalIsOpen(true);
  }

  function afterOpenNextStepModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeNextStepModal() {
    setNextStepModalIsOpen(false);
  }

  const id = props.id;

  //   Get Selected User's Info
  const [applicantInfo, setApplicantInfo] = useState([]);
  const [gotInfo, setGotInfo] = useState(false);
  const getApplicantInfo = async (applicantID) => {
    setGotInfo(false);
    const docRef = doc(db, "quote-requests", applicantID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setApplicantInfo(docSnap.data());
      console.log("Document data:", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
    setGotInfo(true);
  };

  // Edit Next Step
  const [newNextStep, setNewNextStep] = useState("");
  const editNextStep = (e, applicantID) => {
    e.preventDefault();
    const applicantRef = doc(db, "applicants", applicantID);
    setDoc(applicantRef, { nextStep: newNextStep }, { merge: true });
    applicantInfo.nextStep = newNextStep;
    closeNextStepModal();
    setNewNextStep("");
  };

  // Edit Status Modal Stuff
  const [editStatusModalIsOpen, setEditStatusModalIsOpen] =
    React.useState(false);

  function openEditStatusModal(e) {
    // e.preventDefault();
    setEditStatusModalIsOpen(true);
  }

  function afterOpenEditStatusModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeEditStatusModal() {
    setEditStatusModalIsOpen(false);
  }

  // Edit Status
  const [newStatus, setNewStatus] = useState("");
  const editStatus = (e, applicantID) => {
    e.preventDefault();
    const applicantRef = doc(db, "applicants", applicantID);
    setDoc(applicantRef, { status: newStatus }, { merge: true });
    applicantInfo.status = newStatus;
    closeEditStatusModal();
    setNewStatus("");
  };

  // Edit BestFit Modal Stuff
  const [editBestFitModalIsOpen, setBestFitModalIsOpen] = React.useState(false);

  function openBestFitModal(e) {
    // e.preventDefault();
    setBestFitModalIsOpen(true);
  }

  function afterOpenBestFitModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeBestFitModal() {
    setBestFitModalIsOpen(false);
  }

  // Edit Best Fit
  const [newBestFit, setNewBestFit] = useState("");
  const editBestFit = (e, applicantID) => {
    e.preventDefault();
    const applicantRef = doc(db, "applicants", applicantID);
    setDoc(applicantRef, { bestFit: newBestFit }, { merge: true });
    applicantInfo.bestFit = newBestFit;
    closeBestFitModal();
    setNewBestFit("");
  };

  // Edit New Step Modal Stuff
  const [editNewStepModalIsOpen, setNewStepModalIsOpen] = React.useState(false);

  function openNewStepModal(e) {
    // e.preventDefault();
    setNewStepModalIsOpen(true);
  }

  function afterOpenNewStepModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeNewStepModal() {
    setNewStepModalIsOpen(false);
  }

  // Add New Step
  const [newStepTitle, setNewStepTitle] = useState("");
  const [newStepText, setNewStepText] = useState("");
  const [stepUpdated, setStepUpdated] = useState(false);

  const addNewStep = async (e) => {
    e.preventDefault();
    const oldLength = stepList.length;
    const docRef = await addDoc(collection(db, "hiring-steps"), {
      place: oldLength + 1,
      name: newStepTitle,
      description: newStepText,
      questions: [""],
    });
    console.log("Document written with ID: ", docRef.id);
    closeNewStepModal();
    setNewStepTitle("");
    setNewStepText("");
    getSteps();
  };

  const [gotSteps, setGotSteps] = useState(false);
  const [stepList, setStepList] = useState([]);
  const [selectedStep, setSelectedStep] = useState("1oMhI1n9fXKG5lkhEQZz");
  const getSteps = async () => {
    setGotSteps(false);
    const data = await getDocs(
      query(collection(db, "hiring-steps"), orderBy("place"))
    );
    setStepList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setGotSteps(true);
  };

  const handleChangeStep = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setSelectedStep(e.target.value);
    setStepUpdated(false);
  };

  const [customQuestions, setCustomQuestions] = useState([
    { description: "", answer: "" },
  ]);
  const [gotCustomQuestions, setGotCustomQuestions] = useState(false);
  const [showCustomQuestions, setShowCustomQuestions] = useState(true);
  const getCustomQuestions = () => {
    const entries = Object.entries(applicantInfo);
    console.log(entries);
    const filteredEntries = entries.filter((entry) => {
      return entry[0].startsWith("Custom-Question:");
    });
    filteredEntries.forEach((entry, i) => {
      const newEntry = entry[0].slice(17);
      console.log(newEntry);
      filteredEntries[i][0] = newEntry;
    });
    console.log(filteredEntries);
    setCustomQuestions(filteredEntries);
    setGotCustomQuestions(true);
  };

  const removePrefix = (question) => {};

  useEffect(() => {
    if (gotInfo === false) getApplicantInfo(id);
    if (gotInfo === true) getCustomQuestions();
  }, [db, gotInfo, gotSteps, id]);

  return (
    <>
      <h2 className="text-red-900 font-bold text-xl mt-2 mb-3">
        Quote Request Details
      </h2>
      <Container className="mx-auto">
        {/* Applicant Information */}
        <Row className="mt-1 p-3 bg-gray-200 rounded">
          <p className="block font-bold text-xl mb-2">
            {applicantInfo.first + " " + applicantInfo.last}
          </p>
          <Col>
            <h2 className="font-bold mb-2">Personal/Vehicle Information:</h2>
            <table className="mb-3 w-full">
              <tr>
                <td>Email:</td>
                <td>{applicantInfo.email}</td>
              </tr>
              <tr>
                <td>Primary Phone:</td>
                <td>{applicantInfo.primaryPhone}</td>
              </tr>
              <tr>
                <td>Alternate Phone:</td>
                <td>{applicantInfo.alternatePhone}</td>
              </tr>
              <tr>
                <td>Street Address:</td>
                <td>{applicantInfo.address}</td>
              </tr>
              <tr>
                <td>City, State, Zip</td>
                <td>
                  {applicantInfo.city}
                  {', '}
                  {applicantInfo.state}
                  {', '}
                  {applicantInfo.zip}
                </td>
              </tr>
              <tr>
                <td>Date of Birth:</td>
                <td>{applicantInfo.dob}</td>
              </tr>
              <tr>
                <td>DL Number:</td>
                <td>{applicantInfo.dlNumber}</td>
              </tr>
              <tr>
                <td>Gender:</td>
                <td>{applicantInfo.gender}</td>
              </tr>
              <tr>
                <td>Marital Status:</td>
                <td>{applicantInfo.maritalStatus}</td>
              </tr>
            </table>
          </Col>
          <Col>
            <h2 className="font-bold mb-2">Vehicle Information:</h2>
            <table className="mb-3 w-full">
              <tr>
                <td>Vehicle Year:</td>
                <td>{applicantInfo.vehicleYear}</td>
              </tr>
              <tr>
                <td>Vehicle Make:</td>
                <td>{applicantInfo.vehicleMake}</td>
              </tr>
              <tr>
                <td>Vehicle Model:</td>
                <td>{applicantInfo.vehicleModel}</td>
              </tr>
              <tr>
                <td>Vehicle Cylinders:</td>
                <td>{applicantInfo.vehicleCylinders}</td>
              </tr>
              <tr>
                <td>Vehicle VIN</td>
                <td>{applicantInfo.vin}</td>
              </tr>
            </table>
            <h2 className="font-bold mb-2">Discounts:</h2>
            <table className="mb-3 w-full">
              <tr>
                <td>Homeowner:</td>
                <td>{applicantInfo.homeowner}</td>
              </tr>
              <tr>
                <td>Rental Insurance:</td>
                <td>{applicantInfo.rentalInsurance}</td>
              </tr>
              <tr>
                <td>Auto Insurance:</td>
                <td>{applicantInfo.currentlyInsured}</td>
              </tr>
              <tr>
                <td>Major Violations (5 yrs):</td>
                <td>{applicantInfo.majorViolationsFiveYears}</td>
              </tr>
              <tr>
                <td>Accidents/Minor Violations (3 yrs)</td>
                <td>{applicantInfo.accidentsOrViolationsThreeYears}</td>
              </tr>
              <tr>
                <td>Claims (3 yrs)</td>
                <td>{applicantInfo.claimsThreeYears}</td>
              </tr>
            </table>
          </Col>
          <Col>
            <h2 className="font-bold mb-2">Coverages:</h2>
            <table className="mb-3 w-full">
            <tr>
                <td>Deductible:</td>
                <td>{applicantInfo.deductible}</td>
              </tr>
              <tr>
                <td>Bodily Injury:</td>
                <td>{applicantInfo.bodilyInjury}</td>
              </tr>
              <tr>
                <td>Property Damage Liability:</td>
                <td>{applicantInfo.propertyDamageLiability}</td>
              </tr>
              <tr>
                <td>Uninsured/Underinsured Motorist Bodily Inury:</td>
                <td>{applicantInfo.uninsuredBI}</td>
              </tr>
              <tr>
                <td>Uninsured/Underinsured Motorist Property Damage:</td>
                <td>{applicantInfo.uninsuredPD}</td>
              </tr>
              <tr>
                <td>Personal Injury Plan (PIP):</td>
                <td>{applicantInfo.pip}</td>
              </tr>
              <tr>
                <td>Medical Pay:</td>
                <td>{applicantInfo.medicalPay}</td>
              </tr>
              <tr>
                <td>Towing:</td>
                <td>{applicantInfo.towingCoverage}</td>
              </tr>
              <tr>
                <td>Rental Reimbursement:</td>
                <td>{applicantInfo.rentalReimbursement}</td>
              </tr>
            </table>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h2 className="text-red-900 font-bold text-lg mb-2">Notes:</h2>
            <HumanResourcesNotes selectedUserID={id}></HumanResourcesNotes>
          </Col>
        </Row>
      </Container>
      {/* Next Step Modal */}
      <div>
        <Modal
          isOpen={nextStepModalIsOpen}
          onAfterOpen={afterOpenNextStepModal}
          onRequestClose={closeNextStepModal}
          style={customStyles}
          contentLabel="Add NextStep"
        >
          <div>Update Next Step</div>
          <label className="block">New Next Step:</label>
          <input
            type="text"
            onChange={(e) => setNewNextStep(e.target.value)}
            value={newNextStep}
          />
          <button className="block" onClick={(e) => editNextStep(e, id)}>
            Submit
          </button>
          <button onClick={(e) => closeNextStepModal(e)}>Cancel</button>
        </Modal>
      </div>
      {/* Edit Status Modal */}
      <div>
        <Modal
          isOpen={editStatusModalIsOpen}
          onAfterOpen={afterOpenEditStatusModal}
          onRequestClose={closeEditStatusModal}
          style={customStyles}
          contentLabel="Edit Status"
        >
          <div>Update Status</div>
          <label className="block">New Status:</label>
          <input
            type="text"
            onChange={(e) => setNewStatus(e.target.value)}
            value={newStatus}
          />
          <button className="block" onClick={(e) => editStatus(e, id)}>
            Submit
          </button>
          <button onClick={(e) => closeEditStatusModal(e)}>Cancel</button>
        </Modal>
      </div>
      {/* Best Fit Modal */}
      <div>
        <Modal
          isOpen={editBestFitModalIsOpen}
          onAfterOpen={afterOpenBestFitModal}
          onRequestClose={closeBestFitModal}
          style={customStyles}
          contentLabel="Edit Best Fit"
        >
          <div>Update BestFit</div>
          <label className="block">New Best Fit:</label>
          <input
            type="text"
            onChange={(e) => setNewBestFit(e.target.value)}
            value={newBestFit}
          />
          <button className="block" onClick={(e) => editBestFit(e, id)}>
            Submit
          </button>
          <button onClick={(e) => closeBestFitModal(e)}>Cancel</button>
        </Modal>
      </div>

      {/* New Question Modal */}
      <div>
        <Modal
          isOpen={editNewStepModalIsOpen}
          onAfterOpen={afterOpenNewStepModal}
          onRequestClose={closeNewStepModal}
          style={customStyles}
          contentLabel="Add A Step"
        >
          <div>Add A Step</div>
          <label className="block">Step Name:</label>
          <input
            type="text"
            onChange={(e) => setNewStepTitle(e.target.value)}
            value={newStepTitle}
          />
          <label className="block">Description:</label>

          <textarea
            onChange={(e) => setNewStepText(e.target.value)}
            value={newStepText}
          />
          <button className="block" onClick={(e) => addNewStep(e)}>
            Submit
          </button>
          <button onClick={(e) => closeNewStepModal(e)}>Cancel</button>
        </Modal>
      </div>
    </>
  );
}

export default Applicant;
