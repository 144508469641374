import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { UserAuth } from "../../Context/AuthContent";

export default function AdminPanel() {
  const { user } = UserAuth();
  const userID = user.uid;
  const [gotRole, setGotRole] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const getRole = async (usersID) => {
    setGotRole(false);
    console.log("Getting User Info & Checking Role");
    const docRef = doc(db, "users", usersID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUserInfo(docSnap.data());
      setGotRole(true);
    } else {
      // doc.data() will be undefined in this case
      console.log("No role info found!");
      return null;
    }
  };

  useEffect(() => {
    userID && !gotRole && getRole(userID);
  }, [gotRole, userID]);

  return (
    <main>
      {gotRole && userInfo.admin ? (
        <section>
          Coming Soon...
          <ul>
            <li>Edit Recipients For Notifications</li>
            <li>Edit Departments</li>
          </ul>
        </section>
      ) : (
        <p>You must be an administrator to view this page.</p>
      )}
    </main>
  );
}
