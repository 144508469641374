import React, { useEffect, useState } from "react";
import {
  getDocs,
  addDoc,
  collection,
  query,
  where,
  deleteDoc,
  doc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { Col, Container, Row } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../app.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { filter } from "lodash";

function ManageAnnouncements(props) {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [dateInput, setDateInput] = useState(new Date());
  const newAnnouncement = {
    dateCreated: new Date(),
    authorName: props.name,
    authorID: props.userID,
    subject: subject,
    message: message,
    expiration: dateInput,
  };

  const [announcementList, setAnnouncementList] = useState([]);
  const [gotAnnouncements, setGotAnnouncements] = useState(false);
  const today = new Date();
  const todayInSeconds = today.getTime() / 1000;
  const getAnnouncements = async () => {
    console.log("Getting Announcements");
    setGotAnnouncements(false);

    const q = query(collection(db, "announcements"));
    const queryData = await getDocs(q);
    setAnnouncementList(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
    setGotAnnouncements(true);
  };

  const submitAnnouncement = async () => {
    // Add a new document with a generated id.
    await addDoc(collection(db, "announcements"), newAnnouncement);
    getAnnouncements();
    setDisplayNewAnnouncement(false);
    setMessage("");
    setSubject("");
    setDateInput(new Date());
    // Log System Activity
    await addDoc(collection(db, "system-activity"), {
      activityDate: new Date(),
      authorID: auth.currentUser.uid,
      authorName: auth.currentUser.displayName,
      description:
        "New Announcement Created by: " + auth.currentUser.displayName,
    });
  };

  const deleteAnnouncement = async (docID) => {
    let verify = prompt(
      "You are about to delete an Announcement! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "announcements", docID));
      getAnnouncements();
      // Log System Activity
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          "An announcement was deleted by: " + auth.currentUser.displayName,
      });
    }
  };

  const [feedbackRequestList, setFeedbackRequestList] = useState([]);
  const [gotFeedbackRequests, setGotFeedbackRequests] = useState(false);
  const getFeedbackRequests = async () => {
    console.log("Getting Feedback Requests");
    setGotFeedbackRequests(false);

    const q = query(
      collection(db, "feedback-requests"),
      where("reviewerID", "==", props.userID)
    );
    const queryData = await getDocs(q);
    setFeedbackRequestList(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
    setGotFeedbackRequests(true);
  };
  const deleteFeedbackRequest = async (docID) => {
    let verify = prompt(
      "You are about to delete a Feedback Request! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "feedback-requests", docID));
      getFeedbackRequests();
      // Log System Activity
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          "A feedback request was deleted by: " + auth.currentUser.displayName,
      });
    }
  };

  const [notificationList, setNotificationList] = useState([]);
  const [gotNotifications, setGotNotifications] = useState(false);
  const getNotifications = async () => {
    console.log("Getting Notifications");
    setGotNotifications(false);

    const q = query(
      collection(db, "notifications"),
      where("recipient", "==", props.userID)
    );
    const queryData = await getDocs(q);
    setNotificationList(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
    setGotNotifications(true);
  };
  const deleteNotification = async (docID) => {
    await deleteDoc(doc(db, "notifications", docID));
    getNotifications();
  };

  const [userFeedbackList, setUserFeedbackList] = useState([]);
  const [gotUserFeedback, setGotUserFeedback] = useState(false);
  const getUserFeedback = async () => {
    console.log("Getting Feedback Requests");
    setGotUserFeedback(false);

    const q = query(
      collection(db, "hr-feedback"),
      where("employee", "==", props.userID)
    );
    const queryData = await getDocs(q);
    setUserFeedbackList(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
    setGotUserFeedback(true);
  };
  const deleteUserFeedback = async (docID) => {
    let verify = prompt(
      "You are about to delete a Feedback Request! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "feedback-requests", docID));
      getUserFeedback();
      // Log System Activity
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          "A feedback request was deleted by: " + auth.currentUser.displayName,
      });
    }
  };

  const [trainingRequestsList, setTrainingRequestsList] = useState([]);
  const [gotTrainingRequests, setGotTrainingRequests] = useState(false);
  const getTrainingRequests = async () => {
    console.log("Getting Training Requests");
    setGotTrainingRequests(false);

    const q = query(
      collection(db, "training-requests")
      // where("employee", "==", props.userID)
    );
    const queryData = await getDocs(q);
    setTrainingRequestsList(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
    setGotTrainingRequests(true);
  };
  const deleteTrainingRequests = async (docID) => {
    let verify = prompt(
      "You are about to delete a Training Request! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "training-requests", docID));
      getTrainingRequests();
      // Log System Activity
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          "A training request was deleted by: " + auth.currentUser.displayName,
      });
    }
  };

  const [PTORequestList, setPTORequestList] = useState([]);
  const [gotPTORequestList, setGotPTORequestList] = useState(false);
  const getPTORequests = async () => {
    console.log("Getting PTO Requests");
    setGotPTORequestList(false);

    const q = query(
      collection(db, "pto-requests"),
      where("supervisor", "==", props.userID)
    );
    const queryData = await getDocs(q);
    setPTORequestList(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
    setGotPTORequestList(true);
  };

  const deletePTORequest = async (docID) => {
    let verify = prompt(
      "You are about to delete a Time Off Request! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "pto-requests", docID));
      getTrainingRequests();
      // Log System Activity
      await addDoc(collection(db, "system-activity"), {
        activityDate: new Date(),
        authorID: auth.currentUser.uid,
        authorName: auth.currentUser.displayName,
        description:
          "A PTO request was deleted by: " + auth.currentUser.displayName,
      });
    }
  };

  const [displayNewAnnouncement, setDisplayNewAnnouncement] = useState(false);

  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toDateString();
  };

  useEffect(() => {
    !gotAnnouncements && getAnnouncements();
    !gotFeedbackRequests && getFeedbackRequests();
    if (gotFeedbackRequests) {
      props.setUnpublishedFeedbackRequests(
        feedbackRequestList.filter((e) => !e.published).length
      );
      props.setPendingFeedbackRequests(
        feedbackRequestList.filter((e) => e.status === "Incomplete").length
      );
    }
    props.setUnpublishedFeedbackRequests(
      feedbackRequestList.filter((e) => !e.published).length
    );
    !gotUserFeedback && getUserFeedback();
    !gotTrainingRequests && getTrainingRequests();
    !gotPTORequestList && getPTORequests();
    !gotNotifications && getNotifications();
  }, [
    db,
    gotAnnouncements,
    gotFeedbackRequests,
    gotUserFeedback,
    gotTrainingRequests,
    gotPTORequestList,
    gotNotifications
  ]);

  return (
    <main className="max-w-[800px] mx-auto">
      <h1 className="text-red-900 text-2xl font-bold pt-3 mb-2">
        Announcements
      </h1>
      {props.userInfo.admin && (
        <>
          {displayNewAnnouncement === false && (
            <button
              className="text-sky-600 mb-3"
              onClick={(e) => setDisplayNewAnnouncement(true)}
            >
              Add An Announcement
            </button>
          )}
        </>
      )}
      {displayNewAnnouncement === true && (
        <section className="bg-gray-200 p-3 rounded-md mb-5">
          <h2 className="text-xl font-medium mb-2">New Announcement</h2>
          <label className="input-label">Subject:</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="text-input w-full"
          />
          <label className="input-label">Message:</label>
          <textarea
            className="text-input w-full"
            placeholder="Type your new announcement here..."
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
          <label className="input-label">Expiration:</label>
          <DatePicker
            selected={dateInput}
            onChange={(date: Date) => {
              setDateInput(date);
            }}
            className={`text-input  w-full`}
          />
          <button
            onClick={() => {
              setMessage("");
              setSubject("");
              setDateInput(new Date());
              setDisplayNewAnnouncement(false);
            }}
            className="button-1"
          >
            Cancel
          </button>
          <button onClick={submitAnnouncement} className="button-2">
            Submit
          </button>
        </section>
      )}

      <ul>
        {notificationList.map((notification, i) => {
          if (!notification.dismissedByUser)
            return (
              <li key={"notification" + i} className="mb-3">
                <Card>
                  <Card.Body>
                    <Card.Text>
                      <p className="mt-2">{notification.description}</p>
                    </Card.Text>
                  </Card.Body>
                  {props.userInfo.admin && (
                    <Card.Footer>
                      <button
                        onClick={(e) => deleteNotification(notification.id)}
                        className="text-red-600"
                      >
                        Delete Notification
                      </button>
                    </Card.Footer>
                  )}
                </Card>
              </li>
            );
        })}

        {feedbackRequestList.map((feedbackRequest, i) => {
          if (feedbackRequest.status === "Incomplete")
            return (
              <li key={"feedbackRequest" + i} className="mb-3">
                <Card>
                  <Card.Header className="bg-red-300">
                    <span className="font-bold text-lg">Feedback Request </span>
                    {feedbackRequest.authorName}{" "}
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>{feedbackRequest.employeeName}</Card.Title>
                    <Card.Text>
                      <p className="mt-2">
                        You have received a request to submit feedback for{" "}
                        {feedbackRequest.employeeName} for the following reason:
                      </p>
                      <p className="ml-5 italic">{feedbackRequest.reason}</p>
                      <p className="my-3">
                        Please complete feedback requests in a timely manner.
                        Click the button below to complete the feedback request
                        now.
                      </p>
                      <a
                        href={"/feedback-request/" + feedbackRequest.id}
                        className="mt-2 rounded py-2 px-3 bg-sky-600 hover:bg-sky-900 text-white font-[600]"
                      >
                        Submit Feedback
                      </a>
                    </Card.Text>
                  </Card.Body>
                  {props.userInfo.admin && (
                    <Card.Footer>
                      <button
                        onClick={(e) =>
                          deleteFeedbackRequest(feedbackRequest.id)
                        }
                        className="text-red-600"
                      >
                        Delete This Request
                      </button>
                    </Card.Footer>
                  )}
                </Card>
              </li>
            );
        })}
        {trainingRequestsList.map((trainingRequests, i) => {
          if (trainingRequests.status === "Incomplete" && props.userInfo.admin)
            if (!trainingRequests.dismissedByUser)
              return (
                <li key={"trainingRequests" + i} className="mb-3">
                  <Card>
                    <Card.Header className="bg-red-300">
                      <span className="font-bold text-lg">
                        Training Request{" "}
                      </span>
                      {trainingRequests.authorName}{" "}
                    </Card.Header>
                    <Card.Body>
                      <Card.Title>{trainingRequests.employeeName}</Card.Title>
                      <Card.Text>
                        <p className="mt-2">
                          A training request was submitted for{" "}
                          {trainingRequests.employeeName} by Supervisor{" "}
                          {trainingRequests.requester}:
                        </p>
                        <div className="ml-5">
                          <p>
                            Reason: <i>{trainingRequests.reason}</i>
                          </p>
                          <p>
                            Type Of Training:{" "}
                            <i>{trainingRequests.typeOfTraining}</i>
                          </p>
                          <p>
                            Comments: <i>{trainingRequests.comments}</i>
                          </p>
                        </div>
                        <p className="my-2">
                          Dismissing this notification will not mark the request
                          as complete, it will only hide the notification.
                        </p>
                        <button
                          onClick={async (e) => {
                            await updateDoc(
                              doc(db, "training-requests", trainingRequests.id),
                              {
                                dismissedByUser: true,
                              }
                            );
                            getTrainingRequests();
                          }}
                          className="mt-2 rounded py-2 px-3 bg-sky-600 hover:bg-sky-900 text-white font-[600]"
                        >
                          Dismiss Notification
                        </button>
                      </Card.Text>
                    </Card.Body>
                    {props.userInfo.admin && (
                      <Card.Footer>
                        <button
                          onClick={(e) =>
                            deleteTrainingRequests(trainingRequests.id)
                          }
                          className="text-red-600"
                        >
                          Delete This Request
                        </button>
                      </Card.Footer>
                    )}
                  </Card>
                </li>
              );
        })}
        {userFeedbackList.map((userFeedback, i) => {
          if (userFeedback.status === "Complete" && userFeedback.published)
            if (!userFeedback.dismissedByUser)
              return (
                <li key={"userFeedback" + i} className="mb-3">
                  <Card>
                    <Card.Header className="bg-green-300">
                      <span className="font-bold text-lg">New Feedback</span>
                      {userFeedback.authorName}{" "}
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <p className="mt-2">
                          You have received feedback from {userFeedback.author}{" "}
                          on {convertDate(userFeedback.createdOn.seconds)} for
                          the following reason:
                        </p>
                        <p className="ml-5 italic">{userFeedback.reason}</p>
                        <p className="my-3">
                          You may view the feedback details in your feedback
                          history.
                        </p>
                        <p className="my-3">
                          Click the button below to dismiss this alert.
                        </p>
                        <button
                          onClick={async (e) => {
                            await updateDoc(
                              doc(db, "hr-feedback", userFeedback.id),
                              {
                                dismissedByUser: true,
                              }
                            );
                            getUserFeedback();
                          }}
                          className="mt-2 rounded py-2 px-3 bg-sky-600 hover:bg-sky-900 text-white font-[600]"
                        >
                          Dismiss Notification
                        </button>
                      </Card.Text>
                    </Card.Body>
                    {props.userInfo.admin && (
                      <Card.Footer>
                        <button
                          onClick={(e) => deleteUserFeedback(userFeedback.id)}
                          className="text-red-600"
                        >
                          Delete This Request
                        </button>
                      </Card.Footer>
                    )}
                  </Card>
                </li>
              );
        })}
        {PTORequestList.map((PTORequest, i) => {
          if (PTORequest.status === "Pending" && props.userInfo.admin)
            if (!PTORequest.dismissedByUser)
              return (
                <li key={"PTORequest" + i} className="mb-3">
                  <Card>
                    <Card.Header className="bg-blue-300">
                      <span className="font-bold text-lg">PTO Request </span>
                      {PTORequest.authorName}{" "}
                    </Card.Header>
                    <Card.Body>
                      <Card.Title>{PTORequest.employeeName}</Card.Title>
                      <Card.Text>
                        <p className="mt-2">
                          A time off request was submitted for{" "}
                          {PTORequest.employeeName} by Supervisor{" "}
                          {PTORequest.requesterName}:
                        </p>
                        <div className="ml-5">
                          <p>
                            Start Date:{" "}
                            <i>{convertDate(PTORequest.startDate.seconds)}</i>
                          </p>
                          <p>
                            End Date:{" "}
                            <i>{convertDate(PTORequest.endDate.seconds)}</i>
                          </p>
                          <p>
                            Reason: <i>{PTORequest.reason}</i>
                          </p>
                          <p>
                            Supervisor: <i>{PTORequest.supervisorName}</i>
                          </p>
                          <p>
                            Status: <i>{PTORequest.status}</i>
                          </p>
                        </div>
                        <p className="my-2">
                          Dismissing this notification will not affect the
                          status of the request, it will only hide the
                          notification.
                        </p>
                        <button
                          onClick={async (e) => {
                            await updateDoc(
                              doc(db, "pto-requests", PTORequest.id),
                              {
                                dismissedByUser: true,
                              }
                            );
                            getPTORequests();
                          }}
                          className="mt-2 rounded py-2 px-3 bg-sky-600 hover:bg-sky-900 text-white font-[600]"
                        >
                          Dismiss Notification
                        </button>
                      </Card.Text>
                    </Card.Body>
                    {props.userInfo.admin && (
                      <Card.Footer>
                        <button
                          onClick={(e) => deletePTORequest(PTORequest.id)}
                          className="text-red-600"
                        >
                          Delete This Request
                        </button>
                      </Card.Footer>
                    )}
                  </Card>
                </li>
              );
        })}
        {announcementList.map((announcement, i) => {
          if (announcement.expiration.seconds > todayInSeconds)
            return (
              <li key={"announcement" + i} className="mb-3">
                <Card>
                  <Card.Header>
                    <Container fluid>
                      <Row>
                        <Col xs={12} md={9}>
                          <b>Announcement </b>on{" "}
                          {convertDate(announcement.dateCreated.seconds)
                            .toLocaleString()
                            .slice(3, 15)}{" "}
                          by {announcement.authorName}{" "}
                        </Col>
                        <Col xs={12} md={3}>
                          <span className="mb-2 md:absolute right-3">
                            {"("}Expires:{" "}
                            {convertDate(announcement.expiration.seconds)}
                            {")"}
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>{announcement.subject}</Card.Title>
                    <Card.Text>{announcement.message}</Card.Text>
                    {props.userInfo.admin && (
                      <Button
                        onClick={(e) => deleteAnnouncement(announcement.id)}
                        variant="danger"
                        className="mt-2 bg-red-900 text-white"
                      >
                        Delete
                      </Button>
                    )}
                  </Card.Body>
                </Card>
              </li>
            );
        })}
      </ul>
    </main>
  );
}

export default ManageAnnouncements;
