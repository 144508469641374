import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../Context/AuthContent";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";

function Signin(props) {
  const provider = new GoogleAuthProvider();
  const { signIn } = UserAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signIn(email, password);
      navigate("/account");
    } catch (e) {
      alert("Error Signing In:" + e.message);
    }
    props.changeAuthStatus(true);
  };

  const auth = getAuth();

  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const googleSignIn = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        navigate("/account");
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        // const user = result.user;
        localStorage.setItem("loggedInToLicona", "true");
        props.changeAuthStatus(true);
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        setLoginErrorMessage("Invalid login. Please contact an administrator.");
      });
  };

  useEffect(() => {
    if (auth.currentUser !== null) navigate("/account");
  });

  return (
    <>
      <div className="max-w-[700px] mx-auto p-4 text-center">
        <div>
          <h1 className="text-center text-red-900 text-3xl font-bold">
            Welcome
          </h1>
          <p className="pt-1 pb-3 text-xl text-red-900">
            To The Licona Toolkit
          </p>
          {loginErrorMessage !== "" && (
            <p className="py-3 text-xl text-red-900 bg-red-100">
              {loginErrorMessage}
            </p>
          )}
          {/* <p className="py-3 text-xl text-red-900">Are you a new user?</p>
          <button
            onClick={(e) => navigate("/signup")}
            className="block mx-auto text-xl text-white bg-red-900 hover:bg-red-800 pt-1 pb-2 px-3 rounded-md"
          >
            Sign Up
          </button> */}
          {/* <form onSubmit={handleSubmit}>
            <div className="flex flex-col pt-3 text-left text-xl">
              <label className="py-2 font-medium">Email Address</label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                className="border p-3 rounded-md"
                type="email"
              />
            </div>
            <div className="flex flex-col pt-1 pb-3 text-left text-xl">
              <label className="py-2 font-medium">Password</label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                className="border p-3 rounded-md"
                type="password"
              />
            </div>
            <button className="bg-red-900 hover:bg-red-800 w-full p-4 mt-4 text-white text-xl rounded-md">
              Sign In
            </button>
          </form> */}
          <button
            onClick={googleSignIn}
            className="bg-red-900 hover:bg-red-800 rounded w-full p-4 mt-4 text-white text-xl rounded-md"
          >
            Sign In With Google
          </button>
          {/* <div>
            <p className="pt-3 text-xl text-red-900">Are you a new user?</p>
            <p className="text-xl text-black">
              You can still sign in with any Google Account or
              <a className="text-sky-600" href="/signup">
                {" "}
                Click Here
              </a>{" "}
              to create an account using any email address.
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Signin;
