import React, { useEffect, useState } from "react";
import { getDocs, collection, doc, deleteDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import "../app.css";

function JobListings(props) {
  //  Date Converter
  const convertDate = (secs) => {
    const output = new Date(secs * 1000);
    return output.toDateString();
  };

  const [gotInfo, setGotInfo] = useState(false);
  const updateListing = async (e, i, requestID, newStatus) => {
    const listingRef = doc(db, "job-listings", requestID);
    await setDoc(listingRef, { status: newStatus }, { merge: true });
    
  };

  // Get all PTO Entries
  const [jobList, setJobList] = useState([]);
  const [gotJobList, setGotJobList] = useState(false);
  const getAllJobListings = async () => {
    setJobList([]);
    const tempValues = []
    const querySnapshot = await getDocs(collection(db, "job-listings"));
    querySnapshot.forEach((doc) => {
      tempValues.push({ ...doc.data(), id: doc.id });
    });
    setJobList(tempValues);
    setGotJobList(true);
    setGotInfo(true);
  };

  const deleteThis = async (thisID) => {
    let verify = prompt(
      "You are about to delete a Job Listing! If you are sure, type DELETE below: "
    );
    if (verify === "DELETE") {
      await deleteDoc(doc(db, "job-listings", thisID));
      setGotJobList(false);
    }
  };

  useEffect(() => {
    if (gotJobList === false) {
      getAllJobListings()
    }
  }, [db, gotJobList, gotInfo]);

  return (
    <section>
      {gotInfo === true && (
        <div className="data-table-container mt-4">
          <Table className="data-table">
            <Thead>
              <Tr className="data-table-header">
                <Td>Created On</Td>
                <Td>Position/Title</Td>
                <Td>Department</Td>
                <Td width="90px">Applicants</Td>
                <Td width="105px">Status</Td>
                <Td width="30px"></Td>
                <Td width="30px"></Td>
                <Td width="30px"></Td>
              </Tr>
            </Thead>
            <Tbody>
              {/* Map User Request History */}
              {jobList.map((listing, i) => {
                return (
                  <Tr key={listing.id}>
                    <Td>{convertDate(listing.createdOn.seconds)}</Td>
                    <Td>{listing.title}</Td>
                    <Td>{listing.department}</Td>
                    <Td>{listing.numberOfApplicants}</Td>
                    <Td>
                      <p id={'cell' + i}></p>
                      <select
                        className="rounded"
                        name="status"
                        id={"select" + i}
                        onChange={(e) => {
                          updateListing(e, i, listing.id, e.target.value);                          
                          document.getElementById('select' + i).remove();
                          document.getElementById('cell' + i).textContent = e.target.value;
                        }}
                        value={listing.status}
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </Td>
                    <Td className="text-center">
                      <a
                        className="text-sky-600"
                        title="Edit"
                        href={"./JobListing/" + listing.id}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-pencil-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                        </svg>
                      </a>
                    </Td>
                    <Td>
                    <a
                      title="Preview"
                      href={"https://www.liconainsurance.com/listing.html?id=" + listing.id}
                      className="text-sky-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-eye-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                      </svg>
                    </a>
                    </Td>
                    <Td>
                      <button title="Delete" className="text-danger" onClick={(e) => {deleteThis(listing.id)}}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-x-octagon-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                        </svg>
                      </button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
      )}
    </section>
  );
}

export default JobListings;
