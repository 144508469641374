import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  where,
  orderBy,
  setDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import DatePicker from "react-multi-date-picker";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import "../app.css";
import { et } from "date-fns/locale";

function PTORequests(props) {
  // Date Stuff
  const today = new Date();
  function toDateTime(secs) {
    var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    t.setSeconds(secs);
    return t.toDateString();
  }

  //   Get Selected User's Info
  const [userInfo, setUserInfo] = useState([]);
  const [gotInfo, setGotInfo] = useState(false);
  const getUserInfo = async (usersID) => {
    setGotInfo(false);
    const q = query(
      collection(db, "pto-requests"),
      where("requesterID", "==", usersID)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setUserInfo([...userInfo, doc.data()]);
    });
    setGotInfo(true);
  };

  const [ptoUpdated, setPtoUpdated] = useState(1);
  const updatePTO = (requestID, newStatus) => {
    const ptoRef = doc(db, "pto-requests", requestID);
    setDoc(
      ptoRef,
      {
        status: newStatus,
        reviewedBy: auth.currentUser.displayName,
        reviewedOn: today,
      },
      { merge: true }
    );
  };

  // Get all PTO Entries
  const [ptoList, setPtoList] = useState([]);
  const [gotPTO, setGotPTO] = useState(false);
  const getAllPTO = async () => {
    setPtoList([]);
    console.log("Getting All PTO History");
    const querySnapshot = await getDocs(collection(db, "pto-requests"));
    querySnapshot.forEach((doc) => {
      ptoList.push({ ...doc.data(), id: doc.id });
    });
    setPtoList(ptoList);
    setGotPTO(true);
    setGotInfo(true);
    console.log(ptoList);
  };

  useEffect(() => {
    if (gotPTO === false) {
      getAllPTO();
    }
  }, [db, gotPTO, ptoUpdated]);

  return (
    <section>
      {gotInfo === true && (
        <div>
                      <Table className="data-table">
              <Thead>
                <Tr>
                  <Td className="bg-red-900 text-white">Submitted</Td>
                  <Td className="bg-red-900 text-white">Submitter</Td>
                  <Td className="bg-red-900 text-white">Reason</Td>
                  <Td className="bg-red-900 text-white">Dates</Td>
                  <Td className="bg-red-900 text-white">Status</Td>
                </Tr>
              </Thead>
            <Tbody>
              {/* Map User Request History */}
              {ptoList.map((ptoRequest, i) => {
                return (
                  <Tr key={"ptoRequest" + i}>
                    <Td>{toDateTime(ptoRequest.requestDate.seconds)}</Td>
                    <Td>{ptoRequest.requesterName}</Td>
                    <Td>{ptoRequest.reason}</Td>

                    {/* Map Request Dates */}
                    <Td>
                      {ptoRequest.dates.map((myDate, i) => {
                        return (
                          <>
                            <p key={"date" + i} className="block">
                              {toDateTime(myDate.seconds)}
                            </p>
                          </>
                        );
                      })}
                    </Td>

                    <Td>
                      <p id={"cell" + i}></p>
                      <select
                        className={`rounded ${
                          ptoRequest.status === "Declined" && `bg-red-200`
                        } ${
                          ptoRequest.status === "Approved" && `bg-green-200`
                        }`}
                        id={"select" + i}
                        onChange={(e) => {
                          updatePTO(ptoRequest.id, e.target.value);
                          document.getElementById("select" + i).remove();
                          document.getElementById("cell" + i).textContent =
                            "Status changed to " + e.target.value;
                        }}
                        value={ptoRequest.status}
                      >
                        <option value="Pending">Pending</option>
                        <option value="Approved">Approved</option>
                        <option value="Declined">Declined</option>
                      </select>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
      )}
    </section>
  );
}

export default PTORequests;
