import React from "react";

export default function UserInfo(props) {
  return (
    <div className="card mb-4 shadow py-3 mt-1 min-h-[380px] flex items-center justify-center">
      <div className="text-center">
        <img
          src={props.userPhoto}
          className="max-h-[150px] mx-auto"
          alt="User Avatar"
        />
        <h3 className="text-2xl font-bold text-center">
          {props.userInfo?.first} {props.userInfo?.last}
        </h3>
        <h4 className="text-lg font-medium text-center">
        {props.userInfo?.department} {props.userInfo?.title}
        </h4>
        <h4 className="text-lg font-medium text-center">
          {props.userInfo?.email}
        </h4>
      </div>
    </div>
  );
}
