import { useEffect, useState } from "react";
import * as React from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  orderBy,
  setDoc,
  addDoc,
  where,
} from "firebase/firestore";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { auth, db } from "../../firebase";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import HumanResourcesNotes from "./HumanResourcesNotes";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import "../app.css";
import QuickPerformanceReview from "./QuickPerformanceReview";
import PTOhistory from "./PTOhistory";
import PTORequests from "./PTORequests";
import Applicants from "./Applicants";
import JobListings from "./JobListings";
import NewJobListing from "./NewJobListing";
import HumanResourcesFeedback from "./HumanResourcesFeedback";
import HumanResourcesGoals from "./HumanResourcesGoals";
import Signup from "../Signup";
import HumanResourcesTraining from "./HumanResourcesTraining";
import AddEmployee from "./AddEmployee";
import DatePicker from "react-datepicker";
import MultiDatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import PTOPanel from "./PTOPanel";
import SupervisorNotes from "./SupervisorNotes";
import HumanResourcesWarning from "./HumanResourcesWarning";
import HumanResourcesPerformanceReview from "./HumanResourcesPerformance";
import { UserAuth } from "../../Context/AuthContent";

// Tab Stuff
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Exported Function
function HRDash(props) {
    // Check User's Role
    const { user } = UserAuth();
    const userID = user.uid;
    const [userInfo, setUserInfo] = useState({});
    const [gotRole, setGotRole] = useState(false);
    const getRole = async (usersID) => {
      setGotRole(false);
      console.log("Getting User Info & Checking Role");
      const docRef = doc(db, "users", usersID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserInfo(docSnap.data());
        setGotRole(true);
      } else {
        // doc.data() will be undefined in this case
        console.log("No role info found!");
        return null;
      }
    };
  // Tabs stuff
  const [value, setValue] = React.useState(0);

  // Modal Stuff
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  // Handle Drop Down Selection
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");
  const [userSelected, setUserSelected] = useState(false);
  const handleChangeUser = (event) => {
    setUserSelected(false);
    setGotInfo(false);
    setFixedData(false);
    const { value } = event.target;
    setSelectedUserID(value.split("-")[0]);
    setSelectedUserName(value.split("-")[1]);
    setUserSelected(true);
    setValue(0);
  };
  // Handle Drop Down Selection
  const [selectedSupervisorName, setSelectedSupervisorName] = useState("");
  const [selectedSupervisorID, setSelectedSupervisorID] = useState("");
  const [supervisorSelected, setSupervisorSelected] = useState(false);
  const handleChangeSupervisor = (event) => {
    event.preventDefault();
    setSupervisorSelected(false);
    const { value } = event.target;
    setSelectedSupervisorID(value.split("-")[0]);
    setSelectedSupervisorName(value.split("-")[1]);
    setSupervisorSelected(true);
    setSelectedUserInfo({
      ...selectedUserInfo,
      supervisorName: value.split("-")[1],
      supervisor: value.split("-")[0],
    });
  };

  //   Get users for user dropdown list
  const [userList, setUserList] = useState([]);
  const usersCollectionRef = collection(db, "users");
  const getUsers = async () => {
    const data = await getDocs(query(usersCollectionRef, orderBy("first")));
    setUserList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  //   Get Selected User's Info
  const [selectedUserInfo, setSelectedUserInfo] = useState({
    supervisorID: selectedSupervisorID,
  });

  const [gotInfo, setGotInfo] = useState(false);
  const getSelectedUserInfo = async () => {
    console.log("Getting Employee HR File");

    const docRef = doc(db, "users", selectedUserID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setSelectedUserInfo({ ...docSnap.data() });
      setGotInfo(true);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  // Edit Users
  const [PTOadjustment, setPTOadjustment] = useState();

  const updateSelectedUserInfo = async (e) => {
    e.preventDefault();
    const userRef = doc(db, "users", selectedUserID);
    await setDoc(userRef, selectedUserInfo, { merge: true });

    setGotInfo(false);
    setFixedData(false);
    getSelectedUserInfo();

    handleClose();
    setPTOadjustment();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Notes
  const [displayNotes, setDisplayNotes] = useState(false);

  // Show what?
  const [showEmployees, setShowEmployees] = useState(true);
  const [showRequests, setShowRequests] = useState(false);
  const [showApplicants, setShowApplicants] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showJobListings, setShowJobListings] = useState(false);
  const [showCreateJobListing, setShowCreateJobListing] = useState(false);

  const showNothing = () => {
    setShowEmployees(false);
    setShowRequests(false);
    setShowApplicants(false);
    setShowCalendar(false);
    setShowJobListings(false);
    setShowCreateJobListing(false);
  };

  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [displayEmployeePerformance, setDisplayEmployeePerformance] =
    useState(true);

  const convertDateToShortString = (secs) => {
    const output = new Date(secs * 1000);
    return output.toString().slice(3, 10);
  };

  const convertDateForDatePicker = (secs) => {
    const output = new Date(secs * 1000);
    return output;
  };

  const convertToMDY = (secs) => {
    const output = new Date(secs * 1000);
    return secs.toString().slice(3, 15) || "";
  };

  const [displayPTORequestsData, setDisplayPTORequestsData] = useState(false);

  const [fixedData, setFixedData] = useState(false);
  const fixData = () => {
    setFixedData(false);
    try {
      const newBirthday = convertDateForDatePicker(
        selectedUserInfo.birthday.seconds
      );
      const newHireDate = convertDateForDatePicker(
        selectedUserInfo.hireDate.seconds
      );
      setSelectedUserInfo({
        ...selectedUserInfo,
        hireDate: newHireDate || "",
        birthday: newBirthday || "",
      });
    } catch (e) {
      console.log(e.message);
    } finally {
      setFixedData(true);
    }
  };

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const [gotDepartmentList, setGotDepartmentList] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const getDepartmentList = async () => {
    setGotDepartmentList(false);
    const querySnapshot = await getDocs(collection(db, "departments"));
    let values = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      values.push({ name: doc.data().name, id: doc.id });
    });
    setDepartmentList(values);
    setGotDepartmentList(true);
  };

  useEffect(() => {
    !gotRole && getRole(userID);
    !gotInfo && userSelected && getSelectedUserInfo();
    gotInfo && !fixedData && fixData();
    getUsers();
    !gotDepartmentList && getDepartmentList();
  }, [db, selectedUserID, userSelected, gotDepartmentList, showEmployees, gotInfo, fixedData]);

  return (
    <main>
      <div className="card my-3">
        <div className="card-header bg-gray-200">
          <button
            onClick={(e) =>
              setDisplayEmployeePerformance(!displayEmployeePerformance)
            }
            className="text-xl font-bold"
          >
            Manage Employees
          </button>
        </div>
        {displayEmployeePerformance && (
          <div className="card-body">
            {/* Employee Selection */}
            <section>
              Select an Employee To View/Edit Their Profile, Notes, Performance
              Reviews, Goals, Training, and Paid Time Off.
              <select
                onChange={handleChangeUser}
                value={selectedUserID + "-" + selectedUserName}
                className="w-full block mx-auto rounded-md font-medium border-2 p-3 my-2 sm:text-xl md:text-xl"
              >
                <option>Select A User</option>
                {userList.map((userObject, i) => {
                  if (
                    userInfo.admin ||
                    userObject.supervisor === auth.currentUser.uid
                  ) {
                    if (userObject.id !== auth.currentUser.uid) {
                      return (
                        <option
                          key={"id" + userObject.id}
                          value={
                            userObject.id +
                            "-" +
                            userObject.first +
                            " " +
                            userObject.last
                          }
                        >
                          {toTitleCase(
                            userObject.first + " " + userObject.last
                          )}
                        </option>
                      );
                    }
                  }
                })}
              </select>
            </section>
            {/* Display Once Employee Selected */}
            {userSelected && (
              <section>
                {fixedData && (
                  <>
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="HR Tabs"
                          sx={{
                            "& .MuiTabs-flexContainer": {
                              flexWrap: "wrap",
                            },
                          }}
                        >
                          <Tab label="Profile" {...a11yProps(0)} />
                          <Tab label="Notes" {...a11yProps(1)} />
                          <Tab label="Feedback" {...a11yProps(2)} />
                          <Tab label="Goals" {...a11yProps(3)} />
                          <Tab label="Warning" {...a11yProps(4)} />
                          <Tab label="Training" {...a11yProps(5)} />
                          <Tab label="PTO" {...a11yProps(6)} />
                          <Tab label="Performance" {...a11yProps(7)} />
                        </Tabs>
                      </Box>
                      {/* Employee Profile Tab */}
                      <TabPanel value={value} index={0}>
                        {gotInfo && (
                          <div>
                            <h1 className="text-xl font-bold pt-3">
                              Employee Profile:
                            </h1>
                            {userInfo.admin === true && (
                              <button
                                className="text-md text-sky-600 font-medium mb-3"
                                onClick={handleShow}
                              >
                                Edit Employee Information
                              </button>
                            )}
                            <Container>
                              <Row>
                                <Col>
                                  <section>
                                    <h2 className="text-lg font-bold mt-2">
                                      Name:
                                    </h2>
                                    <p className="block text-lg font-medium">
                                      {toTitleCase(
                                        selectedUserInfo?.first +
                                          " " +
                                          selectedUserInfo?.last
                                      )}
                                    </p>
                                    <h2 className="text-lg font-bold mt-2">
                                      Company:
                                    </h2>
                                    <p className="block text-lg font-medium">
                                      Licona Insurange Group
                                    </p>
                                    <h2 className="text-lg font-bold mt-2">
                                      Title:
                                    </h2>
                                    <p className="block text-lg font-medium">
                                      {selectedUserInfo?.title}
                                    </p>
                                    <h2 className="text-lg font-bold mt-2">
                                      Department:
                                    </h2>
                                    <p className="block text-lg font-medium">
                                      {selectedUserInfo?.department}
                                    </p>
                                  </section>
                                </Col>
                                <Col>
                                  <h2 className="text-lg font-bold mt-2">
                                    Email:
                                  </h2>
                                  <p className="block text-lg font-medium">
                                    {selectedUserInfo?.email}
                                  </p>
                                  <h2 className="text-lg font-bold mt-2">
                                    Phone/Extension:
                                  </h2>
                                  <p className="block text-lg font-medium">
                                    {selectedUserInfo?.phone}
                                    {selectedUserInfo.extension && (
                                      <>
                                        {selectedUserInfo?.extension !== "" &&
                                          " / "}
                                      </>
                                    )}
                                    {selectedUserInfo?.extension}
                                  </p>
                                  <h2 className="text-lg font-bold mt-2">
                                    System Role:
                                  </h2>
                                  <p className="block text-lg font-medium">
                                    {selectedUserInfo?.role}
                                  </p>
                                  <h2 className="text-lg font-bold mt-2">
                                    Office:
                                  </h2>
                                  <p className="block text-lg font-medium">
                                    {selectedUserInfo?.office}
                                  </p>
                                </Col>
                                <Col>
                                  <h2 className="text-lg font-bold mt-2">
                                    Supervisor:
                                  </h2>
                                  <p className="block text-lg font-medium">
                                    {selectedUserInfo?.supervisorName}
                                  </p>
                                  <h2 className="text-lg font-bold mt-2">
                                    Hire Date:
                                  </h2>
                                  <p className="block text-lg font-medium">
                                    {convertToMDY(selectedUserInfo.hireDate)}
                                  </p>
                                  <h2 className="text-lg font-bold mt-2">
                                    Birthday:
                                  </h2>
                                  <p className="block text-lg font-medium">
                                    {convertDateToShortString(
                                      selectedUserInfo.birthday
                                    )}
                                  </p>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        )}
                      </TabPanel>
                      {/* HR Notes Tab */}
                      <TabPanel value={value} index={1}>
                        {/* HR Notes */}
                        {userInfo.admin && (
                          <section>
                            <h2 className="text-lg font-bold mt-3">
                              HR Notes for{" "}
                              {toTitleCase(
                                selectedUserInfo?.first +
                                  " " +
                                  selectedUserInfo?.last
                              )}
                            </h2>
                            {displayNotes != true && (
                              <p>
                                HR Notes are automatically hidden for privacy.{" "}
                                <button onClick={() => setDisplayNotes(true)}>
                                  Click here to display.
                                </button>
                              </p>
                            )}
                            {displayNotes === true && (
                              <>
                                <button onClick={() => setDisplayNotes(false)}>
                                  Click here to hide notes.
                                </button>
                                <HumanResourcesNotes
                                userInfo={userInfo}
                                selectedUserInfo={selectedUserInfo}
                                  selectedUserID={selectedUserID}
                                ></HumanResourcesNotes>
                              </>
                            )}
                          </section>
                        )}
                        {/* Supervisor Notes */}
                        {selectedUserInfo.supervisor === auth.currentUser.uid || userInfo.admin ? (
                          <section>
                            <h2 className="text-lg font-bold mt-3">
                              Supervisor Notes for{" "}
                              {toTitleCase(
                                selectedUserInfo?.first +
                                  " " +
                                  selectedUserInfo?.last
                              )}
                            </h2>
                            {displayNotes != true && (
                              <p>
                                Supervisor Notes are automatically hidden for
                                privacy.{" "}
                                <button onClick={() => setDisplayNotes(true)}>
                                  Click here to display.
                                </button>
                              </p>
                            )}
                            {displayNotes === true && (
                              <>
                                <button onClick={() => setDisplayNotes(false)}>
                                  Click here to hide notes.
                                </button>
                                <SupervisorNotes
                                userInfo={userInfo}
                                selectedUserInfo={selectedUserInfo}
                                  selectedUserID={selectedUserID}
                                />
                              </>
                            )}
                          </section>
                        ) : (
                          <p>
                            You must be the employees supervisor or an
                            administrator to view this page.
                          </p>
                        )}
                      </TabPanel>
                      {/* Feedback Tab */}
                      <TabPanel value={value} index={2}>
                        <HumanResourcesFeedback
                          selectedUserID={selectedUserID}
                          selectedUserInfo={selectedUserInfo}
                          userInfo={userInfo}
                          userList={userList}
                        />
                      </TabPanel>
                      {/* Goals Tab */}
                      <TabPanel value={value} index={3}>
                        <h1 className="text-2xl font-bold text-red-900">
                          Goals
                        </h1>
                        <div className="p-3">
                          <HumanResourcesGoals
                            selectedUserID={selectedUserID}
                            userInfo={userInfo}
                            selectedUserInfo={selectedUserInfo}
                          />
                        </div>
                        {/* <h1 className="text-2xl font-bold text-red-900">
                          Performance
                        </h1>
                        <div className="p-3">
                          <QuickPerformanceReview
                            selectedUserInfo={selectedUserInfo}
                            selectedUserID={selectedUserID}
                          />
                        </div> */}
                      </TabPanel>
                                            {/* Warning Tab */}
                                            <TabPanel value={value} index={4}>
                        <HumanResourcesWarning
                          selectedUserInfo={selectedUserInfo}
                          userInfo={userInfo}
                          selectedUserID={selectedUserID}
                        />
                      </TabPanel>
                      {/* Training Tab */}
                      <TabPanel value={value} index={5}>
                        <HumanResourcesTraining
                          selectedUserInfo={selectedUserInfo}
                          userInfo={userInfo}
                          selectedUserID={selectedUserID}
                        />
                      </TabPanel>
                      {/* PTO Tab */}
                      <TabPanel value={value} index={6}>
                        Coming Soon
                        {userInfo.admin && (
                          <PTOPanel
                          PTOadjustment={PTOadjustment}
                          setPTOadjustment={setPTOadjustment}
                          setDisplayPTORequestsData={setDisplayPTORequestsData}
                            setGotInfo={setGotInfo}
                            userInfo={userInfo}
                            fixedData={fixedData}
                            setFixedData={setFixedData}
                            getSelectedUserInfo={getSelectedUserInfo}
                            userSelected={userSelected}
                            selectedUserID={selectedUserID}
                            selectedUserInfo={selectedUserInfo}
                            setSelectedUserInfo={setSelectedUserInfo}
                          />
                        )}
                        
                      </TabPanel>
                                            {/* Performance Tab */}
                                            <TabPanel value={value} index={7}>
                        <HumanResourcesPerformanceReview
                          selectedUserInfo={selectedUserInfo}
                          userInfo={userInfo}
                          selectedUserID={selectedUserID}
                        />
                      </TabPanel>
                    </Box>
                  </>
                )}
              </section>
            )}
          </div>
        )}
      </div>
      {/* <div className="card mb-3">
        <div className="card-header bg-gray-200">
          <button
            onClick={(e) => setDisplayPTORequestsData(!displayPTORequestsData)}
            className="text-xl font-bold"
          >
            All Employees' PTO Requests
          </button>
        </div>
        {displayPTORequestsData && (
          <div className="card-body">
            <p className="mb-4">
              Approve and decline employee PTO requests. To edit or view an
              employee's PTO settings, visit their profile in the "Manage
              Employees" tab.
            </p>
            <PTORequests selectedUserID={selectedUserID} />
          </div>
        )}
      </div> */}

      {/* Modal Stuff */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fixedData && (
            <>
              <input
                required
                disabled
                placeholder="Company"
                type="text"
                onChange={(e) =>
                  setSelectedUserInfo({
                    ...selectedUserInfo,
                    company: e.target.value,
                  })
                }
                className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
                value="Licona Insurance Group"
              />
              <input
                required
                disabled
                placeholder="Email"
                type="text"
                onChange={(e) =>
                  setSelectedUserInfo({
                    ...selectedUserInfo,
                    email: e.target.value,
                  })
                }
                className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
                value={selectedUserInfo?.email}
              />
              <input
                required
                placeholder="Phone"
                onChange={(e) =>
                  setSelectedUserInfo({
                    ...selectedUserInfo,
                    phone: e.target.value,
                  })
                }
                type="text"
                className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
                value={selectedUserInfo?.phone}
              />
              <input
                required
                placeholder="Extension"
                onChange={(e) =>
                  setSelectedUserInfo({
                    ...selectedUserInfo,
                    extension: e.target.value,
                  })
                }
                type="text"
                className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
                value={selectedUserInfo?.extension}
              />
              Title:
              <input
                required
                placeholder="Title"
                onChange={(e) =>
                  setSelectedUserInfo({
                    ...selectedUserInfo,
                    title: e.target.value,
                  })
                }
                type="text"
                className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
                value={selectedUserInfo?.title}
              />
              Department:
              <select
              required
              onChange={(e) =>
                setSelectedUserInfo({
                  ...selectedUserInfo,
                  department: e.target.value,
                })
              }
              value={selectedUserInfo?.department}
              className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
              >
              <option value="">Choose One</option>
              {departmentList.map((department) => {
                return (
                  <option value={department.name}>{department.name}</option>
                );
              })}
            </select>
              Supervisor:
              <select
                required
                onChange={(e) => handleChangeSupervisor(e)}
                value={selectedSupervisorID + "-" + selectedSupervisorName}
                className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
              >
                <option>Select A Supervisor</option>
                {userList.map((userObject, i) => {
                  if (userObject.first && userObject.role === "Supervisor") {
                    return (
                      <option
                        key={"id" + userObject.id}
                        value={
                          userObject.id +
                          "-" +
                          userObject.first +
                          " " +
                          userObject.last
                        }
                      >
                        {userObject.first + " " + userObject.last}
                      </option>
                    );
                  }
                })}
              </select>
              Office:
              <select
                required
                onChange={(e) => {
                  setSelectedUserInfo({
                    ...selectedUserInfo,
                    office: e.target.value,
                  });
                }}
                className={`w-full block text-lg font-medium bg-sky-200 mb-1 p-1`}
                value={selectedUserInfo.office}
              >
                <option value="Choose One">Choose One</option>
                <option value="Juarez">Juarez</option>
                <option value="Mexico City">Mexico City</option>
                <option value="McAllen">McAllen</option>
                <option value="El Paso">El Paso</option>
                <option value="San Antonio">San Antonio</option>
                <option value="Laredo">Laredo</option>
                <option value="Remote">Remote</option>
              </select>
              Birthday:
              <DatePicker
                selected={new Date(selectedUserInfo.birthday)}
                onChange={(date: Date) =>
                  setSelectedUserInfo({ ...selectedUserInfo, birthday: date })
                }
                className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
              />
              Hire Date:
              <DatePicker
                selected={new Date(selectedUserInfo.hireDate)}
                onChange={(date: Date) =>
                  setSelectedUserInfo({ ...selectedUserInfo, hireDate: date })
                }
                className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
              />
              System Role:
              <select
                required
                onChange={(e) => {
                  setSelectedUserInfo({
                    ...selectedUserInfo,
                    role: e.target.value,
                  });
                }}
                className={`w-full block text-lg font-medium bg-sky-200 mb-1 p-1`}
                value={selectedUserInfo.role}
              >
                <option value="New User">New User (No Access)</option>
                <option value="Employee">Employee</option>
                <option value="Supervisor">Supervisor</option>
              </select>
              Admin:
              <select
                required
                onChange={(e) => {
                  setSelectedUserInfo({
                    ...selectedUserInfo,
                    admin: e.target.value,
                  });
                }}
                className={`w-full block text-lg font-medium bg-sky-200 mb-1 p-1`}
                value={selectedUserInfo.admin}
              >
                <option>Choose One</option>
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </select>
              <button
                className="w-full block text-lg font-medium bg-green-200 p-1 mt-3"
                onClick={(e) => updateSelectedUserInfo(e)}
              >
                Save Changes
              </button>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="rounded bg-red-900" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
}

export default HRDash;
