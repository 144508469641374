import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../Context/AuthContent";

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const { createUser } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createUser(email, password);
    //   await createUserDocument(first, last, email, phone);
      navigate("/account");
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <div className="max-w-[700px] mx-auto my-16 p-4">
      <div>
        <h1 className="text-center text-3xl font-bold">
          Sign up for a new account
        </h1>
        <p className="py-2 text-sky-600">
          Existing user? <Link to="/signin">Sign In</Link>
        </p>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">Email Address</label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              className="border p-3"
              type="email"
            />
          </div>
          <div className="flex flex-col py-2">
            <label className="py-2 font-medium">Password</label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              className="border p-3"
              type="password"
            />
          </div>
          <button className="bg-red-900 hover:bg-red-700 rounded w-full p-4 my-2 text-white">
            Sign Up
          </button>
        </form>
      </div>
    </div>
  );
}

export default Signup;
