import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  where,
  orderBy,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import DatePicker from "react-multi-date-picker";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import "../app.css";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Applicants(props) {
  // Date Stuff
  function toDateTime(secs) {
    var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    t.setSeconds(secs);
    return t.toDateString();
  }

  // NextStep Modal Stuff
  let subtitle;
  const [nextStepModalIsOpen, setNextStepModalIsOpen] = React.useState(false);

  function openNextStepModal(e) {
    // e.preventDefault();
    setNextStepModalIsOpen(true);
  }

  function afterOpenNextStepModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeNextStepModal() {
    setNextStepModalIsOpen(false);
  }

  // Edit Next Step
  const [newNextStep, setNewNextStep] = useState("");
  const [selectedApplicant, setSelectedApplicant] = useState("");
  const [applicantIterator, setApplicantIterator] = useState(0);
  const editNextStep = (e, applicantID) => {
    e.preventDefault();
    const applicantRef = doc(db, "applicants", applicantID);
    setDoc(applicantRef, { nextStep: newNextStep }, { merge: true });
    applicantList[applicantIterator].nextStep = newNextStep;
    closeNextStepModal();
    setNewNextStep("");
  };

  // Edit Status
  const [newStatus, setNewStatus] = useState("");
  const addNewStatus = async (e) => {
    // Add a new document with a generated id.
    const docRef = await addDoc(collection(db, "applicant-statuses"), {
      name: newStatus,
    });
    setGotStatusList(false);
    getStatusList();
    setNewStatus("");
    closeNewStatusModal();
  };
  const editStatus = (e, applicantID) => {
    e.preventDefault();
    const applicantRef = doc(db, "applicants", applicantID);
    setDoc(applicantRef, { status: e.target.value }, { merge: true });
  };

  // New Status Modal Stuff
  const [newStatusModalIsOpen, setNewStatusModalIsOpen] = React.useState(false);

  function openNewStatusModal(e) {
    // e.preventDefault();
    setNewStatusModalIsOpen(true);
  }

  function afterOpenNewStatusModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeNewStatusModal() {
    setNewStatusModalIsOpen(false);
  }

  // Edit BestFit Modal Stuff
  const [editBestFitModalIsOpen, setBestFitModalIsOpen] = React.useState(false);

  function openBestFitModal(e) {
    // e.preventDefault();
    setBestFitModalIsOpen(true);
  }

  function afterOpenBestFitModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeBestFitModal() {
    setBestFitModalIsOpen(false);
  }

  // Edit Best Fit
  const [newBestFit, setNewBestFit] = useState("");
  const editBestFit = (e, applicantID) => {
    e.preventDefault();
    const applicantRef = doc(db, "applicants", applicantID);
    setDoc(applicantRef, { bestFit: newBestFit }, { merge: true });
    applicantList[applicantIterator].bestFit = newBestFit;
    closeBestFitModal();
    setNewBestFit("");
  };

  //   Get Selected Applicant's Info
  const [applicantInfo, setApplicantInfo] = useState([]);
  const [gotInfo, setGotInfo] = useState(false);
  const getApplicantInfo = async (applicantID) => {
    setGotInfo(false);
    const q = query(
      collection(db, "applicant-requests"),
      where("requesterID", "==", applicantID)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setApplicantInfo([...applicantInfo, doc.data()]);
    });
    setGotInfo(true);
  };

  const [applicantUpdated, setApplicantUpdated] = useState(1);
  const updateApplicant = () => {
    const applicantRef = doc(db, "applicant-requests", props.selectedUserID);
    setDoc(applicantRef, applicantInfo, { merge: true });
    setApplicantUpdated(applicantUpdated + 1);
  };

  // Get all Applicant Entries
  const [applicantList, setApplicantList] = useState([]);
  const [gotApplicant, setGotApplicant] = useState(false);
  const getAllApplicant = async () => {
    setGotApplicant(false);
    const q = query(collection(db, "applicants"));
    const queryData = await getDocs(q);
    setApplicantList(
      queryData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
    setGotApplicant(true);
  };

  const fillInTheBlanks = (data) => {
    const values = [...data];
    values.forEach((field) => {
      if (!field.desiredPosition) field.desiredPosition = "";
      if (!field.bestFit) field.bestFit = "";
      if (!field.first) field.first = "";
      if (!field.last) field.last = "";
      if (!field.status) field.status = "";
      if (!field.nextStep) field.nextStep = "";
    });
  };

  const sortData = (column) => {
    fillInTheBlanks(applicantList);
    // Name
    if (column === "name") {
      const data2 = [...applicantList].sort((a, b) =>
        a.first.toLowerCase() < b.first.toLowerCase() ? -1 : 1
      );

      setApplicantList(data2);
    }
    // Next Step
    if (column === "nextStep") {
      const data2 = [...applicantList].sort((a, b) =>
        a.nextStep?.toLowerCase() < b.nextStep?.toLowerCase() ? -1 : 1
      );

      setApplicantList(data2);
    }
    // Best Fit
    if (column === "bestFit") {
      const data2 = [...applicantList].sort((a, b) =>
        a.bestFit?.toLowerCase() < b.bestFit?.toLowerCase() ? -1 : 1
      );

      setApplicantList(data2);
    }
    // Desired Position
    if (column === "desiredPosition") {
      const data2 = [...applicantList].sort((a, b) =>
        a.desiredPosition?.toLowerCase() < b.desiredPosition?.toLowerCase()
          ? -1
          : 1
      );

      setApplicantList(data2);
    }
    // Status
    if (column === "status") {
      const data2 = [...applicantList].sort((a, b) =>
        a.status?.toLowerCase() < b.status?.toLowerCase() ? -1 : 1
      );

      setApplicantList(data2);
    }
  };

  const [statusList, setStatusList] = useState([]);
  const [gotStatusList, setGotStatusList] = useState(false);
  const getStatusList = async () => {
    const querySnapshot = await getDocs(collection(db, "applicant-statuses"));
    let values = [];
    querySnapshot.forEach((doc) => {
      values.push(doc.data());
    });
    setStatusList(values);
  };

  useEffect(() => {
    if (gotApplicant === false) getAllApplicant();
    if (gotStatusList === false) getStatusList();
  }, [db]);

  return (
    <section className="data-table-container mt-2">
      <Table className="data-table">
        <Thead>
          <Tr className="data-table-header">
            <Td>
              <button onClick={() => sortData("name")}>Applicant</button>
            </Td>
            <Td>
              <button onClick={() => sortData("nextStep")}>Next Step</button>
            </Td>
            <Td>
              <button onClick={() => sortData("desiredPosition")}>
                Desired Position
              </button>
            </Td>
            <Td>
              <button onClick={() => sortData("bestFit")}>Best Fit</button>
            </Td>
            <Td>
              <button onClick={() => sortData("status")}>Status</button>
              <button
                className="text-sky-600 ml-2"
                onClick={(e) => openNewStatusModal(e)}
              >
                (+ New)
              </button>
            </Td>
          </Tr>
        </Thead>
        <Tbody>
          {applicantList.map((applicant, i) => {
            return (
              <Tr key={"applicant" + i}>
                <Td>
                  <a
                    href={"/applicant/" + applicant.id}
                    className="block text-sky-600"
                  >
                    {applicant.first + " " + applicant.last}
                  </a>
                  View:
                  <a
                    className="text-sky-600"
                    href={applicant.resume}
                    target="new"
                  >
                    {" "}
                    Resume{" "}
                  </a>{" "}
                  |
                  <a
                    className="text-sky-600"
                    href={applicant.coverLetter}
                    target="new"
                  >
                    {" "}
                    Cover Letter
                  </a>
                </Td>
                <Td>
                  <button
                    className="text-sky-600"
                    onClick={(e) => {
                      setSelectedApplicant(applicant.id);
                      setApplicantIterator(i);
                      openNextStepModal();
                    }}
                  >
                    {applicant.nextStep}
                  </button>
                </Td>
                <Td>{applicant.desiredPosition}</Td>
                <Td>
                  <button
                    className="text-sky-600"
                    onClick={(e) => {
                      setSelectedApplicant(applicant.id);
                      setApplicantIterator(i);
                      openBestFitModal();
                    }}
                  >
                    {applicant.bestFit}
                  </button>
                </Td>
                <Td>
                  <p id={"cell" + i}></p>
                  <select
                    id={"select" + i}
                    value={applicant.status}
                    onChange={(e) => {
                      editStatus(e, applicant.id);
                      document.getElementById('select' + i).remove();
                      document.getElementById('cell' + i).textContent = e.target.value;
                    }}
                  >
                    <option>Choose One</option>
                    {statusList.map((myStatus, i) => {
                      return (
                        <option value={myStatus.name}>{myStatus.name}</option>
                      );
                    })}
                  </select>
                </Td>
                {/* Next Step Modal */}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <div>
        <Modal
          isOpen={nextStepModalIsOpen}
          onAfterOpen={afterOpenNextStepModal}
          onRequestClose={closeNextStepModal}
          style={customStyles}
          contentLabel="Add NextStep"
        >
          <div>Update Next Step</div>
          <label className="block">New Next Step:</label>
          <input
            type="text"
            onChange={(e) => setNewNextStep(e.target.value)}
            value={newNextStep}
          />
          <button
            className="block"
            onClick={(e) => editNextStep(e, selectedApplicant)}
          >
            Submit
          </button>
          <button onClick={(e) => closeNextStepModal(e)}>Cancel</button>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={editBestFitModalIsOpen}
          onAfterOpen={afterOpenBestFitModal}
          onRequestClose={closeBestFitModal}
          style={customStyles}
          contentLabel="Edit Best Fit"
        >
          <div>Update BestFit</div>
          <label className="block">New Best Fit:</label>
          <input
            type="text"
            onChange={(e) => setNewBestFit(e.target.value)}
            value={newBestFit}
          />
          <button
            className="block"
            onClick={(e) => editBestFit(e, selectedApplicant)}
          >
            Submit
          </button>
          <button onClick={(e) => closeBestFitModal(e)}>Cancel</button>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={newStatusModalIsOpen}
          onAfterOpen={afterOpenNewStatusModal}
          onRequestClose={closeNewStatusModal}
          style={customStyles}
          contentLabel="Add New Status"
        >
          <div>Add New Status</div>
          <label className="block">New Status:</label>
          <input
            type="text"
            onChange={(e) => setNewStatus(e.target.value)}
            value={newStatus}
          />
          <button className="block" onClick={(e) => addNewStatus(e)}>
            Submit
          </button>
          <button onClick={(e) => closeNewStatusModal(e)}>Cancel</button>
        </Modal>
      </div>
    </section>
  );
}

export default Applicants;
