import React, { Component } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import App from "./App";
// import { root } from "postcss";
import { BrowserRouter } from "react-router-dom";
import { ProSidebarProvider } from 'react-pro-sidebar';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ProSidebarProvider>
      <App />
    </ProSidebarProvider>
  </BrowserRouter>
);


