import { useEffect, useState } from "react";
import * as React from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  orderBy,
  setDoc,
} from "firebase/firestore";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { db } from "../../firebase";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import HumanResourcesNotes from "./HumanResourcesNotes";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import "../app.css";
import QuickPerformanceReview from "./QuickPerformanceReview";
import TimeOffRequest from "./TimeOffRequest";
import PTOhistory from "./PTOhistory";
import PTORequests from "./PTORequests";
import Applicants from "./Applicants";
import JobListings from "./JobListings";
import NewJobListing from "./NewJobListing";
import HumanResourcesFeedback from "./HumanResourcesFeedback";
import HumanResourcesGoals from "./HumanResourcesGoals";
import Signup from "../Signup";
import HumanResourcesTraining from "./HumanResourcesTraining";
import AddEmployee from "./AddEmployee";

// Tab Stuff
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Exported Function
function HumanResourcesDashboard() {
  // Date Stuff
  function toDateTime(secs) {
    var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    t.setSeconds(secs);
    return t.toDateString();
  }

  // Tabs stuff
  const [value, setValue] = React.useState(0);

  // Modal Stuff
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Handle Drop Down Selection
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");
  const [userSelected, setUserSelected] = useState(false);
  const handleChangeUser = (event) => {
    const { value } = event.target;
    setSelectedUserID(value.split("-")[0]);
    setSelectedUserName(value.split("-")[1]);
    setUserSelected(true);
    console.log("Selected User => " + selectedUserID);
    setValue(0);
  };

  //   Get users for user dropdown list
  const [userList, setUserList] = useState([]);
  const usersCollectionRef = collection(db, "users");
  const getUsers = async () => {
    const data = await getDocs(query(usersCollectionRef, orderBy("first")));
    setUserList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    console.log(userList);
  };

  //   Get Selected User's Info
  const userID = selectedUserID;
  const [userInfo, setUserInfo] = useState({
    company: "",
    email: "",
    first: "",
    last: "",
    phone: "",
    extension: "",
    role: "",
    title: "",
    department: "",
    supervisor: "",
    office: "",
    hireDate: "",
    birthday: "",
    userID: "",
    hrNotes: [],
    pto: {},
  });

  const getUserInfo = async (usersID) => {
    console.log("Getting Employee HR File");

    const docRef = doc(db, "users", usersID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setUserInfo(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  // Edit Users
  const [showEditUser, setShowEditUser] = useState(false);
  const updateUserInfo = (e, id) => {
    e.preventDefault();
    const userRef = doc(db, "users", id);
    setDoc(userRef, userInfo, { merge: true });
    handleClose();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Notes
  const [displayNotes, setDisplayNotes] = useState(false);

  // Show what?
  const [showEmployees, setShowEmployees] = useState(true);
  const [showRequests, setShowRequests] = useState(false);
  const [showApplicants, setShowApplicants] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showJobListings, setShowJobListings] = useState(false);
  const [showCreateJobListing, setShowCreateJobListing] = useState(false);

  const showNothing = () => {
    setShowEmployees(false);
    setShowRequests(false);
    setShowApplicants(false);
    setShowCalendar(false);
    setShowJobListings(false);
    setShowCreateJobListing(false);
  };

  // PTO Tab Display
  const [displayRequests, setDisplayRequests] = useState(false);
  const [displayHistory, setDisplayHistory] = useState(false);

  const displayNothing = () => {
    setDisplayRequests(false);
    setDisplayHistory(false);
  };

  const [showAddEmployee, setShowAddEmployee] = useState(false);

  const [gotDepartmentList, setGotDepartmentList] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const getDepartmentList = async () => {
    setGotDepartmentList(false);
    const querySnapshot = await getDocs(collection(db, "departments"));
    let values = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      values.push({ name: doc.data().name, id: doc.id });
    });
    setDepartmentList(values);
    setGotDepartmentList(true);
  };

  useEffect(() => {
    if (userID) {
      getUserInfo(userID);
    }
    getUsers();
    !gotDepartmentList && getDepartmentList();
  }, [db, selectedUserID, showEmployees, gotDepartmentList]);

  return (
    <Container className="bg-white" fluid>
      {/* Header */}
      <Container
        id="user-Info"
        className="mt-3 mx-auto border-1 pt-1 bg-red-900 rounded overflow-hidden shadow-md z-50"
        fluid
      >
        <h1 className="text-white text-2xl font-bold pt-3 mb-3">
          Human Resources Portal
        </h1>
        {/* HR Menu Bar */}
        <Row className="border-2 p-4 bg-gray-200 text-xl">
          <Container fluid>
            <Row className="mx-auto">
              <Col className="text-center">
                <button
                  className="text-sky-600"
                  onClick={() => {
                    showNothing();
                    setShowEmployees(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className=" align-bottom inline mr-1 bi bi-person-lines-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                  </svg>
                  Manage Employees
                </button>
              </Col>
              <Col className="text-center">
                <button
                  className="text-sky-600"
                  onClick={() => {
                    showNothing();
                    setShowRequests(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="inline mr-1 align-bottom bi bi-calendar3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                    <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                  </svg>
                  PTO Requests
                </button>
              </Col>
              <Col className="text-center">
                <button
                  className="text-sky-600"
                  onClick={() => {
                    showNothing();
                    setShowApplicants(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="inline align-bottom mr-1 bi bi-people-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  </svg>
                  Manage Applicants
                </button>
              </Col>
              <Col className="text-center">
                <button
                  className="text-sky-600"
                  onClick={() => {
                    showNothing();
                    setShowCalendar(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="inline align-bottom mr-1 bi bi-calendar2-heart-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5Zm-2 4v-1c0-.276.244-.5.545-.5h10.91c.3 0 .545.224.545.5v1c0 .276-.244.5-.546.5H2.545C2.245 5 2 4.776 2 4.5Zm6 3.493c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
                  </svg>
                  HR Calendar
                </button>
              </Col>
              <Col className="text-center">
                <button
                  className="text-sky-600"
                  onClick={() => {
                    showNothing();
                    setShowJobListings(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="inline align-bottom mr-1 bi bi-hourglass-split"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                  </svg>
                  Job Listings
                </button>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
      {/* Manage Employees Tab */}
      <Container fluid>
        {showEmployees && (
          <>
            {/* Employee Selection */}
            <Container className="mt-2" fluid>
              <h1 className="text-red-900 text-xl font-bold pt-3 mb-1">
                Manage Employees
              </h1>
              Select an Employee To View/Edit Their Profile, Notes, Performance
              Reviews, Paid Time Off, and Rewards.
              <div className="card">
                <div className="card-header">
                  <button
                    onClick={(e) => setShowAddEmployee(!showAddEmployee)}
                    className="text-lg font-bold text-sky-600"
                  >
                    Add Employee
                  </button>
                </div>
                {showAddEmployee && (
                  <div className="card-body">
                    <AddEmployee getUsers={getUsers} />
                  </div>
                )}
              </div>
              <select
                onChange={handleChangeUser}
                value={selectedUserID + "-" + selectedUserName}
                className="w-full block mx-auto rounded-md font-medium border-2 p-3 my-2 sm:text-xl md:text-xl"
              >
                <option>Select A User</option>
                {userList.map((userObject, i) => {
                  if (userObject.first) {
                    return (
                      <option
                        key={"id" + userObject.id}
                        value={
                          userObject.id +
                          "-" +
                          userObject.first +
                          " " +
                          userObject.last
                        }
                      >
                        {userObject.first + " " + userObject.last}
                      </option>
                    );
                  }
                })}
              </select>
            </Container>
            {/* Display Once Employee Selected */}
            {userSelected && (
              <Container fluid>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="HR Tabs"
                      sx={{
                        "& .MuiTabs-flexContainer": {
                          flexWrap: "wrap",
                        },
                      }}
                    >
                      <Tab label="Profile" {...a11yProps(0)} />
                      <Tab label="Notes" {...a11yProps(1)} />
                      <Tab label="Feedback" {...a11yProps(2)} />
                      <Tab label="Development" {...a11yProps(3)} />
                      <Tab label="Training" {...a11yProps(4)} />
                      <Tab label="PTO" {...a11yProps(5)} />
                      <Tab label="Rewards" {...a11yProps(6)} />
                    </Tabs>
                  </Box>
                  {/* Employee Profile Tab */}
                  <TabPanel value={value} index={0}>
                    <h1 className="text-xl font-bold pt-3">
                      Employee Profile:
                    </h1>
                    <button
                      className="text-md text-sky-600 font-medium mb-3"
                      onClick={handleShow}
                    >
                      Edit Employee Information
                    </button>
                    <Container>
                      <Row>
                        <Col>
                          <section>
                            <h2 className="text-lg font-bold">Name:</h2>
                            <p className="block text-lg font-medium">
                              {userInfo?.first} {userInfo?.last}
                            </p>
                            <h2 className="text-lg font-bold mt-2">Company:</h2>
                            <p className="block text-lg font-medium">
                              {userInfo?.company}
                            </p>
                            <h2 className="text-lg font-bold">Title:</h2>
                            <p className="block text-lg font-medium">
                              {userInfo?.title}
                            </p>
                            <h2 className="text-lg font-bold mt-2">
                              Department:
                            </h2>
                            <p className="block text-lg font-medium">
                              {userInfo?.department}
                            </p>
                          </section>
                        </Col>
                        <Col>
                          <h2 className="text-lg font-bold mt-2">Email:</h2>
                          <p className="block text-lg font-medium">
                            {userInfo?.email}
                          </p>
                          <h2 className="text-lg font-bold mt-2">
                            Phone/Extension:
                          </h2>
                          <p className="block text-lg font-medium">
                            {userInfo?.phone}
                            {userInfo.extension && (
                              <>{userInfo?.extension !== "" && " / "}</>
                            )}
                            {userInfo?.extension}
                          </p>
                          <h2 className="text-lg font-bold mt-2">
                            System Role:
                          </h2>
                          <p className="block text-lg font-medium">
                            {userInfo?.role}
                          </p>
                          <h2 className="text-lg font-bold mt-2">Office:</h2>
                          <p className="block text-lg font-medium">
                            {userInfo?.office}
                          </p>
                        </Col>
                        <Col>
                          <h2 className="text-lg font-bold mt-2">
                            Supervisor:
                          </h2>
                          <p className="block text-lg font-medium">
                            {userInfo?.supervisor}
                          </p>
                          <h2 className="text-lg font-bold mt-2">Hire Date:</h2>
                          <p className="block text-lg font-medium">
                            {userInfo?.hireDate}
                          </p>
                          <h2 className="text-lg font-bold mt-2">Birthday:</h2>
                          <p className="block text-lg font-medium">
                            {userInfo?.birthday}
                          </p>
                        </Col>
                      </Row>
                    </Container>
                  </TabPanel>
                  {/* HR Notes Tab */}
                  <TabPanel value={value} index={1}>
                    {/* HR Notes */}
                    <section>
                      <h2 className="text-lg font-bold mt-3">
                        HR Notes for {userInfo?.first} {userInfo?.last}
                      </h2>
                      {displayNotes != true && (
                        <p>
                          Notes are automatically hidden for privacy.{" "}
                          <button onClick={() => setDisplayNotes(true)}>
                            Click here to display.
                          </button>
                        </p>
                      )}
                      {displayNotes === true && (
                        <>
                          <button onClick={() => setDisplayNotes(false)}>
                            Click here to hide notes.
                          </button>
                          <HumanResourcesNotes
                            selectedUserID={selectedUserID}
                          ></HumanResourcesNotes>
                        </>
                      )}
                    </section>
                  </TabPanel>
                  {/* Feedback Tab */}
                  <TabPanel value={value} index={2}>
                    <HumanResourcesFeedback selectedUserID={selectedUserID} />
                  </TabPanel>
                  {/* Development Tab */}
                  <TabPanel value={value} index={3}>
                    <h1 className="text-2xl font-bold text-red-900">Goals</h1>
                    <div className="p-3">
                      <HumanResourcesGoals selectedUserID={selectedUserID} />
                    </div>
                    <h1 className="text-2xl font-bold text-red-900">
                      Performance
                    </h1>
                    <div className="p-3">
                      <QuickPerformanceReview
                        userInfo={userInfo}
                        selectedUserID={selectedUserID}
                      />
                    </div>
                  </TabPanel>
                  {/* Training Tab */}
                  <TabPanel value={value} index={4}>
                    <HumanResourcesTraining selectedUserID={selectedUserID} />
                  </TabPanel>
                  {/* PTO Tab */}
                  <TabPanel value={value} index={5}>
                    {/* PTO Usage Table */}
                    <h2 className="font-bold text-red-900">PTO Usage:</h2>
                    <Table className="my-2 max-w-[400px] border-2 border-black">
                      <Thead>
                        <Tr className="font-medium bg-black text-white">
                          <Td>Total PTO</Td>
                          <Td>PTO Used</Td>
                          <Td>PTO Remaining</Td>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr className="border-2 border-black">
                          {!userInfo.pto ? (
                            <div>No PTO Record Found, Enter PTO Details</div>
                          ) : (
                            <>
                              <Td>{userInfo?.pto?.total}</Td>
                              <Td>{userInfo?.pto?.used}</Td>
                              <Td>
                                {userInfo?.pto?.total - userInfo.pto.used}
                              </Td>
                            </>
                          )}
                        </Tr>
                      </Tbody>
                    </Table>
                    <p className="block font-bold text-red-900">
                      PTO Reset Date:
                      <span className="text-black font-medium">
                        {userInfo?.hireDate}
                      </span>
                    </p>
                    <p className="mt-1 block font-bold text-red-900">
                      PTO Will Increase By:{" "}
                      <span className="text-black font-medium">
                        {userInfo?.ptoIncrease || ""}{" "}
                        {userInfo.ptoIncrease && "days"}
                      </span>
                    </p>
                    {displayRequests === false && (
                      <button
                        className="text-sky-600 mt-2"
                        onClick={() => {
                          setDisplayRequests(true);
                        }}
                      >
                        View PTO Requests
                      </button>
                    )}
                    {displayRequests === true && (
                      <>
                        <button
                          className="text-sky-600 mt-2"
                          onClick={() => {
                            setDisplayRequests(false);
                          }}
                        >
                          Hide PTO Requests
                        </button>

                        <PTOhistory selectedUserID={selectedUserID} />
                      </>
                    )}
                    <br />
                    {displayHistory === false && (
                      <button
                        className="text-sky-600"
                        onClick={() => {
                          setDisplayHistory(true);
                        }}
                      >
                        View PTO History
                      </button>
                    )}
                    {displayHistory === true && (
                      <>
                        <button
                          className="text-sky-600"
                          onClick={() => {
                            setDisplayHistory(false);
                          }}
                        >
                          Hide PTO History
                        </button>
                      </>
                    )}
                  </TabPanel>
                  {/* Rewards Tab */}
                  <TabPanel value={value} index={6}>
                    <p>Needs Content</p>
                  </TabPanel>
                </Box>
              </Container>
            )}
          </>
        )}
        {showRequests === true && (
          <>
            <h1 className="text-red-900 text-xl font-bold pt-3 mt-1 mb-1">
              PTO Requests
            </h1>
            <p className="mb-4">
              Approve and decline employee PTO requests. To edit or view an
              employee's PTO settings, visit their profile in the "Manage
              Employees" tab.
            </p>
            <PTORequests selectedUserID={selectedUserID} />
          </>
        )}
        {showApplicants === true && (
          <>
            <h1 className="text-red-900 text-xl font-bold pt-3 mt-1 mb-1">
              Manage Applicants
            </h1>
            <p className="mb-4">
              Manage applicants that applied through one of our online forms.
              Update Status to know where the applicant is in the process and
              Next Step to know what is next in the workflow.
            </p>

            <Applicants />
          </>
        )}
        {showCalendar === true && (
          <>
            <h1 className="text-red-900 text-xl font-bold pt-3 mt-1 mb-1">
              Coming Soon!
            </h1>
            <p className="mb-4">
              Check back for Google Calendar integrated scheduling interviews,
              events, and more that allow you to add items to the calendar in
              the system and see them on your Google Calendar.
            </p>
          </>
        )}
        {/* Job Listings */}
        {showJobListings === true && (
          <>
            <h1 className="text-red-900 text-xl font-bold pt-3 mt-1 mb-1">
              Job Listings
            </h1>
            <p className="mb-4">
              Manage which job listings appear on the Licona Insurance Group
              website and view data on the number and quality of applicants. A
              listing will appear on the website when it is in Active status.
            </p>
            <button
              onClick={() => {
                showNothing();
                setShowCreateJobListing(true);
              }}
              className="button"
            >
              Create New Listing
            </button>
            <JobListings />
          </>
        )}
        {showCreateJobListing === true && (
          <>
            <button
              onClick={() => {
                showNothing();
                setShowJobListings(true);
              }}
              className="text-sky-600 pt-3"
            >
              {"<"} Cancel & Go Back{" "}
            </button>
            <h1 className="text-red-900 text-xl font-bold mt-1 mb-1">
              New Job Listing
            </h1>
            <p className="mb-4">
              Fill out the following form to add a listing to the database. A
              listing will appear on the website when it is in Active status.
            </p>
            <NewJobListing
              setShowCreateJobListing={setShowCreateJobListing}
              setShowJobListings={setShowJobListings}
            />
          </>
        )}
      </Container>

      {/* Modal Stuff */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <input
              placeholder="Company"
              type="text"
              onChange={(e) =>
                setUserInfo({ ...userInfo, company: e.target.value })
              }
              className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
              value={userInfo?.company}
            />
            <input
              placeholder="Email"
              type="text"
              onChange={(e) =>
                setUserInfo({ ...userInfo, email: e.target.value })
              }
              className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
              value={userInfo?.email}
            />
            <input
              placeholder="Phone"
              onChange={(e) =>
                setUserInfo({ ...userInfo, phone: e.target.value })
              }
              type="text"
              className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
              value={userInfo?.phone}
            />
            <input
              placeholder="Extension"
              onChange={(e) =>
                setUserInfo({ ...userInfo, extension: e.target.value })
              }
              type="text"
              className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
              value={userInfo?.extension}
            />
            <input
              placeholder="Title"
              onChange={(e) =>
                setUserInfo({ ...userInfo, title: e.target.value })
              }
              type="text"
              className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
              value={userInfo?.title}
            />
            <select
              onChange={(e) =>
                setUserInfo({ ...userInfo, department: e.target.value })
              }
              value={userInfo?.department}
              className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
            >
              <option value="">Department</option>
              {departmentList.map((department) => {
                return (
                  <option value={department.name}>{department.name}</option>
                );
              })}
            </select>
            <input
              placeholder="Supervisor"
              onChange={(e) =>
                setUserInfo({ ...userInfo, supervisor: e.target.value })
              }
              type="text"
              className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
              value={userInfo?.supervisor}
            />
            <input
              placeholder="Office"
              onChange={(e) =>
                setUserInfo({ ...userInfo, office: e.target.value })
              }
              type="text"
              className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
              value={userInfo?.office}
            />
            <input
              placeholder="Hire Date"
              onChange={(e) =>
                setUserInfo({ ...userInfo, hireDate: e.target.value })
              }
              type="text"
              className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
              value={userInfo?.hireDate}
            />
            <input
              placeholder="Birthday"
              onChange={(e) =>
                setUserInfo({ ...userInfo, birthday: e.target.value })
              }
              type="text"
              className="w-full block text-lg font-medium bg-sky-200 mb-1 p-1"
              value={userInfo?.birthday}
            />
            <button
              className="w-full block text-lg font-medium bg-green-200 p-1"
              onClick={(e) => updateUserInfo(e, selectedUserID)}
            >
              Save Changes
            </button>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button className="rounded bg-red-900" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default HumanResourcesDashboard;
