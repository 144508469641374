import React, { useState, useEffect } from "react";
import { UserAuth } from "../../Context/AuthContent";
import { Link, useNavigate } from "react-router-dom";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Container, Row, Col } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { collection, query, where, getDocs, getDoc } from "firebase/firestore";
import UserInfo from "../user/dashboard/UserInfo";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import "../app.css";
import TimeOffRequest from "./TimeOffRequest";
import HumanResourcesDashboard from "./HumanResourcesDashboard";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Account() {

  const navigate = useNavigate();

  // Modal Stuff
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Page Stuff
  const { user } = UserAuth();
  const [loading, setLoading] = useState(true);
  const [tabular, setTabular] = useState(true);
  const userID = user.uid;

  // Check User's Role
  const [userInfo, setUserInfo] = useState({
    company: "",
    email: "",
    first: "",
    last: "",
    permissions: {},
    phone: "",
    userID: ""
  });
  const getRole = async (usersID) => {
    console.log("Getting User Info & Checking Role");
    const docRef = doc(db, "users", userID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUserInfo(docSnap.data());
      // if (userInfo.role != 'Admin') navigate('/account');
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  // Count User's Unread Messages
  const [messageCount, setMessageCount] = useState(0);
  const [doneCountingMessages, setDoneCountingMessages] = useState(false);
  const getMessageCount = async (usersID) => {
    console.log("Counting Unread messages");
    setLoading(true);
    const q = query(
      collection(db, "messages"),
      where("recipient", "==", usersID),
      where("status", "==", "Unread")
    );
    const queryData = await getDocs(q);
    const unread = queryData.docs.filter(
      (entry) => entry.data().status === "Unread"
    );
    setMessageCount(unread.length);
    setDoneCountingMessages(true);
    setLoading(false);
  };


  useEffect(() => {
    getRole(userID);
    
      
      if (doneCountingMessages === false) {
        getMessageCount(userID);
      }
      
  }, [db, userID, messageCount]);

  return (
    
    <Container className="h-screen bg-white" fluid>
      <Container className="bg-white h-100 overflow-none" fluid>
        {/* User Info */}
        <Container id="user-Info" className="mx-auto">
          <h1 className="text-2xl font-bold pt-3 mb-3">Dashboard</h1>
          <Row className="border-2 p-4 bg-gray-200">
            <Col sm="12" md="4" className="pb-4">
              <UserInfo userID={userID} />
            </Col>
            <Col sm="12" md="5" className="pb-4">
              <h1 className="pb-2 text-2xl font-bold">Alert Center</h1>
              {messageCount === 1 && (<>You have {messageCount} unread message.</>)}
              {messageCount > 1 && (<>You have {messageCount} unread messages.</>)}
              {messageCount === 0 && <p>No alerts at this time.</p>}
            </Col>
            <Col sm="12" md="3" className="pb-4">
            <button 
                onClick={handleOpen}
                className="border w-full block px-6 py-2 mr-5 text-center mb-2 bg-white text-sky-600"
              >
                PTO Request
              </button>
              <a
                href="/messages"
                className="border w-full block px-6 py-2 mr-5 text-center bg-white text-sky-600"
              >
                View Messages
              </a>
            </Col>
          </Row>
        </Container>
        {/* At A Glance */}
        <Container id="at-a-glance" className="mt-3 bg-white">
          <Row className="text-center">
            <Col sm="12" md="3" className="mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                className="bi bi-pencil-square block mx-auto mb-2 text-red-900"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fillRule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
              <p className="text-red-900">0 New Applications</p>
            </Col>
            <Col sm="12" md="3" className="mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                className="bi bi-folder-check block mx-auto mb-2 text-red-900"
                viewBox="0 0 16 16"
              >
                <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z" />
                <path d="M15.854 10.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.707 0l-1.5-1.5a.5.5 0 0 1 .707-.708l1.146 1.147 2.646-2.647a.5.5 0 0 1 .708 0z" />
              </svg>
              <p className="text-red-900">0 Pending Items</p>
            </Col>
            <Col sm="12" md="3" className="mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                className="bi bi-envelope-paper-heart block mx-auto mb-2 text-red-900"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v1.133l.941.502A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765L2 3.133V2Zm0 2.267-.47.25A1 1 0 0 0 1 5.4v.817l1 .6v-2.55Zm1 3.15 3.75 2.25L8 8.917l1.25.75L13 7.417V2a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v5.417Zm11-.6 1-.6V5.4a1 1 0 0 0-.53-.882L14 4.267v2.55ZM8 2.982C9.664 1.309 13.825 4.236 8 8 2.175 4.236 6.336 1.31 8 2.982Zm7 4.401-4.778 2.867L15 13.117V7.383Zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738ZM1 13.116l4.778-2.867L1 7.383v5.734Z"
                />
              </svg>
              <p className="text-red-900">{messageCount} Unread Messages</p>
            </Col>
            <Col sm="12" md="3" className="mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                className="bi bi-emoji-smile block mx-auto mb-2 text-red-900"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
              </svg>
              <p className="text-red-900">1250 Reward Tokens</p>
            </Col>
          </Row>
        </Container>

        {/* Human Resources Dashboard */}
        <HumanResourcesDashboard />
        

        {/* PTO Request Modal */}
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TimeOffRequest />
          <button
            onClick={handleClose}
            className="button-red mt-2 mb-2"
            >
            Close
            </button>
        </Box>
      </Modal>
      </Container>
    </Container>
  );
}

export default Account;
