import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  query,
  orderBy,
  setDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import "../app.css";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import { Chart } from "react-charts";
import { Col, Container, Row } from "react-bootstrap";

function valuetext(value) {
  return `${value}`;
}

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 70,
    label: "70",
  },
  {
    value: 80,
    label: "80",
  },
  {
    value: 90,
    label: "90",
  },
  {
    value: 100,
    label: "100",
  },
];

function QuickPerformanceReview(props) {
  // Props
  const selectedUserID = props.selectedUserID;
  // Date Stuff
  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  const [satisfactionValue, setSatisfactionValue] = React.useState(30);
  const [productivityValue, setProductivityValue] = React.useState(30);
  const [effortValue, setEffortValue] = React.useState(30);
  const [attitudeValue, setAttitudeValue] = React.useState(30);

  const handleChangeSatisfaction = (event, newValue) => {
    setSatisfactionValue(newValue);
  };

  const handleChangeProductivity = (event, newValue) => {
    setProductivityValue(newValue);
  };

  const handleChangeAttitude = (event, newValue) => {
    setAttitudeValue(newValue);
  };

  const handleChangeEffort = (event, newValue) => {
    setEffortValue(newValue);
  };

  // Get HR Notes
  const [PRlist, setPRlist] = useState([]);
  const [gotList, setGotList] = useState(false);
  const [listUpdated, setListUpdated] = useState(1);
  const getPRlist = async () => {
    const docRef = doc(db, "performance-reviews", selectedUserID);
    const docSnap = await getDoc(docRef);
    setPRlist([]);
    setGotList(false);
    if (docSnap.exists()) {
      console.log("Performance Review List:", docSnap.data());
      setPRlist(docSnap.data());
      if (docSnap.data().quickReviews) {
        setGotList(true);
      }
    } else {
      // doc.data() will be undefined in this case
      console.log("No Performance Review List Found");
    }
  };

  const updateReviews = async () => {
    await setDoc(doc(db, "performance-reviews", selectedUserID), PRlist);
    setListUpdated(listUpdated + 1);
  };

  const addReview = async () => {
    await setDoc(doc(db, "performance-reviews", selectedUserID), {
      quickReviews: [
        {
          date: date,
          reviewer: auth.currentUser.displayName,
          reviewerID: auth.currentUser.uid,
          reviewData: {
            satisfaction: satisfactionValue,
            productivity: productivityValue,
            attitude: attitudeValue,
            effort: effortValue,
          },
          comments: [],
        },
      ],
      annualReviews: [],
    });

    setListUpdated(listUpdated + 1);
  };

  //   Line Graph
  const [array, setArray] = useState([]);
  const [satisfactionData, setSatisfactionData] = useState([]);
  const [productivityData, setProductivityData] = useState([]);
  const [attitudeData, setAttitudeData] = useState([]);
  const [effortData, setEffortData] = useState([]);
  const [dateData, setDateData] = useState([]);

  const [dataState, setDataState] = useState([]);
  const data = React.useMemo(
    () => [
      {
        label: "Overall Performance",
        data: satisfactionData,
      },
      {
        label: "Productivity",
        data: productivityData,
      },
      {
        label: "Attitude",
        data: attitudeData,
      },
      {
        label: "Effort",
        data: effortData, // Array
      },
    ],
    []
  );

  const axes = React.useMemo(
    () => [
      { primary: true, type: "linear", position: "bottom" },
      { type: "linear", position: "left" },
    ],
    []
  );

  const [dataReady, setDataReady] = useState(false);
  const setData = () => {
    PRlist.quickReviews.forEach((PRobject, i) => {
      setDataReady(false);
      const effortArray = [i, PRobject.reviewData.effort];
      const attitudeArray = [i, PRobject.reviewData.attitude];
      const satisfactionArray = [i, PRobject.reviewData.satisfaction];
      const productivityArray = [i, PRobject.reviewData.productivity];

      effortData.push(effortArray);
      attitudeData.push(attitudeArray);
      satisfactionData.push(satisfactionArray);
      productivityData.push(productivityArray);
      dateData.push(PRobject.date);

      setDataReady(true);
      console.log("Pushing PR " + i);
      array.push(PRobject.reviewData.effort);
    });
    console.log(array);
  };

  const [newReview, setNewReview] = useState(false);
  const toggleNewReview = () => {
    if (newReview === false) {
      setNewReview(true);
    } else {
      setNewReview(false);
    }
  };

  const [newAnnualReview, setNewAnnualReview] = useState(false);
  const toggleNewAnnualReview = () => {
    if (newAnnualReview === false) {
      setNewAnnualReview(true);
    } else {
      setNewAnnualReview(false);
    }
  };

  const [history, setHistory] = useState(false);
  const toggleHistory = () => {
    if (history === false) {
      setHistory(true);
    } else {
      setHistory(false);
    }
  };

  const [graph, setGraph] = useState(false);
  const toggleGraph = () => {
    if (graph === false) {
      setGraph(true);
    } else {
      setGraph(false);
    }
  };

  const [showReviewRequest, setShowReviewRequest] = useState(false);
  const userInfo = props.userInfo;

  useEffect(() => {
    getPRlist().then(() => {
      if (dataReady === false) {
        setData();
      }
    });
  }, [db, selectedUserID, gotList, listUpdated, dataReady]);

  return (
    <section>
      {gotList === true && (
        <p className="mb-3">
          This employees last review was on {dateData[dateData.length - 1]}.
        </p>
      )}
      {/* Supervisor Review Request */}
      <div className="card mb-3">
        <div className="card-header">
          <button
            onClick={(e) => setShowReviewRequest(!showReviewRequest)}
            className="text-lg text-sky-600 font-bold"
          >
            Request A Review 
          </button>
        </div>
        {showReviewRequest && <div className="card-body"></div>}
      </div>
      {/* New Review */}
      <div className="card mb-3">
        <div className="card-header">
        <button
          className="text-lg text-sky-600 font-bold"
          onClick={toggleNewReview}
        >
          New Review
        </button>
        </div>
        {newReview === true && (
        <div className="card-body">
          <div>
            Overall Performance:
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="Satsifaction"
                defaultValue={0}
                value={satisfactionValue}
                onChange={handleChangeSatisfaction}
                valueLabelDisplay="auto"
                step={10}
                marks
                min={0}
                max={100}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            Productivity:
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="Productivity"
                defaultValue={0}
                value={productivityValue}
                onChange={handleChangeProductivity}
                valueLabelDisplay="auto"
                step={10}
                marks={marks}
                min={0}
                max={100}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            Attitude:
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="Attitude"
                defaultValue={0}
                value={attitudeValue}
                onChange={handleChangeAttitude}
                valueLabelDisplay="auto"
                step={10}
                marks={marks}
                min={0}
                max={100}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            Effort:
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <SentimentVeryDissatisfiedIcon />
              <Slider
                aria-label="Effort"
                defaultValue={0}
                value={effortValue}
                onChange={handleChangeEffort}
                valueLabelDisplay="auto"
                step={10}
                marks={marks}
                min={0}
                max={100}
              />
              <SentimentSatisfiedAltIcon />
            </Stack>
            <button
              onClick={(e) => {
                if (gotList === true) {
                  PRlist.quickReviews.push({
                    comments: [],
                    date,
                    reviewData: {
                      satisfaction: satisfactionValue,
                      productivity: productivityValue,
                      attitude: attitudeValue,
                      effort: effortValue,
                    },
                    reviewer: auth.currentUser.displayName,
                    reviewerID: auth.currentUser.uid,
                  });
                  updateReviews();
                  setNewReview(false);
                  setGotList(false);
                } else {
                  addReview();
                  setNewReview(false);
                }
              }}
              className="button mb-2"
            >
              Submit Review
            </button>
            <button
              onClick={() => setNewReview(false)}
              className="button-red mb-2 ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
        )}
      </div>
      {/* Review History */}
      <div className="card mb-3">
        <div className="card-header">
        <button
          className="text-lg font-bold text-sky-600"
          onClick={toggleHistory}
        >
          Review History
        </button>
        </div>
        {history === true && (
        <div className="card-body">
          <div>
            {/* Graph */}
            {graph === false && (
              <button
                className="text-md font-bold mb-1 text-sky-600"
                onClick={toggleGraph}
              >
                View History As Line Chart
              </button>
            )}
            {graph === true && (
              <>
                <button
                  className="text-md font-bold mb-1 text-sky-600"
                  onClick={toggleGraph}
                >
                  Hide Line Chart
                </button>
                {dataReady === true && (
                  <>
                    <div
                      style={{
                        width: "100%",
                        height: "400px",
                      }}
                    >
                      <Chart data={data} axes={axes} />
                    </div>
                    {/* Legend */}
                    <div>
                      Legend:
                      <Container>
                        <Row>
                          <Col>
                            <p className="graph-blue">– Overall Performance</p>
                            <p className="graph-red">– Productivity</p>
                          </Col>
                          <Col>
                            <p className="graph-yellow">– Attitude</p>
                            <p className="graph-green">– Effort</p>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </>
                )}
              </>
            )}
            {/* Review History */}
            <Table className="my-4 max-w-[1100px] mx-auto border-2 border-black">
              <Thead>
                <Tr className="font-medium bg-black text-white">
                  <Td>#</Td>
                  <Td>Date</Td>
                  <Td>Reviewer</Td>
                  <Td>Overall</Td>
                  <Td>Productivity</Td>
                  <Td>Attitude</Td>
                  <Td>Effort</Td>
                </Tr>
              </Thead>
              <Tbody>
                {gotList === true && (
                  <>
                    {PRlist.quickReviews.map((review, i) => {
                      return (
                        <Tr
                          key={"review" + i}
                          className="border-2 border-black"
                        >
                          <Td>{i + 1}</Td>
                          <Td>{review.date}</Td>
                          <Td>{review.reviewer}</Td>
                          <Td>{review.reviewData.satisfaction}</Td>
                          <Td>{review.reviewData.productivity}</Td>
                          <Td>{review.reviewData.attitude}</Td>
                          <Td>{review.reviewData.effort}</Td>
                        </Tr>
                      );
                    })}
                  </>
                )}
                {/* {gotList === true && (
                        <>
                        {PRlist.quickReviews.map((review, i) => (
                            <li className="mb-2">
                            <p className="font-bold">
                                Review {i + 1} by {review.reviewer} on {review.date}:
                            </p>
                            Overall Performance: {review.reviewData.satisfaction} 
                            {i > 0 && (<>{" "}({satisfactionData[i][1] - satisfactionData[i-1][1]})</>)}
                            <br />
                            Productivity: {review.reviewData.productivity}
                            {i > 0 && (<>{" "}({productivityData[i][1] - productivityData[i-1][1]})</>)}
                            <br />
                            Attitude: {review.reviewData.attitude}
                            {i > 0 && (<>{" "}({attitudeData[i][1] - attitudeData[i-1][1]})</>)}
                            <br />
                            Effort: {review.reviewData.effort}
                            {i > 0 && (<>{" "}({effortData[i][1] - effortData[i-1][1]})</>)}
                            </li>
                        ))}
                        </>
                    )} */}
              </Tbody>
            </Table>
          </div>
        </div>
        )}
      </div>
    </section>
  );
}

export default QuickPerformanceReview;
